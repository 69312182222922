import { FC } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Box, Link, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fDate } from '../../utils/formatTime';
import { PaymentsSourceFileAvatar } from '../../utils/stringAvatar';
import { CompletePaymentsSourceFile } from '../../ctx/PaymentsSourceFile.Context';
import { fEur } from '../../utils/formatNumber';

interface IListCampaignColumns {
  reloadData?: () => void;
  navigate: any;
  customCols?: GridColDef[];
}

interface IRowDef {
  row: CompletePaymentsSourceFile;
}

const paymentSourceFileColumns = ({
  reloadData,
  navigate,
  customCols = [],
}: IListCampaignColumns): GridColDef[] => [
  {
    field: 'caption',
    headerName: 'Názov',
    flex: 4,
    renderCell: ({ row }: IRowDef) => (
      <>
        <PaymentsSourceFileAvatar psf={row} />
        <Typography variant="body1" noWrap sx={{ ml: 1 }}>
          <Link
            onClick={() => navigate(`/payments/${row._id}`)}
            color="inherit"
            // sx={{ width: '100%' }}
          >
            {row.name}
          </Link>
        </Typography>
      </>
    ),
  },
  {
    field: 'from',
    headerName: 'od',
    flex: 1,
    renderCell: ({ row }: IRowDef) => <>{fDate(row.from)}</>,
  },
  {
    field: 'to',
    headerName: 'do',
    flex: 1,
    renderCell: ({ row }: IRowDef) => <>{fDate(row.to)}</>,
  },

  {
    field: 'crdt',
    headerName: 'Prijmy',
    flex: 1,
    renderCell: ({ row }: IRowDef) => (
      <>
        {row.crdtCount} ({fEur(row.crdtSum)})
      </>
    ),
  },
  {
    field: 'dbt',
    headerName: 'Vydaje',
    flex: 1,
    renderCell: ({ row }: IRowDef) => (
      <>
        {row.dbitCount} ({fEur(row.dbitSum)})
      </>
    ),
  },

  ...customCols,
];

interface IPaymentSourceFilesTable {
  paymentSourceFiles: Array<CompletePaymentsSourceFile>;
  reloadData?: () => void;
  customCols?: GridColDef[];
}

export const PaymentSourceFilesTable: FC<IPaymentSourceFilesTable> = ({
  paymentSourceFiles,
  reloadData,
  customCols,
}) => {
  const navigate = useNavigate();
  return (
    <DataGrid
      autoHeight
      columns={paymentSourceFileColumns({ reloadData, navigate, customCols })}
      rows={paymentSourceFiles.map((c) => ({ ...c, id: c._id }))}
    />
  );
};
