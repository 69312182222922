import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import QRCode from 'react-qr-code';
import {
  SubscriptionProvider,
  useSubscription,
} from '../../ctx/subscriptions/Subscription.Context';
import { PaymentsTable } from '../../components/payments/PaymentsTable';
import SubscriptionBasicInfoCard from '../../components/subscription/SubscriptionBasicInfoCard';
import ContactFullInfoCard from '../../components/contact/ContactFullInfoCard';
import SubscriptionExpectedPaymentsCard from '../../components/subscription/SubscriptionExpectedPaymentsCard';

const SubscriptionInfo: FC = () => {
  const { subscriptionData } = useSubscription();

  const paid = subscriptionData?.actualVariableState?.sumPaid;
  const total = subscriptionData?.expectedPayments.reduce((p, c) => p + c, 0);
  const percentPaid = Math.round((paid / total) * 100);

  return subscriptionData ? (
    <Grid container spacing={3}>
      <Grid item xs={12} container spacing={3}>
        <Grid item md={6}>
          <SubscriptionBasicInfoCard
            noContactCard
            subscriptionData={subscriptionData}
            title="Variabilný symbol"
          />
        </Grid>
        <Grid item md={2}>
          <Card>
            <CardContent>
              <QRCode
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={subscriptionData.qrCodeText}
                viewBox="0 0 256 256"
                size={256}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4}>
          <ContactFullInfoCard noLinks contactInfo={subscriptionData.payer} />
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={3}>
        <Grid item md={6}>
          <SubscriptionExpectedPaymentsCard noControls subscriptionData={subscriptionData} />
        </Grid>
        <Grid item md={6} xs={12}>
          <Card>
            <CardHeader
              title="Platby"
              subheader={
                <Grid container spacing={2} justifyContent="space-between">
                  <Grid item>
                    <Stack direction="column">
                      <Typography variant="caption">{`Očakávaných platieb: ${subscriptionData.actualState.expectedPayments}`}</Typography>
                      <Typography variant="caption">{`Zaplatených platieb: ${subscriptionData.actualState.payments}`}</Typography>
                      <Typography variant="caption">{`Odpustených platieb: ${subscriptionData.actualState.forgivedPayments}`}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={6} container>
                    <Grid item xs={12}>
                      <LinearProgress variant="determinate" value={percentPaid} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="text.secondary">
                        {paid}€ / {total}€
                      </Typography>
                      {/* </Box> */}
                    </Grid>
                  </Grid>
                </Grid>
              }
            />
            <CardContent>
              <PaymentsTable
                omit={['type', 'counterParty']}
                payments={subscriptionData.payments.map((p) => ({ ...p, id: p._id }))}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Skeleton />
  );
};

const PublicSubscription: FC = () => {
  const { subscriptionUrl } = useParams();

  return (
    <SubscriptionProvider url={subscriptionUrl} id={undefined}>
      <SubscriptionInfo />
    </SubscriptionProvider>
  );
};

export default PublicSubscription;
