import { Modal, Card } from '@mui/material';
import { FC } from 'react';
import { getWidth, IRightDrawer } from './RightDrawer';

const MyModal: FC<IRightDrawer> = (props) => {
  const { isOpened, handleClose, size = 'third' } = props;

  const width = getWidth(size);
  return (
    <Modal
      open={isOpened}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        {props.children}
      </Card>
    </Modal>
  );
};

export default MyModal;
