import { FC, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useNavigate } from 'react-router-dom';
import ImportFileStep from '../components/contacts/import/ImportFileStep';
import CheckContactsStep from '../components/contacts/import/CheckContactsStep';
import CheckDuplicitesStep from '../components/contacts/import/CheckDuplicitesStep';
import { TagsProvider } from '../ctx/tags/Tags.Context';
import ImportSummary from '../components/contacts/import/ImportSummary';

const steps = [
  'Zvoľte typ kontaktov a nahrajte csv súbor',
  'Skontrolujte kontakty',
  'Skontrolujte duplicity a pridajte tagy',
];

export interface IImportContactsStep {
  next: () => void;
  back?: () => void;
}

enum ImportStepsEnum {
  IMPORT_FILE,
  CHECK_CONTACTS,
  CHECK_DUPLICITIES,
  SUMMARY,
}

export const HorizontalLabelPositionBelowStepper = ({ activeStep }: { activeStep: number }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

const ImportContacts: FC = () => {
  const [activeStep, setActiveStep] = useState<ImportStepsEnum>(ImportStepsEnum.IMPORT_FILE);
  const navigate = useNavigate();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} container>
        <Grid item>
          <Typography variant="h5">Importovať kontakty</Typography>
        </Grid>
        <Grid item xs>
          <HorizontalLabelPositionBelowStepper activeStep={activeStep} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {activeStep === ImportStepsEnum.IMPORT_FILE && (
          <ImportFileStep
            next={() => {
              setActiveStep(ImportStepsEnum.CHECK_CONTACTS);
            }}
          />
        )}
        {activeStep === ImportStepsEnum.CHECK_CONTACTS && (
          <CheckContactsStep
            next={() => {
              setActiveStep(ImportStepsEnum.CHECK_DUPLICITIES);
            }}
            back={() => {
              setActiveStep(ImportStepsEnum.IMPORT_FILE);
            }}
          />
        )}
        {activeStep === ImportStepsEnum.CHECK_DUPLICITIES && (
          <TagsProvider>
            <CheckDuplicitesStep
              next={() => {
                setActiveStep(ImportStepsEnum.SUMMARY);
              }}
              back={() => {
                setActiveStep(ImportStepsEnum.CHECK_CONTACTS);
              }}
            />
          </TagsProvider>
        )}
        {activeStep === ImportStepsEnum.SUMMARY && (
          <ImportSummary
            next={() => {
              navigate('/contacts');
            }}
            back={() => {
              setActiveStep(ImportStepsEnum.IMPORT_FILE);
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ImportContacts;
