import { FC } from 'react';
import { useRoutes } from 'react-router-dom';
// layouts
import AuthorizedLayout from './layouts/AuthorizedLayout';
import UnauthorizedLayout from './layouts/UnauthorizedLayout';
//
import Dashboard from './pages/Dashboard';
import Requests from './pages/Requests';
import Login from './pages/Login';
import Settings from './pages/Settings';
import Claims from './pages/Claims';
import { ClaimsProvider } from './ctx/Claims.Context';
import Claim from './pages/Claim';
import PaymentProcess from './pages/PaymentProcess';
import { PaymentProcessProvider } from './ctx/PaymentProcess.Context';
import Contacts from './pages/Contacts';
import { ContactsProvider } from './ctx/contacts/Contacts.Context';
import { ImportContactsProvider } from './ctx/contacts/ImportContacts.Context';
import ImportContacts from './pages/ImportContacts';
import { TagsProvider } from './ctx/tags/Tags.Context';
import Tags from './pages/Tags';
import Contact from './pages/Contact';
import { SubscriptionsProvider } from './ctx/subscriptions/Subscriptions.Context';
import ListCampaign from './pages/ListCampaign';
import { ListCampaignProvider } from './ctx/listCampaigns/ListCampaign.context';
import ListCampaigns from './pages/ListCampaigns';
import { ListCampaignsProvider } from './ctx/listCampaigns/ListCampaigns.Context';
import Subscription from './pages/Subscription';
import SubscriptionTypes from './pages/SubscriptionTypes';
import SubscriptionType from './pages/SubscriptionType';
import { PaymentSourceFilesProvider } from './ctx/paymentSourceFiles/PaymentSourceFiles.Context';
import PaymentSourceFiles from './pages/PaymentSourceFiles';
import PaymentSourceFile from './pages/PaymentSourceFile';
import { StockItemsProvider } from './ctx/stockItems/StockItems.Context';
import StockItems from './pages/StockItems';
import PublicLayout from './layouts/PublicLayout';
import PublicSubscription from './pages/public/PublicSubscription';
import NotFound from './pages/NotFound';
import Lyduska from './pages/public/lyduska/Lyduska';
import { LyduskaProvider } from './pages/public/lyduska/Lyduska.Context';
import Orders from './pages/Orders';
import { OrdersProvider } from './ctx/order/Orders.Context';

// ----------------------------------------------------------------------

const Router: FC = () => {
  return useRoutes([
    {
      path: '/',
      element: <AuthorizedLayout />,
      children: [
        { path: '', element: <Dashboard /> },
        { path: 'settings', element: <Settings /> },
        { path: 'requests', element: <Requests /> },
        {
          path: 'tags',
          element: (
            <TagsProvider>
              <Tags />
            </TagsProvider>
          ),
        },
        { path: 'contacts/:id', element: <Contact /> },
        {
          path: 'contacts',
          element: (
            <ContactsProvider>
              <Contacts />
            </ContactsProvider>
          ),
        },
        {
          path: 'import/contacts',
          element: (
            <ImportContactsProvider>
              <ImportContacts />
            </ImportContactsProvider>
          ),
        },
        {
          path: 'payments',
          element: (
            <PaymentSourceFilesProvider>
              <PaymentSourceFiles />
            </PaymentSourceFilesProvider>
          ),
        },
        {
          path: 'payments/:id',
          element: <PaymentSourceFile />,
        },
        {
          path: 'import/payments',
          element: (
            <PaymentProcessProvider>
              <PaymentProcess />
            </PaymentProcessProvider>
          ),
        },
        { path: 'claims/:id', element: <Claim /> },
        {
          path: 'claims',
          element: (
            <ClaimsProvider>
              <Claims />
            </ClaimsProvider>
          ),
        },
        {
          path: 'orders',
          element: (
            <OrdersProvider>
              <Orders />
            </OrdersProvider>
          ),
        },
        { path: 'subscriptiontypes/:id/:subscriptionId', element: <Subscription /> },
        // {
        //   path: 'subscriptions',
        //   element: (
        //     <SubscriptionsProvider>
        //       <Subscriptions />
        //     </SubscriptionsProvider>
        //   ),
        // },
        { path: 'subscriptiontypes/:id', element: <SubscriptionType /> },
        {
          path: 'subscriptiontypes',
          element: (
            <SubscriptionsProvider>
              <SubscriptionTypes />
            </SubscriptionsProvider>
          ),
        },
        {
          path: 'stockitems',
          element: (
            <StockItemsProvider>
              <StockItems />
            </StockItemsProvider>
          ),
        },
        { path: 'listcampaigns/:id', element: <ListCampaign /> },
        {
          path: 'listcampaigns',
          element: (
            <ListCampaignsProvider>
              <ListCampaigns />
            </ListCampaignsProvider>
          ),
        },
      ],
    },
    {
      path: '/login',
      element: <UnauthorizedLayout />,
      children: [{ path: '', element: <Login /> }],
    },
    {
      path: '/public',
      element: <PublicLayout />,
      children: [{ path: 'subscription/:subscriptionUrl', element: <PublicSubscription /> }],
    },
    {
      path: '/lyduska',
      element: (
        <LyduskaProvider>
          <Lyduska />
        </LyduskaProvider>
      ),
    },
    // { path: '*', element: <NotFound /> },
  ]);
};

export default Router;
