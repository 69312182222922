import { Card, CardContent } from '@mui/material';
import { cloneElement, FC, ReactElement } from 'react';
import Popper from '@mui/material/Popper';
import { usePopupState, bindHover, bindPopper } from 'material-ui-popup-state/hooks';
import Fade from '@mui/material/Fade';
import { BasicContactInfo } from '../../ctx/Claims.Context';
import ContactBasicInfoCard from './ContactBasicInfoCard';

interface IContactBasicInfoPoper {
  basicContactInfoData: BasicContactInfo;
  rootElement: ReactElement;
}

const ContactBasicInfoPoper: FC<IContactBasicInfoPoper> = ({
  basicContactInfoData,
  rootElement,
}) => {
  const popupState = usePopupState({ variant: 'popper', popupId: 'demoPopper' });

  return (
    <>
      {cloneElement(rootElement, { ...bindHover(popupState) })}
      <Popper {...bindPopper(popupState)} transition style={{ zIndex: 10000 }}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Card
              sx={{
                backgroundColor: 'rgba(255,255,255,0.8)',
                backdropFilter: 'blur(5px)',
                zIndex: 100,
                maxWidth: 300,
              }}
            >
              <CardContent>
                <ContactBasicInfoCard basicContactInfoData={basicContactInfoData} />
              </CardContent>
            </Card>
          </Fade>
        )}
      </Popper>
    </>
  );
};
export default ContactBasicInfoPoper;
