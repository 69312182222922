import { FC, useState } from 'react';
// material
import { styled, alpha } from '@mui/material/styles';
import {
  Input,
  Slide,
  Box,
  Button,
  InputAdornment,
  ClickAwayListener,
  IconButton,
  Stack,
  TextField,
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FuzzyContactSearcher from '../../components/contact/FuzzyContactSearcher';
import ContactFullInfoCard from '../../components/contact/ContactFullInfoCard';
import { ContactsProvider, useContacts } from '../../ctx/contacts/Contacts.Context';
// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(0, 3),
  // eslint-disable-next-line @typescript-eslint/dot-notation
  boxShadow: theme['customShadows'].z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

const SearchbarWrapped = () => {
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [query, setQuery] = useState('');
  const { pullFuzzyContacts, currentContactsFuzzyWithoutOmited } = useContacts();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);

    pullFuzzyContacts(event.target.value);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box sx={{ width: '100%' }}>
        {!isOpen && (
          <IconButton onClick={handleOpen}>
            <SearchIcon />
          </IconButton>
        )}

        <Slide direction="right" in={isOpen}>
          <SearchbarStyle>
            <TextField
              fullWidth
              id="outlined-name"
              autoComplete="off"
              inputProps={{
                autoComplete: 'off',
              }}
              label="Hľadať kontakt..."
              value={query}
              onChange={handleChange}
            />
          </SearchbarStyle>
        </Slide>
        {isOpen && (
          <Card
            sx={{
              width: '100%',
              top: `${APPBAR_DESKTOP}px`,
              left: 0,
              // position: 'fixed',
              bgcolor: 'background.paper',
            }}
          >
            <CardContent>
              <Stack direction="column" sx={{ width: '100%' }} spacing={3}>
                {currentContactsFuzzyWithoutOmited.map((c) => (
                  <ContactFullInfoCard
                    key={`found-${c._id}`}
                    contactInfo={c}
                    onRedirect={handleClose}
                  />
                ))}
                {currentContactsFuzzyWithoutOmited.length === 0 && (
                  <Typography variant="h6">Žiadne výsledky</Typography>
                )}
              </Stack>
            </CardContent>
          </Card>
        )}
      </Box>
    </ClickAwayListener>
  );
};

const Searchbar: FC = () => (
  <ContactsProvider>
    <SearchbarWrapped />
  </ContactsProvider>
);

export default Searchbar;
