import { FC, useState } from 'react';
import { Box, Button, Card, Grid, IconButton, Stack, Typography } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import { Close, Delete } from '@mui/icons-material';
import SubscriptionTypeForm from '../components/subscriptions/forms/SubscriptionTypeForm';
import RightDrawer from '../components/RightDrawer';
import { useSubscriptions } from '../ctx/subscriptions/Subscriptions.Context';
import { SubscriptionTypesTable } from '../components/subscriptions/SubscriptionTypesTable';
import AcceptRejectButtonPair from '../components/AcceptRejectButtonPair';
import MyModal from '../components/MyModal';
import SubscriptionTypeInfoCard from '../components/subscriptions/SubscriptionTypeInfoCard';

const SubscriptionTypes: FC = () => {
  const [addingSubscriptionType, setAddingSubscriptionType] = useState<boolean>(false);
  const handleStartAddingSubscriptionType = () => setAddingSubscriptionType(true);
  const handleStopAddingSubscriptionType = () => setAddingSubscriptionType(false);

  const { subscriptionTypes, submitNewSubscriptionType, deleteSubscriptionType } =
    useSubscriptions();

  const [subscriptionTypeIdToBeDeleted, setSubscriptionTypeIdToBeDeleted] = useState<string>();
  const subscriptionTypeToBeDeleted = subscriptionTypes.find(
    (f) => f._id === subscriptionTypeIdToBeDeleted
  );
  const handleStartRemovingSubscriptionType = (id: string) => {
    setSubscriptionTypeIdToBeDeleted(id);
  };
  const handleStopRemovingSubscriptionType = () => {
    setSubscriptionTypeIdToBeDeleted(undefined);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} spacing={2} container>
          <Grid item>
            <Typography variant="h4">Predplatné</Typography>
          </Grid>
          <Grid item xs />
          <Grid item>
            <Button
              sx={{ mr: 1 }}
              onClick={handleStartAddingSubscriptionType}
              variant="outlined"
              startIcon={<AddIcon />}
            >
              Pridať typ predplatného
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <SubscriptionTypesTable
              subscriptionTypes={subscriptionTypes}
              customCols={[
                {
                  field: 'delete',
                  headerName: '',
                  renderCell: ({ row }) => (
                    <IconButton onClick={() => handleStartRemovingSubscriptionType(row._id)}>
                      <Delete />
                    </IconButton>
                  ),
                },
              ]}
            />
          </Card>
        </Grid>
      </Grid>
      <RightDrawer isOpened={addingSubscriptionType} handleClose={handleStopAddingSubscriptionType}>
        <Stack sx={{ m: 3 }} spacing={3}>
          <Box>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Vytvoriť typ predplatného</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleStopAddingSubscriptionType}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <SubscriptionTypeForm
            onConfirm={(data) => {
              submitNewSubscriptionType(data);
              handleStopAddingSubscriptionType();
            }}
            autoSubmit={false}
            requiredFields={['caption', 'prefix', 'dayInMonth', 'price']}
          />
        </Stack>
      </RightDrawer>
      <MyModal
        isOpened={!!subscriptionTypeToBeDeleted}
        handleClose={handleStopRemovingSubscriptionType}
      >
        {subscriptionTypeToBeDeleted && (
          <Stack direction="column" spacing={3}>
            <Typography variant="h5">
              Chystáte sa vymazať typ predplatného a všetky predplatné v ňom
            </Typography>
            <SubscriptionTypeInfoCard {...subscriptionTypeToBeDeleted} />
            <Stack direction="row-reverse" spacing={1}>
              <AcceptRejectButtonPair
                onConfirm={() => deleteSubscriptionType(subscriptionTypeToBeDeleted._id)}
                onReject={handleStopRemovingSubscriptionType}
              />
            </Stack>
          </Stack>
        )}
      </MyModal>
    </>
  );
};

export default SubscriptionTypes;
