import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFeedback } from '../Feedback';
import { useApi } from '../Api';
import { CompleteSubscription } from './Subscriptions.Context';

export interface EmailOptions {
  from: string;
  to: string;
  replyTo: string;
  subject: string;
  text: string;
}

export interface Email {
  options: EmailOptions;
  sent?: Date;
}

interface ISubscriptionContext {
  subscriptionData?: CompleteSubscription;
  pullData: () => Promise<CompleteSubscription>;
  editSubscription: (newData: CompleteSubscription) => Promise<CompleteSubscription>;
  deleteSubscription: () => Promise<void>;
  addExpectedPaymentToSubscription: (payment: number) => Promise<CompleteSubscription>;
  updateCurrentExpectedPaymentInSubscription: (payment: number) => Promise<CompleteSubscription>;
  updateExpectedPaymentInSubscription: (
    idx: number,
    payment: number
  ) => Promise<CompleteSubscription>;
  removeExpectedPaymentFromSubscription: (idx: number) => Promise<CompleteSubscription>;
  previewEmailForCurrentMonth: () => Promise<Email>;
  sendEmailForCurrentMonth: (email: EmailOptions) => Promise<CompleteSubscription>;
}

const SubscriptionContext = createContext<ISubscriptionContext>({
  subscriptionData: undefined,
  pullData: () => undefined,
  editSubscription: () => undefined,
  deleteSubscription: () => undefined,
  addExpectedPaymentToSubscription: () => undefined,
  updateCurrentExpectedPaymentInSubscription: () => undefined,
  updateExpectedPaymentInSubscription: () => undefined,
  removeExpectedPaymentFromSubscription: () => undefined,
  previewEmailForCurrentMonth: () => undefined,
  sendEmailForCurrentMonth: () => undefined,
});

export const useSubscription = () => {
  const context = useContext(SubscriptionContext);
  if (!context) {
    throw new Error('Parent must be wrapped inside SubscriptionProvider');
  }
  return context;
};

interface ISubscriptionProvider {
  id: string;
  url?: string;
  dataProvider?: boolean;
  initialData?: CompleteSubscription;
}

export const SubscriptionProvider: FC<ISubscriptionProvider> = ({
  id,
  url,
  dataProvider = true,
  children,
  initialData,
}) => {
  const { API, defaultErrorHandle } = useApi();
  const { error, success } = useFeedback();
  const navigate = useNavigate();
  const [subscriptionData, setSubscriptionData] = useState<CompleteSubscription>(initialData);

  const expectedPayments = subscriptionData?.expectedPayments;
  const currentPaymentIndex = (subscriptionData?.actualState.expectedPayments || 0) - 1;

  const pullData = useCallback(async (): Promise<CompleteSubscription> => {
    console.log('pulling subscription');
    if (!API) return undefined;
    try {
      if (url) {
        const res = await API.get(`subscriptions/byurlid/${url}`);
        console.log(res);
        if (dataProvider) setSubscriptionData(res.data);
        return res.data;
      }
      const res = await API.get(`subscriptions/${id}`);
      console.log(res);
      if (dataProvider) setSubscriptionData(res.data);
      return res.data;
    } catch (e: any) {
      defaultErrorHandle(e);
      return undefined;
    }
  }, [API, dataProvider, defaultErrorHandle, id, url]);

  const editSubscription = useCallback(
    async (newData: CompleteSubscription): Promise<CompleteSubscription> => {
      if (!API) return undefined;
      try {
        const res = await API.put(`subscriptions/${id}`, newData);
        console.log(res);
        if (dataProvider) setSubscriptionData(res.data);
        return res.data;
      } catch (e: any) {
        defaultErrorHandle(e);
        return undefined;
      }
    },
    [API, dataProvider, defaultErrorHandle, id]
  );

  const deleteSubscription = useCallback(async (): Promise<void> => {
    if (!API) return;
    try {
      const res = await API.delete(`subscriptions/${id}`);
      console.log(res);
      success('Predplatné zmazané.');
      navigate('/subscriptions');
    } catch (e: any) {
      defaultErrorHandle(e);
    }
  }, [API, defaultErrorHandle, id, navigate, success]);

  const editSubscriptionExpectedPayments = useCallback(
    async (newExpectedPayments: number[]): Promise<CompleteSubscription> => {
      if (!API) return undefined;
      try {
        const res = await API.put(`subscriptions/${id}/expectedpayments`, {
          expectedPayments: newExpectedPayments,
        });
        console.log(res);
        if (dataProvider) setSubscriptionData(res.data);
        return res.data;
      } catch (e: any) {
        defaultErrorHandle(e);
        return undefined;
      }
    },
    [API, dataProvider, defaultErrorHandle, id]
  );

  const addExpectedPaymentToSubscription = useCallback(
    (payment: number, idx: number = expectedPayments.length) => {
      const p = [...expectedPayments];
      p.splice(idx, 0, payment);
      return editSubscriptionExpectedPayments([...p]);
    },
    [editSubscriptionExpectedPayments, expectedPayments]
  );

  const removeExpectedPaymentFromSubscription = useCallback(
    (idx: number) => {
      const p = [...expectedPayments];
      p.splice(idx, 1);
      return editSubscriptionExpectedPayments([...p]);
    },
    [editSubscriptionExpectedPayments, expectedPayments]
  );

  const updateExpectedPaymentInSubscription = useCallback(
    (idx: number, payment: number) => {
      const p = [...expectedPayments];
      p.splice(idx, 1, payment);
      return editSubscriptionExpectedPayments([...p]);
    },
    [editSubscriptionExpectedPayments, expectedPayments]
  );

  const updateCurrentExpectedPaymentInSubscription = useCallback(
    (payment: number) => {
      const p = [...expectedPayments];
      p.splice(currentPaymentIndex, 1, payment);
      return editSubscriptionExpectedPayments([...p]);
    },
    [currentPaymentIndex, editSubscriptionExpectedPayments, expectedPayments]
  );
  const previewEmailForCurrentMonth = useCallback(async (): Promise<Email> => {
    if (!API) return undefined;
    try {
      const res = await API.get(`subscriptions/${id}/previewEmailForCurrentMonth`);
      console.log(res);
      return res.data;
    } catch (e: any) {
      defaultErrorHandle(e);
      return undefined;
    }
  }, [API, defaultErrorHandle, id]);

  const sendEmailForCurrentMonth = useCallback(
    async (emailOptions: EmailOptions): Promise<CompleteSubscription> => {
      if (!API) return undefined;
      try {
        const res = await API.post(`subscriptions/${id}/sendEmailForCurrentMonth`, emailOptions);
        console.log(res);
        if (dataProvider) setSubscriptionData(res.data);
        return res.data;
      } catch (e: any) {
        defaultErrorHandle(e);
        return undefined;
      }
    },
    [API, dataProvider, defaultErrorHandle, id]
  );

  useEffect(() => {
    if (dataProvider) pullData();
  }, [dataProvider, pullData]);

  const contextObjects = useMemo(
    () => ({
      subscriptionData,
      pullData,
      editSubscription,
      deleteSubscription,
      addExpectedPaymentToSubscription,
      updateExpectedPaymentInSubscription,
      removeExpectedPaymentFromSubscription,
      updateCurrentExpectedPaymentInSubscription,
      previewEmailForCurrentMonth,
      sendEmailForCurrentMonth,
    }),
    [
      subscriptionData,
      pullData,
      editSubscription,
      deleteSubscription,
      addExpectedPaymentToSubscription,
      updateExpectedPaymentInSubscription,
      removeExpectedPaymentFromSubscription,
      updateCurrentExpectedPaymentInSubscription,
      previewEmailForCurrentMonth,
      sendEmailForCurrentMonth,
    ]
  );

  return (
    <SubscriptionContext.Provider value={contextObjects}>{children}</SubscriptionContext.Provider>
  );
};
