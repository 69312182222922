import { AttachMoney, Circle, Money, MoneyOff } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Grid, IconButton, Stack, Tab } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ClaimGroupsProvider, useClaimGroups } from '../../../ctx/claimGroups/ClaimGroups.Context';
import { useContactFilters } from '../../../ctx/contacts/ContactFilters.Context';
import { CompleteTag, TagsProvider, useTags } from '../../../ctx/tags/Tags.Context';
import TagsGrid from '../../tag/TagsGrid';

const getOnlyNecessaryTags = (tags: CompleteTag[]): CompleteTag[] => {
  const toRemove = tags.flatMap((t) => t.children.map((ch) => ch._id));
  return tags.filter((t) => !toRemove.includes(t._id));
};

const TagTab: FC = () => {
  const { selectedComlete, selected, selectedNotComlete, selectNotAll, tagData } = useTags();
  const { setTagFilter, tagFilter } = useContactFilters();

  useEffect(() => {
    setTagFilter((p) => ({
      ...p,
      filters: [
        ...getOnlyNecessaryTags(selectedComlete).map((tag) => ({
          tagId: tag._id,
          value: true,
          tag,
        })),
        ...getOnlyNecessaryTags(selectedNotComlete).map((tag) => ({
          tagId: tag._id,
          value: false,
          tag,
        })),
      ],
    }));
    setTagFilter((p) => p);
  }, [selectedComlete, selectedNotComlete, setTagFilter]);

  return (
    <Stack direction="column" spacing={1}>
      <Box>
        <TagsGrid
          binarySelect={false}
          editingTags={false}
          defaultExpanded
          defaultSlected={tagFilter.filters.map((f) => f.tagId)}
        />
      </Box>
    </Stack>
  );
};

const ClaimGroupTab: FC = () => {
  const { setClaimGroupFilter, claimGroupFilter } = useContactFilters();
  const { claimGroups } = useClaimGroups();

  return (
    <Stack direction="column" spacing={1}>
      <Box sx={{ width: '100%' }}>
        <Grid container>
          {claimGroups.map((cg) => {
            const myIdx = claimGroupFilter.filters.findIndex((cgf) => cgf.claimGroupId === cg._id);
            const myVal = claimGroupFilter.filters.find(
              (cgf) => cgf.claimGroupId === cg._id
            )?.value;

            return (
              <Grid item container key={`cg-filter-${cg._id}`}>
                <Grid item>{cg.title}</Grid>
                <Grid item xs />
                <Grid item>
                  <IconButton
                    title="má"
                    color={myIdx > -1 && myVal === undefined ? 'secondary' : 'primary'}
                    onClick={() => {
                      const newFilters = [...claimGroupFilter.filters];

                      if (myIdx > -1 && myVal === undefined) {
                        newFilters.splice(myIdx, 1);
                      } else if (myIdx > -1) {
                        newFilters.splice(myIdx, 1, {
                          claimGroupId: cg._id,
                          claimGroup: cg,
                          value: undefined,
                        });
                      } else {
                        newFilters.push({ claimGroupId: cg._id, claimGroup: cg, value: undefined });
                      }
                      setClaimGroupFilter({ ...claimGroupFilter, filters: newFilters });
                    }}
                  >
                    <Circle />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    title="má zaplatenú"
                    color={myIdx > -1 && myVal === true ? 'secondary' : 'primary'}
                    onClick={() => {
                      const newFilters = [...claimGroupFilter.filters];

                      if (myIdx > -1 && myVal === true) {
                        newFilters.splice(myIdx, 1);
                      } else if (myIdx > -1) {
                        newFilters.splice(myIdx, 1, {
                          claimGroupId: cg._id,
                          claimGroup: cg,
                          value: true,
                        });
                      } else {
                        newFilters.push({ claimGroupId: cg._id, claimGroup: cg, value: true });
                      }
                      setClaimGroupFilter({ ...claimGroupFilter, filters: newFilters });
                    }}
                  >
                    <AttachMoney />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    title="má nezaplatenú"
                    color={myIdx > -1 && myVal === false ? 'secondary' : 'primary'}
                    onClick={() => {
                      const newFilters = [...claimGroupFilter.filters];

                      if (myIdx > -1 && myVal === false) {
                        newFilters.splice(myIdx, 1);
                      } else if (myIdx > -1) {
                        newFilters.splice(myIdx, 1, {
                          claimGroupId: cg._id,
                          claimGroup: cg,
                          value: false,
                        });
                      } else {
                        newFilters.push({ claimGroupId: cg._id, claimGroup: cg, value: false });
                      }
                      setClaimGroupFilter({ ...claimGroupFilter, filters: newFilters });
                    }}
                  >
                    <MoneyOff />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Stack>
  );
};

const ContactFiltersSelector: FC = () => {
  const [tab, setTab] = useState('1');
  const { setTagFilter, tagFilter } = useContactFilters();

  const handleChangeTab = (_: React.SyntheticEvent, newTab: string) => {
    setTab(newTab);
  };

  return (
    <Grid container spacing={2}>
      <TabContext value={tab}>
        <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab} aria-label="lab tabs filters">
              <Tab label="Tagy" value="1" />
              <Tab label="Listy" value="2" disabled />
              <Tab label="Sk. pohľadávok" value="3" />
            </TabList>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TabPanel value="1" sx={{ p: 0 }}>
            <TagsProvider
              initialSelected={tagFilter.filters.flatMap((f) => (f.value ? [f.tagId] : []))}
              initialSelectedNot={tagFilter.filters.flatMap((f) => (!f.value ? [f.tagId] : []))}
            >
              <TagTab />
            </TagsProvider>
          </TabPanel>
        </Grid>
        <TabPanel value="2">{/* tba */}</TabPanel>
        <TabPanel value="3" sx={{ p: 0, width: '100%' }}>
          <ClaimGroupsProvider initialData={[]}>
            <ClaimGroupTab />
          </ClaimGroupsProvider>
        </TabPanel>
      </TabContext>
    </Grid>
  );
};

export default ContactFiltersSelector;
