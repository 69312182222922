import { FC } from 'react';

import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';

import { ApiProvider } from './ctx/Api';
import { FeedbackProvider } from './ctx/Feedback';
import Router from './routes';

const App: FC = () => {
  return (
    <FeedbackProvider>
      <ApiProvider>
        <ThemeConfig>
          <GlobalStyles />
          <Router />
        </ThemeConfig>
      </ApiProvider>
    </FeedbackProvider>
  );
};

export default App;
