import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useApi } from '../Api';
import { ClaimGroup } from '../Claims.Context';

interface IClaimGroupsContext {
  claimGroups?: ClaimGroup[];
  pullData: () => Promise<ClaimGroup[]>;
}

const ClaimGroupsContext = createContext<IClaimGroupsContext>({
  claimGroups: undefined,
  pullData: () => undefined,
});

export const useClaimGroups = () => {
  const context = useContext(ClaimGroupsContext);
  if (!context) {
    throw new Error('Parent must be wrapped inside ClaimGroupsProvider');
  }

  return context;
};

interface IClaimGroupsProvider {
  // tba
  dataProvider?: boolean;
  initialData?: ClaimGroup[];
}

export const ClaimGroupsProvider: FC<IClaimGroupsProvider> = ({
  dataProvider = true,
  children,
  initialData,
}) => {
  const { API, defaultErrorHandle } = useApi();

  const [claimGroups, setClaimGroups] = useState<ClaimGroup[]>(initialData);

  const pullData = useCallback(async (): Promise<ClaimGroup[]> => {
    console.log('pulling claim groups');
    try {
      const res = await API.get(`/claimGroups`);
      if (dataProvider) setClaimGroups(res.data);
      return res.data;
    } catch (e: any) {
      defaultErrorHandle(e);
      return undefined;
    }
  }, [API, dataProvider, defaultErrorHandle]);

  useEffect(() => {
    if (dataProvider) pullData();
  }, [dataProvider, pullData]);

  const contextObjects = useMemo(
    () => ({
      claimGroups,
      pullData,
    }),
    [pullData, claimGroups]
  );

  return (
    <ClaimGroupsContext.Provider value={contextObjects}>{children}</ClaimGroupsContext.Provider>
  );
};
