import { FC, useState } from 'react';
import { Button, Card, Grid, IconButton, Stack, Typography } from '@mui/material';

import { Add, Delete, Edit } from '@mui/icons-material';
import { useStockItems } from '../ctx/stockItems/StockItems.Context';
import AcceptRejectButtonPair from '../components/AcceptRejectButtonPair';
import MyModal from '../components/MyModal';
import StockItemInfoCard from '../components/stockItems/StockItemInfoCard';
import { StockItemsTable } from '../components/stockItems/StockItemsTable';
import RightDrawer from '../components/RightDrawer';
import StockItemForm from '../components/stockItems/forms/StockItemForm';

const StockItems: FC = () => {
  const { stockItemsData, deleteStockItem, createStockItem, updateStockItem } = useStockItems();

  const [addingStockItem, setAddingStockItem] = useState<boolean>(false);
  const handleStartAddingStockItem = () => setAddingStockItem(true);
  const handleStopAddingStockItem = () => setAddingStockItem(false);

  const [stockItemIdToBeDeleted, setStockItemIdToBeDeleted] = useState<string>();
  const stockItemToBeDeleted = stockItemsData.find((f) => f._id === stockItemIdToBeDeleted);
  const handleStartRemovingStockItem = (id: string) => {
    setStockItemIdToBeDeleted(id);
  };
  const handleStopRemovingStockItem = () => {
    setStockItemIdToBeDeleted(undefined);
  };

  const [stockItemIdToBeEdited, setStockItemIdToBeEdited] = useState<string>();
  const stockItemToBeEdited = stockItemsData.find((f) => f._id === stockItemIdToBeEdited);
  const handleStartEditingStockItem = (id: string) => {
    setStockItemIdToBeEdited(id);
  };
  const handleStopEditingStockItem = () => {
    setStockItemIdToBeEdited(undefined);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} spacing={2} container>
          <Grid item>
            <Typography variant="h4">Skladové položky</Typography>
          </Grid>
          <Grid item xs />
          <Grid item>
            <Button variant="outlined" startIcon={<Add />} onClick={handleStartAddingStockItem}>
              Pridať
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <StockItemsTable
              stockItems={stockItemsData}
              customCols={[
                {
                  field: 'actions',
                  headerName: '',
                  renderCell: ({ row }) => (
                    <Stack direction="row" spacing={1}>
                      <IconButton onClick={() => handleStartEditingStockItem(row._id)}>
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleStartRemovingStockItem(row._id)}>
                        <Delete />
                      </IconButton>
                    </Stack>
                  ),
                },
              ]}
            />
          </Card>
        </Grid>
      </Grid>
      <MyModal isOpened={!!stockItemToBeDeleted} handleClose={handleStopRemovingStockItem}>
        {stockItemToBeDeleted && (
          <Stack direction="column" spacing={3}>
            <Typography variant="h5">Chystáte sa vymazať položku a všetky odkazy na ňu</Typography>
            <StockItemInfoCard {...stockItemToBeDeleted} />
            <Stack direction="row-reverse" spacing={1}>
              <AcceptRejectButtonPair
                onConfirm={() => deleteStockItem(stockItemToBeDeleted._id)}
                onReject={handleStopRemovingStockItem}
              />
            </Stack>
          </Stack>
        )}
      </MyModal>
      <RightDrawer isOpened={addingStockItem} handleClose={handleStopAddingStockItem}>
        <Stack direction="column" sx={{ m: 3 }} spacing={3}>
          <Typography variant="h5">Pridať položku</Typography>
          <StockItemForm
            requiredFields={['rawUnitPrice', 'title']}
            onConfirm={(d) => {
              createStockItem(d);
              handleStopAddingStockItem();
            }}
            autoSubmit={false}
          />
        </Stack>
      </RightDrawer>
      <RightDrawer isOpened={!!stockItemToBeEdited} handleClose={handleStopEditingStockItem}>
        <Stack direction="column" sx={{ m: 3 }} spacing={3}>
          <Typography variant="h5">Upraviť položku</Typography>
          <StockItemForm
            requiredFields={['rawUnitPrice', 'title']}
            initialValues={stockItemToBeEdited}
            onConfirm={(d) => {
              updateStockItem(stockItemToBeEdited._id, d);
              handleStopEditingStockItem();
            }}
            autoSubmit={false}
          />
        </Stack>
      </RightDrawer>
    </>
  );
};

export default StockItems;
