import { Skeleton, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useApi } from '../../ctx/Api';

interface IAccountLink {
  id: string;
}

const AccountLink: FC<IAccountLink> = ({ id }) => {
  const [name, setName] = useState<string>();
  const { pullUserInfo } = useApi();

  useEffect(() => {
    pullUserInfo(id)
      .then((user) => {
        setName(`${user.firstName} ${user.lastName}`);
      })
      .catch(() => {
        setName('Vyskytla sa chyba.');
      });
  }, [id, pullUserInfo]);

  return (
    <>
      {name && <Typography>{name}</Typography>}
      {!name && (
        <Skeleton>
          <Typography>{name}</Typography>
        </Skeleton>
      )}
    </>
  );
};

export default AccountLink;
