import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { Close } from '@mui/icons-material';
import { ContactTypes, useNewContact } from '../../ctx/contacts/NewContact.Context';
import CompanyInfoForm from './forms/CompanyInfoForm';
import BasicContactInfoForm from './forms/BasicContactInfoForm';
import IndividualInfoForm from './forms/IndividualInfo';
import ContactTypeSelector from './ContactTypeSelector';

interface INewContact {
  handleStopAdding: () => void;
}

const NewContact: FC<INewContact> = ({ handleStopAdding }) => {
  const {
    isValid,
    submitNewContact,
    contactType,
    setContactType,
    setBasicInfo,
    setCompanyInfo,
    setIndividualInfo,
  } = useNewContact();

  const handleSubmitNewContact = async () => {
    const ok = await submitNewContact();
    console.log(ok);
    // if (ok) handleStopAdding();
  };

  return (
    <Card>
      <CardContent>
        <Stack direction="column" spacing={2}>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="h5" color="secondary">
                Nový kontakt
              </Typography>
            </Grid>

            <Grid item xs />
            <Grid item>
              <IconButton onClick={handleStopAdding}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Divider />
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} container spacing={3} alignItems="center">
                <Grid item>
                  <Typography variant="h6" color="primary">
                    Údaje
                  </Typography>
                </Grid>
                <Grid item>
                  <ContactTypeSelector value={contactType} onSelect={setContactType} />
                </Grid>
              </Grid>
              <Grid item xs={6}>
                {contactType === ContactTypes.Company && (
                  <CompanyInfoForm onConfirm={setCompanyInfo} />
                )}
                {contactType === ContactTypes.Individual && (
                  <IndividualInfoForm onConfirm={setIndividualInfo} />
                )}
              </Grid>
              <Grid item xs={6}>
                <BasicContactInfoForm onConfirm={setBasicInfo} />
              </Grid>
            </Grid>
          </Box>
          <Divider />
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          color="secondary"
          variant="contained"
          startIcon={<SaveIcon />}
          disabled={!isValid}
          onClick={handleSubmitNewContact}
        >
          Uložiť
        </Button>
        <Button
          onClick={handleStopAdding}
          variant="outlined"
          startIcon={<CancelIcon />}
          sx={{ mr: 1 }}
        >
          Zrušiť
        </Button>
      </CardActions>
    </Card>
  );
};

export default NewContact;
