import { FC, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { Download, Edit, Send } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { NewClaimProvider } from '../ctx/NewClaim.Context';

import NewClaim from '../components/claims/NewClaim';
import NewAccountingYearForm from '../components/claims/NewAccountingYearForm';
import RightDrawer from '../components/RightDrawer';
import {
  ClaimStateEnum,
  IEmailPreviewData,
  IReplace,
  StateFilterType,
  useClaims,
} from '../ctx/Claims.Context';
import { ClaimsTable } from '../components/claims/ClaimsTable';
import AccountingYearReportCard from '../components/claims/AccountingYearReportCard';
import EmailOptionsForm from '../components/email/forms/EmailForm';
import { modalStyle } from './Claim';
import ClaimsDownloader from '../components/claims/ClaimsDownloader';

const ClaimFilterSearchBar: FC = () => {
  const { searchValue, setSearchValue } = useClaims();

  return (
    <TextField
      size="small"
      type="text"
      label="Hľadať"
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
    />
  );
};

const Claims: FC = () => {
  const {
    accountingYears,
    currentAccountingYear,
    setCurrentAccountingYearId,
    currentClaims,
    editAccountingYearCurrent,
    pullCurrentClaims,
    checkAccountingYearValidity,
    fixCreditNotes,
    currentAccountingYearReport,
    currentClaimGroup,
    claimGroups,
    setCurrentClaimGroupId,
    previewInvoiceEmails,
    sendInvoiceEmails,
    currentStateFilter,
    setCurrentStateFilter,
  } = useClaims();

  const [addingClaim, setAddingClaim] = useState<boolean>(false);
  const handleStartAddingClaim = () => setAddingClaim(true);
  const handleStopAddingClaim = () => setAddingClaim(false);

  const [downloadingClaims, setDownloadingClaims] = useState<boolean>(false);
  const handleStartDownloadingClaims = () => setDownloadingClaims(true);
  const handleStopDownloadingClaims = () => setDownloadingClaims(false);

  const [addingYear, setAddingYear] = useState<boolean>(false);
  const handleStartAddingYear = () => setAddingYear(true);
  const handleStopAddingYear = () => setAddingYear(false);

  const [emailDrawerOpen, setEmailDrawerOpen] = useState<boolean>(false);
  const handleOpenEmailDrawer = () => setEmailDrawerOpen(true);
  const handleCloseEmailDrawer = () => setEmailDrawerOpen(false);

  const [rowIsBeingEdited, setRowIsBeingEdited] = useState<boolean>(false);

  const [editingYear, setEditingYear] = useState<boolean>(false);
  const handleStartEditingYear = () => {
    setEditingYear(true);
    setLastNumber(currentAccountingYear.numberOfInvoices);
  };
  const handleStopEditingYear = () => setEditingYear(false);

  const [lastNumber, setLastNumber] = useState<number>(currentAccountingYear?.numberOfInvoices);

  const handleChange = (event: SelectChangeEvent) => {
    setCurrentAccountingYearId(event.target.value as string);
  };

  const handleChangeClaimGroup = (event: SelectChangeEvent) => {
    setCurrentClaimGroupId(event.target.value as string);
  };

  const [emailPreviewData, setEmailPreviewData] = useState<IEmailPreviewData>();

  const [mailCountForMail, setMailCountForMail] = useState<number>(0);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} spacing={2} container>
          <Grid item>
            <Typography variant="h4">Pohľadávky</Typography>
          </Grid>
          <Grid item xs={1}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Účtovný rok</InputLabel>
              <Select
                value={currentAccountingYear?._id || ''}
                label="Účtovný rok"
                size="small"
                onChange={handleChange}
              >
                {accountingYears.map((ay) => (
                  <MenuItem key={`ay-select-${ay._id}`} value={ay._id}>
                    {ay.prefix}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Skupina</InputLabel>
              <Select
                value={currentClaimGroup?._id || 'all'}
                label="Skupina"
                size="small"
                onChange={handleChangeClaimGroup}
              >
                <MenuItem value="all">Všetky</MenuItem>
                {claimGroups.map((cg) => (
                  <MenuItem key={`cg-select-${cg._id}`} value={cg._id}>
                    {cg.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select">Stav</InputLabel>
              <Select
                value={currentStateFilter || 'all'}
                label="Stav"
                size="small"
                onChange={(e) => {
                  setCurrentStateFilter(e.target.value as StateFilterType);
                }}
              >
                <MenuItem value="all">Všetky</MenuItem>
                {(Object.keys(ClaimStateEnum) as Array<keyof typeof ClaimStateEnum>).map((key) => (
                  <MenuItem key={`cs-select-${key}`} value={key}>
                    {ClaimStateEnum[key]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <ClaimFilterSearchBar />
          </Grid>
          <Grid item xs />
          <Grid item>
            <Button
              sx={{ mr: 1 }}
              // color="secondary"
              onClick={handleStartDownloadingClaims}
              variant="outlined"
              startIcon={<Download />}
              disabled={!currentAccountingYear}
            >
              Stiahnuť
            </Button>
            <Button
              sx={{ mr: 1 }}
              // color="secondary"
              onClick={handleStartEditingYear}
              variant="outlined"
              startIcon={<Edit />}
              disabled={!currentAccountingYear}
            >
              Upraviť ÚR
            </Button>
            <RightDrawer isOpened={editingYear} handleClose={handleStopEditingYear}>
              <Box sx={{ m: 3 }}>
                <Stack direction="column" spacing={3}>
                  <Typography variant="h5">Editovať rok {currentAccountingYear?.prefix}</Typography>
                  <TextField
                    type="number"
                    label="Číslo poslednej faktúry /dobropisu"
                    value={lastNumber}
                    onChange={(e) => setLastNumber(Number(e.target.value))}
                  />
                  <Stack direction="row-reverse">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        editAccountingYearCurrent(currentAccountingYear._id, lastNumber);
                        handleStopEditingYear();
                      }}
                    >
                      Potvrdiť
                    </Button>
                  </Stack>
                  <Divider />
                  <Stack direction="row-reverse">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        fixCreditNotes(currentAccountingYear._id);
                      }}
                    >
                      Opravit dobropisy
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        checkAccountingYearValidity(currentAccountingYear._id);
                      }}
                    >
                      Skontrolovať validitu
                    </Button>
                  </Stack>
                  {currentAccountingYearReport && (
                    <AccountingYearReportCard
                      accoutingYearReportData={currentAccountingYearReport}
                    />
                  )}
                </Stack>
              </Box>
            </RightDrawer>
            <Button
              sx={{ mr: 1 }}
              // color="secondary"
              onClick={handleStartAddingYear}
              variant="outlined"
              startIcon={<AddIcon />}
            >
              Pridať ÚR
            </Button>

            <RightDrawer isOpened={addingYear} handleClose={handleStopAddingYear}>
              <NewAccountingYearForm handleStopAddingYear={handleStopAddingYear} />
            </RightDrawer>
            <Button
              color="secondary"
              variant="contained"
              startIcon={<AddIcon />}
              disabled={addingClaim}
              onClick={handleStartAddingClaim}
            >
              Nová pohľadávka
            </Button>
          </Grid>
        </Grid>

        {/* while group is selected */}
        <Grid
          item
          xs={12}
          sx={{ ...(!currentClaimGroup && { pt: '0 !important' }), transition: 'all 1s' }}
        >
          <Collapse
            in={!!currentClaimGroup}
            sx={{
              transition: 'all 2s',
              boxShadow: '0px 0px 0px 1px rgba(224, 224, 224, 0)',
              backgroundColor: 'rgba(224, 224, 224, 0)',
              outline: 'solid 1px rgba(224, 224, 224, 1)',
            }}
            component={Card}
          >
            <CardContent>
              <Stack direction="row-reverse" spacing={1}>
                <Button
                  variant="outlined"
                  startIcon={<Send />}
                  onClick={async () => {
                    const emailPreview = await previewInvoiceEmails(
                      currentClaims
                        .filter((cc) => cc.state.title === 'Čakajúca')
                        .map((cc) => cc._id)
                    );
                    console.log(emailPreview);
                    setEmailPreviewData(emailPreview);
                  }}
                >
                  Email čakajúcim
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<Send />}
                  onClick={async () => {
                    const emailPreview = await previewInvoiceEmails(
                      currentClaims
                        .filter((cc) => cc.state.title === 'Čakajúca' && !cc.emailInvoiceSent)
                        .map((cc) => cc._id)
                    );
                    console.log(emailPreview);
                    setEmailPreviewData(emailPreview);
                  }}
                >
                  Email tým čo nedostali
                </Button>
                <Button variant="outlined" startIcon={<Send />} onClick={handleOpenEmailDrawer}>
                  Email tým čo ...
                </Button>
              </Stack>
            </CardContent>
          </Collapse>
        </Grid>
        {/* preview email modal */}
        <Modal
          open={!!emailPreviewData}
          onClose={() => setEmailPreviewData(undefined)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            position: 'absolute',
            // top: '10%',
            left: '10%',
            overflow: 'scroll',
            height: '100%',
            display: 'block',
            // pt: 100,
            zIndex: 9999999999999,
          }}
        >
          <Card sx={{ ...modalStyle, width: '80vw' }}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <EmailOptionsForm
                  requiredFields={['from', 'to', 'text', 'subject', 'replyTo']}
                  initialValues={emailPreviewData?.email.options}
                  autoSubmit={false}
                  disabled={rowIsBeingEdited}
                  onConfirm={async (d) => {
                    const toSend: IEmailPreviewData = {
                      data: emailPreviewData.data,
                      email: { options: { ...d } },
                    };
                    console.log(toSend);
                    const res = await sendInvoiceEmails(toSend);
                    setEmailPreviewData(undefined);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <DataGrid
                  rows={emailPreviewData?.data.map((d, c) => ({ ...d, id: `cprw-${c}` }))}
                  columns={[
                    { field: 'to', flex: 1, editable: true },
                    {
                      field: 'actions',
                      headerName: '',
                      renderCell: (params) => (
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            setEmailPreviewData((d) => ({
                              ...d,
                              data: d.data.filter((dd) => dd.VS !== params.row.VS),
                            }));
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      ),
                    },
                  ]}
                  editMode="row"
                  onRowEditStop={(e) => {
                    setEmailPreviewData((d) => ({
                      ...d,
                      data: d.data.map((dd) => (dd.VS === e.row.VS ? (e.row as IReplace) : dd)),
                    }));
                    setRowIsBeingEdited(false);
                  }}
                  onRowEditStart={() => setRowIsBeingEdited(true)}
                />
              </Grid>
            </Grid>
          </Card>
        </Modal>

        {addingClaim && (
          <Grid item xs={12}>
            <Collapse in={addingClaim}>
              <NewClaimProvider
                accountingYear={currentAccountingYear}
                onNewClaimSuccess={pullCurrentClaims}
              >
                <NewClaim handleStopAdding={handleStopAddingClaim} />
              </NewClaimProvider>
            </Collapse>
          </Grid>
        )}
        <Grid item xs={12}>
          <Card>
            <ClaimsTable reloadData={pullCurrentClaims} claims={currentClaims} />
          </Card>
        </Grid>
      </Grid>
      <RightDrawer isOpened={emailDrawerOpen} handleClose={handleCloseEmailDrawer}>
        <Box sx={{ margin: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>Email tým čo</Typography>
            </Grid>
            <Grid item>
              <TextField
                id="outlined-a"
                label="Dostali Emailov"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                value={mailCountForMail}
                onChange={(e) => {
                  setMailCountForMail(Number(e.target.value));
                }}
              />
            </Grid>
            <Grid item xs={12} container direction="row-reverse">
              <Button
                variant="contained"
                color="secondary"
                onClick={async () => {
                  const emailPreview = await previewInvoiceEmails(
                    currentClaims
                      .filter((cc) => cc.emails?.length === mailCountForMail)
                      .map((cc) => cc._id)
                  );
                  console.log(emailPreview);
                  setEmailPreviewData(emailPreview);
                }}
              >
                Potvrdiť
              </Button>
            </Grid>
          </Grid>
        </Box>
      </RightDrawer>
      {downloadingClaims && (
        <ClaimsDownloader isOpened={downloadingClaims} handleClose={handleStopDownloadingClaims} />
      )}
    </>
  );
};

export default Claims;
