import { FC } from 'react';
import {
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Box,
} from '@mui/material';
import { ClaimGroup } from '../../ctx/Claims.Context';

interface IClaimGroupSelector {
  value: 'new' | 'none' | 'existing';
  handleValueChange: (value: 'new' | 'none' | 'existing') => void;
  groups: Array<ClaimGroup>;
  selectedGroup: ClaimGroup;
  handleSelectGroup: (id: string) => void;
  newGroupName: string;
  handleChangeNewGroupName: (name: string) => void;
}

export const ClaimGroupSelector: FC<IClaimGroupSelector> = ({
  value,
  groups,
  selectedGroup,
  handleSelectGroup,
  newGroupName,
  handleChangeNewGroupName,
  handleValueChange,
}) => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <Typography variant="h6" color="primary">
          Skupina pohľadávok
        </Typography>
      </Grid>
      <Grid item>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-form-control-label-placement"
            name="position"
            defaultValue="none"
            value={value}
            onChange={(e) => handleValueChange(e.target.value as 'new' | 'none' | 'existing')}
          >
            <FormControlLabel value="none" control={<Radio />} label="Žiadna" />
            <FormControlLabel
              value="new"
              control={<Radio />}
              label={
                <TextField
                  label="Nová"
                  disabled={value !== 'new'}
                  value={newGroupName}
                  error={groups.some(
                    (g) => g.title.toLocaleLowerCase() === newGroupName?.toLowerCase()
                  )}
                  helperText={
                    groups.some(
                      (g) => g.title.toLocaleLowerCase() === newGroupName?.toLocaleLowerCase()
                    )
                      ? 'Názov už existuje'
                      : ''
                  }
                  onChange={(e) => handleChangeNewGroupName(e.target.value)}
                />
              }
            />
            <FormControlLabel
              value="existing"
              control={<Radio />}
              label={
                <Box width={250}>
                  <FormControl fullWidth>
                    <InputLabel disabled={value !== 'existing'} id="demo-simple-select-label">
                      Existujúca
                    </InputLabel>

                    <Select
                      value={selectedGroup?._id || ''}
                      label="Existujúca"
                      onChange={(e) => handleSelectGroup(e.target.value)}
                      disabled={value !== 'existing'}
                    >
                      {groups.map((cg) => (
                        <MenuItem key={`cg-select-${cg._id}`} value={cg._id}>
                          {cg.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              }
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};
