import { FC } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { IconButton, Link, Stack, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fDate, fDateTime } from '../../utils/formatTime';
import { fEur } from '../../utils/formatNumber';
import SubscriptionStateChip from './SubscriptionStateChip';
import { ContactNameColumnRender } from '../contacts/ContactsTable';
import {
  SubscriptionProvider,
  useSubscription,
} from '../../ctx/subscriptions/Subscription.Context';
import { CompleteSubscription } from '../../ctx/subscriptions/Subscriptions.Context';
import VariableSubscriptionStateChip from './VariableSubscriptionStateChip';
import { DataGridWithSelection, IDataGridWithSelection } from '../DataGridWithSelection';

interface ISubscriptionColumns {
  reloadData?: () => void;
}

export const SubscriptionVSColumnRender = (subscription: CompleteSubscription) => {
  const navigate = useNavigate();
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Link
        onClick={() =>
          navigate(`/subscriptiontypes/${subscription.subscriptionType._id}/${subscription._id}`)
        }
        color="inherit"
      >
        <Typography variant="body1" sx={{ fontFamily: 'monospace' }}>
          {subscription.variableSymbol}
        </Typography>
      </Link>
    </Stack>
  );
};

const subscriptionColumns = ({ reloadData }: ISubscriptionColumns): GridColDef[] => [
  {
    field: 'variableSymbol',
    headerName: 'Variabilný sym.',
    flex: 1,
    renderCell: ({ row }) => SubscriptionVSColumnRender(row),
  },
  {
    field: 'payer',
    headerName: 'Platca',
    flex: 1,
    renderCell: ({ row }) => ContactNameColumnRender(row.payer),
  },

  {
    field: 'start',
    headerName: 'Začiatok',
    flex: 1,
    renderCell: (params) => <>{fDate(params.row.start)}</>,
  },
  {
    field: 'end',
    headerName: 'Koniec',
    flex: 1,
    renderCell: (params) => <>{fDate(params.row.end)}</>,
  },
  {
    field: 'state',
    headerName: 'Zaplatené (KRÁT)',
    flex: 1,
    renderCell: ({ row }) => <SubscriptionStateChip {...row.actualState} />,
  },
  {
    field: 'price',
    headerName: 'Zaplatené (EUR)',
    flex: 1,
    renderCell: ({ row }: { row: CompleteSubscription }) => {
      return <VariableSubscriptionStateChip {...row.actualVariableState} />;
      return fEur(row.price);
    },
  },
];

const SubscriptionRedirecter: FC = () => {
  const navigate = useNavigate();
  const { subscriptionData } = useSubscription();

  return (
    <Tooltip title="Otvoriť smostatne">
      <IconButton onClick={() => navigate(`/subscriptions/${subscriptionData._id}`)}>
        <OpenInNewIcon />
      </IconButton>
    </Tooltip>
  );
};

interface ISubscriptionsTable extends IDataGridWithSelection {
  subscriptions: Array<CompleteSubscription>;
  reloadData?: () => void;
}

export const SubscriptionsTable: FC<ISubscriptionsTable> = ({
  subscriptions,
  reloadData,
  ...props
}) => {
  return (
    <DataGridWithSelection
      {...props}
      autoHeight
      columns={subscriptionColumns({ reloadData })}
      rows={subscriptions.map((c) => ({ ...c, id: c._id }))}
    />
  );
};
