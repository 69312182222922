import {
  BuildCircle,
  Business,
  CheckCircle,
  Edit,
  Groups,
  Person,
  Send,
} from '@mui/icons-material';
import {
  Grid,
  Card,
  CardContent,
  Stack,
  IconButton,
  Tooltip,
  Skeleton,
  Avatar,
  Typography,
  Button,
  CardHeader,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import toMaterialStyle from 'material-color-hash';
import ContactBasicInfoCard from '../components/contact/ContactBasicInfoCard';
import { BasicContactInfo, Gender } from '../ctx/Claims.Context';
import {
  CompleteIndividual,
  ContactProvider,
  isCompany,
  useContact,
} from '../ctx/contacts/Contact.Context';
import AvatarManIcon from '../icons/AvatarManIcon';
import AvatarWomanIcon from '../icons/AvatarWomanIcon';
import { stringToColor } from '../utils/stringAvatar';
import RightDrawer from '../components/RightDrawer';
import BasicContactInfoForm from '../components/contacts/forms/BasicContactInfoForm';
import IndividualInfoForm from '../components/contacts/forms/IndividualInfo';
import { JustContact } from '../ctx/contacts/NewContact.Context';
import TagsGrid from '../components/tag/TagsGrid';
import { TagsProvider } from '../ctx/tags/Tags.Context';
import { ClaimsTable } from '../components/claims/ClaimsTable';
import { ListCampaignsTable } from '../components/listCampaigns/ListCampaignTable';
import { SubscriptionsTable } from '../components/subscriptions/SubscriptionsTable';
import { ListCampaignWithContacts } from '../ctx/listCampaigns/ListCampaign.context';
import { CompleteContact } from '../components/contact/IndividualBusinessCard';
import ContactPersonsEditor from '../components/contacts/forms/ContactPersonsEditor';

const ContactInfo: FC = () => {
  const {
    contactData,
    pullData,
    editContact,
    contactClaims,
    contactListCampaigns,
    contactSubscriptions,
  } = useContact();

  const [newContactData, setNewContactData] = useState<CompleteIndividual>();

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const startEditing = () => setIsEditing(true);
  const stopEditing = () => {
    setIsEditing(false);
    setNewContactData(undefined);
  };

  const [isEditingContactPersons, setIsEditingContactPersons] = useState<boolean>(false);
  const startEditingContactPersons = () => setIsEditingContactPersons(true);
  const stopEditingContactPersons = () => {
    setIsEditingContactPersons(false);
    setNewContactData(undefined);
  };

  const addData = (data: BasicContactInfo | JustContact) => {
    console.log('ZDEEEEEEEEE');
    console.log(data);
    setNewContactData((p) => ({ ...p, ...data }));
  };

  const editContactHandle = () => {
    editContact(newContactData);
    stopEditing();
  };

  useEffect(() => console.log(contactData), [contactData]);

  return contactData ? (
    <>
      <Grid container spacing={3}>
        <Grid item md={3} container spacing={3} alignContent="flex-start">
          <Grid item xs={12} container alignContent="flex-start">
            <Grid
              item
              xs={12}
              sx={{
                position: 'relative',
                '&::before': {
                  paddingTop: '100%',
                  content: '""',
                  display: 'block',
                },
              }}
            >
              <Card
                sx={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  backgroundColor: stringToColor(`${contactData._id}${contactData._id}`),
                }}
              >
                {/* <Avatar
                sx={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
              ></Avatar> */}
                {contactData.gender === Gender.FEMALE && (
                  <AvatarWomanIcon
                    sx={{
                      width: '90%',
                      height: '90%',
                      position: 'absolute',
                      bottom: 0,
                      left: '50%',
                      transform: 'translateX(-50%)',
                    }}
                  />
                )}
                {contactData.gender === Gender.MALE && (
                  <AvatarManIcon
                    sx={{
                      width: '90%',
                      height: '90%',
                      position: 'absolute',
                      bottom: 0,
                      left: '50%',
                      transform: 'translateX(-50%)',
                    }}
                  />
                )}
                {!contactData.gender &&
                  (isCompany(contactData) ? (
                    <Business
                      sx={{
                        width: '90%',
                        height: '90%',
                        position: 'absolute',
                        bottom: 0,
                        left: '50%',
                        transform: 'translateX(-50%)',
                      }}
                    />
                  ) : (
                    <Person
                      sx={{
                        width: '90%',
                        height: '90%',
                        position: 'absolute',
                        bottom: 0,
                        left: '50%',
                        transform: 'translateX(-50%)',
                      }}
                    />
                  ))}
              </Card>
            </Grid>
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Typography variant="h4">{contactData.fullName}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <ContactBasicInfoCard basicContactInfoData={contactData} />
              </CardContent>
            </Card>
          </Grid>
          {contactData.contactPersons.map((cp) => (
            <Grid item xs={12} key={`contactPerson-${cp.person._id}`}>
              <Card>
                <CardHeader title={cp.caption} />
                <CardContent>
                  <ContactBasicInfoCard basicContactInfoData={cp.person} />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Grid item md={6} container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Tagy" />
              <CardContent>
                <Stack direction="row">
                  <TagsProvider dataProvider={false} initialData={contactData.tagTrees}>
                    <TagsGrid defaultExpanded freeze onlyView />
                  </TagsProvider>
                  {/* {contactData.tagTrees.map((tt,i) => (
                  ))} */}
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Pohľadávky" />
              <CardContent>
                <Stack direction="row">
                  <ClaimsTable claims={contactClaims} />
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Listy" />
              <CardContent>
                <Stack direction="row">
                  <ListCampaignsTable
                    listcampaigns={contactListCampaigns}
                    customCols={[
                      {
                        field: 'reakcia',
                        headerName: 'Reakcia',
                        flex: 1,
                        renderCell: ({ row }: { row: ListCampaignWithContacts }) => {
                          const reaction = row.contactRelations.find(
                            (cr) => (cr.contact as CompleteContact)._id === contactData._id
                          );
                          return (
                            <Grid container direction="row" spacing={2} alignItems="center">
                              <Grid item xs={3}>
                                {reaction.reacted && <CheckCircle sx={{ color: 'success.main' }} />}
                              </Grid>
                              <Grid item xs={9}>
                                {reaction.note && (
                                  <Tooltip title={reaction.note}>
                                    <Typography variant="body2" noWrap>
                                      {reaction.note}
                                    </Typography>
                                  </Tooltip>
                                )}
                              </Grid>
                            </Grid>
                          );
                        },
                      },
                    ]}
                  />
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Predplatné" />
              <CardContent>
                <Stack direction="row">
                  <SubscriptionsTable subscriptions={contactSubscriptions} />
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid item md={3} container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Stack direction="row" spacing={1}>
                  <IconButton onClick={startEditing}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={startEditingContactPersons}>
                    <Groups />
                  </IconButton>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <RightDrawer isOpened={isEditing} handleClose={stopEditing}>
        <Stack direction="column" spacing={3} sx={{ m: 3 }}>
          <IndividualInfoForm initialValues={contactData} onConfirm={addData} />
          <BasicContactInfoForm initialValues={contactData} onConfirm={addData} />
          <Stack direction="row-reverse" spacing={1}>
            <Button
              variant="contained"
              color="secondary"
              onClick={editContactHandle}
              disabled={!newContactData}
            >
              Potvrdiť
            </Button>
            <Button variant="outlined" onClick={stopEditing}>
              Zrušiť
            </Button>
          </Stack>
        </Stack>
      </RightDrawer>
      {/* editing contact persons */}
      <RightDrawer
        isOpened={isEditingContactPersons}
        handleClose={stopEditingContactPersons}
        size="half"
      >
        <Stack direction="column" spacing={3} sx={{ m: 3 }}>
          <ContactPersonsEditor initialValues={newContactData || contactData} onConfirm={addData} />
          <Stack direction="row-reverse" spacing={1}>
            <Button
              variant="contained"
              color="secondary"
              onClick={editContactHandle}
              disabled={!newContactData}
            >
              Potvrdiť
            </Button>
            <Button variant="outlined" onClick={stopEditingContactPersons}>
              Zrušiť
            </Button>
          </Stack>
        </Stack>
      </RightDrawer>
    </>
  ) : (
    <Skeleton />
  );
};

const Contact: FC = () => {
  const { id } = useParams();

  return (
    <ContactProvider id={id}>
      <ContactInfo />
    </ContactProvider>
  );
};

export default Contact;
