import { Delete } from '@mui/icons-material';
import { Stack, IconButton, Grid, Typography, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { FC, useState } from 'react';
import { CompletePayment } from '../../ctx/PaymentProcess.Context';
import { fEur } from '../../utils/formatNumber';
import AcceptRejectButtonPair from '../AcceptRejectButtonPair';
import PaymentForm from './forms/PaymentForm';

export interface CPaymentWParts extends Omit<CompletePayment, 'parts'> {
  parts: CompletePayment[];
}

interface IPaymentSplitter {
  payment: CPaymentWParts;
  noControls?: boolean;
  onConfirm: (id: string, parts: Partial<CompletePayment>[]) => void;
}
const PaymentSplitter: FC<IPaymentSplitter> = ({ payment, noControls, onConfirm }) => {
  const [parts, setParts] = useState<Partial<CompletePayment>[]>(payment ? payment.parts : []);

  return payment ? (
    <Grid container>
      <Grid item xs={12}>
        <Typography>
          Pridať časť k platbe: {payment.payer.name} {'->'} {payment.receiver.name} (
          {fEur(payment.amount)})
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <PaymentForm onConfirm={(d) => setParts((p) => [d, ...p])} autoSubmit={false} />
      </Grid>
      <Grid item xs={12}>
        <Typography>Výsledné časti</Typography>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          rows={parts.map((p, i) => ({ ...p, id: i }))}
          autoHeight
          disableSelectionOnClick
          columns={[
            {
              field: 'variableSymbol',
              headerName: 'VS',
              flex: 1,
            },
            {
              field: 'price',
              headerName: 'Suma',
              renderCell: ({ row }) => fEur(row.amount),
              flex: 1,
            },
            ...(noControls
              ? []
              : [
                  {
                    field: 'actions',
                    headerName: '',
                    width: 50,
                    renderCell: ({ row }) => (
                      <Stack direction="row" spacing={1}>
                        <IconButton
                          onClick={() => {
                            setParts((p) => {
                              p.splice(row.id, 1);
                              return [...p];
                            });
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Stack>
                    ),
                  },
                ]),
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row-reverse" spacing={1}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onConfirm(payment._id, parts)}
            disabled={
              parts.length > 0 && parts.reduce((p, c) => p + c.amount, 0) !== payment.amount
            }
          >
            Uložiť
          </Button>
        </Stack>
      </Grid>
    </Grid>
  ) : null;
};

export default PaymentSplitter;
