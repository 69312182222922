import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CheckCircle, Close, Delete, Edit, GroupAdd } from '@mui/icons-material';
import {
  useListCampaign,
  ListCampaignProvider,
  ListCampaignContactRelation,
} from '../ctx/listCampaigns/ListCampaign.context';
import RightDrawer from '../components/RightDrawer';
import ListCampaignForm from '../components/listCampaigns/forms/ListCampaignForm';
import ListCampaignInfoCard from '../components/listCampaigns/ListCampaignInfoCard';
import { ContactsTable } from '../components/contacts/ContactsTable';
import AddContactsSelector from '../components/contacts/AddContactsSelector';
import Scrollbar from '../layouts/authorized/Scrollbar';
import { CompleteContact } from '../components/contact/IndividualBusinessCard';
import { isCompany } from '../ctx/contacts/Contact.Context';
import ContactFullInfoCard from '../components/contact/ContactFullInfoCard';
import ListCampaignContactRelFrom from '../components/listCampaigns/forms/ListCampaignContactRelFrom';
import AcceptRejectButtonPair from '../components/AcceptRejectButtonPair';
import MyModal from '../components/MyModal';

const ListCampaignInfo: FC = () => {
  const navigate = useNavigate();
  const {
    listcampaignData,
    pullData,
    editListCampaign,
    addContactsToListCampaign,
    editListCampaignRelation,
    deleteListCampaign,
  } = useListCampaign();
  const [editingListCampaign, setEditingListCampaign] = useState<boolean>(false);
  const handleStartEditingListCampaign = () => setEditingListCampaign(true);
  const handleStopEditingListCampaign = () => setEditingListCampaign(false);

  const [addingpeopletoListCampaign, setAddingPeopleToListCampaign] = useState<boolean>(false);
  const handleStartAddingPeopleToListCampaign = () => setAddingPeopleToListCampaign(true);
  const handleStopAddingPeopleToListCampaign = () => setAddingPeopleToListCampaign(false);

  const [editingRelation, setEditingRelation] = useState<boolean>(false);
  const handleStartEditingRelation = () => setEditingRelation(true);
  const handleStopEditingRelation = () => setEditingRelation(false);

  const [relationBeingEdited, setRelationBeingEdited] = useState<ListCampaignContactRelation>();

  const [listCampaignIdToBeDeleted, setListCampaignIdToBeDeleted] = useState<string>();
  const listCampaignToBeDeleted = listCampaignIdToBeDeleted ? listcampaignData._id : undefined;
  const handleStartRemovingListCampaign = () => {
    setListCampaignIdToBeDeleted(listcampaignData._id);
  };
  const handleStopRemovingListCampaign = () => {
    setListCampaignIdToBeDeleted(undefined);
  };

  return listcampaignData ? (
    <>
      <Grid container spacing={3}>
        <Grid item md={6} container spacing={3}>
          <Grid item xs={12}>
            <ListCampaignInfoCard listCampaignData={listcampaignData} />
          </Grid>
        </Grid>
        <Grid item md={6} container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Stack direction="row">
                  <Tooltip title="Editovať kampaň">
                    <IconButton onClick={handleStartEditingListCampaign}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Pridať kontakty">
                    <IconButton onClick={handleStartAddingPeopleToListCampaign}>
                      <GroupAdd />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Zmazať kampaň">
                    <IconButton onClick={handleStartRemovingListCampaign}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Kampaň dostali" />
              <CardContent>
                <ContactsTable
                  priorityContactField="address"
                  contacts={[
                    ...listcampaignData.contactRelations.map((r) => ({
                      ...(r.contact as CompleteContact),
                      additionalInfo: { ...r },
                    })),
                  ]}
                  customCols={[
                    {
                      field: 'reakcia',
                      headerName: 'Reakcia',
                      flex: 1,
                      renderCell: ({ row }) => (
                        <Grid container direction="row" spacing={2} alignItems="center">
                          <Grid item xs={3}>
                            {row.additionalInfo.reacted && (
                              <CheckCircle sx={{ color: 'success.main' }} />
                            )}
                          </Grid>
                          <Grid item xs={9}>
                            {row.additionalInfo.note && (
                              <Tooltip title={row.additionalInfo.note}>
                                <Typography variant="body2" noWrap>
                                  {row.additionalInfo.note}
                                </Typography>
                              </Tooltip>
                            )}
                          </Grid>
                        </Grid>
                      ),
                    },
                    {
                      field: 'seteditrelation',
                      headerName: '',
                      flex: 0.5,
                      renderCell: ({ row }) => (
                        <Stack direction="row">
                          <Tooltip title="Editovať reakciu">
                            <IconButton
                              onClick={() => {
                                setRelationBeingEdited(row.additionalInfo);
                                handleStartEditingRelation();
                              }}
                            >
                              <Edit />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      ),
                    },
                  ]}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <RightDrawer isOpened={editingRelation} handleClose={handleStopEditingRelation}>
        <Stack sx={{ m: 3 }} spacing={3}>
          <Box>
            <Grid container justifyContent="space-between">
              <Grid item container spacing={2} direction="column" xs={9}>
                <Grid item>
                  <Typography variant="h5">Editovať vzťah kampane s</Typography>
                </Grid>
                <Grid item>
                  <ContactFullInfoCard
                    contactInfo={relationBeingEdited?.contact as CompleteContact}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <IconButton onClick={handleStopEditingRelation}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <ListCampaignContactRelFrom
            onConfirm={(data) => {
              editListCampaignRelation(relationBeingEdited._id, data);
              handleStopEditingRelation();
            }}
            initialValues={relationBeingEdited}
            autoSubmit={false}
          />
        </Stack>
      </RightDrawer>
      <RightDrawer isOpened={editingListCampaign} handleClose={handleStopEditingListCampaign}>
        <Stack sx={{ m: 3 }} spacing={3}>
          <Box>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Editovať Kampaň</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleStopEditingListCampaign}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <ListCampaignForm
            onConfirm={(data) => editListCampaign(data, handleStopEditingListCampaign)}
            initialValues={listcampaignData}
            autoSubmit={false}
            requiredFields={['caption']}
          />
        </Stack>
      </RightDrawer>
      <RightDrawer
        isOpened={addingpeopletoListCampaign}
        handleClose={handleStopAddingPeopleToListCampaign}
        size="twothirds"
      >
        <Stack sx={{ m: 3 }} spacing={3}>
          <Box>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Zmeniť príjemcov</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleStopAddingPeopleToListCampaign}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </Stack>
        <Box sx={{ height: '100%' }}>
          <Scrollbar
            sx={{
              // height: '100%',
              // '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' },
              '& .simplebar-placeholder': {
                height: `200px!important`,
              },
              overflow: 'scroll',
            }}
          >
            <Box sx={{ px: 2.5, py: 3 }}>
              <AddContactsSelector
                onConfirm={(data) => {
                  addContactsToListCampaign(data, pullData);
                }}
                initialSelected={[
                  ...listcampaignData.contactRelations.map(
                    (i) => (i.contact as CompleteContact)._id
                  ),
                ]}
                autoConfirm={false}
              />
            </Box>
          </Scrollbar>
        </Box>
      </RightDrawer>
      <MyModal isOpened={!!listCampaignToBeDeleted} handleClose={handleStopRemovingListCampaign}>
        {listCampaignToBeDeleted && (
          <Stack direction="column" spacing={3}>
            <Typography variant="h5">
              Chystáte sa vymazať kampaň a všetky jej asociácie s kontaktami
            </Typography>
            <ListCampaignInfoCard listCampaignData={listcampaignData} />
            <Stack direction="row-reverse" spacing={1}>
              <AcceptRejectButtonPair
                onConfirm={() => deleteListCampaign(() => navigate('/listcampaigns'))}
                onReject={handleStopRemovingListCampaign}
              />
            </Stack>
          </Stack>
        )}
      </MyModal>
    </>
  ) : (
    <Skeleton />
  );
};

const ListCampaign: FC = () => {
  const { id } = useParams();

  return (
    <ListCampaignProvider id={id}>
      <ListCampaignInfo />
    </ListCampaignProvider>
  );
};

export default ListCampaign;
