import { Box, Card, CardContent, Chip, Stack, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DateRangeIcon from '@mui/icons-material/DateRange';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import Popper from '@mui/material/Popper';
import { usePopupState, bindHover, bindPopper } from 'material-ui-popup-state/hooks';
import Fade from '@mui/material/Fade';
import { DoneAll } from '@mui/icons-material';
import { fDateTime } from '../../utils/formatTime';
import {
  SubscriptionState,
  SubscriptionStateEnum,
} from '../../ctx/subscriptions/Subscriptions.Context';

const SubscriptionStateChip: FC<SubscriptionState> = (subscriptionState) => {
  const popupState = usePopupState({ variant: 'popper', popupId: 'demoPopper' });

  return (
    <>
      {subscriptionState.title === SubscriptionStateEnum.pending && (
        <Chip
          {...bindHover(popupState)}
          icon={<PendingIcon />}
          label={subscriptionState.title}
          color="warning"
          variant="filled"
        />
      )}
      {subscriptionState.title === SubscriptionStateEnum.paid && (
        <Chip
          {...bindHover(popupState)}
          icon={<CheckCircleIcon />}
          label={subscriptionState.title}
          color="success"
          variant="filled"
        />
      )}
      {subscriptionState.title === SubscriptionStateEnum.delayed && (
        <Chip
          {...bindHover(popupState)}
          icon={<CancelIcon />}
          label={subscriptionState.title}
          color="error"
          variant="filled"
        />
      )}
      {subscriptionState.title === SubscriptionStateEnum.inAdvance && (
        <Chip
          {...bindHover(popupState)}
          icon={<DoneAll />}
          label={subscriptionState.title}
          color="primary"
          variant="filled"
        />
      )}
      <Popper {...bindPopper(popupState)} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Card sx={{ backgroundColor: 'white', backdropFilter: 'blur(5px)' }}>
              <CardContent>
                <Stack direction="column" spacing={2}>
                  <Stack direction="row" spacing={2}>
                    <DateRangeIcon />
                    <Typography>{fDateTime(subscriptionState.date)}</Typography>
                  </Stack>
                  <Tooltip
                    title={
                      <Box>
                        <Stack direction="column" spacing={1}>
                          <Typography>{`Očakávaných platieb: ${subscriptionState.expectedPayments}`}</Typography>
                          <Typography>{`Zaplatených platieb: ${subscriptionState.payments}`}</Typography>
                          <Typography>{`Odpustených platieb: ${subscriptionState.forgivedPayments}`}</Typography>
                        </Stack>
                      </Box>
                    }
                  >
                    <Box>
                      {subscriptionState.title === SubscriptionStateEnum.inAdvance && (
                        <Stack direction="row" spacing={2}>
                          <TextSnippetIcon />
                          <Typography>{`Počet platieb naviac: ${subscriptionState.delayedTimes}`}</Typography>
                        </Stack>
                      )}
                      {subscriptionState.title === SubscriptionStateEnum.delayed && (
                        <Stack direction="row" spacing={2}>
                          <TextSnippetIcon />
                          <Typography>{`Počet omeškaných platieb: ${subscriptionState.delayedTimes}`}</Typography>
                        </Stack>
                      )}
                      {subscriptionState.title === SubscriptionStateEnum.paid && (
                        <Stack direction="row" spacing={2}>
                          <TextSnippetIcon />
                          <Typography>{`Počet platieb: ${subscriptionState.expectedPayments}`}</Typography>
                        </Stack>
                      )}
                    </Box>
                  </Tooltip>
                </Stack>
              </CardContent>
            </Card>
          </Fade>
        )}
      </Popper>
    </>
  );
};
export default SubscriptionStateChip;
