import { Mail } from '@mui/icons-material';
import {
  Card,
  CardHeader,
  Typography,
  CardContent,
  Grid,
  Divider,
  Stack,
  Tooltip,
} from '@mui/material';
import { FC } from 'react';
import { Claim, CompleteClaim } from '../../ctx/Claims.Context';
import { isCompany, isIndividual } from '../../ctx/contacts/Contact.Context';
import { fEur, fPercent } from '../../utils/formatNumber';
import { fDate } from '../../utils/formatTime';
import ClaimStateChip from '../claims/ClaimStateChip';
import { ClaimItems } from './ClaimItems';

const ClaimantDisplay: FC<Claim['claimant']> = (claimant) => {
  return (
    <Grid container alignContent="flex-start">
      <Grid item xs={12}>
        <Typography variant="caption">Zadávateľ</Typography>
      </Grid>
      {isCompany(claimant) && (
        <Grid item xs={12}>
          <Typography>{claimant.companyName}</Typography>
        </Grid>
      )}
      {isIndividual(claimant) && (
        <Grid item xs={12}>
          <Typography>
            {claimant.firstName} {claimant.lastName}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography>{claimant.address.street}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          {claimant.address.zip} {claimant.address.city}
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography>{claimant.address.state}</Typography>
      </Grid>
    </Grid>
  );
};

const ContactDisplay: FC<Claim['payer']> = (payer) => {
  return (
    <Grid container alignContent="flex-start">
      <Grid item xs={12}>
        <Typography variant="caption">Platca</Typography>
      </Grid>
      {isCompany(payer) && (
        <Grid item xs={12}>
          <Typography>{payer.companyName}</Typography>
        </Grid>
      )}
      {isIndividual(payer) && (
        <Grid item xs={12}>
          <Typography>
            {payer.firstName} {payer.lastName}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography>{payer.address.street}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          {payer.address.zip} {payer.address.city}
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography>{payer.address.state}</Typography>
      </Grid>
      {(payer.email || payer.phoneNumber) && (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      )}
      {payer.email && (
        <Grid item xs={12}>
          <Typography>{payer.email}</Typography>
        </Grid>
      )}
      {payer.phoneNumber && (
        <Grid item xs={12}>
          <Typography>{payer.phoneNumber}</Typography>
        </Grid>
      )}
    </Grid>
  );
};
interface IClaimBasicInfoCard {
  claimData: CompleteClaim;
}
const ClaimBasicInfoCard: FC<IClaimBasicInfoCard> = ({ claimData }) => {
  return (
    <Card>
      <CardHeader
        title={`Pohľadávka ${claimData.number}`}
        subheader={
          <>
            {/* <Typography variant="caption">Účtovný rok: {accountingYear?.prefix}</Typography> */}
            <Typography variant="caption">Vytvorená: {fDate(claimData.issued)}</Typography>
            <br />
            <Typography variant="caption">Suma: {fEur(claimData.finalPrice)}</Typography>
            <br />
            <Typography variant="caption">Variabilný symbol: {claimData.invoice._id}</Typography>
          </>
        }
        action={
          <Stack direction="row-reverse" spacing={1} alignItems="center">
            <ClaimStateChip {...claimData.state} />
            {claimData.emailInvoiceSent && (
              <Tooltip title="Email s faktúrou bol odoslaný">
                <Mail />
              </Tooltip>
            )}
          </Stack>
        }
      />
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item md={6} container>
            <ClaimantDisplay {...claimData.claimant} />
          </Grid>
          <Grid item md={6} container>
            <ContactDisplay {...claimData.payer} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ClaimItems
              items={claimData.items.map((itm, i) => ({
                ...itm,
                id: `${claimData._id}-${i}`,
              }))}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="column" justifyContent="flex-end">
              <Typography variant="body2" textAlign="right">
                Súčet položiek: {fEur(claimData.rawTotal)}
              </Typography>
              <Typography variant="body2" textAlign="right">
                Zľava z celej objednávky: {fPercent(claimData.discount)}
              </Typography>
              <Typography variant="body2" textAlign="right">
                Výsledná cena: <b>{fEur(claimData.finalPrice)}</b>
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ClaimBasicInfoCard;
