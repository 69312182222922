import { Card, CardHeader, Typography, CardContent, Box } from '@mui/material';
import { FC } from 'react';
import { ListCampaignWithContacts } from '../../ctx/listCampaigns/ListCampaign.context';
import { fDate } from '../../utils/formatTime';
import { ListCampaignAvatar } from '../../utils/stringAvatar';
import ListCampaignReactedProgress from './ListCampaignReactedProgress';

interface IListCampaignInfoCard {
  listCampaignData: ListCampaignWithContacts;
}
const ListCampaignInfoCard: FC<IListCampaignInfoCard> = ({ listCampaignData }) => {
  return (
    <Card>
      <CardHeader
        title={`Kampaň: ${listCampaignData.caption}`}
        avatar={<ListCampaignAvatar campaign={listCampaignData} />}
        action={
          <Box>
            <Typography variant="caption">Reakcie</Typography>
            <ListCampaignReactedProgress {...listCampaignData} />
          </Box>
        }
        subheader={
          <>
            {/* <Typography variant="caption">Účtovný rok: {accountingYear?.prefix}</Typography> */}
            <Typography variant="caption">{fDate(listCampaignData.date)}</Typography>
            <br />
            <Typography variant="caption">
              Počet kontaktov: {listCampaignData.contactRelations.length}
            </Typography>
            <br />
          </>
        }
      />
      <CardContent>{listCampaignData.description}</CardContent>
    </Card>
  );
};

export default ListCampaignInfoCard;
