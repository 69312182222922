import { Add, Delete, Edit } from '@mui/icons-material';
import {
  Card,
  CardHeader,
  CardContent,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Grid,
  Button,
} from '@mui/material';
import { DataGrid, GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import { FC } from 'react';
import { useSubscription } from '../../ctx/subscriptions/Subscription.Context';
import { CompleteSubscription } from '../../ctx/subscriptions/Subscriptions.Context';
import { fEur } from '../../utils/formatNumber';
import VariableSubscriptionStateChip from '../subscriptions/VariableSubscriptionStateChip';

const CustomEditComponent = (props: GridRenderEditCellParams) => {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value; // The new value entered by the user
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };
  return (
    <TextField
      type="number"
      label="Cena"
      value={value}
      onChange={handleChange}
      InputProps={{
        endAdornment: <InputAdornment position="end">€</InputAdornment>,
      }}
    />
  );
};

interface ISubscriptionExpectedPaymentsCard {
  subscriptionData: CompleteSubscription;
  noControls?: boolean;
}
const SubscriptionExpectedPaymentsCard: FC<ISubscriptionExpectedPaymentsCard> = ({
  subscriptionData,
  noControls,
}) => {
  const {
    editSubscription,
    updateExpectedPaymentInSubscription,
    removeExpectedPaymentFromSubscription,
    addExpectedPaymentToSubscription,
  } = useSubscription();

  return (
    <Card>
      <CardHeader
        title="Očakávané platby"
        action={
          <Stack direction="row-reverse" spacing={1} alignItems="center">
            <VariableSubscriptionStateChip {...subscriptionData.actualVariableState} />
          </Stack>
        }
      />
      <CardContent>
        {!noControls && (
          <Grid container direction="row-reverse">
            <Grid item>
              <Button
                variant="outlined"
                startIcon={<Add />}
                onClick={() =>
                  addExpectedPaymentToSubscription(subscriptionData.subscriptionType.price)
                }
              >
                Pridať platbu
              </Button>
            </Grid>
          </Grid>
        )}
        <DataGrid
          rows={subscriptionData.expectedPayments.map((vp, i) => ({ price: vp, id: i }))}
          autoHeight
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true } as any}
          onCellEditCommit={(e) => {
            console.log(e);
            updateExpectedPaymentInSubscription(Number(e.id), Number(e.value));
          }}
          columns={[
            {
              field: 'id',
              headerName: 'Platba č.',
              flex: 1,
            },
            {
              field: 'monthString',
              headerName: 'Mesiac',
              valueGetter: ({ row }) => {
                const date = new Date(subscriptionData.start);
                date.setMonth(date.getMonth() + row.id);
                return `${date.toLocaleDateString('sk-SK', {
                  month: 'short',
                })} ${date.toLocaleDateString('sk-SK', { year: '2-digit' })}`;
              },
              flex: 1,
            },
            {
              field: 'price',
              headerName: 'Suma',
              renderCell: ({ row }) => fEur(row.price),
              flex: 1,
              editable: true,
              renderEditCell: (params: GridRenderEditCellParams) => (
                <CustomEditComponent {...params} />
              ),
            },
            ...(noControls
              ? []
              : [
                  {
                    field: 'actions',
                    headerName: 'Akcie',
                    renderCell: (params) => (
                      <Stack direction="row" spacing={1}>
                        <IconButton
                          onClick={() => {
                            removeExpectedPaymentFromSubscription(Number(params.row.id));
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Stack>
                    ),
                  },
                ]),
          ]}
        />
      </CardContent>
    </Card>
  );
};

export default SubscriptionExpectedPaymentsCard;
