// ----------------------------------------------------------------------

export default function TimelineItem(theme) {
  return {
    MuiTimelineItem: {
      styleOverrides: {
        root: {
          '&:before': {
            flex: 0,
          },
        },
        main: {
          border: 0,
        },
      },
    },
  };
}
