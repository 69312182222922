import { FC } from 'react';
import { object, string } from 'yup';

import { useFormik, Form, FormikProvider } from 'formik';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';

import { MyFormikTextField } from '../../MyFormikTextField';
import { SubmitListener } from '../../../utils/SubmitListener';
import { JustCompany } from '../../../ctx/contacts/NewContact.Context';

interface ICompanyInfoInfo {
  onConfirm(d: JustCompany): void;
  initialValues?: JustCompany;
  requiredFields?: Array<keyof JustCompany>;
  omitFields?: Array<keyof JustCompany>;
}

const CompanyInfoForm: FC<ICompanyInfoInfo> = ({
  onConfirm,
  initialValues,
  requiredFields,
  omitFields,
}) => {
  const ValidationSchema = object().shape({
    companyName: string().when('x', {
      is: (x) => requiredFields?.includes('companyName') || false,
      then: string().required(),
    }),
    ico: string().when('x', {
      is: (x) => requiredFields?.includes('ico') || false,
      then: string().required(),
    }),
    dic: string().when('x', {
      is: (x) => requiredFields?.includes('dic') || false,
      then: string().required(),
    }),
    contactPersonFirstName: string().when('x', {
      is: (x) => requiredFields?.includes('contactPersonFirstName') || false,
      then: string().required(),
    }),
    contactPersonLastName: string().when('x', {
      is: (x) => requiredFields?.includes('contactPersonLastName') || false,
      then: string().required(),
    }),
  });

  const formik = useFormik<JustCompany>({
    initialValues: initialValues || {
      companyName: '',
      ico: '',
      dic: '',
      icDph: '',
      dphPayer: false,
      contactPersonFirstName: '',
      contactPersonLastName: '',
    },
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      console.log(values);
      console.log('COMPANY INFO FORM');
      console.log(onConfirm);
      onConfirm({
        companyName: values.companyName,
        ico: values.ico,
        dic: values.dic,
        icDph: values.icDph,
        dphPayer: values.dphPayer,
        contactPersonFirstName: values.contactPersonFirstName,
        contactPersonLastName: values.contactPersonLastName,
      });
    },
  });
  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <SubmitListener />
        <Grid container spacing={1}>
          {!omitFields?.includes('companyName') && (
            <Grid item xs={12}>
              <MyFormikTextField
                label="Názov spoločnosti"
                fieldName="companyName"
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}
          {!omitFields?.includes('ico') && (
            <Grid item xs={6}>
              <MyFormikTextField
                label="IČO"
                fieldName="ico"
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}
          {!omitFields?.includes('dic') && (
            <Grid item xs={6}>
              <MyFormikTextField
                label="DIČ"
                fieldName="dic"
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}
          {!omitFields?.includes('icDph') && (
            <Grid item xs={6}>
              <MyFormikTextField
                label="IČ DPH"
                fieldName="icDph"
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}
          {!omitFields?.includes('dphPayer') && (
            <Grid item xs={6} container>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.dphPayer || false}
                    onChange={(e) => {
                      console.log(e.target.value);
                      formik.setFieldValue('dphPayer', e.target.checked);
                    }}
                  />
                }
                label="Platca DPH"
                name="dphPayer"
              />
            </Grid>
          )}
          {!omitFields?.includes('contactPersonFirstName') && (
            <Grid item xs={6}>
              <MyFormikTextField
                label="Meno kon. osoby"
                fieldName="contactPersonFirstName"
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}
          {!omitFields?.includes('contactPersonLastName') && (
            <Grid item xs={6}>
              <MyFormikTextField
                label="Priezvisko kon. osoby"
                fieldName="contactPersonLastName"
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default CompanyInfoForm;
