import { FC, useState } from 'react';
import { Box, Button, Card, CardContent, Collapse, Grid, Stack, Typography } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import { useNavigate } from 'react-router-dom';
import { Delete, ExpandLess, ExpandMore, Label, UploadFile } from '@mui/icons-material';
import { NewContactProvider } from '../ctx/contacts/NewContact.Context';
import { useContacts } from '../ctx/contacts/Contacts.Context';
import NewContact from '../components/contacts/NewContact';
import AddContactsSelector from '../components/contacts/AddContactsSelector';
import RightDrawer from '../components/RightDrawer';
import AddTags from '../components/contacts/massactions/AddTags';
import Scrollbar from '../layouts/authorized/Scrollbar';
import { TagsProvider } from '../ctx/tags/Tags.Context';
import CSVDownload from '../components/contacts/massactions/CSVDownload';

const Contacts: FC = () => {
  const { selectedContacts, setSelectedContacts, pullSelectedContacts, deleteContacts } =
    useContacts();

  const [addingContact, setAddingContact] = useState<boolean>(false);
  const handleStartAddingContact = () => setAddingContact(true);
  const handleStopAddingContact = () => setAddingContact(false);

  const [massActions, setMassActions] = useState<boolean>(false);
  const handleStartMassActions = () => setMassActions(true);
  const handleStopMassActions = () => setMassActions(false);

  const [addingTags, setAddingTags] = useState<boolean>(false);
  const handleStartAddingTags = () => setAddingTags(true);
  const handleStopAddingTags = () => setAddingTags(false);

  const navigate = useNavigate();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} spacing={1} container>
          <Grid item>
            <Typography variant="h4">Kontakty</Typography>
          </Grid>

          <Grid item xs />
          <Grid item>
            <Button
              variant="outlined"
              startIcon={!massActions ? <ExpandMore /> : <ExpandLess />}
              onClick={massActions ? handleStopMassActions : handleStartMassActions}
            >
              Hromadné akcie
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              disabled={addingContact}
              onClick={handleStartAddingContact}
            >
              Nový kontakt
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              startIcon={<UploadFile />}
              onClick={() => navigate('/import/contacts')}
            >
              Importovať kontakty
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ ...(!massActions && { pt: '0 !important' }), transition: 'all 1s' }}
        >
          <Collapse
            in={massActions}
            sx={{
              transition: 'all 2s',
              boxShadow: '0px 0px 0px 1px rgba(224, 224, 224, 0)',
              backgroundColor: 'rgba(224, 224, 224, 0)',
              outline: 'solid 1px rgba(224, 224, 224, 1)',
            }}
            component={Card}
          >
            <CardContent>
              <Stack direction="row-reverse" spacing={1}>
                <Button variant="outlined" startIcon={<Label />} onClick={handleStartAddingTags}>
                  Otagovať
                </Button>
                <CSVDownload getContacts={pullSelectedContacts} />
                <Button
                  variant="outlined"
                  startIcon={<Delete />}
                  onClick={() => deleteContacts(selectedContacts)}
                >
                  Odstrániť
                </Button>
              </Stack>
            </CardContent>
          </Collapse>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ ...(!addingContact && { padding: '0 !important' }), transition: 'all 1s' }}
        >
          <Collapse in={addingContact}>
            <NewContactProvider>
              <NewContact handleStopAdding={handleStopAddingContact} />
            </NewContactProvider>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <AddContactsSelector
            selectionEnabled={massActions}
            onConfirm={(data) => {
              setSelectedContacts(data);
            }}
            autoConfirm
          />
        </Grid>
      </Grid>
      <RightDrawer isOpened={addingTags} handleClose={handleStopAddingTags}>
        <Scrollbar sx={{ m: 3 }}>
          <TagsProvider>
            <AddTags selectedContacts={selectedContacts} onSuccess={handleStopAddingTags} />
          </TagsProvider>
        </Scrollbar>
      </RightDrawer>
    </>
  );
};

export default Contacts;
