import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFuse } from '../../hooks/useFuse';
import { useApi } from '../Api';
import { WithFuzzyContext } from '../contacts/Contacts.Context';

export interface StockItem {
  rawUnitPrice: number;
  title: string;
}

export interface CompleteStockItem extends StockItem {
  _id: string;
}

interface IStockItemsContext extends WithFuzzyContext {
  stockItemsData?: CompleteStockItem[];
  pullData: () => Promise<CompleteStockItem[]>;
  deleteStockItem: (stockItemsId: string) => Promise<void>;
  createStockItem: (data: StockItem) => Promise<void>;
  updateStockItem: (stockItemId: string, data: StockItem | CompleteStockItem) => Promise<void>;
}

const StockItemsContext = createContext<IStockItemsContext>({
  stockItemsData: [],
  pullData: () => undefined,
  deleteStockItem: () => undefined,
  createStockItem: () => undefined,
  updateStockItem: () => undefined,
  hits: undefined,
  query: undefined,
  onSearch: undefined,
  onClearQuery: undefined,
});

export const useStockItems = () => {
  const context = useContext(StockItemsContext);
  if (!context) {
    throw new Error('Parent must be wrapped inside StockItemsProvider');
  }

  return context;
};

interface IStockItemsProvider {
  dataProvider?: boolean;
  initialData?: CompleteStockItem[];
}

export const StockItemsProvider: FC<IStockItemsProvider> = ({
  dataProvider = true,
  children,
  initialData,
}) => {
  const { API, defaultErrorHandle } = useApi();

  const [stockItemsData, setStockItemsData] = useState<CompleteStockItem[]>(initialData || []);

  const { hits, query, onSearch, onClearQuery } = useFuse(stockItemsData, {
    keys: ['title', 'price'],
    includeMatches: true,
    matchAllOnEmptyQuery: false,
  });

  const pullData = useCallback(async (): Promise<CompleteStockItem[]> => {
    if (!API) return undefined;
    try {
      const res = await API.get(`stockItems`);
      if (dataProvider) setStockItemsData(res.data);
      return res.data;
    } catch (e: any) {
      defaultErrorHandle(e);
      return undefined;
    }
  }, [API, dataProvider, defaultErrorHandle]);
  useEffect(() => {
    if (dataProvider) pullData();
  }, [dataProvider, pullData]);

  const deleteStockItem = useCallback(
    async (stockItemId: string) => {
      if (!API) return;
      try {
        const res = await API.delete(`stockItems/${stockItemId}`);
        console.log(res);
        pullData();
      } catch (e: any) {
        defaultErrorHandle(e);
      }
    },
    [API, defaultErrorHandle, pullData]
  );

  const createStockItem = useCallback(
    async (data: StockItem) => {
      if (!API) return;
      try {
        const res = await API.post(`stockItems`, data);
        console.log(res);
        pullData();
      } catch (e: any) {
        defaultErrorHandle(e);
      }
    },
    [API, defaultErrorHandle, pullData]
  );

  const updateStockItem = useCallback(
    async (stockItemId: string, data: StockItem | CompleteStockItem) => {
      try {
        const res = await API.put(`stockItems/${stockItemId}`, data);
        console.log(res);
        pullData();
      } catch (e: any) {
        defaultErrorHandle(e);
      }
    },
    [API, defaultErrorHandle, pullData]
  );

  const contextObjects = useMemo(
    () => ({
      stockItemsData,
      pullData,
      deleteStockItem,
      createStockItem,
      updateStockItem,
      hits,
      query,
      onSearch,
      onClearQuery,
    }),
    [
      stockItemsData,
      pullData,
      deleteStockItem,
      createStockItem,
      updateStockItem,
      hits,
      query,
      onSearch,
      onClearQuery,
    ]
  );

  return <StockItemsContext.Provider value={contextObjects}>{children}</StockItemsContext.Provider>;
};
