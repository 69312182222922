import { FC } from 'react';
import { DataGrid, GridColDef, GridRowId } from '@mui/x-data-grid';
import { IconButton, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { claimItemTotal } from '../../utils/claimsCalculations';
import { useNewClaim } from '../../ctx/NewClaim.Context';
import { fEur, fPercent } from '../../utils/formatNumber';

export interface ClaimItem {
  rawUnitPrice: number;
  unitsCount: number;
  discount: number;
  title: string;
}

const itemColumns = (removeItem: (idx: number) => boolean): GridColDef[] => {
  return [
    {
      field: 'title',
      headerName: 'Názov',
      flex: 2,
    },
    {
      field: 'rawUnitPrice',
      headerName: 'Jednotková cena',
      flex: 1,
      align: 'right',
      renderCell: ({ row }) => fEur(row.rawUnitPrice),
    },
    {
      field: 'discount',
      headerName: 'Zľava',
      flex: 1,
      align: 'right',
      renderCell: ({ row }) => fPercent(row.discount),
    },
    {
      field: 'unitsCount',
      headerName: 'Počet ks',
      flex: 1,
      align: 'right',
    },
    {
      field: 'finalPrice',
      headerName: 'Výsledná cena',
      flex: 1,
      align: 'right',
      renderCell: ({ row }) => fEur(row.finalPrice),
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      renderCell: ({ row }) => (
        <Stack spacing={1}>
          <IconButton onClick={() => removeItem(row.idx)}>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    },
  ];
};

interface IClaimItemsTable {
  items: Array<ClaimItem & { id: GridRowId; idx: number }>;
}

export const ClaimItemsTable: FC<IClaimItemsTable> = ({ items }) => {
  const { removeItem } = useNewClaim();

  return <DataGrid hideFooter autoHeight columns={itemColumns(removeItem)} rows={items} />;
};
