import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Papa from 'papaparse';
import { useFeedback } from '../../../ctx/Feedback';

export interface IDataSource {
  fileName: string;
  fileText: string;
  featureName: string;
}

interface ILyduskaContext {
  addFile: (e: IDataSource) => void;
  files: IDataSource[];
  toDownload: any[];
  headers: string[];
}

const LyduskaContext = createContext<ILyduskaContext>({
  addFile: () => undefined,
  files: undefined,
  toDownload: [],
  headers: [],
});

export const useLyduska = () => {
  const context = useContext(LyduskaContext);
  if (!context) {
    throw new Error('Parent must be wrapped inside LyduskaProvider');
  }

  return context;
};

interface ILyduskaProvider {}

export const LyduskaProvider: FC<ILyduskaProvider> = ({ children }) => {
  const { error } = useFeedback();

  const [files, setFiles] = useState<IDataSource[]>([]);
  const [toDownload, setToDownload] = useState<any[]>([]);
  const [headers, setHeaders] = useState<string[]>([]);

  const addFile = useCallback((file: IDataSource) => {
    setFiles((p) => [file, ...p]);
  }, []);

  const parseFileText = useCallback(
    (fileText: string) => {
      if (!fileText) return [];
      const parsed = Papa.parse(fileText);
      // eslint-disable-next-line prefer-destructuring
      const data: [] = parsed.data;
      const headers: [] = data.shift();
      if (!headers) {
        error('Súbor neobsahuje záhlavie.');
        return [];
      }

      const result: any[] = [];
      data.forEach((d: any) => {
        const parsedLine: any = {};
        headers.forEach((h, i) => {
          if (!h) return;
          const fieldValue = d[i];
          parsedLine[h] = fieldValue;
        });

        result.push(parsedLine);
      });
      console.log(result);
      return result;
    },
    [error]
  );

  const constructResult = () => {
    const res = [];
    // const headers = new Set();
    files.forEach((f) => {
      console.log(parseFileText(f.fileText));
      const parsed = parseFileText(f.fileText);

      parsed.forEach((pf) => {
        const state = pf.TIME;
        if (state === 'GEO (Labels)') return;
        Object.entries(pf).forEach(([key, value]) => {
          if (key === 'TIME') return;
          const year = key;
          const stateInRes = res.find((s) => s.state === state && s.year === year);
          if (!stateInRes) {
            const toAdd = { state, year };
            toAdd[f.featureName] = value;
            res.push(toAdd);
            return;
          }
          stateInRes[f.featureName] = value;

          // const featureKey = `${key} - ${f.featureName}`;
          // headers.add(f.featureName);
          // if (stateInRes[featureKey]) {
          // stateInRes[key] = [...stateInRes[key], { feature: f.featureName, value }];
          // } else {
          // stateInRes[key] = [{ feature: f.featureName, value }];
          // }
        });
      });
    });
    console.log(res);
    const headersArr = Array.from(headers);
    headersArr.sort();
    console.log(headersArr);
    setHeaders(['state', ...headersArr] as string[]);
    setToDownload(res);
  };

  useEffect(constructResult, [files, parseFileText]);

  const contextObjects = useMemo(
    () => ({
      addFile,
      files,
      toDownload,
      headers,
    }),
    [addFile, files, toDownload, headers]
  );

  return <LyduskaContext.Provider value={contextObjects}>{children}</LyduskaContext.Provider>;
};
