import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFeedback } from './Feedback';
import { useApi } from './Api';
import {
  AccountingYear,
  Address,
  BasicContactInfo,
  Claim,
  ClaimGroup,
  ClaimItem,
  Company,
  CompleteAccountingYear,
  CompleteClaim,
  Individual,
  PaymentForm,
} from './Claims.Context';
import { applyDiscount, claimTotal, round10 } from '../utils/claimsCalculations';
import { fEur } from '../utils/formatNumber';
import { ContactTypes, JustCompany, JustContact } from './contacts/NewContact.Context';
import {
  CompleteCompany,
  CompleteIndividual,
  isCompany,
  isIndividual,
} from './contacts/Contact.Context';
import { CompleteContact } from '../components/contact/IndividualBusinessCard';

export const isCompleteAddress = (a?: Address) =>
  !!a && !!a.street && !!a.city && !!a.state && !!a.zip;

interface INewClaimContext {
  items: ClaimItem[];
  totalString: string;
  rawTotalString: string;
  accountingYear: AccountingYear | undefined;
  isValid: boolean;
  isMultiPayers: boolean;
  en: boolean;
  discount: number;
  payerType: ContactTypes;
  claimantType: ContactTypes;
  multiPayers: Array<CompleteIndividual | CompleteCompany>;
  setMultiPayers(contacts: CompleteContact[]): void;
  setDiscount(discount: number): void;
  setIsMultiPayers(is: boolean): void;
  setEn(en: boolean): void;
  addItem(item: ClaimItem): boolean;
  removeItem(idx: number): boolean;
  updateItem(idx: number, item: ClaimItem): boolean;
  addMultiPayer(item: CompleteIndividual | CompleteCompany): void;
  removeMultiPayer(idx: number): void;

  setClaimantCompanyContact(claimant: JustCompany): void;
  setClaimantContact(claimant: JustContact): void;
  setClaimantBasicContactInfo(claimant: BasicContactInfo): void;

  setPayerCompanyContact(payer: JustCompany): void;
  setPayerContact(payer: JustContact): void;
  setPayerBasicContactInfo(payer: BasicContactInfo): void;

  setPayerType(type: ContactTypes): void;
  setClaimantType(type: ContactTypes): void;
  submitNewClaim(): Promise<boolean>;

  claimantDatabaseIndividualContact: CompleteIndividual;
  setClaimantDatabaseIndividualContact: (c: CompleteIndividual) => void;
  payerDatabaseIndividualContact: CompleteIndividual;
  setPayerDatabaseIndividualContact: (c: CompleteIndividual) => void;
  claimantDatabaseCompanyContact: CompleteCompany;
  setClaimantDatabaseCompanyContact: (c: CompleteCompany) => void;
  payerDatabaseCompanyContact: CompleteCompany;
  setPayerDatabaseCompanyContact: (c: CompleteCompany) => void;

  hardNumber: number | undefined;
  setHardNumber: (c?: number) => void;
  hardDate: Date | undefined;
  setHardDate: (c?: Date) => void;

  issued: Date;
  setIssued: (c?: Date) => void;
  deliveryDate: Date;
  setDeliveryDate: (c?: Date) => void;
  maturityDate: Date;
  setMaturityDate: (c?: Date) => void;

  orderNumber?: number;
  setOrderNumber: (c: number) => void;
  paymentForm?: PaymentForm;
  setPaymentForm: (c: PaymentForm) => void;

  claimGroupValue: 'new' | 'existing' | 'none';
  setClaimGroupValue: (value: 'new' | 'existing' | 'none') => void;
  claimGroups: ClaimGroup[];
  selectedGroup: ClaimGroup;
  setSelectedGroup: (id: string) => void;
  newGroupName: string;
  setNewGroupName: (name: string) => void;

  claimantCompanyContact: JustCompany;
  claimantContact: JustContact;
  claimantBasicContactInfo: BasicContactInfo;
  payerCompanyContact: JustCompany;
  payerContact: JustContact;
  payerBasicContactInfo: BasicContactInfo;
}

const NewClaimContext = createContext<INewClaimContext>({
  items: [],
  multiPayers: [],
  totalString: '-',
  rawTotalString: '-',
  accountingYear: undefined,
  payerType: ContactTypes.Individual,
  claimantType: ContactTypes.Company,
  isValid: false,
  isMultiPayers: false,
  en: false,
  discount: 0,
  setDiscount: () => undefined,
  setIsMultiPayers: () => undefined,
  setEn: () => undefined,
  addItem: () => false,
  removeItem: () => false,
  updateItem: () => false,
  addMultiPayer: () => false,
  removeMultiPayer: () => false,
  setMultiPayers: () => false,

  setClaimantType: () => undefined,
  setClaimantContact: () => undefined,
  setClaimantCompanyContact: () => undefined,
  setClaimantBasicContactInfo: () => undefined,

  setPayerType: () => undefined,
  setPayerContact: () => undefined,
  setPayerCompanyContact: () => undefined,
  setPayerBasicContactInfo: () => undefined,

  submitNewClaim: async () => false,

  claimantDatabaseIndividualContact: undefined,
  setClaimantDatabaseIndividualContact: () => undefined,
  payerDatabaseIndividualContact: undefined,
  setPayerDatabaseIndividualContact: () => undefined,
  claimantDatabaseCompanyContact: undefined,
  setClaimantDatabaseCompanyContact: () => undefined,
  payerDatabaseCompanyContact: undefined,
  setPayerDatabaseCompanyContact: () => undefined,

  hardNumber: undefined,
  setHardNumber: () => undefined,
  hardDate: undefined,
  setHardDate: () => undefined,

  issued: new Date(),
  setIssued: () => undefined,
  deliveryDate: new Date(),
  setDeliveryDate: () => undefined,
  maturityDate: new Date(),
  setMaturityDate: () => undefined,

  orderNumber: undefined,
  setOrderNumber: () => undefined,
  paymentForm: undefined,
  setPaymentForm: () => undefined,

  claimGroupValue: 'none',
  claimGroups: [],
  selectedGroup: undefined,
  setSelectedGroup: () => undefined,
  newGroupName: '',
  setNewGroupName: () => undefined,
  setClaimGroupValue: () => undefined,

  claimantCompanyContact: undefined,
  claimantContact: undefined,
  claimantBasicContactInfo: undefined,
  payerCompanyContact: undefined,
  payerContact: undefined,
  payerBasicContactInfo: undefined,
});

export const useNewClaim = () => {
  const context = useContext(NewClaimContext);
  if (!context) {
    throw new Error('Parent must be wrapped inside NewClaimProvider');
  }
  return context;
};

interface INewClaimProvider {
  accountingYear: CompleteAccountingYear | undefined;
  onNewClaimSuccess?: Function;
  editing?: CompleteClaim;
}

export const NewClaimProvider: FC<INewClaimProvider> = ({
  accountingYear,
  onNewClaimSuccess,
  editing,
  children,
}) => {
  const { API } = useApi();
  const { error, success } = useFeedback();

  const [items, setItems] = useState<ClaimItem[]>(editing ? editing.items : []);

  const [claimantContact, setClaimantContact] = useState<JustContact>(
    editing && isIndividual(editing.claimant)
      ? {
          firstName: editing.claimant.firstName,
          lastName: editing.claimant.lastName,
          gender: editing.claimant.gender,
        }
      : undefined
  );
  const [payerContact, setPayerContact] = useState<JustContact>(
    editing && isIndividual(editing.payer)
      ? {
          firstName: editing.payer.firstName,
          lastName: editing.payer.lastName,
          gender: editing.payer.gender,
        }
      : undefined
  );

  const [claimantCompanyContact, setClaimantCompanyContact] = useState<JustCompany>(
    editing && isCompany(editing.claimant)
      ? {
          companyName: editing.claimant.companyName,
          ico: editing.claimant.ico,
          dic: editing.claimant.dic,
          icDph: editing.claimant.icDph,
          contactPersonFirstName: editing.claimant.contactPersonFirstName,
          contactPersonLastName: editing.claimant.contactPersonLastName,
        }
      : undefined
  );
  const [payerCompanyContact, setPayerCompanyContact] = useState<JustCompany>(
    editing && isCompany(editing.payer)
      ? {
          companyName: editing.payer.companyName,
          ico: editing.payer.ico,
          dic: editing.payer.dic,
          icDph: editing.payer.icDph,
          contactPersonFirstName: editing.payer.contactPersonFirstName,
          contactPersonLastName: editing.payer.contactPersonLastName,
        }
      : undefined
  );

  const [payerType, setPayerType] = useState<ContactTypes>(
    editing && isCompany(editing.payer) ? ContactTypes.Company : ContactTypes.Individual
  );
  const [claimantType, setClaimantType] = useState<ContactTypes>(
    editing && isIndividual(editing.claimant) ? ContactTypes.Individual : ContactTypes.Company
  );

  const [payerBasicContactInfo, setPayerBasicContactInfo] = useState<BasicContactInfo>(
    editing
      ? {
          email: editing.payer.email,
          phoneNumber: editing.payer.phoneNumber,
          address: editing.payer.address,
          iban: editing.payer.iban,
          fullName: editing.payer.fullName,
          otherEmails: editing.payer.otherEmails,
          otherAddresses: editing.payer.otherAddresses,
          otherPhoneNumbers: editing.payer.otherPhoneNumbers,
          otherIbans: editing.payer.otherIbans,
          contactPersons: editing.payer.contactPersons,
          swift: editing.payer.swift,
          bank: editing.payer.bank,
        }
      : undefined
  );
  const [claimantBasicContactInfo, setClaimantBasicContactInfo] = useState<BasicContactInfo>(
    editing
      ? {
          email: editing.claimant.email,
          phoneNumber: editing.claimant.phoneNumber,
          address: editing.claimant.address,
          iban: editing.claimant.iban,
          fullName: editing.claimant.fullName,
          otherEmails: editing.claimant.otherEmails,
          otherAddresses: editing.claimant.otherAddresses,
          otherPhoneNumbers: editing.claimant.otherPhoneNumbers,
          otherIbans: editing.claimant.otherIbans,
          contactPersons: editing.claimant.contactPersons,
          swift: editing.claimant.swift,
          bank: editing.claimant.bank,
        }
      : undefined
  );

  // editing and what
  const [claimantDatabaseIndividualContact, setClaimantDatabaseIndividualContact] =
    useState<CompleteIndividual>();
  const [payerDatabaseIndividualContact, setPayerDatabaseIndividualContact] =
    useState<CompleteIndividual>();

  const [claimantDatabaseCompanyContact, setClaimantDatabaseCompanyContact] =
    useState<CompleteCompany>();
  const [payerDatabaseCompanyContact, setPayerDatabaseCompanyContact] = useState<CompleteCompany>();

  const [isMultiPayers, setIsMultiPayers] = useState<boolean>(false);
  const [en, setEn] = useState<boolean>(false);
  const [multiPayers, setMultiPayers] = useState<Array<CompleteIndividual | CompleteCompany>>([]);

  const [discount, setDiscount] = useState<number>(editing ? editing.discount : 0);

  const payer: Company | Individual = useMemo(
    () =>
      (payerType === ContactTypes.Company && {
        ...payerCompanyContact,
        ...payerBasicContactInfo,
        type: ContactTypes.Company,
      }) ||
      (payerType === ContactTypes.Individual && {
        ...payerContact,
        ...payerBasicContactInfo,
        type: ContactTypes.Individual,
      }),
    [payerBasicContactInfo, payerCompanyContact, payerContact, payerType]
  );

  const claimant: Company | Individual = useMemo(
    () =>
      (claimantType === ContactTypes.Company && {
        ...claimantCompanyContact,
        ...claimantBasicContactInfo,
        type: ContactTypes.Company,
      }) ||
      (claimantType === ContactTypes.Individual && {
        ...claimantContact,
        ...claimantBasicContactInfo,
        type: ContactTypes.Individual,
      }),
    [claimantBasicContactInfo, claimantCompanyContact, claimantContact, claimantType]
  );

  const isValid: boolean =
    isCompleteAddress(claimant.address) &&
    (isCompleteAddress(payer.address) || (isMultiPayers && multiPayers.length > 0)) &&
    items.length > 0;

  const rawTotalString = fEur(claimTotal({ items }));
  const totalString = fEur(claimTotal({ items, discount }));

  const [hardNumber, setHardNumber] = useState<number>();
  const [hardDate, setHardDate] = useState<Date>();

  const [issued, setIssued] = useState<Date>(editing ? editing.issued : new Date());
  const [deliveryDate, setDeliveryDate] = useState<Date>(
    editing ? editing.deliveryDate : new Date()
  );
  const [maturityDate, setMaturityDate] = useState<Date>(
    editing ? editing.maturityDate : new Date()
  );

  const [orderNumber, setOrderNumber] = useState<number>(editing ? editing.orderNumber : undefined);
  const [paymentForm, setPaymentForm] = useState<PaymentForm>(
    editing ? editing.paymentForm : PaymentForm.Account
  );

  const [claimGroupValue, setClaimGroupValue] = useState<'none' | 'new' | 'existing'>('none');
  const [claimGroups, setClaimGroups] = useState<ClaimGroup[]>([]);
  const [selectedGroup, setSelectedClaimGroup] = useState<ClaimGroup>();
  const setSelectedGroup = useCallback(
    (id: string) => setSelectedClaimGroup(claimGroups.find((cg) => cg._id === id)),
    [claimGroups]
  );
  const [newGroupName, setNewGroupName] = useState<string>();

  const pullClaimGroups = useCallback(() => {
    if (!API || !accountingYear) return;
    // setIsLoading(true);
    API.get(`/claimGroups/ofAccountingYear/${accountingYear?._id}`)
      .then((res) => {
        console.log('claimgrupen', res.data);
        setClaimGroups(res.data);
      })
      .catch((e: any) => {
        error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
      });
  }, [API, accountingYear, error]);

  useEffect(pullClaimGroups, [pullClaimGroups]);

  const addItem = useCallback((item: ClaimItem): boolean => {
    setItems((p) => [...p, item]);
    return true;
  }, []);

  const removeItem = useCallback((idx: number): boolean => {
    console.log('removing', idx);
    setItems((p) => {
      p.splice(idx, 1);
      return [...p];
    });
    return true;
  }, []);

  const updateItem = useCallback((idx: number, item: ClaimItem): boolean => {
    setItems((p) => [...p.splice(idx, 1, item)]);
    return true;
  }, []);

  const addMultiPayer = useCallback((item: CompleteIndividual | CompleteCompany) => {
    setMultiPayers((p) => [...p, item]);
  }, []);

  const removeMultiPayer = useCallback((idx: number) => {
    console.log(idx);

    setMultiPayers((p) => {
      p.splice(idx, 1);
      return [...p];
    });
  }, []);

  const submitNewClaim = useCallback(async () => {
    if (!API || !isValid || !accountingYear) return false;
    // if well in grup, create
    const claimGroups = [];

    if (editing) {
      const newClaim: Claim = {
        items,
        payer,
        claimant,
        orderNumber,
        paymentForm,
        rawTotal: claimTotal({ items }),
        accountingYear: accountingYear._id,
        discount,
        issued,
        maturityDate,
        deliveryDate,
        finalPrice: round10(applyDiscount(claimTotal({ items }), discount), -2),
      };

      console.log(newClaim);

      if (payerType === ContactTypes.Individual && payerDatabaseIndividualContact) {
        newClaim.whichPayer = ContactTypes.Individual;
        newClaim.payerRef = payerDatabaseIndividualContact._id;
      }

      if (payerType === ContactTypes.Company && payerDatabaseCompanyContact) {
        newClaim.whichPayer = ContactTypes.Company;
        newClaim.payerRef = payerDatabaseCompanyContact._id;
      }

      if (claimantType === ContactTypes.Individual && claimantDatabaseIndividualContact) {
        newClaim.whichClaimant = ContactTypes.Individual;
        newClaim.claimantRef = claimantDatabaseIndividualContact._id;
      }

      if (claimantType === ContactTypes.Company && claimantDatabaseCompanyContact) {
        newClaim.whichClaimant = ContactTypes.Company;
        newClaim.claimantRef = claimantDatabaseCompanyContact._id;
      }

      const newClaimToSend: any = newClaim;
      if (hardDate !== undefined) newClaimToSend.issued = hardDate;
      if (hardNumber !== undefined) newClaimToSend.hardNumber = hardNumber;

      try {
        console.log('newClaimToSend', newClaimToSend);

        const res = await API.put(`/claims/${editing._id}`, newClaimToSend);
        success('Pohľadávka vytvorená.');
        if (onNewClaimSuccess) onNewClaimSuccess();
        return true;
      } catch (e: any) {
        error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
        return false;
      }
    }

    console.log('submitting');
    if (claimGroupValue === 'new') {
      console.log('creating new claimgroup');
      // tba
      const newGroup = await API.post('/claimGroups', {
        title: newGroupName,
        accountingYear: accountingYear._id,
      });
      console.log(newGroup);
      claimGroups.push(newGroup.data._id);
    }
    if (claimGroupValue === 'existing' && selectedGroup) {
      claimGroups.push(selectedGroup._id);
    }

    if (isMultiPayers) {
      const created = await Promise.all(
        multiPayers.map(async (payer) => {
          if (!isCompleteAddress(payer.address)) return 0;
          const newClaim: Claim = {
            items,
            payer,
            claimant,
            orderNumber,
            paymentForm,
            rawTotal: claimTotal({ items }),
            accountingYear: accountingYear._id,
            payerRef: payer._id,
            discount,
            claimGroups,
            issued,
            maturityDate,
            deliveryDate,
            finalPrice: round10(applyDiscount(claimTotal({ items }), discount), -2),
          };

          if (claimantType === ContactTypes.Individual && claimantDatabaseIndividualContact) {
            newClaim.whichClaimant = ContactTypes.Individual;
            newClaim.claimantRef = claimantDatabaseIndividualContact._id;
          }

          if (claimantType === ContactTypes.Company && claimantDatabaseCompanyContact) {
            newClaim.whichClaimant = ContactTypes.Company;
            newClaim.claimantRef = claimantDatabaseCompanyContact._id;
          }

          const newClaimToSend: any = newClaim;
          if (hardDate !== undefined) newClaimToSend.issued = hardDate;

          try {
            const res = await API.post(`/claims`, { ...newClaimToSend, en });
            console.log(res);
            if (onNewClaimSuccess) onNewClaimSuccess();
            return 1;
          } catch (e: any) {
            error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
            return 0;
          }
        })
      );
      const count = created.reduce((p, c) => p + c, 0);
      success(`Podarilo sa vytvoriť ${count} pohľadávok.`);
      return true;
    }
    const newClaim: Claim = {
      items,
      payer,
      claimant,
      orderNumber,
      paymentForm,
      rawTotal: claimTotal({ items }),
      accountingYear: accountingYear._id,
      discount,
      claimGroups,
      issued,
      maturityDate,
      deliveryDate,
      finalPrice: round10(applyDiscount(claimTotal({ items }), discount), -2),
    };

    console.log(newClaim);

    if (payerType === ContactTypes.Individual && payerDatabaseIndividualContact) {
      newClaim.whichPayer = ContactTypes.Individual;
      newClaim.payerRef = payerDatabaseIndividualContact._id;
    }

    if (payerType === ContactTypes.Company && payerDatabaseCompanyContact) {
      newClaim.whichPayer = ContactTypes.Company;
      newClaim.payerRef = payerDatabaseCompanyContact._id;
    }

    if (claimantType === ContactTypes.Individual && claimantDatabaseIndividualContact) {
      newClaim.whichClaimant = ContactTypes.Individual;
      newClaim.claimantRef = claimantDatabaseIndividualContact._id;
    }

    if (claimantType === ContactTypes.Company && claimantDatabaseCompanyContact) {
      newClaim.whichClaimant = ContactTypes.Company;
      newClaim.claimantRef = claimantDatabaseCompanyContact._id;
    }

    const newClaimToSend: any = newClaim;
    if (hardDate !== undefined) newClaimToSend.issued = hardDate;
    if (hardNumber !== undefined) newClaimToSend.hardNumber = hardNumber;

    try {
      console.log('newClaimToSend', newClaimToSend);

      const res = await API.post(`/claims`, { ...newClaimToSend, en });
      success('Pohľadávka vytvorená.');
      if (onNewClaimSuccess) onNewClaimSuccess();
      return true;
    } catch (e: any) {
      error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
      return false;
    }
  }, [
    API,
    isValid,
    accountingYear,
    editing,
    claimGroupValue,
    selectedGroup,
    isMultiPayers,
    items,
    payer,
    claimant,
    orderNumber,
    paymentForm,
    discount,
    issued,
    maturityDate,
    deliveryDate,
    payerType,
    payerDatabaseIndividualContact,
    payerDatabaseCompanyContact,
    claimantType,
    claimantDatabaseIndividualContact,
    claimantDatabaseCompanyContact,
    hardDate,
    hardNumber,
    success,
    onNewClaimSuccess,
    error,
    newGroupName,
    multiPayers,
    en,
  ]);

  const contextObjects = useMemo(
    () => ({
      items,
      accountingYear,
      rawTotalString,
      totalString,
      isValid,
      discount,
      payerType,
      claimantType,
      setPayerType,
      setClaimantType,
      setDiscount,
      setClaimantCompanyContact,
      setClaimantContact,
      setPayerCompanyContact,
      setPayerContact,
      setPayerBasicContactInfo,
      setClaimantBasicContactInfo,
      submitNewClaim,
      addItem,
      removeItem,
      updateItem,
      claimantDatabaseIndividualContact,
      setClaimantDatabaseIndividualContact,
      payerDatabaseIndividualContact,
      setPayerDatabaseIndividualContact,
      claimantDatabaseCompanyContact,
      setClaimantDatabaseCompanyContact,
      payerDatabaseCompanyContact,
      setPayerDatabaseCompanyContact,
      isMultiPayers,
      setIsMultiPayers,
      multiPayers,
      addMultiPayer,
      removeMultiPayer,
      setMultiPayers,
      hardNumber,
      setHardNumber,
      hardDate,
      setHardDate,
      claimGroupValue,
      claimGroups,
      selectedGroup,
      setSelectedGroup,
      newGroupName,
      setNewGroupName,
      setClaimGroupValue,
      issued,
      setIssued,
      deliveryDate,
      setDeliveryDate,
      maturityDate,
      setMaturityDate,
      orderNumber,
      setOrderNumber,
      paymentForm,
      setPaymentForm,
      claimantCompanyContact,
      claimantContact,
      claimantBasicContactInfo,
      payerCompanyContact,
      payerContact,
      payerBasicContactInfo,
      en,
      setEn,
    }),
    [
      items,
      accountingYear,
      rawTotalString,
      totalString,
      isValid,
      discount,
      payerType,
      claimantType,
      submitNewClaim,
      addItem,
      removeItem,
      updateItem,
      claimantDatabaseIndividualContact,
      payerDatabaseIndividualContact,
      claimantDatabaseCompanyContact,
      payerDatabaseCompanyContact,
      isMultiPayers,
      multiPayers,
      addMultiPayer,
      removeMultiPayer,
      hardNumber,
      hardDate,
      claimGroupValue,
      claimGroups,
      selectedGroup,
      setSelectedGroup,
      newGroupName,
      issued,
      deliveryDate,
      maturityDate,
      orderNumber,
      paymentForm,
      claimantCompanyContact,
      claimantContact,
      claimantBasicContactInfo,
      payerCompanyContact,
      payerContact,
      payerBasicContactInfo,
      en,
    ]
  );

  return <NewClaimContext.Provider value={contextObjects}>{children}</NewClaimContext.Provider>;
};
