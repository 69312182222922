import React from 'react';
import { SvgIcon } from '@mui/material';
import { ReactComponent as AvatarWoman } from './svg/avatar-woman.svg';

const AvatarWomanIcon = (props) => (
  <SvgIcon {...props}>
    <AvatarWoman />
  </SvgIcon>
);

export default AvatarWomanIcon;
