import { FC, useState } from 'react';
import { Button, Card, Grid, Skeleton, CardContent } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useApi } from '../../ctx/Api';
import UserCard from '../UserCard';
import ChangePassword from './ChangePassword';
import EditUserForm from './EditUserForm';
import RightDrawer from '../RightDrawer';

const MyAccount: FC = () => {
  const { user } = useApi();

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const handleStopEditing = () => setIsEditing(false);
  const handleStartEditing = () => setIsEditing(true);

  return (
    <Grid container spacing={5}>
      <Grid item xs={6} container spacing={3}>
        <Grid item xs={12}>
          {!user ? (
            <Skeleton>
              <UserCard user={user} />
            </Skeleton>
          ) : (
            <UserCard
              user={user}
              actions={[
                <>
                  <Button variant="outlined" startIcon={<EditIcon />} onClick={handleStartEditing}>
                    Upraviť
                  </Button>
                  <RightDrawer isOpened={isEditing} handleClose={handleStopEditing}>
                    <EditUserForm type="me" formName="Upraviť Vaše údaje" user={user} />
                  </RightDrawer>
                </>,
              ]}
            />
          )}
        </Grid>
      </Grid>

      <Grid item xs={6} container spacing={3}>
        <Grid item>
          <Card>
            <CardContent>
              <ChangePassword />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MyAccount;
