import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { Close, Group, RemoveCircle } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useNewSubscription } from '../../ctx/subscriptions/NewSubscription.Context';
import FuzzyContactSearcher from '../contact/FuzzyContactSearcher';
import { fEur } from '../../utils/formatNumber';
import { ContactsTable } from '../contacts/ContactsTable';
import ContactIconButtonAction from '../contact/actions/ContactIconButtonAction';
import RightDrawerAddContactSelector from '../RightDrawerAddContactSelector';
import { indexArray } from '../../ctx/contacts/ImportContacts.Context';

interface INewSubscription {
  handleStopAdding: () => void;
}

const NewSubscription: FC<INewSubscription> = ({ handleStopAdding }) => {
  const {
    subscriptionType,
    submitNewSubscription,
    start,
    setStart,
    addMultiPayer,
    removeMultiPayer,
    addMultiPayers,
    setEnd,
    end,
    setPrice,
    price,
    setMultiPayers,
    multiPayers,
    isValid,
  } = useNewSubscription();

  useEffect(() => {
    const date = new Date();
    date.setDate(1);
    // date.setHours(0, 0, 0, 0);
    console.log(date.toISOString());
    setStart(date);
  }, []);

  const handleSubmitNewSubscription = async () => {
    const ok = await submitNewSubscription();
    console.log(ok);
    if (ok) handleStopAdding();
  };

  const [addingPeopleToSubscription, setAddingPeopleToSubscription] = useState<boolean>(false);
  const handleStartAddingPeopleToSubscription = () => setAddingPeopleToSubscription(true);
  const handleStopAddingPeopleToSubscription = () => setAddingPeopleToSubscription(false);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Card>
          <CardContent>
            <Stack direction="column" spacing={2}>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography variant="h5" color="secondary">
                    Nové predplatné
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="caption">Typ: {subscriptionType?.prefix}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption">Cena: {fEur(subscriptionType.price)}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption">
                    Deň splatnosti v mesiaci: {subscriptionType.dayInMonth}
                  </Typography>
                </Grid>
                <Grid item xs />
                <Grid item>
                  <IconButton onClick={handleStopAdding}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider />
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Nastavenia predplatného</Typography>
                  </Grid>
                  <Grid item>
                    <DatePicker
                      label="Začiatok"
                      value={start}
                      views={['month', 'year']}
                      onChange={(value) => {
                        const date = new Date(value!);
                        date.setDate(1);
                        setStart(date);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                  <Grid item>
                    <DatePicker
                      label="Koniec"
                      value={end}
                      views={['month', 'year']}
                      onChange={(value) => {
                        const date = new Date(value!);
                        date.setDate(-1);
                        date.setMonth(date.getMonth() + 1);
                        setEnd(date);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      type="number"
                      label="Cena"
                      value={price}
                      onChange={(e) => setPrice(Number(e.target.value))}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">€</InputAdornment>,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Divider />
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} container>
                    <Grid item>
                      <Typography variant="h6">Predplatitelia</Typography>
                    </Grid>
                    <Grid item xs />
                    <Grid item>
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<Group />}
                        onClick={handleStartAddingPeopleToSubscription}
                      >
                        Upraviť hromadne
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid item md={6}>
                    <FuzzyContactSearcher
                      omit={multiPayers.map((mp) => mp._id)}
                      actions={[
                        {
                          icon: <CheckCircleIcon />,
                          shouldClosePoper: true,
                          onClick: (contactData) => addMultiPayer(contactData),
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12} container>
                    <ContactsTable
                      contacts={indexArray(multiPayers)}
                      actions={[
                        {
                          element: ContactIconButtonAction,
                          props: {
                            icon: <RemoveCircle />,
                            action: (_c, idx) => removeMultiPayer(idx),
                          },
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </CardContent>
          <CardActions>
            <Button
              color="secondary"
              variant="contained"
              startIcon={<SaveIcon />}
              disabled={!isValid}
              onClick={handleSubmitNewSubscription}
            >
              Uložiť
            </Button>
            <Button
              onClick={handleStopAdding}
              variant="outlined"
              startIcon={<CancelIcon />}
              sx={{ mr: 1 }}
            >
              Zrušiť
            </Button>
          </CardActions>
        </Card>
      </LocalizationProvider>
      <RightDrawerAddContactSelector
        opened={addingPeopleToSubscription}
        handleClose={handleStopAddingPeopleToSubscription}
        caption={`Výber kontaktov pre nové predplatné ${subscriptionType.caption}`}
        initialSelected={multiPayers.map((mp) => mp._id)}
        onConfirm={(_x) => undefined}
        onConfirmFull={setMultiPayers}
      />
    </>
  );
};

export default NewSubscription;
