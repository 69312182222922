/* eslint-disable react/prop-types */
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

const renderOptions = (options) => {
  return options.map((option) => (
    <FormControlLabel
      key={option.value}
      value={option.value}
      control={<Radio />}
      label={option.label}
    />
  ));
};

const MyFormikRadioGroup = ({
  field,
  form: { touched, errors },
  name,
  options,
  children,
  groupName,
  ...props
}) => {
  const fieldName = name || field.name;

  return (
    <>
      <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label">{groupName}</FormLabel>
        <RadioGroup {...{ ...field, value: field.value || '' }} {...props} name={fieldName} row>
          {options ? renderOptions(options) : children}
        </RadioGroup>
      </FormControl>
      {touched[fieldName] && errors[fieldName] && (
        <span style={{ color: 'red', fontFamily: 'sans-serif' }}>{errors[fieldName]}</span>
      )}
    </>
  );
};

export default MyFormikRadioGroup;
