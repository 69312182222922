import { createContext, FC, useCallback, useContext, useMemo, useState } from 'react';
import { useFeedback } from '../Feedback';
import { useApi } from '../Api';
import { SubscriptionType, CompleteSubscriptionType } from './Subscriptions.Context';
import { CompleteCompany, CompleteIndividual } from '../contacts/Contact.Context';
import { CompleteContact } from '../../components/contact/IndividualBusinessCard';

interface INewSubscriptionContext {
  subscriptionType: SubscriptionType | undefined;
  isValid: boolean;
  multiPayers: Array<CompleteIndividual | CompleteCompany>;
  start: Date;
  end: Date;
  price: number;
  setPrice: (p: number) => void;
  setStart: (d: Date) => void;
  setEnd: (d: Date) => void;
  addMultiPayer(item: CompleteIndividual | CompleteCompany): void;
  addMultiPayers(item: CompleteContact[]): void;
  setMultiPayers(item: CompleteContact[]): void;
  removeMultiPayer(idx: number): void;
  submitNewSubscription(): Promise<boolean>;
}

const NewSubscriptionContext = createContext<INewSubscriptionContext>({
  subscriptionType: undefined,
  start: undefined,
  end: undefined,
  price: undefined,
  isValid: false,
  multiPayers: [],
  setPrice: () => undefined,
  setStart: () => undefined,
  setEnd: () => undefined,
  addMultiPayer: () => undefined,
  addMultiPayers: () => undefined,
  setMultiPayers: () => undefined,
  removeMultiPayer: () => undefined,
  submitNewSubscription: async () => false,
});

export const useNewSubscription = () => {
  const context = useContext(NewSubscriptionContext);
  if (!context) {
    throw new Error('Parent must be wrapped inside NewSubscriptionProvider');
  }
  return context;
};

interface INewSubscriptionProvider {
  subscriptionType: CompleteSubscriptionType | undefined;
  onNewSubscriptionSuccess?: Function;
}

export const NewSubscriptionProvider: FC<INewSubscriptionProvider> = ({
  subscriptionType,
  onNewSubscriptionSuccess,
  children,
}) => {
  const { API, defaultErrorHandle } = useApi();
  const { error, success } = useFeedback();

  const [start, setStart] = useState<Date>(() => {
    const date = new Date();
    date.setDate(1);
    return date;
  });
  const [end, setEnd] = useState<Date>(() => {
    const date = new Date();
    date.setDate(-1);
    date.setMonth(date.getMonth() + 1);
    return date;
  });
  const [multiPayers, setMultiPayers] = useState<Array<CompleteIndividual | CompleteCompany>>([]);
  const [price, setPrice] = useState<number>(subscriptionType.price);
  const isValid = multiPayers.length > 0;

  const addMultiPayer = useCallback((item: CompleteIndividual | CompleteCompany) => {
    setMultiPayers((p) => [...p, item]);
  }, []);

  const addMultiPayers = useCallback((items: CompleteContact[]) => {
    setMultiPayers((p) => [...p, ...items]);
  }, []);

  const removeMultiPayer = useCallback((idx: number) => {
    setMultiPayers((p) => {
      p.splice(idx, 1);
      return [...p];
    });
  }, []);

  const submitNewSubscription = useCallback(async () => {
    const createdSubscriptions = await Promise.all(
      multiPayers.flatMap(async (mp) => {
        try {
          const res = await API.post('subscriptions', {
            subscriptionType,
            payer: mp._id,
            start,
            end,
            price,
          });
          return [res.data];
        } catch (e) {
          defaultErrorHandle(e);
          return [];
        }
      })
    );
    console.log(createdSubscriptions);
    if (onNewSubscriptionSuccess) onNewSubscriptionSuccess();
    return true;
  }, [
    API,
    defaultErrorHandle,
    end,
    multiPayers,
    onNewSubscriptionSuccess,
    price,
    start,
    subscriptionType,
  ]);

  const contextObjects = useMemo(
    () => ({
      multiPayers,
      subscriptionType,
      isValid,
      start,
      end,
      setStart,
      setEnd,
      addMultiPayer,
      removeMultiPayer,
      submitNewSubscription,
      addMultiPayers,
      setMultiPayers,
      price,
      setPrice,
    }),
    [
      addMultiPayer,
      addMultiPayers,
      end,
      isValid,
      multiPayers,
      price,
      removeMultiPayer,
      start,
      submitNewSubscription,
      subscriptionType,
    ]
  );

  return (
    <NewSubscriptionContext.Provider value={contextObjects}>
      {children}
    </NewSubscriptionContext.Provider>
  );
};
