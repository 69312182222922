import { FC } from 'react';
import { date, number, object, string } from 'yup';

import { useFormik, Form, FormikProvider } from 'formik';
import { Button, Grid, Stack, TextField } from '@mui/material';

import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { MyFormikTextField } from '../../MyFormikTextField';
import { SubmitListener } from '../../../utils/SubmitListener';
import { EmailOptions } from '../../../ctx/subscriptions/Subscription.Context';

interface IEmailOptionsForm {
  onConfirm(d: EmailOptions): void;
  initialValues?: EmailOptions;
  requiredFields?: Array<keyof EmailOptions>;
  omitFields?: Array<keyof EmailOptions>;
  autoSubmit?: boolean;
  disabled?: boolean;
}

const EmailOptionsForm: FC<IEmailOptionsForm> = ({
  onConfirm,
  initialValues,
  requiredFields,
  omitFields,
  disabled,
  autoSubmit = true,
}) => {
  const ValidationSchema = object().shape({
    to: string().when('x', {
      is: () => requiredFields?.includes('to') || false,
      then: string().required(),
    }),
    replyTo: string().when('x', {
      is: () => requiredFields?.includes('replyTo') || false,
      then: string().required(),
    }),
    text: string().when('x', {
      is: () => requiredFields?.includes('text') || false,
      then: string().required(),
    }),
    subject: string().when('x', {
      is: () => requiredFields?.includes('subject') || false,
      then: string().required(),
    }),
  });

  // const now = new Date();
  const formik = useFormik<Partial<EmailOptions>>({
    initialValues: initialValues || {
      to: '',
      replyTo: '',
      text: '',
      subject: '',
      from: '',
    },
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      console.log(values);
      onConfirm(values as EmailOptions);
    },
  });
  const { errors, touched, values, handleSubmit, getFieldProps, isValid, dirty } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {autoSubmit && <SubmitListener />}
        <Grid container spacing={2}>
          {!omitFields?.includes('from') && (
            <Grid item xs={12}>
              <MyFormikTextField
                label="From"
                fieldName="from"
                disabled
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}
          {!omitFields?.includes('to') && (
            <Grid item xs={12}>
              <MyFormikTextField
                label="To"
                fieldName="to"
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}
          {!omitFields?.includes('replyTo') && (
            <Grid item xs={12}>
              <MyFormikTextField
                label="Reply To"
                fieldName="replyTo"
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}
          {!omitFields?.includes('subject') && (
            <Grid item xs={12}>
              <MyFormikTextField
                label="Subject"
                fieldName="subject"
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}
          <Grid item xs={12} />
          {!omitFields?.includes('text') && (
            <Grid item xs={12}>
              <MyFormikTextField
                label="Text"
                fieldName="text"
                multiline
                minRows={4}
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}
          {!autoSubmit && (
            <Grid item xs={12}>
              <Stack direction="row-reverse" spacing={1}>
                <Stack spacing={1} direction="row-reverse">
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={!isValid || disabled}
                  >
                    Potvrdiť
                  </Button>
                  <Button variant="outlined" color="primary" type="reset" disabled={!dirty}>
                    Resetovať
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default EmailOptionsForm;
