import { createContext, FC, useCallback, useContext, useMemo, useState } from 'react';
import { useFeedback } from '../Feedback';
import { useApi } from '../Api';
import {
  AccountingYear,
  Address,
  BasicContactInfo,
  ClaimItem,
  Company,
  CompleteAccountingYear,
  CompleteClaim,
  Individual,
} from '../Claims.Context';
import { applyDiscount, claimTotal, round10 } from '../../utils/claimsCalculations';
import { fEur } from '../../utils/formatNumber';
import { ContactTypes, JustCompany, JustContact } from '../contacts/NewContact.Context';
import {
  CompleteCompany,
  CompleteIndividual,
  isCompany,
  isIndividual,
} from '../contacts/Contact.Context';

export const isCompleteAddress = (a?: Address) =>
  !!a && !!a.street && !!a.city && !!a.state && !!a.zip;

export interface IOrder {
  accountingYear: string;
  rawTotal: number;
  finalPrice: number;
  issued: Date;
  claimant: Individual | Company;
  payer: Individual | Company;
  items: ClaimItem[];
  payerRef?: string;
  claimantRef?: string;
}

interface INewOrderContext {
  items: ClaimItem[];
  totalString: string;
  rawTotalString: string;
  accountingYear: AccountingYear | undefined;
  isValid: boolean;
  payerType: ContactTypes;
  claimantType: ContactTypes;
  addItem(item: ClaimItem): boolean;
  removeItem(idx: number): boolean;
  updateItem(idx: number, item: ClaimItem): boolean;

  setClaimantCompanyContact(claimant: JustCompany): void;
  setClaimantContact(claimant: JustContact): void;
  setClaimantBasicContactInfo(claimant: BasicContactInfo): void;

  setPayerCompanyContact(payer: JustCompany): void;
  setPayerContact(payer: JustContact): void;
  setPayerBasicContactInfo(payer: BasicContactInfo): void;

  setPayerType(type: ContactTypes): void;
  setClaimantType(type: ContactTypes): void;
  submitNewOrder(): Promise<boolean>;

  claimantDatabaseIndividualContact: CompleteIndividual;
  setClaimantDatabaseIndividualContact: (c: CompleteIndividual) => void;
  payerDatabaseIndividualContact: CompleteIndividual;
  setPayerDatabaseIndividualContact: (c: CompleteIndividual) => void;
  claimantDatabaseCompanyContact: CompleteCompany;
  setClaimantDatabaseCompanyContact: (c: CompleteCompany) => void;
  payerDatabaseCompanyContact: CompleteCompany;
  setPayerDatabaseCompanyContact: (c: CompleteCompany) => void;

  issued: Date;
  setIssued: (c?: Date) => void;

  claimantCompanyContact: JustCompany;
  claimantContact: JustContact;
  claimantBasicContactInfo: BasicContactInfo;
  payerCompanyContact: JustCompany;
  payerContact: JustContact;
  payerBasicContactInfo: BasicContactInfo;
}

const NewOrderContext = createContext<INewOrderContext>({
  items: [],
  totalString: '',
  rawTotalString: '',
  accountingYear: undefined,
  isValid: false,
  payerType: ContactTypes.Individual, // replace with your actual default value
  claimantType: ContactTypes.Company, // replace with your actual default value
  addItem: () => false,
  removeItem: () => false,
  updateItem: () => false,
  setClaimantCompanyContact: () => undefined,
  setClaimantContact: () => undefined,
  setClaimantBasicContactInfo: () => undefined,
  setPayerCompanyContact: () => undefined,
  setPayerContact: () => undefined,
  setPayerBasicContactInfo: () => undefined,
  setPayerType: () => undefined,
  setClaimantType: () => undefined,
  submitNewOrder: () => undefined,
  claimantDatabaseIndividualContact: {} as CompleteIndividual,
  setClaimantDatabaseIndividualContact: () => undefined,
  payerDatabaseIndividualContact: {} as CompleteIndividual,
  setPayerDatabaseIndividualContact: () => undefined,
  claimantDatabaseCompanyContact: {} as CompleteCompany,
  setClaimantDatabaseCompanyContact: () => undefined,
  payerDatabaseCompanyContact: {} as CompleteCompany,
  setPayerDatabaseCompanyContact: () => undefined,
  issued: new Date(),
  setIssued: () => undefined,
  claimantCompanyContact: {} as JustCompany,
  claimantContact: {} as JustContact,
  claimantBasicContactInfo: {} as BasicContactInfo,
  payerCompanyContact: {} as JustCompany,
  payerContact: {} as JustContact,
  payerBasicContactInfo: {} as BasicContactInfo,
});

export const useNewOrder = () => {
  const context = useContext(NewOrderContext);
  if (!context) {
    throw new Error('Parent must be wrapped inside NewOrderProvider');
  }
  return context;
};

interface INewOrderProvider {
  accountingYear: CompleteAccountingYear | undefined;
  onNewOrderSuccess?: Function;
  editing?: CompleteClaim;
}

export const NewOrderProvider: FC<INewOrderProvider> = ({
  accountingYear,
  onNewOrderSuccess,
  editing,
  children,
}) => {
  const { API } = useApi();
  const { error, success } = useFeedback();

  const [items, setItems] = useState<ClaimItem[]>(editing ? editing.items : []);

  const [claimantContact, setClaimantContact] = useState<JustContact>(
    editing && isIndividual(editing.claimant)
      ? {
          firstName: editing.claimant.firstName,
          lastName: editing.claimant.lastName,
          gender: editing.claimant.gender,
        }
      : undefined
  );
  const [payerContact, setPayerContact] = useState<JustContact>(
    editing && isIndividual(editing.payer)
      ? {
          firstName: editing.payer.firstName,
          lastName: editing.payer.lastName,
          gender: editing.payer.gender,
        }
      : undefined
  );

  const [claimantCompanyContact, setClaimantCompanyContact] = useState<JustCompany>(
    editing && isCompany(editing.claimant)
      ? {
          companyName: editing.claimant.companyName,
          ico: editing.claimant.ico,
          dic: editing.claimant.dic,
          icDph: editing.claimant.icDph,
          contactPersonFirstName: editing.claimant.contactPersonFirstName,
          contactPersonLastName: editing.claimant.contactPersonLastName,
        }
      : undefined
  );
  const [payerCompanyContact, setPayerCompanyContact] = useState<JustCompany>(
    editing && isCompany(editing.payer)
      ? {
          companyName: editing.payer.companyName,
          ico: editing.payer.ico,
          dic: editing.payer.dic,
          icDph: editing.payer.icDph,
          contactPersonFirstName: editing.payer.contactPersonFirstName,
          contactPersonLastName: editing.payer.contactPersonLastName,
        }
      : undefined
  );

  const [payerType, setPayerType] = useState<ContactTypes>(
    editing && isCompany(editing.payer) ? ContactTypes.Company : ContactTypes.Individual
  );
  const [claimantType, setClaimantType] = useState<ContactTypes>(
    editing && isIndividual(editing.claimant) ? ContactTypes.Individual : ContactTypes.Company
  );

  const [payerBasicContactInfo, setPayerBasicContactInfo] = useState<BasicContactInfo>(
    editing ? { ...editing.payer } : undefined
  );
  const [claimantBasicContactInfo, setClaimantBasicContactInfo] = useState<BasicContactInfo>(
    editing ? { ...editing.claimant } : undefined
  );

  const [claimantDatabaseIndividualContact, setClaimantDatabaseIndividualContact] =
    useState<CompleteIndividual>();
  const [payerDatabaseIndividualContact, setPayerDatabaseIndividualContact] =
    useState<CompleteIndividual>();

  const [claimantDatabaseCompanyContact, setClaimantDatabaseCompanyContact] =
    useState<CompleteCompany>();
  const [payerDatabaseCompanyContact, setPayerDatabaseCompanyContact] = useState<CompleteCompany>();

  const payer: Company | Individual = useMemo(
    () =>
      (payerType === ContactTypes.Company && {
        ...payerCompanyContact,
        ...payerBasicContactInfo,
        type: ContactTypes.Company,
      }) ||
      (payerType === ContactTypes.Individual && {
        ...payerContact,
        ...payerBasicContactInfo,
        type: ContactTypes.Individual,
      }),
    [payerBasicContactInfo, payerCompanyContact, payerContact, payerType]
  );

  const claimant: Company | Individual = useMemo(
    () =>
      (claimantType === ContactTypes.Company && {
        ...claimantCompanyContact,
        ...claimantBasicContactInfo,
        type: ContactTypes.Company,
      }) ||
      (claimantType === ContactTypes.Individual && {
        ...claimantContact,
        ...claimantBasicContactInfo,
        type: ContactTypes.Individual,
      }),
    [claimantBasicContactInfo, claimantCompanyContact, claimantContact, claimantType]
  );

  const isValid: boolean =
    isCompleteAddress(claimant.address) && isCompleteAddress(payer.address) && items.length > 0;

  const rawTotalString = fEur(claimTotal({ items }));
  const totalString = fEur(claimTotal({ items }));

  const [issued, setIssued] = useState<Date>(editing ? editing.issued : new Date());

  const [orderNumber, setOrderNumber] = useState<number>(editing ? editing.orderNumber : undefined);

  const addItem = useCallback((item: ClaimItem): boolean => {
    setItems((p) => [...p, item]);
    return true;
  }, []);

  const removeItem = useCallback((idx: number): boolean => {
    console.log('removing', idx);
    setItems((p) => {
      p.splice(idx, 1);
      return [...p];
    });
    return true;
  }, []);

  const updateItem = useCallback((idx: number, item: ClaimItem): boolean => {
    setItems((p) => [...p.splice(idx, 1, item)]);
    return true;
  }, []);

  const submitNewOrder = useCallback(async () => {
    if (!API || !isValid) return false;

    if (editing) {
      const newOrder: IOrder = {
        accountingYear: accountingYear._id,
        items,
        payer,
        claimant,
        rawTotal: claimTotal({ items }),
        issued,
        finalPrice: round10(applyDiscount(claimTotal({ items }), 0), -2),
      };

      console.log(newOrder);

      if (payerType === ContactTypes.Individual && payerDatabaseIndividualContact) {
        newOrder.payerRef = payerDatabaseIndividualContact._id;
      }

      if (payerType === ContactTypes.Company && payerDatabaseCompanyContact) {
        newOrder.payerRef = payerDatabaseCompanyContact._id;
      }

      if (claimantType === ContactTypes.Individual && claimantDatabaseIndividualContact) {
        newOrder.claimantRef = claimantDatabaseIndividualContact._id;
      }

      if (claimantType === ContactTypes.Company && claimantDatabaseCompanyContact) {
        newOrder.claimantRef = claimantDatabaseCompanyContact._id;
      }

      const newOrderToSend: any = newOrder;

      try {
        console.log('newOrderToSend', newOrderToSend);

        const res = await API.put(`/orders/${editing._id}`, newOrderToSend);
        success('Objednávka zmenená.');
        if (onNewOrderSuccess) onNewOrderSuccess();
        return true;
      } catch (e: any) {
        error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
        return false;
      }
    }

    const newOrder: IOrder = {
      accountingYear: accountingYear._id,
      items,
      payer,
      claimant,
      rawTotal: claimTotal({ items }),
      issued,
      finalPrice: round10(applyDiscount(claimTotal({ items }), 0), -2),
    };

    console.log(newOrder);

    if (payerType === ContactTypes.Individual && payerDatabaseIndividualContact) {
      newOrder.payerRef = payerDatabaseIndividualContact._id;
    }

    if (payerType === ContactTypes.Company && payerDatabaseCompanyContact) {
      newOrder.payerRef = payerDatabaseCompanyContact._id;
    }

    if (claimantType === ContactTypes.Individual && claimantDatabaseIndividualContact) {
      newOrder.claimantRef = claimantDatabaseIndividualContact._id;
    }

    if (claimantType === ContactTypes.Company && claimantDatabaseCompanyContact) {
      newOrder.claimantRef = claimantDatabaseCompanyContact._id;
    }

    const newOrderToSend: any = newOrder;

    try {
      console.log('newOrderToSend', newOrderToSend);

      const res = await API.post(`/orders`, { ...newOrderToSend });
      success('Objednávka vytvorená.');
      if (onNewOrderSuccess) onNewOrderSuccess();
      return true;
    } catch (e: any) {
      error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
      return false;
    }
  }, [
    API,
    isValid,
    items,
    accountingYear,
    claimant,
    payer,
    issued,
    claimantDatabaseIndividualContact,
    claimantDatabaseCompanyContact,
    payerDatabaseIndividualContact,
    payerDatabaseCompanyContact,
  ]);

  const contextObjects = useMemo<INewOrderContext>(
    () => ({
      items,
      accountingYear,
      rawTotalString,
      totalString,
      isValid,
      payerType,
      claimantType,
      setPayerType,
      setClaimantType,
      setClaimantCompanyContact,
      setClaimantContact,
      setPayerCompanyContact,
      setPayerContact,
      setPayerBasicContactInfo,
      setClaimantBasicContactInfo,
      submitNewOrder,
      addItem,
      removeItem,
      updateItem,
      claimantDatabaseIndividualContact,
      setClaimantDatabaseIndividualContact,
      payerDatabaseIndividualContact,
      setPayerDatabaseIndividualContact,
      claimantDatabaseCompanyContact,
      setClaimantDatabaseCompanyContact,
      payerDatabaseCompanyContact,
      setPayerDatabaseCompanyContact,
      issued,
      setIssued,

      orderNumber,
      setOrderNumber,

      claimantCompanyContact,
      claimantContact,
      claimantBasicContactInfo,
      payerCompanyContact,
      payerContact,
      payerBasicContactInfo,
    }),
    [
      items,
      accountingYear,
      rawTotalString,
      totalString,
      isValid,
      payerType,
      claimantType,
      setPayerType,
      setClaimantType,
      setClaimantCompanyContact,
      setClaimantContact,
      setPayerCompanyContact,
      setPayerContact,
      setPayerBasicContactInfo,
      setClaimantBasicContactInfo,
      submitNewOrder,
      addItem,
      removeItem,
      updateItem,
      claimantDatabaseIndividualContact,
      setClaimantDatabaseIndividualContact,
      payerDatabaseIndividualContact,
      setPayerDatabaseIndividualContact,
      claimantDatabaseCompanyContact,
      setClaimantDatabaseCompanyContact,
      payerDatabaseCompanyContact,
      setPayerDatabaseCompanyContact,
      issued,
      setIssued,

      orderNumber,
      setOrderNumber,

      claimantCompanyContact,
      claimantContact,
      claimantBasicContactInfo,
      payerCompanyContact,
      payerContact,
      payerBasicContactInfo,
    ]
  );

  return <NewOrderContext.Provider value={contextObjects}>{children}</NewOrderContext.Provider>;
};
