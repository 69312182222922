import { Card, CardHeader, Typography, Grid, Stack, IconButton, Box, Tooltip } from '@mui/material';
import { FC } from 'react';
import { CompleteCompany, CompleteIndividual, isCompany } from '../../ctx/contacts/Contact.Context';
import { IContactIconAction } from './FuzzyContactSearcher';

export type CompleteContact = CompleteIndividual | CompleteCompany;

interface IIndividualBusinessCard {
  actions: IContactIconAction[];
  contactData: CompleteContact;
}

const IndividualBusinessCard: FC<IIndividualBusinessCard> = ({ actions, contactData }) => {
  return (
    <Card
      sx={{
        boxShadow: '0px 0px 0px 1px rgba(224, 224, 224, 0)',
        backgroundColor: 'rgba(224, 224, 224, 0)',
        outline: 'solid 1px rgba(224, 224, 224, 1)',
      }}
    >
      <Grid container>
        <Grid item>
          <CardHeader
            sx={{ m: 0, p: 1 }}
            title={
              <Typography variant="body2">
                {isCompany(contactData)
                  ? contactData.companyName
                  : `${contactData.firstName} ${contactData.lastName}`}
              </Typography>
            }
            subheader={
              <>
                <Typography variant="caption">{contactData.email}</Typography>
                <br />
                <Typography variant="caption">{contactData.phoneNumber}</Typography>
                <br />
                <Typography variant="caption">{contactData.address?.street}</Typography>
                <br />
                <Typography variant="caption">
                  {contactData.address?.zip} {contactData.address?.city}
                </Typography>
                <br />
                <Typography variant="caption">{contactData.address?.state}</Typography>
              </>
            }
          />
        </Grid>
        <Grid sx={{ ml: 'auto' }} item>
          <Stack direction="column" sx={{ m: 0, p: 0, height: '100%' }} justifyContent="center">
            {actions?.map((a, i) => (
              <Box key={`${i}-action-${contactData._id}`}>
                <IconButton onClick={() => a.onClick(contactData)}>{a.icon}</IconButton>
              </Box>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};

export default IndividualBusinessCard;
