import { FC } from 'react';
import { DataGrid, GridColDef, GridRowId } from '@mui/x-data-grid';
import { fEur, fPercent } from '../../utils/formatNumber';

export interface ClaimItem {
  rawUnitPrice: number;
  unitsCount: number;
  discount: number;
  title: string;
}

const itemColumns = (): GridColDef[] => {
  return [
    {
      field: 'title',
      headerName: 'Názov',
      flex: 2,
    },
    {
      field: 'rawUnitPrice',
      headerName: 'Jednotková cena',
      flex: 1,
      align: 'right',
      renderCell: ({ row }) => fEur(row.rawUnitPrice),
    },
    {
      field: 'discount',
      headerName: 'Zľava',
      flex: 1,
      align: 'right',
      renderCell: ({ row }) => fPercent(row.discount),
    },
    {
      field: 'unitsCount',
      headerName: 'Počet ks',
      flex: 1,
      align: 'right',
    },
    {
      field: 'finalPrice',
      headerName: 'Výsledná cena',
      flex: 1,
      align: 'right',
      renderCell: ({ row }) => fEur(row.finalPrice),
    },
  ];
};

interface IClaimItemsTable {
  items: Array<ClaimItem & { id: GridRowId }>;
}

export const ClaimItems: FC<IClaimItemsTable> = ({ items }) => {
  return <DataGrid hideFooter autoHeight columns={itemColumns()} rows={items} />;
};
