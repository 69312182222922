import { CardContent, Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { OutlinedCard } from './OutlinedCard';

interface IOuslinedSection {
  label?: ReactNode;
}

const OutlinedSection: FC<IOuslinedSection> = ({ children, label }) => (
  <OutlinedCard>
    <Stack direction="row-reverse" sx={{ pr: 3, pt: 1, mb: -2 }}>
      <Typography color="gray" variant="caption">
        {label}
      </Typography>
    </Stack>
    <CardContent sx={{ mb: -2 }}>{children}</CardContent>
  </OutlinedCard>
);

export default OutlinedSection;
