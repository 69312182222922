import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { Stack, TextField, IconButton, InputAdornment, Button } from '@mui/material';
import { useApi } from '../../ctx/Api';

// ----------------------------------------------------------------------

const ChangePassword = () => {
  const navigate = useNavigate();
  const { changePassword } = useApi();
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] = useState(false);

  const LoginSchema = Yup.object().shape({
    password: Yup.string().required('Je nevyhnutné zadať heslo'),
    newPassword: Yup.string().required('Je nevyhnutné zadať nové heslo'),
    newPasswordConfirmation: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Heslá sa musia zhodovať')
      .required('Je nevyhnutné potvrdiť nové heslo'),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      newPassword: '',
      newPasswordConfirmation: '',
      enableReinitialize: true,
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values: any) => {
      const success = await changePassword(values);
      setSubmitting(false);
      if (success) resetForm();
    },
  });
  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    resetForm,
  } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleShowNewPassword = () => {
    setShowNewPassword((show) => !show);
  };
  const handleShowNewPasswordConfirmation = () => {
    setShowNewPasswordConfirmation((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ my: 2 }}>
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Aktuálne heslo"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <TextField
            fullWidth
            autoComplete="none"
            type={showNewPassword ? 'text' : 'password'}
            label="Nové heslo"
            {...getFieldProps('newPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowNewPassword} edge="end">
                    {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.newPassword && errors.newPassword)}
            helperText={touched.newPassword && errors.newPassword}
          />
          <TextField
            fullWidth
            autoComplete="none"
            type={showNewPasswordConfirmation ? 'text' : 'password'}
            label="Potvrďte nové heslo"
            {...getFieldProps('newPasswordConfirmation')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowNewPasswordConfirmation} edge="end">
                    {showNewPasswordConfirmation ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.newPasswordConfirmation && errors.newPasswordConfirmation)}
            helperText={touched.newPasswordConfirmation && errors.newPasswordConfirmation}
          />
        </Stack>

        <Button variant="contained" color="secondary" fullWidth type="submit">
          Zmeniť heslo
        </Button>
      </Form>
    </FormikProvider>
  );
};

export default ChangePassword;
