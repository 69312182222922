import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { recursiveIndexGetter, recursiveTagGetter } from '../../utils/tagsRecursive';
import { useApi } from '../Api';

export interface TagBasicInfo {
  caption: string;
  description: string;
}

export interface CompleteTag extends TagBasicInfo {
  isRoot: boolean;
  isCategory: boolean;
  _id: string;
  parent: string;
  children: CompleteTag[];
}

interface ITagsContext {
  tagData?: CompleteTag[];
  selectedComlete?: CompleteTag[];
  selectedNotComlete?: CompleteTag[];
  pullData: () => Promise<CompleteTag[]>;
  createTag: (tagData: TagBasicInfo) => Promise<void>;
  moveTag: (tagId: string, destinationId: string) => Promise<void>;
  addSelected: (id: string, children: string[]) => void;
  moveSelectedToSelectedNot: (id: string, parents: string[], children: string[]) => void;
  removeSelectedOrSelectedNot: (id: string, parentsAndChildrens: string[]) => void;
  isSelected: (tag: CompleteTag) => boolean;
  isSelectedNot: (tag: CompleteTag) => boolean;
  confirmSelection: (
    onConfirmSelection: (selected: string[], selectedNot: string[]) => void
  ) => void;
  selectAll: () => void;
  selectNotAll: () => void;
  removeSelectedOrSelectedNotAll: () => void;
  isAllSelected: boolean;
  isAllSelectedNot: boolean;
  allIndexes: string[];
  selected: string[];
  selectedNot: string[];
  removeTags: (tagsIds: string[]) => Promise<void>;
  removeTag: (tagsId: string) => Promise<void>;
  updateTag: (tagId: string, newData: TagBasicInfo) => Promise<void>;
}

const TagsContext = createContext<ITagsContext>({
  tagData: [],
  selectedComlete: [],
  selectedNotComlete: [],
  pullData: () => undefined,
  createTag: () => undefined,
  moveTag: () => undefined,
  addSelected: () => undefined,
  moveSelectedToSelectedNot: () => undefined,
  removeSelectedOrSelectedNot: () => undefined,
  isSelected: () => undefined,
  isSelectedNot: () => undefined,
  confirmSelection: () => undefined,
  selectAll: () => undefined,
  selectNotAll: () => undefined,
  removeSelectedOrSelectedNotAll: () => undefined,
  isAllSelected: false,
  isAllSelectedNot: false,
  allIndexes: [],
  selected: [],
  selectedNot: [],
  removeTags: () => undefined,
  removeTag: () => undefined,
  updateTag: () => undefined,
});

export const useTags = () => {
  const context = useContext(TagsContext);
  if (!context) {
    throw new Error('Parent must be wrapped inside TagsProvider');
  }

  return context;
};

interface ITagsProvider {
  // tba
  id?: string;
  dataProvider?: boolean;
  initialData?: CompleteTag[];
  initialSelected?: string[];
  initialSelectedNot?: string[];
  // onConfirmSelection?: ;
}

export const TagsProvider: FC<ITagsProvider> = ({
  id,
  dataProvider = true,
  children,
  initialData,
  initialSelected,
  initialSelectedNot,
}) => {
  const { API, defaultErrorHandle } = useApi();

  const [tagData, setTagData] = useState<CompleteTag[]>(initialData || []);
  const [selected, setSelected] = useState<string[]>(initialSelected || []);
  const [selectedNot, setSelectedNot] = useState<string[]>(initialSelectedNot || []);
  const allIndexes = tagData.flatMap(recursiveIndexGetter);
  const isAllSelected = allIndexes.every((idx) => selected.includes(idx));
  const isAllSelectedNot = allIndexes.every((idx) => selectedNot.includes(idx));
  const allTags = useMemo(() => tagData.flatMap((t) => recursiveTagGetter(t)), [tagData]);

  const selectedComlete = useMemo(() => {
    console.log('recalcSelectedComplete');
    return [...allTags.filter((t) => selected.includes(t._id))];
  }, [selected, allTags]);
  const selectedNotComlete = useMemo(
    () => [...allTags.filter((t) => selectedNot.includes(t._id))],
    [selectedNot, allTags]
  );

  const pullData = useCallback(async (): Promise<CompleteTag[]> => {
    if (!API) return [];
    try {
      const res = await API.get(`tags/`);
      if (dataProvider) setTagData(res.data);
      return res.data;
    } catch (e: any) {
      defaultErrorHandle(e);
      return undefined;
    }
  }, [API, dataProvider, defaultErrorHandle]);
  useEffect(() => {
    if (dataProvider) pullData();
  }, [dataProvider, pullData]);

  const createTag = useCallback(
    async (tagData: TagBasicInfo) => {
      if (!API) return;
      try {
        await API.post(`tags/`, tagData);
        pullData();
      } catch (e: any) {
        defaultErrorHandle(e);
      }
    },
    [API, defaultErrorHandle, pullData]
  );

  const moveTag = useCallback(
    async (tagId: string, destinationId: string) => {
      console.log(tagId, destinationId);
      try {
        await API.post(`tags/move`, { tagId, destinationId });
        pullData();
      } catch (e: any) {
        defaultErrorHandle(e);
      }
    },
    [API, defaultErrorHandle, pullData]
  );

  const removeTags = useCallback(
    async (tagsIds: string[]) => {
      if (!API) return;
      try {
        await API.post(`tags/delete`, { tagsIds });
        pullData();
      } catch (e: any) {
        defaultErrorHandle(e);
      }
    },
    [API, defaultErrorHandle, pullData]
  );

  const removeTag = useCallback(
    async (tagId: string) => {
      if (!API) return;
      try {
        await API.delete(`tags/${tagId}`);
        pullData();
      } catch (e: any) {
        defaultErrorHandle(e);
      }
    },
    [API, defaultErrorHandle, pullData]
  );

  const updateTag = useCallback(
    async (tagId: string, newData: TagBasicInfo) => {
      if (!API) return;
      try {
        await API.put(`tags/${tagId}`, newData);
        pullData();
      } catch (e: any) {
        defaultErrorHandle(e);
      }
    },
    [API, defaultErrorHandle, pullData]
  );

  const addSelected = (id: string, children: string[]) => {
    setSelectedNot((prev) => {
      const newfilters = prev.filter((p) => ![id, ...children].includes(p));
      return newfilters;
    });

    setSelected((prev) => {
      const newfilters = prev.filter((p) => ![id, ...children].includes(p));
      return [id, ...children, ...newfilters];
    });
  };

  const moveSelectedToSelectedNot = (id: string, parents: string[], children: string[]) => {
    setSelected((prev) => {
      const newfilters = prev.filter((p) => ![id, ...parents, ...children].includes(p));
      return newfilters;
    });

    setSelectedNot((prev) => {
      const newfilters = prev.filter((p) => ![id, ...children].includes(p));
      return [id, ...children, ...newfilters];
    });
  };

  const removeSelectedOrSelectedNot = (id: string, parentsAndChildrens: string[]) => {
    console.log(parentsAndChildrens);
    setSelected((prev) => {
      const newfilters = prev.filter((p) => ![id, ...parentsAndChildrens].includes(p));
      return newfilters;
    });
    setSelectedNot((prev) => {
      const newfilters = prev.filter((p) => ![id, ...parentsAndChildrens].includes(p));
      return newfilters;
    });
  };

  const isSelected = useCallback((tag: CompleteTag) => selected.includes(tag._id), [selected]);
  const isSelectedNot = useCallback(
    (tag: CompleteTag) => selectedNot.includes(tag._id),
    [selectedNot]
  );

  const selectAll = useCallback(() => {
    setSelected([...allIndexes]);
    setSelectedNot([]);
  }, [allIndexes]);

  const selectNotAll = useCallback(() => {
    setSelected([]);
    setSelectedNot([...allIndexes]);
  }, [allIndexes]);

  const removeSelectedOrSelectedNotAll = useCallback(() => {
    setSelected([]);
    setSelectedNot([]);
  }, []);

  const confirmSelection = useCallback(
    (onConfirmSelection: (selected: string[], selectedNot: string[]) => void) => {
      if (onConfirmSelection) onConfirmSelection(selected, selectedNot);
    },
    [selected, selectedNot]
  );

  const contextObjects = useMemo(
    () => ({
      tagData,
      pullData,
      createTag,
      moveTag,
      addSelected,
      moveSelectedToSelectedNot,
      removeSelectedOrSelectedNot,
      isSelected,
      isSelectedNot,
      selectAll,
      selectNotAll,
      confirmSelection,
      isAllSelected,
      isAllSelectedNot,
      allIndexes,
      removeSelectedOrSelectedNotAll,
      selected,
      selectedNot,
      removeTags,
      removeTag,
      updateTag,
      selectedComlete,
      selectedNotComlete,
    }),
    [
      allIndexes,
      confirmSelection,
      createTag,
      isAllSelected,
      isAllSelectedNot,
      isSelected,
      isSelectedNot,
      moveTag,
      pullData,
      removeSelectedOrSelectedNotAll,
      removeTags,
      removeTag,
      selectAll,
      selectNotAll,
      selected,
      selectedComlete,
      selectedNot,
      selectedNotComlete,
      tagData,
      updateTag,
    ]
  );

  return <TagsContext.Provider value={contextObjects}>{children}</TagsContext.Provider>;
};
