import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CompleteContact } from '../../components/contact/IndividualBusinessCard';
import { useApi } from '../Api';
import { CompleteListCampaign, ListCampaign } from './ListCampaigns.Context';

export interface ListCampaignWithContacts extends CompleteListCampaign {
  contactRelations: ListCampaignContactRelation[];
}

export interface ListCampaignContactRelationBasicInfo {
  reacted?: boolean;
  note?: string;
}

export interface ListCampaignContactRelation extends ListCampaignContactRelationBasicInfo {
  _id: string;
  contact: string | CompleteContact;
  campaign: string | CompleteListCampaign;
}

interface IListCampaignContext {
  listcampaignData?: ListCampaignWithContacts;
  pullData: () => Promise<ListCampaignWithContacts>;
  editListCampaign: (
    newData: ListCampaign,
    onEditSuccess?: () => void
  ) => Promise<ListCampaignWithContacts>;
  addContactsToListCampaign: (
    contactsIds: string[],
    onSuccess?: () => void
  ) => Promise<ListCampaignWithContacts>;
  editListCampaignRelation: (
    relId: string,
    newData: ListCampaignContactRelationBasicInfo,
    onSuccess?: () => void
  ) => Promise<ListCampaignWithContacts>;
  deleteListCampaign: (onSuccess?: () => void) => Promise<void>;
}

const ListCampaignContext = createContext<IListCampaignContext>({
  listcampaignData: undefined,
  pullData: () => undefined,
  editListCampaign: () => undefined,
  addContactsToListCampaign: () => undefined,
  editListCampaignRelation: () => undefined,
  deleteListCampaign: () => undefined,
});

export const useListCampaign = () => {
  const context = useContext(ListCampaignContext);
  if (!context) {
    throw new Error('Parent must be wrapped inside ListCampaignProvider');
  }

  return context;
};

interface IListCampaignProvider {
  // tba
  id: string;
  dataProvider?: boolean;
  initialData?: ListCampaignWithContacts;
}

export const ListCampaignProvider: FC<IListCampaignProvider> = ({
  id,
  dataProvider = true,
  children,
  initialData,
}) => {
  const { API, defaultErrorHandle } = useApi();

  const [listcampaignData, setListCampaignData] = useState<ListCampaignWithContacts>(initialData);

  const pullData = useCallback(async (): Promise<ListCampaignWithContacts> => {
    if (!API) return undefined;
    try {
      const res = await API.get(`listcampaigns/${id}`);
      console.log('PULLED', res);
      if (dataProvider) setListCampaignData(res.data);
      return res.data;
    } catch (e: any) {
      defaultErrorHandle(e);
      return undefined;
    }
  }, [API, dataProvider, defaultErrorHandle, id]);

  const editListCampaign = useCallback(
    async (
      newData: ListCampaignWithContacts,
      onEditSuccess?: () => void
    ): Promise<ListCampaignWithContacts> => {
      if (!API) return undefined;
      try {
        const res = await API.put(`listcampaigns/${id}`, newData);
        console.log(res);
        if (dataProvider) setListCampaignData(res.data);
        if (onEditSuccess) onEditSuccess();
        return res.data;
      } catch (e: any) {
        defaultErrorHandle(e);
        return undefined;
      }
    },
    [API, dataProvider, defaultErrorHandle, id]
  );

  const editListCampaignRelation = useCallback(
    async (
      relId: string,
      newData: ListCampaignContactRelationBasicInfo,
      onSuccess?: () => void
    ): Promise<ListCampaignWithContacts> => {
      if (!API) return undefined;
      try {
        const res = await API.put(`listcampaigns/relationship/${relId}`, newData);
        console.log(res);
        if (dataProvider) pullData();
        if (onSuccess) onSuccess();
        return res.data;
      } catch (e: any) {
        defaultErrorHandle(e);
        return undefined;
      }
    },
    [API, dataProvider, defaultErrorHandle, pullData]
  );

  const addContactsToListCampaign = useCallback(
    async (contactsIds: string[], onSuccess?: () => void): Promise<ListCampaignWithContacts> => {
      if (!API) return undefined;
      try {
        const res = await API.post(`listcampaigns/${id}/relations`, {
          contactsIds,
          absolute: true,
        });
        console.log(res.data);
        // if (dataProvider) setListCampaignData(res.data);
        if (onSuccess) onSuccess();
        return res.data;
      } catch (e: any) {
        defaultErrorHandle(e);
        return undefined;
      }
    },
    [API, defaultErrorHandle, id]
  );

  const deleteListCampaign = useCallback(
    async (onSuccess?: () => void) => {
      if (!API) return undefined;
      try {
        const res = await API.delete(`listcampaigns/${id}`);
        console.log(res.data);
        // if (dataProvider) setListCampaignData(res.data);
        if (onSuccess) onSuccess();
        return res.data;
      } catch (e: any) {
        defaultErrorHandle(e);
        return undefined;
      }
    },
    [API, defaultErrorHandle, id]
  );

  useEffect(() => {
    if (dataProvider) pullData();
  }, [dataProvider, pullData]);

  const contextObjects = useMemo(
    () => ({
      listcampaignData,
      pullData,
      editListCampaign,
      addContactsToListCampaign,
      editListCampaignRelation,
      deleteListCampaign,
    }),
    [
      listcampaignData,
      pullData,
      editListCampaign,
      addContactsToListCampaign,
      editListCampaignRelation,
      deleteListCampaign,
    ]
  );

  return (
    <ListCampaignContext.Provider value={contextObjects}>{children}</ListCampaignContext.Provider>
  );
};
