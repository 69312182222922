import { format, formatDistanceToNow, parseISO } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  if (!date) return '-';
  try {
    return format(new Date(date), 'dd MMM yyyy');
  } catch {
    return `${date} - neplatný formát`;
  }
}

export function fDateTime(date) {
  try {
    return format(new Date(date), 'dd MMM yyyy HH:mm');
  } catch {
    return `${date} - neplatný formát`;
  }
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export const justParse = (date) => {
  try {
    return parseISO(date);
  } catch {
    return 0;
  }
};
