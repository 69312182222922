import { FC, useEffect } from 'react';
import { number, object } from 'yup';

import { useFormik, Form, FormikProvider } from 'formik';
import { Button, Grid, Stack, TextField } from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { MyFormikTextField } from '../MyFormikTextField';
import { useClaims } from '../../ctx/Claims.Context';

interface INewAccountingYearForm {
  handleStopAddingYear: () => void;
}

const NewAccountingYearForm: FC<INewAccountingYearForm> = ({ handleStopAddingYear }) => {
  const { submitNewAccountingYear } = useClaims();

  const ValidationSchema = object().shape({
    start: number().required(),
    end: number().when('start', (start, yup) => {
      return yup.min(start, 'End time cannot be before start time'); // TODO this aint working
    }),
    prefix: number().required(),
    vsLength: number().required(),
  });

  const formik = useFormik({
    initialValues: {
      prefix: '',
      start: null,
      end: null,
      vsLength: 10,
    },
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: async (values: any) => {
      console.log(values);
      console.log({
        ...values,
        start: new Date(values.start),
        end: new Date(values.end),
      });
      const ok = await submitNewAccountingYear({
        ...values,
        start: new Date(values.start),
        end: new Date(values.end),
      });
      if (ok) handleStopAddingYear();
    },
  });

  useEffect(() => {
    formik.setFieldValue('start', new Date().getTime());
    formik.setFieldValue(
      'end',
      new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getTime()
    );
  }, []);

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3} sx={{ m: 3 }} direction="column">
            <Grid container spacing={3}>
              <Grid item xs={9}>
                <MyFormikTextField
                  label="Prefix"
                  fieldName="prefix"
                  {...{ getFieldProps, touched, errors, values }}
                />
              </Grid>
              <Grid item xs={3}>
                <MyFormikTextField
                  label="VS dĺžka"
                  fieldName="vsLength"
                  type="number"
                  {...{ getFieldProps, touched, errors, values }}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  label="Začiatok"
                  value={values.start}
                  onChange={(value) => {
                    formik.setFieldValue('start', Date.parse(value!));
                  }}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  label="Koniec"
                  value={values.end}
                  onChange={(value) => {
                    formik.setFieldValue('end', Date.parse(value!));
                  }}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Grid>
              <Grid item xs={12} container direction="row-reverse" spacing={1}>
                <Grid item>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    Uložiť
                  </Button>
                </Grid>
                <Grid item>
                  <Button type="reset" variant="outlined" startIcon={<CancelIcon />}>
                    Zrušiť
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Stack>
        </Form>
      </FormikProvider>
    </LocalizationProvider>
  );
};

export default NewAccountingYearForm;
