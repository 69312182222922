import { Tooltip, Box } from '@mui/material';
import { FC } from 'react';
import { ListCampaignWithContacts } from '../../ctx/listCampaigns/ListCampaign.context';
import { BorderLinearProgress } from '../BorderLinearProgress';

const ListCampaignReactedProgress: FC<ListCampaignWithContacts> = (campaign) => {
  const opened = campaign.contactRelations.reduce((p, c) => (c.reacted ? p + 1 : p), 0);
  const total = campaign.contactRelations.length;
  return (
    <Tooltip title={`${opened} / ${total}`}>
      <Box sx={{ flex: 1 }}>
        <BorderLinearProgress variant="determinate" value={(opened * 100) / total} />{' '}
      </Box>
    </Tooltip>
  );
};

export default ListCampaignReactedProgress;
