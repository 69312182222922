import { Card, CardContent } from '@mui/material';
import { cloneElement, FC, ReactElement } from 'react';
import Popper from '@mui/material/Popper';
import { usePopupState, bindHover, bindPopper } from 'material-ui-popup-state/hooks';
import Fade from '@mui/material/Fade';
import { CompleteContact } from './IndividualBusinessCard';
import ContactFullInfoCard from './ContactFullInfoCard';

interface IContactFullInfoPoper {
  contactInfo: CompleteContact;
  rootElement: ReactElement;
}

const ContactFullInfoPoper: FC<IContactFullInfoPoper> = ({ contactInfo, rootElement }) => {
  const popupState = usePopupState({ variant: 'popper', popupId: 'demoPopper' });

  return (
    <>
      {cloneElement(rootElement, { ...bindHover(popupState) })}
      <Popper {...bindPopper(popupState)} transition style={{ zIndex: 100 }}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Card
              sx={{
                backgroundColor: 'rgba(255,255,255,0.8)',
                backdropFilter: 'blur(5px)',
                zIndex: 100,
              }}
            >
              <ContactFullInfoCard contactInfo={contactInfo} />
            </Card>
          </Fade>
        )}
      </Popper>
    </>
  );
};
export default ContactFullInfoPoper;
