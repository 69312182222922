import { CancelRounded, Search } from '@mui/icons-material';
import { Fade, IconButton, InputAdornment, Slide, TextField, TextFieldProps } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useState } from 'react';

interface IMySearchField {
  setQuery: (query: string) => void;
  clearQuery: () => void;
  textFieldProps?: TextFieldProps;
}

export const MySearchField: FC<IMySearchField> = ({ setQuery, clearQuery, textFieldProps }) => {
  const [value, setValue] = useState('');

  return (
    <TextField
      size="small"
      placeholder="Hľadať v zobrazených..."
      autoComplete="off"
      // onKeyUp={onSearch}
      onChange={(e) => {
        setQuery(e.target.value);
        setValue(e.target.value);
      }}
      value={value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),

        endAdornment: (
          <Box>
            <Fade in={!!value}>
              <IconButton
                onClick={() => {
                  clearQuery();
                  setValue('');
                }}
              >
                <CancelRounded />
              </IconButton>
            </Fade>
          </Box>
        ),

        sx: {
          transition: 'all 0.5s',
        },
      }}
      sx={{}}
      {...textFieldProps}
    />
  );
};
