import { FC } from 'react';
import { number, object, string } from 'yup';

import { useFormik, Form, FormikProvider } from 'formik';
import { Button, Grid, InputAdornment, Stack } from '@mui/material';

import { MyFormikTextField } from '../../MyFormikTextField';
import { SubmitListener } from '../../../utils/SubmitListener';
import { Payment } from '../../../ctx/PaymentProcess.Context';

interface IPaymentForm {
  onConfirm(d: Payment): void;
  initialValues?: Payment;
  requiredFields?: Array<keyof Payment>;
  omitFields?: Array<keyof Payment>;
  autoSubmit?: boolean;
}

const PaymentForm: FC<IPaymentForm> = ({
  onConfirm,
  initialValues,
  requiredFields,
  omitFields,
  autoSubmit = true,
}) => {
  const ValidationSchema = object().shape({
    variableSymbol: string().when('x', {
      is: () => requiredFields?.includes('variableSymbol') || false,
      then: string().required(),
    }),
    amount: number().when('x', {
      is: () => requiredFields?.includes('amount') || false,
      then: number().required(),
    }),
  });

  const formik = useFormik<Partial<Payment>>({
    initialValues: initialValues || {
      variableSymbol: '',
      amount: undefined,
    },
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      console.log(values);
      onConfirm(values as Payment);
    },
  });
  const { errors, touched, values, handleSubmit, getFieldProps, isValid, dirty } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {autoSubmit && <SubmitListener />}
        <Grid container spacing={1}>
          {!omitFields?.includes('variableSymbol') && (
            <Grid item xs={8}>
              <MyFormikTextField
                label="VS"
                fieldName="variableSymbol"
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}
          {!omitFields?.includes('amount') && (
            <Grid item xs={4}>
              <MyFormikTextField
                label="Suma"
                fieldName="amount"
                type="number"
                {...{ getFieldProps, touched, errors, values }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">€</InputAdornment>,
                }}
              />
            </Grid>
          )}

          {!autoSubmit && (
            <Grid item xs={12}>
              <Stack direction="row-reverse" spacing={1}>
                <Stack spacing={1} direction="row-reverse">
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={!dirty || !isValid}
                  >
                    Potvrdiť
                  </Button>
                  <Button variant="outlined" color="primary" type="reset" disabled={!dirty}>
                    Resetovať
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default PaymentForm;
