import { FC } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Link, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CompleteSubscriptionType } from '../../ctx/subscriptions/Subscriptions.Context';
import { SubscriptionTypeAvatar } from '../../utils/stringAvatar';
import { DataGridWithSelection, IDataGridWithSelection } from '../DataGridWithSelection';

interface ISubscriptionColumns {
  reloadData?: () => void;
  customCols?: GridColDef[];
}

export const SubscriptionNameColumnRender = (subscription: CompleteSubscriptionType) => {
  const navigate = useNavigate();
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <SubscriptionTypeAvatar subscriptionType={subscription} />
      <Link onClick={() => navigate(`/subscriptiontypes/${subscription._id}`)} color="inherit">
        <Typography variant="body1">{subscription.caption}</Typography>
      </Link>
    </Stack>
  );
};

const subscriptionColumns = ({ reloadData, customCols }: ISubscriptionColumns): GridColDef[] => [
  {
    field: 'caption',
    headerName: 'Názov',
    flex: 1,
    renderCell: ({ row }) => <SubscriptionNameColumnRender {...row} />,
  },
  {
    field: 'numberOfSubscriptions',
    headerName: 'Počet predplatiteľov',
    flex: 0.5,
    align: 'right',
  },
  {
    field: 'dayInMonth',
    headerName: 'Deň splatnosti',
    flex: 0.5,
    align: 'right',
  },
  ...customCols,
];

interface ISubscriptionTypesTable extends IDataGridWithSelection {
  subscriptionTypes: Array<CompleteSubscriptionType>;
  reloadData?: () => void;
  customCols?: GridColDef[];
}

export const SubscriptionTypesTable: FC<ISubscriptionTypesTable> = ({
  subscriptionTypes,
  reloadData,
  customCols = [],

  ...props
}) => {
  return (
    <DataGridWithSelection
      {...props}
      autoHeight
      columns={subscriptionColumns({ reloadData, customCols })}
      rows={subscriptionTypes.map((c) => ({ ...c, id: c._id }))}
    />
  );
};
