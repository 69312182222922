import { Card } from '@mui/material';
import { cloneElement, FC, ReactElement } from 'react';
import Popper from '@mui/material/Popper';
import { usePopupState, bindHover, bindPopper } from 'material-ui-popup-state/hooks';
import Fade from '@mui/material/Fade';
import SubscriptionBasicInfoCard from './SubscriptionBasicInfoCard';
import { CompleteSubscription } from '../../ctx/subscriptions/Subscriptions.Context';

interface ISubscriptionPoper {
  subscriptionData: CompleteSubscription;
  rootElement: ReactElement;
}

const SubscriptionPoper: FC<ISubscriptionPoper> = ({ subscriptionData, rootElement }) => {
  const popupState = usePopupState({ variant: 'popper', popupId: 'demoPopper' });

  return (
    <>
      {cloneElement(rootElement, { ...bindHover(popupState) })}
      <Popper {...bindPopper(popupState)} transition style={{ zIndex: 10000 }}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Card sx={{ backgroundColor: 'white', backdropFilter: 'blur(5px)', zIndex: 100 }}>
              <SubscriptionBasicInfoCard subscriptionData={subscriptionData} />
            </Card>
          </Fade>
        )}
      </Popper>
    </>
  );
};
export default SubscriptionPoper;
