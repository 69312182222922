import { FC, Fragment, useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { Box, Link, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { CompleteIndividual } from '../../ctx/contacts/Contact.Context';
import ContactBasicInfoPoper from '../contact/ContactBasicInfoPopper';
import { CompleteContact } from '../contact/IndividualBusinessCard';
import {
  getToImportContactName,
  isToImportContact,
  ToImportContact,
} from '../../ctx/contacts/ImportContacts.Context';

import { ContactAvatar } from '../../utils/stringAvatar';
import FuseHighlight from '../../hooks/FuseHighlight';
import ContactBasicInfoTextLine, {
  IContactBasicInfoTextLine,
} from '../contact/ContactBasicInfoTextLine';
import { BasicContactInfo } from '../../ctx/Claims.Context';

export interface IContactAction {
  contactData?: CompleteIndividual;
}

export type ContactTableActions = { element: FC<IContactAction>; props?: any }[];
export const mapRenderContactTableActions = (actions, contactData) =>
  actions.map((a) => a.element({ ...a.props, contactData }));

interface IContactColumns {
  reloadData?: () => void;
  actions?: ContactTableActions;
  customCols?: GridColDef[];
  priorityContactField?: IContactBasicInfoTextLine['priorityField'];
}

export const basicContactInfoColumnRender = (
  info: BasicContactInfo,
  priorityField: IContactBasicInfoTextLine['priorityField']
) => (
  <ContactBasicInfoPoper
    basicContactInfoData={info}
    rootElement={
      <Box>
        <ContactBasicInfoTextLine info={info} priorityField={priorityField} />
      </Box>
    }
  />
);

export const basicContactInfoColumn = (
  priorityField: IContactBasicInfoTextLine['priorityField']
): GridColDef => {
  return {
    field: 'contact',
    headerName: 'Kontakt',
    flex: 1,
    renderCell: ({ row }) => basicContactInfoColumnRender(row, priorityField),
  };
};

// export const ContactNameColumnRender = (contact: CompleteContact) => {
export const ContactNameColumnRender = (contact: any) => {
  const navigate = useNavigate();
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <ContactAvatar contact={contact} />
      {isToImportContact(contact) ? (
        <Typography variant="body1">{getToImportContactName(contact)}</Typography>
      ) : (
        <Link onClick={() => navigate(`/contacts/${contact._id}`)} color="inherit">
          <Typography variant="body1">
            {contact.hit ? (
              <FuseHighlight hit={contact.hit} attribute="fullName" />
            ) : (
              contact.fullName
            )}
          </Typography>
        </Link>
      )}
    </Stack>
  );
};

const contactColumns = ({
  reloadData,
  actions = [],
  customCols = [],
  priorityContactField = 'email',
}: IContactColumns): GridColDef[] => [
  {
    field: 'name',
    headerName: 'Meno',
    flex: 1,
    renderCell: ({ row }) => ContactNameColumnRender(row),
  },
  basicContactInfoColumn(priorityContactField),
  ...customCols,

  ...[actions.length > 0].flatMap((c) =>
    c
      ? [
          {
            field: 'actions',
            headerName: '',
            // flex: ,
            width: 50 * actions.length,
            renderCell: ({ row }) => (
              <Stack direction="row">
                {actions.map((a, i) => (
                  <Fragment key={`${row._id}-${i}-table-action`}>
                    {a.element({
                      ...(a.props && { ...a.props }),
                      contactData: row,
                      idx: row.idx,
                    })}
                  </Fragment>
                ))}
              </Stack>
            ),
          },
        ]
      : []
  ),
  {
    field: 'workaround',
    headerName: '',
    width: 0,
    maxWidth: 0,
    minWidth: 0,
  },
];

interface AditionalData {
  aditionalData?: any;
}

type AnyContact = CompleteContact | ToImportContact;

interface IContactsTable extends IContactColumns {
  contacts: Array<AnyContact & AditionalData>;
  controls?: { setFce: (ids: string[]) => void; selectionModel: string[] };
}

export const ContactsTable: FC<IContactsTable> = ({
  controls,
  contacts,
  reloadData,
  actions,
  customCols,
  priorityContactField,
}) => {
  const [sortModel, setSortModel] = useState(controls ? [...controls.selectionModel] : []);

  useEffect(() => {
    if (!controls) return;
    setSortModel([...controls.selectionModel]);
  }, [controls]);

  const handleSelectionModelChange = (newSelectionModel: GridSelectionModel) => {
    controls?.setFce(newSelectionModel as string[]);
  };
  const rows = contacts.map((c) => ({ ...c, id: isToImportContact(c) ? c.idx : c._id }));
  return (
    <DataGrid
      autoHeight
      columns={contactColumns({ reloadData, actions, customCols, priorityContactField })}
      disableSelectionOnClick={!controls}
      checkboxSelection
      onSelectionModelChange={(model) => {
        if (!_.isEqual(model, sortModel) && rows.length > 0) {
          console.log(model, sortModel);
          handleSelectionModelChange(model as string[]);
        }
      }}
      selectionModel={sortModel}
      rows={rows}
      sx={{
        '& .MuiDataGrid-row': {
          ...(!controls
            ? {
                '& .MuiDataGrid-cell:nth-last-of-type(2)': {
                  width: '100%!important',
                  maxWidth: '100%!important',
                  minWidth: '100%!important',
                },
              }
            : {
                '& .MuiDataGrid-cell:last-of-type': {
                  width: '100%!important',
                  maxWidth: '100%!important',
                  minWidth: '100%!important',
                },
              }),
        },

        '& .MuiDataGrid-columnHeaders': {
          ...(!controls
            ? {
                '& .MuiDataGrid-columnHeader:nth-last-of-type(2)': {
                  width: '100%!important',
                  maxWidth: '100%!important',
                  minWidth: '100%!important',
                },
              }
            : {
                '& .MuiDataGrid-columnHeader:last-of-type': {
                  width: '100%!important',
                  maxWidth: '100%!important',
                  minWidth: '100%!important',
                },
              }),
        },
        '& .Mui-selected': {
          ...(!controls && { backgroundColor: 'unset!important' }),
        },

        '& .MuiDataGrid-columnHeader': { transition: 'all 1s' },
        '& .MuiDataGrid-cell': { transition: 'all 1s' },

        '& .MuiDataGrid-cellCheckbox': {
          ...(!controls && { minWidth: '0!important' }),
          transition: 'all 1s',
          width: '0!important',
        },
        '& .MuiDataGrid-columnHeaderCheckbox': {
          ...(!controls && { minWidth: '0!important' }),
          transition: 'all 1s',
          width: '0!important',
        },
      }}
    />
  );
};
