import { FC } from 'react';
import { mixed, object, string } from 'yup';

import { useFormik, Form, FormikProvider, Field } from 'formik';
import { Grid } from '@mui/material';

import { MyFormikTextField } from '../../MyFormikTextField';
import { SubmitListener } from '../../../utils/SubmitListener';
import { JustContact } from '../../../ctx/contacts/NewContact.Context';
import { Gender } from '../../../ctx/Claims.Context';
import MyFormikRadioGroup from '../../MyFormikRadioGroup';

interface IIndividualInfoForm {
  onConfirm(d: JustContact): void;
  initialValues?: JustContact;
  requiredFields?: Array<keyof JustContact>;
  omitFields?: Array<keyof JustContact>;
}

const IndividualInfoForm: FC<IIndividualInfoForm> = ({
  onConfirm,
  initialValues,
  requiredFields,
  omitFields,
}) => {
  const ValidationSchema = object().shape({
    firstName: string().when('x', {
      is: (x) => requiredFields?.includes('firstName') || false,
      then: string().required(),
    }),
    lastName: string().when('x', {
      is: (x) => requiredFields?.includes('lastName') || false,
      then: string().required(),
    }),
    gender: mixed<Gender>()
      .oneOf(Object.values(Gender))
      .when('x', {
        is: (x) => requiredFields?.includes('gender') || false,
        then: mixed().required(),
      }),
  });

  const formik = useFormik<JustContact>({
    initialValues: initialValues || {
      firstName: '',
      lastName: '',
      gender: undefined,
    },
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      console.log(values);
      onConfirm({
        firstName: values.firstName,
        lastName: values.lastName,
        gender: values.gender,
      });
    },
  });
  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <SubmitListener />
        <Grid container spacing={1}>
          {!omitFields?.includes('firstName') && (
            <Grid item xs={6}>
              <MyFormikTextField
                label="Meno"
                fieldName="firstName"
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}
          {!omitFields?.includes('lastName') && (
            <Grid item xs={6}>
              <MyFormikTextField
                label="Priezvisko"
                fieldName="lastName"
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}
          {!omitFields?.includes('gender') && (
            <Grid item xs={6}>
              <Field
                name="gender"
                options={(Object.values(Gender) as Array<Gender>).map((key) => {
                  if (key === Gender.FEMALE) return { value: key, label: 'Žena' };
                  if (key === Gender.MALE) return { value: key, label: 'Muž' };
                  return { value: '', key: '' };
                })}
                component={MyFormikRadioGroup}
                groupName="Pohlavie"
              />
            </Grid>
          )}
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default IndividualInfoForm;
