import { Typography, Grid } from '@mui/material';
import { FC } from 'react';
import { AccountingYearValidityReport } from '../../ctx/Claims.Context';

interface IAccountingYearReportCard {
  accoutingYearReportData: AccountingYearValidityReport;
}

const AccountingYearReportCard: FC<IAccountingYearReportCard> = ({ accoutingYearReportData }) => {
  return (
    <Grid container>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={8}>
          <Typography variant="caption">Stav:</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography sx={{ fontFamily: 'monospace' }}>{accoutingYearReportData.title}</Typography>
        </Grid>
      </Grid>{' '}
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={8}>
          <Typography variant="caption">Skutočné prvé č. dokumentu:</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography sx={{ fontFamily: 'monospace' }}>{accoutingYearReportData.start}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={8}>
          <Typography variant="caption">Skutočné posledné č. dokumentu:</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography sx={{ fontFamily: 'monospace' }}>{accoutingYearReportData.end}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={8}>
          <Typography variant="caption">Posledné č. dokumentu by malo byť:</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography sx={{ fontFamily: 'monospace' }}>{accoutingYearReportData.myEnd}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={8}>
          <Typography variant="caption">Medzi skutočným prvým a posledným chýbajú:</Typography>
        </Grid>
        <Grid item xs>
          <Typography sx={{ fontFamily: 'monospace' }}>
            {accoutingYearReportData.missing.length === 0
              ? 'žiadne'
              : JSON.stringify(accoutingYearReportData.missing)}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={8}>
          <Typography variant="caption">Duplicitné dokumenty:</Typography>
        </Grid>
        <Grid item xs>
          <Typography sx={{ fontFamily: 'monospace' }}>
            {accoutingYearReportData.duplicates.length === 0
              ? 'žiadne'
              : JSON.stringify(accoutingYearReportData.duplicates)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AccountingYearReportCard;
