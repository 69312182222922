import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { downloadFileForSheets, getCrdtItem, getDbitItem } from '../utils/paymentsForSheets';
import { xml2json } from '../utils/xml2json';
import { useApi } from './Api';

export interface PaymentParticipant {
  iban: string;
  name: string;
}

export interface PaymentsSourceFile {
  type: string;
  name: string;
  from: Date;
  to: Date;
  crdtCount: number;
  dbitCount: number;
  crdtSum: number;
  dbitSum: number;
  iban: string;
}

export enum PaymentType {
  CRDT = 'CRDT',
  DBIT = 'DBIT',
}

export interface Payment {
  amount: number;
  type: PaymentType;
  accountId: string;
  variableSymbol?: string;
  ntryRef: string;
  payer: PaymentParticipant;
  receiver: PaymentParticipant;
  date: any;
  source: any;
  note?: string;
  payerRef?: any;
  receiverRef?: any;
  claim?: any;
  subscription?: any;
}

export interface CompletePayment extends Payment {
  _id: string;
  owner: string;
  parts: string[];
  isSplitted: boolean;
  isPart: boolean;
}

interface IPaymentProcessContext {
  fileName: string;
  paymentsSourceFileId: string;
  sourceFileData: PaymentsSourceFile;
  fileParsed: boolean;
  isFilealreadyInDatabase: boolean;
  payments: Payment[];
  uploadedPayments: Payment[];
  fileSelectedHandler: (e: any) => void;
  downloadFilesForGSheets: () => void;
  uploadWholeFile: () => Promise<void>;
  uploadPayments: () => Promise<void>;
}

const PaymentProcessContext = createContext<IPaymentProcessContext>({
  fileName: '',
  paymentsSourceFileId: '',
  sourceFileData: undefined,
  fileParsed: false,
  isFilealreadyInDatabase: true,
  payments: [],
  uploadedPayments: [],
  fileSelectedHandler: () => undefined,
  downloadFilesForGSheets: () => undefined,
  uploadWholeFile: () => undefined,
  uploadPayments: () => undefined,
});

export const usePaymentProcess = () => {
  const context = useContext(PaymentProcessContext);
  if (!context) {
    throw new Error('Parent must be wrapped inside PaymentProcessProvider');
  }

  return context;
};

export const PaymentProcessProvider: FC = ({ children }) => {
  const [fileParsed, setFileParsed] = useState<boolean>(false);
  const [isFilealreadyInDatabase, setIsFilealreadyInDatabase] = useState<boolean>(true);

  const [paymentsSourceFileId, setPaymentsSourceFileId] = useState<string>();
  const [sourceFileData, setSourceFileData] = useState<PaymentsSourceFile>();

  const [fileName, setFileName] = useState<string>();
  const [fileText, setFileText] = useState<string>();
  const [fileDom, setFileDom] = useState<Document>();
  const [fileJson, setFileJson] = useState<any>();
  const [payments, setPayments] = useState<Payment[]>();

  const [uploadedPayments, setUploadedPayments] = useState<Payment[]>();

  const { API } = useApi();

  useEffect(() => {
    if (!fileName) return;
    API.get(`payments/source/check/${fileName}`).then((res) => {
      setIsFilealreadyInDatabase(res.data.result);
      if (res.data.result) setPaymentsSourceFileId(res.data.paymentSourceFile._id);
    });
  }, [API, fileName]);

  const parseFileText = () => {
    if (!fileText) return;
    if (window.DOMParser) {
      try {
        const dom = new DOMParser().parseFromString(fileText, 'text/xml');
        setFileDom(dom);
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log('error while parsing');
    }
  };
  useEffect(parseFileText, [fileText]);

  const parseFileDom = () => {
    if (!fileDom) return;
    const jsonObject = JSON.parse(xml2json(fileDom));
    setFileJson(jsonObject);
    setFileParsed(true);
  };
  useEffect(parseFileDom, [fileDom]);

  const parseFileJson = () => {
    if (!fileJson) return;
    // console.log(fileJson);
    const from = new Date(fileJson.Document.BkToCstmrStmt.Stmt.FrToDt.FrDtTm);
    const to = new Date(fileJson.Document.BkToCstmrStmt.Stmt.FrToDt.ToDtTm);

    const crdtCount = Number(
      fileJson.Document.BkToCstmrStmt.Stmt.TxsSummry.TtlCdtNtries.NbOfNtries
    );
    const crdtSum = Number(fileJson.Document.BkToCstmrStmt.Stmt.TxsSummry.TtlCdtNtries.Sum);
    const dbitCount = Number(
      fileJson.Document.BkToCstmrStmt.Stmt.TxsSummry.TtlDbtNtries.NbOfNtries
    );
    const dbitSum = Number(fileJson.Document.BkToCstmrStmt.Stmt.TxsSummry.TtlDbtNtries.Sum);
    const accountInfo: PaymentParticipant = {
      name: fileJson.Document.BkToCstmrStmt.Stmt.Acct.Ownr.Nm,
      iban: fileJson.Document.BkToCstmrStmt.Stmt.Acct?.Id.IBAN,
    };

    setSourceFileData({
      type: 'camt053',
      name: fileName,
      from,
      to,
      crdtCount,
      crdtSum,
      dbitCount,
      dbitSum,
      iban: accountInfo.iban,
    });

    const arrayOfNtrys = fileJson.Document.BkToCstmrStmt.Stmt.Ntry;

    const crdtArray: Payment[] = [];

    arrayOfNtrys.forEach((ntry) => {
      // eslint-disable-next-line prefer-destructuring
      if (Array.isArray(ntry.NtryDtls)) ntry.NtryDtls = ntry.NtryDtls[0];

      if (ntry.CdtDbtInd === 'CRDT') {
        console.log(ntry);

        const endToEndId: string = ntry.NtryDtls?.TxDtls?.Refs?.EndToEndId || '';
        let variableSymbol = '';
        if (endToEndId.startsWith('/VS')) {
          // eslint-disable-next-line prefer-destructuring
          variableSymbol = endToEndId.split('/VS')[1].split('/SS')[0];
        }

        const payment: Payment = {
          amount: Number(ntry.Amt['#text']),
          ntryRef: ntry.NtryRef,
          type: PaymentType.CRDT,
          accountId: accountInfo.iban,
          variableSymbol,
          payer: {
            iban: ntry.NtryDtls.TxDtls?.RltdPties?.DbtrAcct?.Id.IBAN,
            name:
              ntry.NtryDtls.TxDtls?.RltdPties?.Dbtr?.Nm ||
              ntry.NtryDtls.TxDtls?.RltdPties?.TradgPty?.Nm ||
              '',
          },
          receiver: accountInfo,
          date: new Date(ntry.NtryDtls.TxDtls?.RltdDts.TxDtTm),
          source: undefined,
          note: ntry.NtryDtls.TxDtls?.RmtInf?.Ustrd || '',
        };
        crdtArray.push(payment);
      }

      if (ntry.CdtDbtInd === 'DBIT') {
        // console.log(ntry);

        const endToEndId: string = ntry.NtryDtls?.TxDtls?.Refs?.EndToEndId || '';
        let variableSymbol = '';
        if (endToEndId.startsWith('/VS')) {
          // eslint-disable-next-line prefer-destructuring
          variableSymbol = endToEndId.split('/VS')[1].split('/SS')[0];
        }

        const payment: Payment = {
          amount: Number(ntry.Amt['#text']),
          ntryRef: ntry.NtryRef,
          accountId: accountInfo.iban,
          type: PaymentType.DBIT,
          variableSymbol,
          payer: accountInfo,
          receiver: {
            iban:
              ntry.NtryDtls.TxDtls?.RltdPties?.CdtrAcct?.Id?.IBAN ||
              ntry.NtryDtls.TxDtls?.RltdPties?.Prtry?.Pty?.Nm ||
              '',
            name:
              ntry.NtryDtls.TxDtls?.RltdPties?.Cdtr?.Nm ||
              ntry.NtryDtls.TxDtls?.RltdPties?.TradgPty?.Nm ||
              '',
          },
          date: new Date(ntry.NtryDtls.TxDtls?.RltdDts.TxDtTm),
          source: undefined,
          note: ntry.NtryDtls.TxDtls?.RmtInf?.Ustrd || '',
        };

        crdtArray.push(payment);
      }
    });
    setPayments(crdtArray);
    console.log(crdtArray);
  };
  useEffect(parseFileJson, [fileJson, fileName]);

  const downloadFilesForGSheets = useCallback(() => {
    if (!fileJson) return;
    const arrayOfNtrys = fileJson.Document.BkToCstmrStmt.Stmt.Ntry;
    const startDate = new Date(fileJson.Document.BkToCstmrStmt.Stmt.FrToDt.FrDtTm);
    const endDate = new Date(fileJson.Document.BkToCstmrStmt.Stmt.FrToDt.ToDtTm);

    const crdtArray = [];
    const dbitArray = [];
    arrayOfNtrys.forEach((ntry) => {
      if (ntry.CdtDbtInd === 'CRDT') {
        crdtArray.push(getCrdtItem(ntry));
      } else if (ntry.CdtDbtInd === 'DBIT') {
        dbitArray.push(getDbitItem(ntry));
      } else {
        console.log('unexpected data');
      }
    });
    downloadFileForSheets(startDate, endDate, crdtArray, dbitArray);
  }, [fileJson]);

  const fileSelectedHandler = useCallback((event) => {
    const fileList = event.target.files;
    const file = fileList[0];

    if (file) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.readAsText(file, 'UTF-8');
      reader.onload = (evt) => {
        const text = evt.target.result;
        setFileText(text as string);
        // const dom = parseXml(text);
      };
      reader.onerror = (evt) => {
        console.log(evt);
        console.log('error');
      };
    }
  }, []);

  const uploadPaymentsRaw = useCallback(
    async (source: string = paymentsSourceFileId) => {
      console.log(source);
      console.log(paymentsSourceFileId);
      const found = await API.post(
        'payments',
        payments.map((p) => ({ ...p, source }))
      );
      console.log(found);
      setUploadedPayments(found.data);
    },
    [API, payments, paymentsSourceFileId]
  );
  const uploadPayments = useCallback(async () => {
    uploadPaymentsRaw();
  }, [uploadPaymentsRaw]);

  const uploadWholeFile = useCallback(async () => {
    const sourceFile = (await API.post('payments/source', sourceFileData)).data;
    uploadPaymentsRaw(sourceFile._id);
    setPaymentsSourceFileId(sourceFile._id);
  }, [API, sourceFileData, uploadPaymentsRaw]);

  const contextObjects = useMemo(
    () => ({
      // tba
      fileName,
      fileParsed,
      isFilealreadyInDatabase,
      payments,
      uploadedPayments,
      downloadFilesForGSheets,
      uploadWholeFile,
      fileSelectedHandler,
      uploadPayments,
      sourceFileData,
      paymentsSourceFileId,
    }),
    [
      isFilealreadyInDatabase,
      downloadFilesForGSheets,
      uploadWholeFile,
      payments,
      fileName,
      fileParsed,
      fileSelectedHandler,
      uploadPayments,
      uploadedPayments,
      sourceFileData,
      paymentsSourceFileId,
    ]
  );

  return (
    <PaymentProcessContext.Provider value={contextObjects}>
      {children}
    </PaymentProcessContext.Provider>
  );
};
