export interface FileInfo {
  text: string;
  name: string;
}

export const readFile = (event, then: (info: FileInfo) => void) => {
  const fileList = event.target.files;
  const file = fileList[0];

  if (file) {
    const reader = new FileReader();
    reader.readAsText(file, 'UTF-8');
    reader.onload = (evt) => {
      const text = evt.target.result;
      then({ name: file.name, text: text as string });
    };
    reader.onerror = (evt) => {
      console.log(evt);
      console.log('error');
    };
  }
};
