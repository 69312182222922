import { Button, Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material';
import { FC, Fragment } from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  tableHeaderMappingBasic,
  tableHeaderMappingCompany,
  tableHeaderMappingIndividual,
  useImportContacts,
} from '../../../ctx/contacts/ImportContacts.Context';
import ContactTypeSelector from '../ContactTypeSelector';
import { IImportContactsStep } from '../../../pages/ImportContacts';

const ImportContactStep: FC<IImportContactsStep> = ({ next }) => {
  const { fileSelectedHandler, typeOfContacts, setTypeOfContacts } = useImportContacts();

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item>
                <ContactTypeSelector
                  value={typeOfContacts}
                  onSelect={setTypeOfContacts}
                  label="Typ kontaktov v súbore"
                />
              </Grid>

              <Grid item alignSelf="center">
                <label htmlFor="upload-contacts-file">
                  <input
                    style={{ display: 'none' }}
                    id="upload-contacts-file"
                    name="upload-contacts-file"
                    type="file"
                    onChange={(e) => {
                      fileSelectedHandler(e);
                      next();
                    }}
                  />

                  <Button
                    startIcon={<UploadFileIcon />}
                    color="secondary"
                    variant="contained"
                    component="span"
                  >
                    Nahrať súbor
                  </Button>
                </label>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title="KANis rozpoznáva nasledujúce stĺpce"
            subheader="Všetky hodnoty uvedené v možnostiach sú: (case, whitespace, accent)-insensitive. Teda ako v možnostiach vidíte 'nazovspolocnosti' a vo vasej tabulke sa nachádza stĺpec s hlavičkou 'Názov Spoločnosti' je to ok."
          />
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Typography>V KANis databáze</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography>Vo vašej tabuľke môže byť</Typography>
              </Grid>
              <Grid item xs={9} container alignItems="center">
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="caption">Spločné</Typography>
              </Grid>
              {tableHeaderMappingBasic.map((o) => (
                <Fragment key={`thm-${o.databaseField}`}>
                  <Grid item xs={3}>
                    <Typography sx={{ fontFamily: 'monospace' }}>{o.databaseField}</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography sx={{ fontFamily: 'monospace' }}>
                      {JSON.stringify(o.supportedFields)}
                    </Typography>
                  </Grid>
                </Fragment>
              ))}
              <Grid item xs={9} container alignItems="center">
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="caption">Pre individuálov</Typography>
              </Grid>
              {tableHeaderMappingIndividual.map((o) => (
                <Fragment key={`thm-${o.databaseField}`}>
                  <Grid item xs={3}>
                    <Typography sx={{ fontFamily: 'monospace' }}>{o.databaseField}</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography sx={{ fontFamily: 'monospace' }}>
                      {JSON.stringify(o.supportedFields)}
                    </Typography>
                  </Grid>
                </Fragment>
              ))}
              <Grid item xs={9} container alignItems="center">
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="caption">Pre spoločnosti</Typography>
              </Grid>
              {tableHeaderMappingCompany.map((o) => (
                <Fragment key={`thm-${o.databaseField}`}>
                  <Grid item xs={3}>
                    <Typography sx={{ fontFamily: 'monospace' }}>{o.databaseField}</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography sx={{ fontFamily: 'monospace' }}>
                      {JSON.stringify(o.supportedFields)}
                    </Typography>
                  </Grid>
                </Fragment>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ImportContactStep;
