// ----------------------------------------------------------------------
import { alpha } from '@mui/material/styles';

export default function Card(theme) {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: theme.shadows[4],
          borderRadius: theme.shape.borderRadiusMd,
          // border: `1px solid ${alpha(theme.palette.grey[500], 0.5)}`,
          position: 'relative',
          bgColor: 'rgba(255,255,255,0.3)',
          backgroundColor: 'rgba(255,255,255,0.3)',
          zIndex: 0, // Fix Safari overflow: hidden with border radius
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h6' },
        subheaderTypographyProps: { variant: 'body2' },
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 3, 0),
          overflow: 'hidden',
        },
        content: {
          overflow: 'hidden',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3),
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          flexDirection: 'row-reverse',
        },
      },
    },
  };
}
