import { CompleteTag } from '../ctx/tags/Tags.Context';

export const recursiveIndexGetter = (x: CompleteTag): string[] => {
  if (!x.isCategory) return [x._id];
  return [...x.children.flatMap(recursiveIndexGetter), x._id];
};

export const recursiveTagGetter = (x: CompleteTag): CompleteTag[] => {
  if (!x.isCategory) return [x];
  return [...x.children.flatMap(recursiveTagGetter), x];
};
