import { FC, useEffect, useState } from 'react';
import { number, object, string } from 'yup';

import { useFormik, Form, FormikProvider } from 'formik';
import {
  Grid,
  IconButton,
  Stack,
  TextField,
  TextFieldProps,
  InputAdornment,
  ClickAwayListener,
  Box,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { useNewClaim } from '../../ctx/NewClaim.Context';
import { claimItemTotal } from '../../utils/claimsCalculations';
import { useStockItems } from '../../ctx/stockItems/StockItems.Context';
import PopoverPopupState from '../PopoverPopupState';
import { StockItemsTable } from '../stockItems/StockItemsTable';
import StockItemsPoper from './StockItemsPopper';
import { useNewOrder } from '../../ctx/order/NewOrder.Context';

interface IMyFormikTextField {
  type?: string;
  label: string;
  fieldName: string;
  touched?: any;
  errors?: any;
  values?: any;
  getFieldProps: (fieldName: string) => any;
}

const MyFormikTextField: FC<IMyFormikTextField & Partial<TextFieldProps>> = ({
  getFieldProps,
  type,
  label,
  touched,
  errors,
  values,
  fieldName,
  ...other
}) => {
  return (
    <TextField
      onFocus={(event) => {
        event.target.select();
      }}
      fullWidth
      autoComplete="off"
      type={type || 'text'}
      label={label}
      {...getFieldProps(fieldName)}
      error={Boolean(touched[fieldName] && errors[fieldName])}
      helperText={touched[fieldName] && errors[fieldName]}
      {...other}
    />
  );
};

export interface IAddClaimItemProps {
  skipDiscount?: boolean;
  order?: boolean;
}

const AddClaimItem: FC<IAddClaimItemProps> = ({ skipDiscount, order }) => {
  // const { addItem } = order ? useNewOrder() : useNewClaim();
  const addItemOrder = useNewOrder().addItem;
  const addItemClaim = useNewClaim().addItem;
  const addItem = order ? addItemOrder : addItemClaim;
  const { onSearch, hits, query, onClearQuery, stockItemsData } = useStockItems();
  const popupState = usePopupState({ variant: 'popper', popupId: 'demoPopper' });

  const [stockItem, setStockItem] = useState<string>();

  const ValidationSchema = object().shape({
    title: string().required('Je potrebné zadať názov položky.'),
    rawUnitPrice: number().required('Je potrebné zadať cenu položky.'),
    discount: number(),
    unitsCount: number(),
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      rawUnitPrice: '',
      discount: 0,
      unitsCount: 1,
    },
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: async (values: any) => {
      console.log(values);
      if (addItem({ ...values, finalPrice: claimItemTotal(values), stockItem })) {
        if (document.activeElement instanceof HTMLElement) document.activeElement.blur();
        resetForm();
        // if (values.saveItem && onSaveItem) {
        // onSaveItem(values);
        // }
      }
    },
  });
  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    resetForm,
    dirty,
    isValid,
    setValues,
    setFieldValue,
  } = formik;

  useEffect(() => {
    console.log(values.title);
    onSearch(values.title);
  }, [onSearch, values.title]);

  useEffect(() => {
    console.log(hits);
  }, [hits]);

  const handleItemSelected = (id: string) => {
    const { title, rawUnitPrice } = stockItemsData.find((i) => i._id === id);
    // setValues({ title, rawUnitPrice });
    setStockItem(id);
    setFieldValue('title', title);
    setFieldValue('rawUnitPrice', rawUnitPrice);

    popupState.close();
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ClickAwayListener onClickAway={popupState.close}>
              <Box>
                <StockItemsPoper
                  propPopupState={popupState}
                  rootElement={
                    <MyFormikTextField
                      label="Názov položky"
                      id="nazovPolozky"
                      multiline
                      fieldName="title"
                      {...{ getFieldProps, touched, errors, values }}
                    />
                  }
                  onClose={() => {
                    document.getElementById('nazovPolozky')?.focus();
                    popupState.close();
                  }}
                >
                  <StockItemsTable
                    // onClose={popupState.close}
                    rowClick={(e) => handleItemSelected(e.id)}
                    stockItems={hits.map((h) => ({ ...h.item, hit: h }))}
                  />
                </StockItemsPoper>
              </Box>
            </ClickAwayListener>
          </Grid>
          <Grid item xs={4}>
            <MyFormikTextField
              type="number"
              label="Jdn. cena"
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
              fieldName="rawUnitPrice"
              {...{ getFieldProps, touched, errors, values }}
            />
          </Grid>
          <Grid item xs={4}>
            <MyFormikTextField
              type="number"
              label="Počet"
              InputProps={{
                endAdornment: <InputAdornment position="end">ks</InputAdornment>,
              }}
              fieldName="unitsCount"
              {...{ getFieldProps, touched, errors, values }}
            />
          </Grid>
          {!skipDiscount && (
            <Grid item xs={4}>
              <MyFormikTextField
                type="number"
                label="Zľava"
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                fieldName="discount"
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Stack spacing={1} direction="row">
              <IconButton sx={{ ml: 'auto' }} color="primary" type="reset" disabled={!dirty}>
                <CancelIcon />
              </IconButton>
              <IconButton
                // variant="contained"
                color="secondary"
                type="submit"
                disabled={!dirty || !isValid}
              >
                <CheckCircleIcon />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default AddClaimItem;
