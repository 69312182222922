import { FC } from 'react';
import { date, number, object, string } from 'yup';

import { useFormik, Form, FormikProvider } from 'formik';
import { Button, Grid, InputAdornment, Stack, TextField } from '@mui/material';

import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { MyFormikTextField } from '../../MyFormikTextField';
import { SubmitListener } from '../../../utils/SubmitListener';
import { StockItem } from '../../../ctx/stockItems/StockItems.Context';

interface IStockItemForm {
  onConfirm(d: StockItem): void;
  initialValues?: StockItem;
  requiredFields?: Array<keyof StockItem>;
  omitFields?: Array<keyof StockItem>;
  autoSubmit?: boolean;
}

const StockItemForm: FC<IStockItemForm> = ({
  onConfirm,
  initialValues,
  requiredFields,
  omitFields,
  autoSubmit = true,
}) => {
  const ValidationSchema = object().shape({
    title: string().when('x', {
      is: () => requiredFields?.includes('title') || false,
      then: string().required(),
    }),
    rawUnitPrice: number().when('x', {
      is: () => requiredFields?.includes('rawUnitPrice') || false,
      then: number().required(),
    }),
  });

  const formik = useFormik<Partial<StockItem>>({
    initialValues: initialValues || {
      title: '',
      rawUnitPrice: undefined,
    },
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      console.log(values);
      onConfirm(values as StockItem);
    },
  });
  const { errors, touched, values, handleSubmit, getFieldProps, isValid, dirty } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {autoSubmit && <SubmitListener />}
        <Grid container spacing={1}>
          {!omitFields?.includes('title') && (
            <Grid item xs={8}>
              <MyFormikTextField
                label="Názov"
                fieldName="title"
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}
          {!omitFields?.includes('rawUnitPrice') && (
            <Grid item xs={4}>
              <MyFormikTextField
                label="Cena"
                fieldName="rawUnitPrice"
                {...{ getFieldProps, touched, errors, values }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">€</InputAdornment>,
                }}
              />
            </Grid>
          )}

          {!autoSubmit && (
            <Grid item xs={12}>
              <Stack direction="row-reverse" spacing={1}>
                <Stack spacing={1} direction="row-reverse">
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={!dirty || !isValid}
                  >
                    Potvrdiť
                  </Button>
                  <Button variant="outlined" color="primary" type="reset" disabled={!dirty}>
                    Resetovať
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default StockItemForm;
