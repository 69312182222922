import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useApi } from '../Api';
import { ListCampaignWithContacts } from './ListCampaign.context';

export interface ListCampaign {
  caption: string;
  description: string;
  date: Date;
}

export interface CompleteListCampaign extends ListCampaign {
  _id: string;
}

interface IListCampaignsContext {
  listcampaignsData?: ListCampaignWithContacts[];
  pullData: () => Promise<ListCampaignWithContacts[]>;
  createListCampaign: (listcampaignsData: ListCampaign) => Promise<void>;
  removeListCampaign: (listcampaignId: string) => Promise<void>;
  updateListCampaign: (listcampaignId: string, newData: ListCampaign) => Promise<void>;
}

const ListCampaignsContext = createContext<IListCampaignsContext>({
  listcampaignsData: [],
  pullData: () => undefined,
  createListCampaign: () => undefined,
  removeListCampaign: () => undefined,
  updateListCampaign: () => undefined,
});

export const useListCampaigns = () => {
  const context = useContext(ListCampaignsContext);
  if (!context) {
    throw new Error('Parent must be wrapped inside ListCampaignsProvider');
  }

  return context;
};

interface IListCampaignsProvider {
  // tba
  id?: string;
  dataProvider?: boolean;
  initialData?: ListCampaignWithContacts[];
  // onConfirmSelection?: ;
}

export const ListCampaignsProvider: FC<IListCampaignsProvider> = ({
  id,
  dataProvider = true,
  children,
  initialData,
}) => {
  const { API, defaultErrorHandle } = useApi();

  const [listcampaignsData, setListCampaignData] = useState<ListCampaignWithContacts[]>(
    initialData || []
  );

  const pullData = useCallback(async (): Promise<ListCampaignWithContacts[]> => {
    try {
      const res = await API.get(`listcampaigns`);
      if (dataProvider) setListCampaignData(res.data);
      return res.data;
    } catch (e: any) {
      defaultErrorHandle(e);
      return undefined;
    }
  }, [API, dataProvider, defaultErrorHandle]);

  useEffect(() => {
    if (dataProvider) pullData();
  }, [dataProvider, pullData]);

  const createListCampaign = useCallback(
    async (listcampaignsData: ListCampaign) => {
      try {
        await API.post(`listcampaigns/`, listcampaignsData);
        pullData();
      } catch (e: any) {
        defaultErrorHandle(e);
      }
    },
    [API, defaultErrorHandle, pullData]
  );

  const removeListCampaign = useCallback(
    async (listcampaignId: string) => {
      try {
        await API.delete(`listcampaigns/${listcampaignId}`);
        pullData();
      } catch (e: any) {
        defaultErrorHandle(e);
      }
    },
    [API, defaultErrorHandle, pullData]
  );

  const updateListCampaign = useCallback(
    async (listcampaignId: string, newData: ListCampaign) => {
      try {
        await API.put(`listcampaigns/${listcampaignId}`, { newData });
        pullData();
      } catch (e: any) {
        defaultErrorHandle(e);
      }
    },
    [API, defaultErrorHandle, pullData]
  );

  const contextObjects = useMemo(
    () => ({
      listcampaignsData,
      pullData,
      createListCampaign,
      removeListCampaign,
      updateListCampaign,
    }),
    [createListCampaign, pullData, removeListCampaign, listcampaignsData, updateListCampaign]
  );

  return (
    <ListCampaignsContext.Provider value={contextObjects}>{children}</ListCampaignsContext.Provider>
  );
};
