import { Button, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';

import { FC } from 'react';
import { DoneAll, UploadFile } from '@mui/icons-material';
import { ContactsTable } from '../ContactsTable';
import { useImportContacts } from '../../../ctx/contacts/ImportContacts.Context';
import { IImportContactsStep } from '../../../pages/ImportContacts';

const ImportSummary: FC<IImportContactsStep> = ({ next, back }) => {
  const { importSummary, affectedContacts } = useImportContacts();
  return (
    <Card>
      <CardHeader
        title="Hotovo!"
        subheader={
          <>
            <Typography variant="caption">Nových kontaktov: {importSummary?.created}</Typography>
            <br />
            <Typography variant="caption">
              Updatovaných kontaktov: {importSummary?.updated}
            </Typography>
            <br />
            <Typography variant="caption">
              Otagovaných kontaktov: {importSummary?.tagged}
            </Typography>
            <br />
          </>
        }
        action={
          <Stack direction="row-reverse" spacing={1}>
            <Button variant="contained" color="secondary" startIcon={<DoneAll />} onClick={next}>
              Hotovo
            </Button>
            <Button variant="outlined" startIcon={<UploadFile />} onClick={back}>
              Ďalší import
            </Button>
          </Stack>
        }
      />
      <CardContent>
        <ContactsTable contacts={affectedContacts} />
      </CardContent>
    </Card>
  );
};

export default ImportSummary;
