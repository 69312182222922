import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, PERSIST } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createStateSyncMiddleware } from 'redux-state-sync';
import counterReducer from './tokenSlice';

const persistConfig = {
  key: 'root',
  storage,
};

const persistentReducer = persistReducer(persistConfig, counterReducer);

const middlewareConfig = {
  blacklist: [PERSIST],
};
const middlewares = [createStateSyncMiddleware(middlewareConfig)];

export const store = configureStore({
  reducer: {
    counter: persistentReducer,
  },
  middleware: middlewares,
});

export const persistor = persistStore(store);
