import { FC, useState } from 'react';
import { Button, Card, Grid, IconButton, Stack, Typography } from '@mui/material';

import { Delete, UploadFile } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { usePaymentSourceFiles } from '../ctx/paymentSourceFiles/PaymentSourceFiles.Context';
import { PaymentSourceFilesTable } from '../components/payments/PaymentSourceFilesTable';
import MyModal from '../components/MyModal';
import PaymentSourceFileInfoCard from '../components/payments/PaymentSourceFileInfoCard';
import { PaymentsSourceFileProvider } from '../ctx/PaymentsSourceFile.Context';
import PSFMatchMaker from '../components/payments/PSFMatchMaket';
import AcceptRejectButtonPair from '../components/AcceptRejectButtonPair';

const PaymentSourceFiles: FC = () => {
  const [removingPaymentSourceFile, setRemovingPaymentSourceFile] = useState<boolean>(false);

  const handleStartRemovingPaymentSourceFile = (id: string) => {
    setRemovingPaymentSourceFile(true);
    setFileIdToBeDeleted(id);
  };

  const handleStopRemovingPaymentSourceFile = () => {
    setRemovingPaymentSourceFile(false);
    setFileIdToBeDeleted(undefined);
  };

  const handleConfirmDeletion = () => {};

  const [fileIdToBeDeleted, setFileIdToBeDeleted] = useState<string>();

  const { paymentSourceFilesData, removePaymentSourceFile } = usePaymentSourceFiles();

  const fileToBeDeleted = paymentSourceFilesData.find((f) => f._id === fileIdToBeDeleted);

  const navigate = useNavigate();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} spacing={2} container>
          <Grid item>
            <Typography variant="h4">Súbory s platbami</Typography>
          </Grid>
          <Grid item xs />
          <Grid item>
            <Button
              variant="outlined"
              startIcon={<UploadFile />}
              onClick={() => navigate('/import/payments')}
            >
              Importovať platby
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <PaymentSourceFilesTable
              paymentSourceFiles={paymentSourceFilesData}
              customCols={[
                {
                  field: 'delete',
                  headerName: '',
                  renderCell: ({ row }) => (
                    <IconButton onClick={() => handleStartRemovingPaymentSourceFile(row._id)}>
                      <Delete />
                    </IconButton>
                  ),
                },
              ]}
            />
          </Card>
        </Grid>
      </Grid>
      <MyModal isOpened={!!fileToBeDeleted} handleClose={handleStopRemovingPaymentSourceFile}>
        {fileToBeDeleted && (
          <PaymentsSourceFileProvider
            id={fileToBeDeleted._id}
            dataProvider={false}
            initialData={fileToBeDeleted}
          >
            <Stack direction="column" spacing={3}>
              <Typography variant="h5">Chystáte sa vymazať súbor</Typography>
              <PaymentSourceFileInfoCard {...fileToBeDeleted} />
              <PSFMatchMaker />
            </Stack>
            <Stack direction="row-reverse" spacing={1}>
              <AcceptRejectButtonPair
                onConfirm={() => removePaymentSourceFile(fileToBeDeleted._id)}
                onReject={handleStopRemovingPaymentSourceFile}
              />
            </Stack>
          </PaymentsSourceFileProvider>
        )}
      </MyModal>
    </>
  );
};

export default PaymentSourceFiles;
