import { FC } from 'react';
import { object, string } from 'yup';

import { useFormik, Form, FormikProvider } from 'formik';
import { Button, Grid, Stack } from '@mui/material';

import { MyFormikTextField } from '../MyFormikTextField';
import { SubmitListener } from '../../utils/SubmitListener';
import { TagBasicInfo } from '../../ctx/tags/Tags.Context';

interface DescriptionmpanyInfoInfo {
  onConfirm(d: TagBasicInfo): void;
  initialValues?: TagBasicInfo;
  requiredFields?: Array<keyof TagBasicInfo>;
  omitFields?: Array<keyof TagBasicInfo>;
  autoSubmit?: boolean;
}

const TagForm: FC<DescriptionmpanyInfoInfo> = ({
  onConfirm,
  initialValues,
  requiredFields,
  omitFields,
  autoSubmit = true,
}) => {
  const ValidationSchema = object().shape({
    caption: string().when('x', {
      is: () => requiredFields?.includes('caption') || false,
      then: string().required(),
    }),
    description: string().when('x', {
      is: () => requiredFields?.includes('description') || false,
      then: string().required(),
    }),
  });

  const formik = useFormik<TagBasicInfo>({
    initialValues: initialValues || {
      caption: '',
      description: '',
    },
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      console.log(values);
      onConfirm(values);
    },
  });
  const { errors, touched, values, handleSubmit, getFieldProps, isValid, dirty } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {autoSubmit && <SubmitListener />}
        <Grid container spacing={1}>
          {!omitFields?.includes('caption') && (
            <Grid item xs={12}>
              <MyFormikTextField
                label="Názov tagu"
                fieldName="caption"
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}
          {!omitFields?.includes('description') && (
            <Grid item xs={12}>
              <MyFormikTextField
                label="Popis tagu"
                fieldName="description"
                {...{ getFieldProps, touched, errors, values }}
                multiline
                minRows={4}
              />
            </Grid>
          )}
          {!autoSubmit && (
            <Grid item xs={12}>
              <Stack direction="row-reverse" spacing={1}>
                <Stack spacing={1} direction="row-reverse">
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={!dirty || !isValid}
                  >
                    Potvrdiť
                  </Button>
                  <Button variant="outlined" color="primary" type="reset" disabled={!dirty}>
                    Resetovať
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default TagForm;
