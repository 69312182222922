import { FC, useEffect } from 'react';
import { number, object } from 'yup';

import { useFormik, Form, FormikProvider } from 'formik';
import { Button, Grid, Stack, TextField } from '@mui/material';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { CompleteSubscription } from '../../../ctx/subscriptions/Subscriptions.Context';

interface ISubscriptionBasicInfoForm {
  onConfirm(d: CompleteSubscription): void;
  initialValues?: CompleteSubscription;
  requiredFields?: Array<keyof CompleteSubscription>;
  omitFields?: Array<keyof CompleteSubscription>;
  autoSubmit?: boolean;
}

const SubscriptionBasicInfoForm: FC<ISubscriptionBasicInfoForm> = ({
  onConfirm,
  initialValues,
  requiredFields,
  omitFields,
  autoSubmit = true,
}) => {
  const ValidationSchema = object().shape({
    start: number().when('x', {
      is: () => requiredFields?.includes('start') || false,
      then: number().required(),
    }),
    end: number().when('x', {
      is: () => requiredFields?.includes('end') || false,
      then: number().required(),
    }),
  });

  const formik = useFormik({
    initialValues: initialValues || {
      start: null,
      end: null,
    },
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      console.log(values);
      onConfirm({ ...initialValues, ...values });
    },
  });

  useEffect(() => {
    if (initialValues) return;
    const now = new Date();
    now.setDate(1);
    formik.setFieldValue('start', now);
  }, []);

  const { errors, touched, values, handleSubmit, getFieldProps, isValid, dirty } = formik;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3} sx={{ m: 3 }} direction="column">
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <DatePicker
                  label="Začiatok"
                  value={values.start}
                  views={['month', 'year']}
                  onChange={(value) => {
                    const date = new Date(value!);
                    date.setDate(1);
                    console.log(date.toISOString());
                    formik.setFieldValue('start', date.getTime());
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.start && errors.start)}
                      helperText={touched.start && errors.start}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  label="Koniec"
                  value={values.end}
                  views={['month', 'year']}
                  onChange={(value) => {
                    const date = new Date(value!);
                    date.setDate(-1);
                    date.setMonth(date.getMonth() + 1);
                    console.log(date.toISOString());
                    formik.setFieldValue('end', date.getTime());
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.end && errors.end)}
                      helperText={touched.end && errors.end}
                    />
                  )}
                />
              </Grid>
              {!autoSubmit && (
                <Grid item xs={12}>
                  <Stack direction="row-reverse" spacing={1}>
                    <Stack spacing={1} direction="row-reverse">
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        disabled={!isValid || !dirty}
                      >
                        Potvrdiť
                      </Button>
                      <Button variant="outlined" color="primary" type="reset" disabled={!dirty}>
                        Resetovať
                      </Button>
                    </Stack>
                  </Stack>
                </Grid>
              )}
            </Grid>
          </Stack>
        </Form>
      </FormikProvider>
    </LocalizationProvider>
  );
};

export default SubscriptionBasicInfoForm;
