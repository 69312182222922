import { FC, ReactElement, cloneElement, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Grid,
  Button,
  Card,
  Collapse,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import {
  Delete,
  ChangeCircle,
  FileDownload,
  Download,
  Add,
  Save,
  Cancel,
} from '@mui/icons-material';

import { useOrders, Order, OrderStateEnum } from '../ctx/order/Orders.Context';
import { fDateTime } from '../utils/formatTime';
import { fEur } from '../utils/formatNumber';
import RightDrawer from '../components/RightDrawer';
import MyModal from '../components/MyModal';
import AcceptRejectButtonPair from '../components/AcceptRejectButtonPair';
import NewOrder from '../components/claims/NewOrder';
import { isCompany } from '../ctx/contacts/Contact.Context';
import ClaimStateChip from '../components/claims/ClaimStateChip';
import { NewOrderProvider } from '../ctx/order/NewOrder.Context';

interface IOrderColumns {
  reloadData?: () => void;
}

const orderColumns = ({ reloadData }: IOrderColumns): GridColDef[] => [
  {
    field: 'issued',
    headerName: 'Vystavená',
    flex: 1,
    renderCell: (params) => <Box>{fDateTime(params.row.issued)}</Box>,
  },
  {
    field: 'number',
    headerName: 'Číslo objednávky',
    flex: 1,
    renderCell: ({ row }) => <Typography sx={{ fontFamily: 'monospace' }}>{row.number}</Typography>,
  },
  {
    field: 'payer',
    headerName: 'Dodávateľ',
    flex: 1,
    renderCell: ({ row }) => (
      <Box>
        {isCompany(row.payer)
          ? row.payer.companyName
          : `${row.payer.firstName} ${row.payer.lastName}`}
      </Box>
    ),
  },
  {
    field: 'totalAmount',
    headerName: 'Celková cena',
    flex: 1,
    renderCell: ({ row }) => <Box>{fEur(row.rawTotal)}</Box>,
  },
  {
    field: 'state',
    headerName: 'Stav',
    flex: 1,
    renderCell: ({ row }) => <ClaimStateChip {...row.state} />,
  },
  {
    field: 'actions',
    headerName: '',
    width: 250,
    renderCell: ({ row }) => (
      <Stack direction="row">
        <OrderStateChanger
          afterChange={reloadData}
          order={row}
          button={
            <IconButton>
              <ChangeCircle />
            </IconButton>
          }
        />
        <OrderDownloader order={row} />
        <OrderDeleter afterDone={reloadData} order={row} />
      </Stack>
    ),
  },
];

interface IOrderStateForm {
  handleClose: () => void;
  afterSubmit?: () => void;
  order: Order;
}

const OrderStateForm: FC<IOrderStateForm> = ({ handleClose, afterSubmit, order }) => {
  const { setOrderState } = useOrders();

  const [orderState, setOrderStates] = useState<OrderStateEnum>();
  const [note, setNote] = useState<string>();

  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);
  };

  const handleStateSelectChange = (event: SelectChangeEvent) => {
    setOrderStates(event.target.value as OrderStateEnum);
  };

  const handleSubmit = async () => {
    if (orderState) {
      await setOrderState(order._id, orderState);
      if (afterSubmit) afterSubmit();
      handleClose();
    }
  };

  return (
    <Stack spacing={3} sx={{ m: 3 }} direction="column">
      <Typography>Change order state {order.number}</Typography>
      <FormControl fullWidth>
        <InputLabel>New State</InputLabel>
        <Select value={orderState || ''} label="Nový stav" onChange={handleStateSelectChange}>
          <MenuItem
            disabled={order.state.title === OrderStateEnum.pending}
            value={OrderStateEnum.pending}
          >
            Čakajúca
          </MenuItem>
          <MenuItem
            disabled={order.state.title === OrderStateEnum.paid}
            value={OrderStateEnum.paid}
          >
            Zaplatená
          </MenuItem>
          <MenuItem
            disabled={order.state.title === OrderStateEnum.cancelled}
            value={OrderStateEnum.cancelled}
          >
            Zrušená
          </MenuItem>
        </Select>
      </FormControl>
      <TextField label="Poznámka" multiline minRows={4} value={note} onChange={handleNoteChange} />
      <Stack direction="row-reverse" spacing={1}>
        <Button
          onClick={handleSubmit}
          disabled={!orderState}
          color="secondary"
          variant="contained"
          startIcon={<Save />}
        >
          Save
        </Button>
        <Button onClick={handleClose} variant="outlined" startIcon={<Cancel />}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};

interface IOrderStateChanger {
  button: ReactElement;
  afterChange?: () => void;
  order: Order;
}

const OrderStateChanger: FC<IOrderStateChanger> = ({ button, afterChange, order }) => {
  const [opened, setOpened] = useState<boolean>(false);
  const handleOpen = () => setOpened(true);
  const handleClose = () => setOpened(false);

  return (
    <>
      <Tooltip title="Change State">{cloneElement(button, { onClick: handleOpen })}</Tooltip>
      <RightDrawer isOpened={opened} handleClose={handleClose}>
        <OrderStateForm handleClose={handleClose} afterSubmit={afterChange} order={order} />
      </RightDrawer>
    </>
  );
};

const OrderDownloader: FC<{ order: Order }> = ({ order }) => {
  const { downloadOrderPdf } = useOrders();
  return (
    <Tooltip title="Download Order PDF">
      <IconButton onClick={() => downloadOrderPdf(order._id, `${order.number}.pdf`)}>
        <FileDownload />
      </IconButton>
    </Tooltip>
  );
};

interface IOrderActionComponent {
  afterDone?: () => void;
  order: Order;
}

const OrderDeleter: FC<IOrderActionComponent> = ({ afterDone, order }) => {
  const { deleteOrder } = useOrders();
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  return (
    <>
      <MyModal isOpened={modalOpened} handleClose={() => setModalOpened(false)}>
        <Stack alignItems="center" gap={3} direction="column">
          Delete order {order.number}?
          <Stack direction="row" gap={2}>
            <AcceptRejectButtonPair
              onConfirm={async () => {
                await deleteOrder(order._id);
                if (afterDone) afterDone();
              }}
              onReject={() => setModalOpened(false)}
            />
          </Stack>
        </Stack>
      </MyModal>
      <Tooltip title="Delete Order">
        <IconButton onClick={() => setModalOpened(true)}>
          <Delete />
        </IconButton>
      </Tooltip>
    </>
  );
};

interface IOrdersTable {
  orders: Array<Order>;
  reloadData?: () => void;
}

const OrdersTable: FC<IOrdersTable> = ({ orders, reloadData }) => {
  return (
    <DataGrid
      autoHeight
      columns={orderColumns({ reloadData })}
      rows={orders.map((o) => ({ ...o, id: o._id }))}
    />
  );
};

const Orders: FC = () => {
  const {
    accountingYears,
    currentAccountingYear,
    setCurrentAccountingYearId,
    orders,
    pullOrders,
    downloadOrderPdf,
  } = useOrders();

  const [addingOrder, setAddingOrder] = useState<boolean>(false);
  const handleStartAddingOrder = () => setAddingOrder(true);
  const handleStopAddingOrder = () => setAddingOrder(false);

  const handleChange = (event: SelectChangeEvent) => {
    setCurrentAccountingYearId(event.target.value as string);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} spacing={2} container>
        <Grid item>
          <Typography variant="h4">Objednávky</Typography>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth size="small">
            <InputLabel id="select-accounting-year-label">Účtovný rok</InputLabel>
            <Select
              value={currentAccountingYear?._id || ''}
              label="Accounting Year"
              size="small"
              onChange={handleChange}
            >
              {accountingYears.map((ay) => (
                <MenuItem key={`ay-select-${ay._id}`} value={ay._id}>
                  {ay.prefix}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs />
        <Grid item>
          <Button
            color="secondary"
            variant="contained"
            startIcon={<Add />}
            disabled={addingOrder}
            onClick={handleStartAddingOrder}
          >
            Nová objednávka
          </Button>
        </Grid>
      </Grid>

      {addingOrder && (
        <Grid item xs={12}>
          <Collapse in={addingOrder}>
            <NewOrderProvider accountingYear={currentAccountingYear}>
              <NewOrder handleStopAdding={handleStopAddingOrder} onNewOrderSuccess={pullOrders} />
            </NewOrderProvider>
          </Collapse>
        </Grid>
      )}
      <Grid item xs={12}>
        <Card>
          <OrdersTable reloadData={pullOrders} orders={orders} />
        </Card>
      </Grid>
    </Grid>
  );
};

export default Orders;
