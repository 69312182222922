import { Card, CardHeader, CardContent, Stack, Typography, CardActions } from '@mui/material';
import { FC } from 'react';
import { CompleteStockItem } from '../../ctx/stockItems/StockItems.Context';
import { fEur } from '../../utils/formatNumber';
import { StockItemAvatar } from '../../utils/stringAvatar';

const StockItemInfoCard: FC<CompleteStockItem> = (stockItemData) => {
  return (
    <Card>
      <CardHeader
        title={stockItemData.title}
        avatar={<StockItemAvatar item={stockItemData} />}
        subheader={
          <Stack direction="column">
            <Typography variant="caption">{fEur(stockItemData.rawUnitPrice)}</Typography>
          </Stack>
        }
      />
      <CardActions />
    </Card>
  );
};

export default StockItemInfoCard;
