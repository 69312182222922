import { Download } from '@mui/icons-material';
import { Button } from '@mui/material';
import { FC, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { CompleteContact } from '../../contact/IndividualBusinessCard';

interface ICSVDownload {
  getContacts: () => Promise<CompleteContact[]>;
  onSuccess?: () => void;
}

const CSVDownload: FC<ICSVDownload> = ({ getContacts, onSuccess }) => {
  const csvLink: any = useRef();

  const [contacts, setContacts] = useState<CompleteContact[]>([]);

  const handleCSVDownload = async () => {
    if (csvLink && csvLink.current && csvLink.current.link) {
      setContacts(await getContacts());
      csvLink.current.link.click();
      if (onSuccess) onSuccess();
    }
  };
  return (
    <>
      <Button variant="outlined" startIcon={<Download />} onClick={handleCSVDownload}>
        Stiahnuť CSV
      </Button>
      <CSVLink
        data={contacts.map((c) => {
          /* eslint-disable no-unused-vars */
          /* eslint-disable @typescript-eslint/naming-convention */
          const newLocal = c as any;
          const {
            _id,
            owner,
            type,
            tags,
            otherEmails,
            otherAddresses,
            otherPhoneNumbers,
            otherIbans,
            __v,
            id,
            address,
            ...data
          } = newLocal;

          if (address) {
            const { _id, ...newAddress } = address;
            return { ...data, ...(!!newAddress && newAddress) };
          }
          return { ...data };
          /* eslint-enable no-unused-vars */
          /* eslint-enable @typescript-eslint/naming-convention */
        })}
        filename="Kontakty.csv"
        style={{ display: 'none' }}
        ref={csvLink}
        target="_blank"
      />
    </>
  );
};

export default CSVDownload;
