import { Card, CardProps } from '@mui/material';
import { FC } from 'react';

export const OutlinedCard: FC<CardProps> = (props) => (
  <Card
    {...props}
    sx={(theme) => ({
      // transition: theme.transitions.create(['border-color', 'outline', 'opacity'], {
      //   duration: theme.transitions.duration.complex,
      // }),
      transition: 'all 2s',
      boxShadow: '0px 0px 0px 1px rgba(224, 224, 224, 0)',
      backgroundColor: 'rgba(224, 224, 224, 0)',
      outline: 'solid 1px rgba(224, 224, 224, 1)',
    })}
  />
);
