import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Modal,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Delete, Edit, Public, Send } from '@mui/icons-material';
import SubscriptionBasicInfoCard from '../components/subscription/SubscriptionBasicInfoCard';
import {
  useSubscription,
  SubscriptionProvider,
  EmailOptions,
} from '../ctx/subscriptions/Subscription.Context';
import OutlinedSection from '../components/OutlinedSection';
import { PaymentsTable } from '../components/payments/PaymentsTable';
import { CompleteSubscription } from '../ctx/subscriptions/Subscriptions.Context';
import RightDrawer from '../components/RightDrawer';
import SubscriptionBasicInfoForm from '../components/subscriptions/forms/SubscriptionBasicInfoForm';
import SubscriptionExpectedPaymentsCard from '../components/subscription/SubscriptionExpectedPaymentsCard';
import EmailOptionsForm from '../components/email/forms/EmailForm';
import { EmailsTable } from '../components/email/EmailsTable';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40vw',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const SubscriptionInfo: FC = () => {
  const {
    subscriptionData,
    editSubscription,
    previewEmailForCurrentMonth,
    sendEmailForCurrentMonth,
  } = useSubscription();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [openEmailmodal, setOpenEmailModal] = useState(false);
  const handleOpenEmailModal = () => setOpenEmailModal(true);
  const handleCloseEmailModal = () => setOpenEmailModal(false);

  const [emailOptions, setEmailOptions] = useState<EmailOptions>();

  useEffect(() => {
    const pull = async () => {
      const email = await previewEmailForCurrentMonth();
      setEmailOptions(email.options);
    };
    pull();
  }, [previewEmailForCurrentMonth]);

  const startEditing = () => setIsEditing(true);
  const stopEditing = () => {
    setIsEditing(false);
  };
  const navigate = useNavigate();

  return subscriptionData ? (
    <>
      <Grid container spacing={3}>
        <Grid item md={6} container spacing={3}>
          <Grid item xs={12}>
            <SubscriptionBasicInfoCard subscriptionData={subscriptionData} />
          </Grid>
          <Grid item xs={12}>
            <SubscriptionExpectedPaymentsCard subscriptionData={subscriptionData} />
          </Grid>
        </Grid>
        <Grid item md={6} container spacing={3} alignContent="flex-start">
          <Grid item xs={12}>
            <OutlinedSection label="Akcie">
              {/* <CardContent> */}
              <Stack direction="row">
                <Tooltip
                  title={
                    subscriptionData.payer.email
                      ? 'Odoslať email s údajmi predplatného'
                      : 'Klient predplatného nemá zadaný email.'
                  }
                >
                  <IconButton onClick={handleOpenEmailModal}>
                    <Send />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Otvoriť na verejnej adrese">
                  <IconButton
                    // onClick={() => navigate(`/public/subscriptions/${subscriptionData.urlId}`)}
                    onClick={() => window.open(`/public/subscription/${subscriptionData.urlId}`)}
                  >
                    <Public />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Upraviť predplatné">
                  <IconButton onClick={startEditing}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Zmazať predplatné">
                  <IconButton>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Stack>
              {/* </CardContent> */}
            </OutlinedSection>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Emaily" />
              <CardContent>
                <EmailsTable emails={subscriptionData.emails} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title="Platby"
                subheader={
                  <Stack direction="column">
                    <Typography variant="caption">{`Očakávaných platieb: ${subscriptionData.actualState.expectedPayments}`}</Typography>
                    <Typography variant="caption">{`Zaplatených platieb: ${subscriptionData.actualState.payments}`}</Typography>
                    <Typography variant="caption">{`Odpustených platieb: ${subscriptionData.actualState.forgivedPayments}`}</Typography>
                  </Stack>
                }
              />
              <CardContent>
                <PaymentsTable
                  payments={subscriptionData.payments.map((p) => ({ ...p, id: p._id }))}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <RightDrawer isOpened={isEditing} handleClose={stopEditing}>
        <Stack direction="column" spacing={3} sx={{ m: 3 }}>
          <SubscriptionBasicInfoForm
            initialValues={subscriptionData}
            onConfirm={editSubscription}
            autoSubmit={false}
          />
        </Stack>
      </RightDrawer>
      <Modal
        open={openEmailmodal}
        onClose={handleCloseEmailModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={modalStyle}>
          <EmailOptionsForm
            requiredFields={['from', 'to', 'text', 'subject', 'replyTo']}
            initialValues={emailOptions}
            autoSubmit={false}
            onConfirm={(d) => {
              sendEmailForCurrentMonth(d);
              console.log('tak wtf');
              handleCloseEmailModal();
            }}
          />
        </Card>
      </Modal>
    </>
  ) : (
    <Skeleton />
  );
};

const Subscription: FC = () => {
  const { subscriptionId } = useParams();

  return (
    <SubscriptionProvider id={subscriptionId}>
      <SubscriptionInfo />
    </SubscriptionProvider>
  );
};

export default Subscription;
