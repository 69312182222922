import { Close } from '@mui/icons-material';
import { Stack, Box, Grid, Typography, IconButton } from '@mui/material';
import { FC } from 'react';
import Scrollbar from '../layouts/authorized/Scrollbar';
import { CompleteContact } from './contact/IndividualBusinessCard';
import AddContactsSelector from './contacts/AddContactsSelector';
import RightDrawer from './RightDrawer';

interface IRightDrawerAddContactSelector {
  opened: boolean;
  handleClose: () => void;
  caption: string;
  onConfirm?: (data: string[]) => void;
  onConfirmFull?: (data: CompleteContact[]) => void;
  initialSelected: string[];
}

const RightDrawerAddContactSelector: FC<IRightDrawerAddContactSelector> = ({
  opened,
  handleClose,
  caption,
  onConfirm,
  onConfirmFull,
  initialSelected,
}) => (
  <RightDrawer isOpened={opened} handleClose={handleClose} size="twothirds">
    <Stack sx={{ m: 3 }} spacing={3}>
      <Box>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h5">{caption}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Stack>
    <Box sx={{ height: '100%' }}>
      <Box sx={{ px: 2.5, py: 3 }}>
        <AddContactsSelector
          onConfirm={onConfirm}
          onConfirmFull={onConfirmFull}
          initialSelected={initialSelected}
          autoConfirm={false}
        />
      </Box>
    </Box>
  </RightDrawer>
);

export default RightDrawerAddContactSelector;
