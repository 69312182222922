import { FC, useEffect, useState } from 'react';
import {
  Grid,
  Stack,
  TextField,
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  SelectChangeEvent,
  Button,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Cancel, Save } from '@mui/icons-material';

import { CompleteClaim, useClaims } from '../../ctx/Claims.Context';
import RightDrawer from '../RightDrawer';

interface IClaimDownloadOption {
  value: string;
  label: string;
}

type IClaimDownloadOptions = IClaimDownloadOption[];

const ClaimsDownloadFormats: IClaimDownloadOptions = [
  { value: 'pdf', label: 'Jedno PDF' },
  { value: 'zip', label: 'ZIP archív' },
  { value: 'MRPKS', label: 'MRP-K/S' },
];

const ClaimsDownloadCancelledOptions: IClaimDownloadOptions = [
  { value: 'withCreditNote', label: 'Len tie s dobropismi' },
  { value: 'none', label: 'Žiadne' },
  { value: 'all', label: 'Všetky' },
];

const getFirstDayPreviousMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth() - 1, 1);
};

const getLastDayPreviousMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 0);
};

// const isClaimBetweenDates = (start: number, end: number, issued: Date): boolean => {
//   const stYr = new Date(start).getFullYear();
//   const stMt = new Date(start).getMonth();
//   const stDy = new Date(start).getDate();
//   const endYr = new Date(end).getFullYear();
//   const endMt = new Date(end).getMonth();
//   const endDy = new Date(end).getDate();

//   const claimYr = new Date(issued).getFullYear();
//   const claimMt = new Date(issued).getMonth();
//   const claimDy = new Date(issued).getDate();

//   // console.log(stYr, stMt, stDy, endYr, endMt, endDy, claimYr, claimMt, claimDy);

//   if (stYr <= claimYr && claimYr <= endYr) {
//     // console.log('year is between');
//     if (stYr < claimYr || claimYr < endYr) return true;
//     // console.log('year is equal');
//     if (stMt <= claimMt && claimMt <= endMt) {
//       // console.log('month is less');
//       if (stMt < claimMt || claimMt < endMt) return true;
//       // console.log('month is equal');
//       if (stDy <= claimDy && claimDy <= endDy) {
//         // console.log('day is less');
//         return true;
//       }
//     }
//   }
//   // console.log('false');
//   return false;
// };

const isClaimBetweenDates = (start: number, end: number, issued: Date): boolean => {
  const startDate = new Date(start);
  startDate.setHours(0, 0, 0, 0); // set time to start of the day

  const endDate = new Date(end);
  endDate.setHours(23, 59, 59, 999); // set time to end of the day

  const issuedDate = new Date(issued);
  issuedDate.setHours(0, 0, 0, 0); // set time to start of the day

  return issuedDate >= startDate && issuedDate <= endDate;
};

interface IClaimsDownloader {
  isOpened: boolean;
  handleClose: () => void;
}

const ClaimsDownloader: FC<IClaimsDownloader> = ({ isOpened, handleClose }) => {
  const { currentAccountingYear, currentClaimGroup, currentClaims, downloadInvoices } = useClaims();

  const [downloadFormat, setDownloadFormat] = useState<IClaimDownloadOption>(
    ClaimsDownloadFormats[0]
  );
  const [downloadCancelledOption, setCancelledOption] = useState<IClaimDownloadOption>(
    ClaimsDownloadCancelledOptions[0]
  );

  const [downloadStart, setDownloadStart] = useState<number>(getFirstDayPreviousMonth().getTime());
  const [downloadEnd, setDownloadEnd] = useState<number>(getLastDayPreviousMonth().getTime());

  const handleChangeFormat = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setDownloadFormat(ClaimsDownloadFormats.find((f) => f.value === value));
  };

  const handleChangeCancelledOption = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setCancelledOption(ClaimsDownloadCancelledOptions.find((o) => o.value === value));
  };

  const filtered = currentClaims.flatMap((c) => {
    if (
      downloadCancelledOption.value === 'withCreditNote' &&
      c.state.title === 'Zrušená' &&
      !c.creditNote
    )
      return [];
    if (downloadCancelledOption.value === 'none' && c.state.title === 'Zrušená') return [];

    const creditNoteBetweenDates = c.creditNoteIssued
      ? isClaimBetweenDates(downloadStart, downloadEnd, c.creditNoteIssued)
      : null;

    const claimBetweenDates = isClaimBetweenDates(downloadStart, downloadEnd, c.issued);
    // console.log(c, claimBetweenDates, creditNoteBetweenDates);

    if (!claimBetweenDates && !creditNoteBetweenDates) return [];

    const creditNoteDownloadOption: 'include' | 'exclude' | 'only' = 'include';

    if (creditNoteBetweenDates === null) return [{ ...c, creditNoteDownloadOption }];
    if (claimBetweenDates && creditNoteBetweenDates) return [{ ...c, creditNoteDownloadOption }];
    if (claimBetweenDates && !creditNoteBetweenDates)
      return [{ ...c, creditNoteDownloadOption: 'exclude' }];
    if (!claimBetweenDates && creditNoteBetweenDates)
      return [{ ...c, creditNoteDownloadOption: 'only' }];

    return [];
  });

  // useEffect(() => console.log(filtered), [filtered]);

  const filename = `${currentAccountingYear?.prefix}_${
    currentClaimGroup ? `${currentClaimGroup.title}_` : ''
  }${new Date(downloadStart).toLocaleDateString('sk-SK')}_${new Date(
    downloadEnd
  ).toLocaleDateString('sk-SK')}_canInc_${downloadCancelledOption.value}.${
    downloadFormat.value === 'MRPKS' ? 'zip' : downloadFormat.value
  }`;

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <RightDrawer isOpened={isOpened} handleClose={handleClose}>
          <Box sx={{ m: 3 }}>
            <Stack direction="column" spacing={3}>
              <Typography variant="h5">
                Hromadne stiahnuť faktúry roku {currentAccountingYear?.prefix}{' '}
                {currentClaimGroup && `skupiny ${currentClaimGroup.title}`}
              </Typography>
              <Divider />
              <FormControl fullWidth size="small">
                <InputLabel id="select-df-label">Formát</InputLabel>
                <Select
                  value={downloadFormat.value}
                  label="Formát"
                  size="small"
                  onChange={handleChangeFormat}
                >
                  {ClaimsDownloadFormats.map((df) => (
                    <MenuItem key={`df-select-${df.value}`} value={df.value}>
                      {df.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth size="small">
                <InputLabel id="select-dco-label">Stiahnuť zrušené?</InputLabel>
                <Select
                  value={downloadCancelledOption.value}
                  label="Stiahnuť zrušené?"
                  size="small"
                  onChange={handleChangeCancelledOption}
                >
                  {ClaimsDownloadCancelledOptions.map((dco) => (
                    <MenuItem key={`dco-select-${dco.value}`} value={dco.value}>
                      {dco.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <DatePicker
                      label="Začiatok"
                      value={downloadStart}
                      onChange={(value: any) => {
                        setDownloadStart(Date.parse(value!));
                      }}
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      label="Koniec"
                      value={downloadEnd}
                      onChange={(value: any) => {
                        setDownloadEnd(Date.parse(value!));
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          // value={new Date(params.value as string).toLocaleDateString('sk-SK')}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Typography>Vyhovuje faktúr: {filtered.length}</Typography>
              <Box>
                <Grid item xs={12} container direction="row-reverse" spacing={1}>
                  <Grid item>
                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      startIcon={<Save />}
                      onClick={() =>
                        downloadInvoices(
                          filtered.map(
                            (fc) =>
                              ({
                                id: fc._id,
                                creditNoteDownloadOption: fc.creditNoteDownloadOption,
                              } as any)
                          ),
                          downloadFormat.value,
                          filename
                        )
                      }
                    >
                      Stiahnuť
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button type="reset" variant="outlined" startIcon={<Cancel />}>
                      Zrušiť
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </Box>
        </RightDrawer>
      </LocalizationProvider>

      <Box />
    </>
  );
};

export default ClaimsDownloader;
