import { createContext, FC, useCallback, useContext, useMemo, useState } from 'react';
import { useFeedback } from '../Feedback';
import { useApi } from '../Api';
import { BasicContactInfo, Company, Individual } from '../Claims.Context';

export type JustContact = Omit<Individual, keyof BasicContactInfo>;
export type JustCompany = Omit<Company, keyof BasicContactInfo>;

export enum ContactTypes {
  Individual = 'Individual',
  Company = 'Company',
}

interface INewContactContext {
  basicInfo: BasicContactInfo;
  individualInfo: JustContact;
  companyInfo: JustCompany;
  contactType: ContactTypes;
  isValid: boolean;
  setBasicInfo(d: BasicContactInfo): void;
  setIndividualInfo(d: JustContact): void;
  setCompanyInfo(d: JustCompany): void;
  setContactType(d: ContactTypes): void;
  submitNewContact(): void;
}

const NewContactContext = createContext<INewContactContext>({
  basicInfo: undefined,
  individualInfo: undefined,
  companyInfo: undefined,
  contactType: ContactTypes.Individual,
  isValid: false,
  setBasicInfo: () => false,
  setIndividualInfo: () => undefined,
  setCompanyInfo: () => undefined,
  setContactType: () => undefined,
  submitNewContact: async () => false,
});

export const useNewContact = () => {
  const context = useContext(NewContactContext);
  if (!context) {
    throw new Error('Parent must be wrapped inside NewContactProvider');
  }
  return context;
};

interface INewContactProvider {
  onNewContactSuccess?: Function;
}

export const NewContactProvider: FC<INewContactProvider> = ({ onNewContactSuccess, children }) => {
  const { API, defaultErrorHandle } = useApi();
  const [basicInfo, setBasicInfo] = useState<BasicContactInfo>();
  const [individualInfo, setIndividualInfo] = useState<JustContact>();
  const [companyInfo, setCompanyInfo] = useState<JustCompany>();
  const [contactType, setContactType] = useState<ContactTypes>(ContactTypes.Individual);

  const { success } = useFeedback();

  const completeAddress: boolean = Boolean(
    basicInfo?.address &&
      basicInfo.address.city &&
      basicInfo.address.state &&
      basicInfo.address.zip &&
      basicInfo.address.street
  );

  const emptyAddress: boolean = Boolean(
    basicInfo?.address &&
      basicInfo.address.city === '' &&
      basicInfo.address.state === '' &&
      basicInfo.address.zip === '' &&
      basicInfo.address.street === ''
  );

  const atLeastOneBasicInfo: boolean = Boolean(
    basicInfo && (completeAddress || basicInfo.email || basicInfo.phoneNumber || basicInfo.iban)
  );

  const isValid: boolean =
    atLeastOneBasicInfo &&
    (contactType === ContactTypes.Company
      ? companyInfo?.companyName !== ''
      : individualInfo?.firstName !== '' && individualInfo?.lastName !== '');

  const submitNewContact = useCallback(async () => {
    if (!API || !basicInfo) return false;

    try {
      let newContact = {
        ...basicInfo,
        type: contactType,
        address: emptyAddress ? undefined : basicInfo.address,
      };

      if (contactType === ContactTypes.Company) {
        newContact = { ...newContact, ...companyInfo };
      }
      if (contactType === ContactTypes.Individual) {
        newContact = { ...newContact, ...individualInfo };
      }

      const res = await API.post(`/contacts`, newContact);
      success(`Kontak ${res.data.fullName} vytvorený.`);
      if (onNewContactSuccess) onNewContactSuccess();
      return true;
    } catch (e: any) {
      defaultErrorHandle(e);
      return false;
    }
  }, [
    API,
    basicInfo,
    contactType,
    emptyAddress,
    success,
    onNewContactSuccess,
    companyInfo,
    individualInfo,
    defaultErrorHandle,
  ]);

  const contextObjects = useMemo(
    () => ({
      basicInfo,
      individualInfo,
      companyInfo,
      contactType,
      isValid,
      setBasicInfo,
      setIndividualInfo,
      setCompanyInfo,
      setContactType,
      submitNewContact,
    }),
    [basicInfo, individualInfo, companyInfo, contactType, isValid, submitNewContact]
  );

  return <NewContactContext.Provider value={contextObjects}>{children}</NewContactContext.Provider>;
};
