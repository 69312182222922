import { CheckCircle, Pending } from '@mui/icons-material';
import {
  Card,
  CardHeader,
  Typography,
  CardContent,
  Grid,
  Divider,
  Stack,
  Tooltip,
  CardActions,
} from '@mui/material';
import { FC } from 'react';
import QRCode from 'react-qr-code';
import { CompleteSubscription } from '../../ctx/subscriptions/Subscriptions.Context';
import { fEur } from '../../utils/formatNumber';
import { fDate } from '../../utils/formatTime';
import ContactFullInfoCard from '../contact/ContactFullInfoCard';
import SubscriptionStateChip from '../subscriptions/SubscriptionStateChip';

interface ISubscriptionBasicInfoCard {
  subscriptionData: CompleteSubscription;
  noContactCard?: boolean;
  title?: string;
}
const SubscriptionBasicInfoCard: FC<ISubscriptionBasicInfoCard> = ({
  subscriptionData,
  noContactCard,
  title,
}) => {
  return (
    <Card>
      <CardHeader
        title={`${title || 'Predplatné'} ${subscriptionData.variableSymbol}`}
        subheader={
          <Stack direction="column">
            <Typography variant="caption">Začalo: {fDate(subscriptionData.start)}</Typography>
            <Typography variant="caption">Koniec: {fDate(subscriptionData.end)}</Typography>
            <Typography variant="caption">{`Očakávaných platieb: ${subscriptionData.actualState.expectedPayments}`}</Typography>
            <Typography variant="caption">{`Zaplatených platieb: ${subscriptionData.actualState.payments}`}</Typography>
            <Typography variant="caption">{`Odpustených platieb: ${subscriptionData.actualState.forgivedPayments}`}</Typography>
            <Typography variant="caption">
              Typ: {subscriptionData.subscriptionType.caption} (
              {subscriptionData.subscriptionType.prefix})
            </Typography>
            <Typography variant="caption">
              Cena: {fEur(subscriptionData.subscriptionType.price)}
            </Typography>
          </Stack>
        }
        action={
          <Stack direction="row-reverse" spacing={1} alignItems="center">
            <SubscriptionStateChip {...subscriptionData.actualState} />
            {!subscriptionData.isActive ? (
              <Tooltip title="Ukončené">
                <CheckCircle />
              </Tooltip>
            ) : (
              <Tooltip title="Aktívne">
                <Pending />
              </Tooltip>
            )}
          </Stack>
        }
      />
      {noContactCard ? (
        <CardActions />
      ) : (
        <CardContent>
          <Grid container spacing={1}>
            {/* <Grid item xs={12}>
            <Divider />
          </Grid> */}
            <Grid item md={12}>
              <ContactFullInfoCard outlined contactInfo={subscriptionData.payer} />
            </Grid>
          </Grid>
        </CardContent>
      )}
    </Card>
  );
};

export default SubscriptionBasicInfoCard;
