import { FC, useState } from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import MyAccount from '../components/settings/MyAccount';
import AdminPanel from '../components/settings/AdminPanel';

const Settings: FC = () => {
  const [value, setValue] = useState('1');

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Môj účet" value="1" />
            <Tab label="Tímy" value="2" />
            <Tab label="Admin panel" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <MyAccount />
        </TabPanel>
        <TabPanel value="2" />
        <TabPanel value="3">
          <AdminPanel />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default Settings;
