import { TextField, TextFieldProps } from '@mui/material';
import { FC } from 'react';

interface IMyFormikTextField {
  type?: string;
  label: string;
  fieldName: string;
  touched?: any;
  errors?: any;
  values?: any;
  getFieldProps: (fieldName: string) => any;
}

export const MyFormikTextField: FC<IMyFormikTextField & Partial<TextFieldProps>> = ({
  getFieldProps,
  type,
  label,
  touched,
  errors,
  fieldName,
  ...other
}) => {
  const fieldProps = getFieldProps(fieldName);
  return (
    <TextField
      onFocus={(event) => {
        event.target.select();
      }}
      fullWidth
      autoComplete="off"
      inputProps={{
        autoComplete: 'off',
      }}
      type={type || 'text'}
      label={label}
      {...{ ...fieldProps, value: fieldProps.value || '' }}
      error={Boolean(touched[fieldName] && errors[fieldName])}
      helperText={touched[fieldName] && errors[fieldName]}
      {...other}
    />
  );
};
