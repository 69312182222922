// ----------------------------------------------------------------------
import {
  AccountBalance,
  Autorenew,
  Dashboard,
  Label,
  MarkunreadMailbox,
  People,
  Receipt,
  Storefront,
  RequestQuote,
} from '@mui/icons-material';

const sidebarConfig = [
  {
    title: 'Dashboard',
    path: '/',
    icon: <Dashboard />,
  },
  {
    title: 'Kontakty',
    path: '/contacts',
    icon: <People />,
  },
  {
    title: 'Listy',
    path: '/listcampaigns',
    icon: <MarkunreadMailbox />,
  },
  {
    title: 'Pohľadávky',
    path: '/claims',
    icon: <Receipt />,
  },
  {
    title: 'Predplatné',
    path: '/subscriptiontypes',
    icon: <Autorenew />,
  },
  {
    title: 'Platby',
    path: '/payments',
    icon: <AccountBalance />,
  },
  {
    title: 'Sklad',
    path: '/stockitems',
    icon: <Storefront />,
  },
  {
    title: 'Objednávky',
    path: '/orders',
    icon: <RequestQuote />,
  },
  {
    title: 'Tagy',
    path: '/tags',
    icon: <Label />,
  },
];

export default sidebarConfig;
