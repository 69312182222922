// ----------------------------------------------------------------------

export default function Grid(theme) {
  return {
    MuiGrid: {
      styleOverrides: {
        root: {
          transition: 'all .1s ease-in-out',
        },
      },
    },
  };
}
