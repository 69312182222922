import { Card } from '@mui/material';
import { cloneElement, FC, ReactElement } from 'react';
import Popper from '@mui/material/Popper';
import { usePopupState, bindHover, bindPopper } from 'material-ui-popup-state/hooks';
import Fade from '@mui/material/Fade';
import { CompleteClaim } from '../../ctx/Claims.Context';
import ClaimBasicInfoCard from './ClaimBasicInfoCard';

interface IClaimPoper {
  claimData: CompleteClaim;
  rootElement: ReactElement;
}

const ClaimPoper: FC<IClaimPoper> = ({ claimData, rootElement }) => {
  const popupState = usePopupState({ variant: 'popper', popupId: 'demoPopper' });

  return (
    <>
      {cloneElement(rootElement, { ...bindHover(popupState) })}
      <Popper {...bindPopper(popupState)} transition style={{ zIndex: 10000 }}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Card sx={{ backgroundColor: 'white', backdropFilter: 'blur(5px)', zIndex: 100 }}>
              <ClaimBasicInfoCard claimData={claimData} />
            </Card>
          </Fade>
        )}
      </Popper>
    </>
  );
};
export default ClaimPoper;
