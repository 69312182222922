import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CPaymentWParts } from '../components/payments/PaymentSplitter';
import { useApi } from './Api';
import { Claim, CompleteClaim } from './Claims.Context';
import { Payment, PaymentsSourceFile } from './PaymentProcess.Context';
import { CompleteSubscription } from './subscriptions/Subscriptions.Context';

export interface CompletePaymentsSourceFile extends PaymentsSourceFile {
  _id: string;
}

export interface PaymentClaim extends Payment {
  potentialClaim?: CompleteClaim;
}

export interface MismatchedSumClaim extends Payment {
  claimWithSameVS?: CompleteClaim;
}

export interface PaymentSubscription extends Payment {
  potentialSubscription?: CompleteSubscription;
}

export const isPaymentSubscription = (x: any): x is PaymentSubscription =>
  'potentialSubscription' in x;

export const isPaymentClaim = (x: any): x is PaymentClaim => 'potentialClaim' in x;

interface IPaymentsSourceFileContext {
  sourceFileData?: CompletePaymentsSourceFile;
  potentialMatches?: PaymentClaim[] | undefined;
  potentialSubscriptions?: PaymentSubscription[] | undefined;
  mismatchedSumClaims?: MismatchedSumClaim[] | undefined;
  matches?: PaymentClaim[];
  subscriptions?: PaymentSubscription[];
  payments?: Payment[];
  pullData: () => Promise<CompletePaymentsSourceFile>;
  applyAllPotentials: () => Promise<void>;
  applyPotential: (potential: PaymentClaim) => Promise<void>;
  deleteMatch: (match: PaymentClaim) => Promise<void>;
  deleteAllMatches: () => Promise<void>;
  applyAllPotentialSubscriptions: () => Promise<void>;
  applyPotentialSubscription: (potentialsubscription: PaymentSubscription) => Promise<void>;
  deleteSubscription: (subscription: PaymentSubscription) => Promise<void>;
  deleteAllSubscriptions: () => Promise<void>;
  deleteFile: () => Promise<void>;
  splitPayment: (paymentId: string, parts: Partial<Payment>[]) => Promise<void>;
  getPayment: (paymentId: string) => Promise<CPaymentWParts>;
}

const PaymentsSourceFileContext = createContext<IPaymentsSourceFileContext>({
  sourceFileData: undefined,
  potentialMatches: undefined,
  potentialSubscriptions: undefined,
  mismatchedSumClaims: undefined,
  matches: undefined,
  subscriptions: undefined,
  payments: undefined,
  pullData: () => undefined,
  applyAllPotentials: () => undefined,
  applyPotential: () => undefined,
  deleteMatch: () => undefined,
  deleteAllMatches: () => undefined,
  applyAllPotentialSubscriptions: () => undefined,
  applyPotentialSubscription: () => undefined,
  deleteSubscription: () => undefined,
  deleteAllSubscriptions: () => undefined,
  deleteFile: () => undefined,
  splitPayment: () => undefined,
  getPayment: () => undefined,
});

export const usePaymentsSourceFile = () => {
  const context = useContext(PaymentsSourceFileContext);
  if (!context) {
    throw new Error('Parent must be wrapped inside PaymentsSourceFileProvider');
  }

  return context;
};

interface IPaymentsSourceFileProvider {
  // tba
  id: string;
  dataProvider?: boolean;
  initialData?: CompletePaymentsSourceFile;
}

export const PaymentsSourceFileProvider: FC<IPaymentsSourceFileProvider> = ({
  id,
  dataProvider = true,
  children,
  initialData,
}) => {
  const { API, defaultErrorHandle } = useApi();

  const [sourceFileData, setSourceFileData] = useState<CompletePaymentsSourceFile>(
    initialData || undefined
  );
  const [potentialMatches, setPotentialMatches] = useState<PaymentClaim[]>();
  const [matches, setMatches] = useState<PaymentClaim[]>();
  const [potentialSubscriptions, setPotentialSubscriptions] = useState<PaymentSubscription[]>();
  const [mismatchedSumClaims, setMismatchedSumClaim] = useState<MismatchedSumClaim[]>();
  const [subscriptions, setSubscriptions] = useState<PaymentSubscription[]>();
  const [payments, setPayments] = useState<Payment[]>([]);

  const pullData = useCallback(
    async (force: boolean = false): Promise<CompletePaymentsSourceFile> => {
      if (!API) return undefined;
      try {
        const res = await API.get(`payments/source/${id}`);
        console.log(res);
        setSourceFileData(res.data);
        if (dataProvider || force === true) setSourceFileData(res.data);
        return res.data;
      } catch (e: any) {
        defaultErrorHandle(e);
        return undefined;
      }
    },
    [API, dataProvider, defaultErrorHandle, id]
  );

  useEffect(() => {
    if (dataProvider) pullData();
  }, [dataProvider, pullData]);

  const pullPayments = useCallback(async (): Promise<void> => {
    try {
      if (!API) return;
      const res = await API.get(`payments/source/${id}/paymentswithfullinfo`);
      console.log(res.data);
      // return;
      setPayments(res.data);
      setMatches(res.data.filter((p) => p.claim));
      setSubscriptions(res.data.filter((p) => p.subscription));
      setPotentialMatches(res.data.filter((p) => p.potentialClaim));
      setPotentialSubscriptions(res.data.filter((p) => p.potentialSubscription));
      setMismatchedSumClaim(res.data.filter((p) => p.claimWithSameVS));
    } catch (e: any) {
      console.log(e);
      defaultErrorHandle(e);
    }
  }, [API, defaultErrorHandle, id]);

  useEffect(() => {
    pullPayments();
  }, [dataProvider, pullPayments]);

  const applyAllPotentials = useCallback(async (): Promise<void> => {
    try {
      const res = await API.post(`payments/pairmanywithclaims`, { potentialMatches });
      console.log(res);
      pullPayments();
    } catch (e: any) {
      defaultErrorHandle(e);
    }
  }, [API, defaultErrorHandle, potentialMatches, pullPayments]);

  const applyPotential = useCallback(
    async (potentialMatch: PaymentClaim): Promise<void> => {
      try {
        const res = await API.post(`payments/paironewithclaim`, { potentialMatch });
        console.log(res);
        pullPayments();
      } catch (e: any) {
        defaultErrorHandle(e);
      }
    },
    [API, defaultErrorHandle, pullPayments]
  );

  const deleteMatch = useCallback(
    async (match: PaymentClaim): Promise<void> => {
      try {
        const res = await API.post(`payments/unpairOneWithClaim`, { match });
        console.log(res);
        pullPayments();
      } catch (e: any) {
        defaultErrorHandle(e);
      }
    },
    [API, defaultErrorHandle, pullPayments]
  );

  const deleteAllMatches = useCallback(async (): Promise<void> => {
    try {
      const res = await API.post(`payments/unpairmanywithclaims`, { matches });
      console.log(res);
      pullPayments();
    } catch (e: any) {
      defaultErrorHandle(e);
    }
  }, [API, defaultErrorHandle, matches, pullPayments]);

  // SUBSCRIPTIONS!!
  const applyAllPotentialSubscriptions = useCallback(async (): Promise<void> => {
    try {
      const res = await API.post(`payments/pairmanywithsubscriptions`, { potentialSubscriptions });
      console.log(res);
      pullPayments();
    } catch (e: any) {
      defaultErrorHandle(e);
    }
  }, [API, defaultErrorHandle, potentialSubscriptions, pullPayments]);

  const applyPotentialSubscription = useCallback(
    async (potentialSubscription: PaymentSubscription): Promise<void> => {
      try {
        const res = await API.post(`payments/paironewithsubscription`, { potentialSubscription });
        console.log(res);
        pullPayments();
      } catch (e: any) {
        defaultErrorHandle(e);
      }
    },
    [API, defaultErrorHandle, pullPayments]
  );

  const deleteSubscription = useCallback(
    async (subscription: PaymentSubscription): Promise<void> => {
      try {
        const res = await API.post(`payments/unpairOneWithSubscription`, { subscription });
        console.log(res);
        pullPayments();
      } catch (e: any) {
        defaultErrorHandle(e);
      }
    },
    [API, defaultErrorHandle, pullPayments]
  );

  const deleteAllSubscriptions = useCallback(async (): Promise<void> => {
    try {
      const res = await API.post(`payments/unpairmanywithsubscriptions`, { subscriptions });
      console.log(res);
      pullPayments();
    } catch (e: any) {
      defaultErrorHandle(e);
    }
  }, [API, defaultErrorHandle, pullPayments, subscriptions]);

  const deleteFile = useCallback(async (): Promise<void> => {
    try {
      const res = await API.get(`payment/source/${id}`);
    } catch (e: any) {
      defaultErrorHandle(e);
    }
  }, [API, defaultErrorHandle, id]);

  const splitPayment = useCallback(
    async (paymentId: string, parts: Partial<Payment>[]) => {
      try {
        const res = await API.post(`/payments/${paymentId}/split`, parts);
        console.log(res);
        pullPayments();
      } catch (e: any) {
        defaultErrorHandle(e);
      }
    },
    [API, defaultErrorHandle, pullPayments]
  );

  const getPayment = useCallback(
    async (paymentId: string) => {
      try {
        const res = await API.get(`/payments/${paymentId}/`);
        console.log(res);
        return res.data;
      } catch (e: any) {
        defaultErrorHandle(e);
        return undefined;
      }
    },
    [API, defaultErrorHandle]
  );

  const contextObjects = useMemo(
    () => ({
      sourceFileData,
      potentialMatches,
      matches,
      potentialSubscriptions,
      subscriptions,
      payments,
      mismatchedSumClaims,
      pullData,
      applyAllPotentials,
      applyPotential,
      deleteMatch,
      deleteAllMatches,
      applyAllPotentialSubscriptions,
      applyPotentialSubscription,
      deleteSubscription,
      deleteAllSubscriptions,
      deleteFile,
      splitPayment,
      getPayment,
    }),
    [
      sourceFileData,
      potentialMatches,
      matches,
      potentialSubscriptions,
      subscriptions,
      payments,
      mismatchedSumClaims,
      pullData,
      applyAllPotentials,
      applyPotential,
      deleteMatch,
      deleteAllMatches,
      applyAllPotentialSubscriptions,
      applyPotentialSubscription,
      deleteSubscription,
      deleteAllSubscriptions,
      deleteFile,
      splitPayment,
      getPayment,
    ]
  );

  return (
    <PaymentsSourceFileContext.Provider value={contextObjects}>
      {children}
    </PaymentsSourceFileContext.Provider>
  );
};
