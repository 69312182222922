import { FC, useEffect, useState } from 'react';
import { boolean, object, ref, string } from 'yup';

import { useFormik, Form, FormikProvider } from 'formik';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Switch,
  FormControlLabel,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useApi, User } from '../../ctx/Api';

interface IEditUserFrom {
  user: User;
  formName: string;
  type: 'me' | 'admin' | 'create';
  afterSuccessfulSubmit?: () => void;
}

export const EmptyUser: User = {
  _id: '',
  firstName: '',
  lastName: '',
  email: '',
  admin: false,
};

const EditUserForm: FC<IEditUserFrom> = (props) => {
  const { formName, type, user, afterSuccessfulSubmit } = props;

  const { editMe, createUser, editUser } = useApi();

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] = useState(false);

  const BasicSchema = object().shape({
    firstName: string().required('Používateľ musí mať meno.'),
    lastName: string().required('Používateľ musí mať priezvisko.'),
    email: string().required('Používateľ musí mať email.').email('Nesprávny formát emailu.'),
  });

  const AdminSchema = object().shape({
    admin: boolean().required(),
    password: string()
      .min(6, 'Heslo musí mať aspoň 6 znakov')
      .oneOf([ref('passwordConfirmation'), null], 'Heslá sa musia zhodovať'),
    passwordConfirmation: string().oneOf([ref('password'), null], 'Heslá sa musia zhodovať'),
  });

  const CreateSchema = object().shape({
    admin: boolean().required(),
    password: string()
      .required('Je nutné zvoliť heslo')
      .min(6, 'Heslo musí mať aspoň 6 znakov')
      .oneOf([ref('passwordConfirmation'), null], 'Heslá sa musia zhodovať'),
    passwordConfirmation: string().oneOf([ref('password'), null], 'Heslá sa musia zhodovať'),
  });

  const validationSchema = () => {
    if (type === 'me') return BasicSchema;
    if (type === 'create') return BasicSchema.concat(CreateSchema);
    if (type === 'admin') return BasicSchema.concat(AdminSchema);
  };

  const formik = useFormik({
    initialValues: {
      ...(type === 'me' && { ...user }),
      ...(type === 'create' && { ...user, password: '', passwordConfirmation: '' }),
      ...(type === 'admin' && { ...user, password: '', passwordConfirmation: '' }),
    },
    enableReinitialize: true,
    validationSchema: validationSchema(),
    onSubmit: async (values: any) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { _id, ...valuesWithoutId } = values;
      console.log(values);
      // return;
      let success: boolean = false;
      if (type === 'me') success = await editMe(values);
      if (type === 'create') success = await createUser(valuesWithoutId);
      if (type === 'admin') success = await editUser(values);

      if (success) {
        resetForm();

        if (afterSuccessfulSubmit) afterSuccessfulSubmit();
      }
    },
  });
  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setSubmitting,
    resetForm,
    dirty,
    isValid,
  } = formik;

  const handleShowNewPassword = () => {
    setShowNewPassword((show) => !show);
  };
  const handleShowNewPasswordConfirmation = () => {
    setShowNewPasswordConfirmation((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ m: 3 }} direction="column">
          <Typography variant="h6">{formName}</Typography>

          <Box>
            <Grid container spacing={3}>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Meno"
                  {...getFieldProps('firstName')}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  type="text"
                  label="Priezvisko"
                  {...getFieldProps('lastName')}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>
            </Grid>
          </Box>

          <TextField
            fullWidth
            autoComplete="off"
            type="email"
            label="Emailová adresa"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          {['admin', 'create'].includes(type) && (
            <>
              <Divider />
              <TextField
                fullWidth
                autoComplete="none"
                type={showNewPassword ? 'text' : 'password'}
                label="Nové heslo"
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowNewPassword} edge="end">
                        {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
              <TextField
                fullWidth
                autoComplete="none"
                type={showNewPasswordConfirmation ? 'text' : 'password'}
                label="Potvrďte nové heslo"
                {...getFieldProps('passwordConfirmation')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowNewPasswordConfirmation} edge="end">
                        {showNewPasswordConfirmation ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
                helperText={touched.passwordConfirmation && errors.passwordConfirmation}
              />
              <FormControlLabel
                control={
                  <Switch
                    // {...getFieldProps('admin')}
                    checked={getFieldProps('admin').value}
                    onChange={getFieldProps('admin').onChange}
                    name={getFieldProps('admin').name}
                  />
                }
                label="Admin"
              />
            </>
          )}
          <Stack spacing={1} direction="row-reverse">
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={!dirty || !isValid}
            >
              Potvrdiť
            </Button>
            <Button variant="outlined" color="primary" type="reset" disabled={!dirty}>
              Resetovať
            </Button>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default EditUserForm;
