import { FC, Fragment, ReactElement } from 'react';
import { Avatar, Card, CardActions, CardHeader, Tooltip } from '@mui/material';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import { User } from '../ctx/Api';

import { stringAvatar } from '../utils/stringAvatar';

export interface IUserCard {
  user: User | undefined;
  actions?: Array<ReactElement>;
}

const UserCard: FC<IUserCard> = (props) => {
  const { user, actions } = props;

  return (
    <Card sx={{ width: '100%' }}>
      <CardHeader
        avatar={<Avatar {...stringAvatar(`${user?.firstName} ${user?.lastName}`)} />}
        title={`${user?.firstName} ${user?.lastName}`}
        action={
          user?.admin && (
            <Tooltip title="Admin" arrow>
              <LocalPoliceIcon />
            </Tooltip>
          )
        }
        subheader={`${user?.email}`}
      />
      <CardActions>
        {actions?.map((a, i) => (
          <Fragment key={`${user?._id}-action-${i}`}>{a}</Fragment>
        ))}
      </CardActions>
    </Card>
  );
};

export default UserCard;
