import { OpenInNew } from '@mui/icons-material';
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Tooltip,
  Avatar,
  Link,
  Typography,
  Box,
} from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { isCompany } from '../../ctx/contacts/Contact.Context';
import { ContactAvatar, stringAvatar } from '../../utils/stringAvatar';
import { OutlinedCard } from '../OutlinedCard';
import ContactBasicInfoCard from './ContactBasicInfoCard';
import { CompleteContact } from './IndividualBusinessCard';

interface IContactFullInfoCard {
  contactInfo: CompleteContact;
  outlined?: boolean;
  onRedirect?: () => void;
  noLinks?: boolean;
}

const ContactFullInfoCard: FC<IContactFullInfoCard> = ({
  contactInfo,
  outlined = false,
  onRedirect,
  noLinks,
}) => {
  const navigate = useNavigate();
  const cardBody = (
    <>
      <CardHeader
        avatar={<ContactAvatar contact={contactInfo} />}
        title={
          noLinks ? (
            <Typography variant="body1">{contactInfo.fullName} </Typography>
          ) : (
            <Link
              onClick={() => {
                navigate(`/contacts/${contactInfo._id}`);
                if (onRedirect) onRedirect();
              }}
              color="inherit"
            >
              <Typography variant="body1">{contactInfo.fullName} </Typography>
            </Link>
          )
        }
        action={
          noLinks ? (
            <Box />
          ) : (
            <Tooltip title="Otvoriť kontakt na novej karte." sx={{ zIndex: 1001 }}>
              <IconButton onClick={() => window.open(`/contacts/${contactInfo._id}`)}>
                <OpenInNew />
              </IconButton>
            </Tooltip>
          )
        }
      />
      <CardContent>
        <ContactBasicInfoCard basicContactInfoData={contactInfo} />
      </CardContent>
    </>
  );

  // eslint-disable-next-line no-nested-ternary
  return contactInfo ? (
    outlined ? (
      <OutlinedCard>{cardBody}</OutlinedCard>
    ) : (
      <Card>{cardBody}</Card>
    )
  ) : null;
};

export default ContactFullInfoCard;
