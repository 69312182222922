import { Card, CardContent, IconButton } from '@mui/material';
import { cloneElement, FC, ReactElement } from 'react';
import Popper from '@mui/material/Popper';
import { usePopupState, bindFocus, bindPopper, PopupState } from 'material-ui-popup-state/hooks';
import Fade from '@mui/material/Fade';
import { Cancel } from '@mui/icons-material';

interface IStockItemsPoper {
  rootElement: ReactElement;
  propPopupState?: PopupState;
  onClose?: () => void;
}

const StockItemsPoper: FC<IStockItemsPoper> = ({
  rootElement,
  children,
  propPopupState,
  onClose,
}) => {
  const popupState = usePopupState({ variant: 'popper', popupId: 'demoPopper' });
  return (
    <>
      {cloneElement(rootElement, { ...bindFocus(propPopupState || popupState) })}
      <Popper {...bindPopper(propPopupState || popupState)} transition style={{ zIndex: 10000 }}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Card
              sx={{
                backgroundColor: 'rgba(255,255,255,0.8)',
                backdropFilter: 'blur(5px)',
                zIndex: 100,
                position: 'relative',
                // maxWidth: 300,
              }}
            >
              {onClose && (
                <IconButton onClick={onClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
                  <Cancel />
                </IconButton>
              )}
              <CardContent>{children}</CardContent>
            </Card>
          </Fade>
        )}
      </Popper>
    </>
  );
};
export default StockItemsPoper;
