import { FC } from 'react';
import { boolean, number, object, string } from 'yup';

import { useFormik, Form, FormikProvider } from 'formik';
import { Button, FormControlLabel, Grid, Stack, Switch } from '@mui/material';

import { MyFormikTextField } from '../../MyFormikTextField';
import { SubmitListener } from '../../../utils/SubmitListener';
import { SubscriptionType } from '../../../ctx/subscriptions/Subscriptions.Context';

interface ISubscriptionTypeForm {
  onConfirm(d: SubscriptionType): void;
  initialValues?: SubscriptionType;
  requiredFields?: Array<keyof SubscriptionType>;
  omitFields?: Array<keyof SubscriptionType>;
  autoSubmit?: boolean;
}

const SubscriptionTypeForm: FC<ISubscriptionTypeForm> = ({
  onConfirm,
  initialValues,
  requiredFields,
  omitFields,
  autoSubmit = true,
}) => {
  const ValidationSchema = object().shape({
    prefix: number().when('x', {
      is: () => requiredFields?.includes('prefix') || false,
      then: number().required(),
    }),
    caption: string().when('x', {
      is: () => requiredFields?.includes('caption') || false,
      then: string().required(),
    }),
    dayInMonth: number()
      .max(28)
      .when('x', {
        is: () => requiredFields?.includes('dayInMonth') || false,
        then: number().required(),
      }),
    price: number()
      .min(0)
      .when('x', {
        is: () => requiredFields?.includes('price') || false,
        then: number().required(),
      }),
  });

  const formik = useFormik<SubscriptionType>({
    initialValues: initialValues || {
      prefix: '',
      caption: '',
      dayInMonth: 15,
      price: 0,
    },
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      console.log(values);
      onConfirm(values);
    },
  });
  const { errors, touched, values, handleSubmit, getFieldProps, isValid, dirty, setFieldValue } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {autoSubmit && <SubmitListener />}
        <Grid container spacing={1}>
          {!omitFields?.includes('caption') && (
            <Grid item xs={8}>
              <MyFormikTextField
                label="Názov"
                fieldName="caption"
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}
          {!omitFields?.includes('prefix') && (
            <Grid item xs={4}>
              <MyFormikTextField
                label="Prefix"
                fieldName="prefix"
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}
          {!omitFields?.includes('price') && (
            <Grid item xs={6}>
              <MyFormikTextField
                label="Cena"
                fieldName="price"
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}

          {!omitFields?.includes('dayInMonth') && (
            <Grid item xs={6}>
              <MyFormikTextField
                label="Ďen úhrady"
                fieldName="dayInMonth"
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}

          {!autoSubmit && (
            <Grid item xs={12}>
              <Stack direction="row-reverse" spacing={1}>
                <Stack spacing={1} direction="row-reverse">
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={!dirty || !isValid}
                  >
                    Potvrdiť
                  </Button>
                  <Button variant="outlined" color="primary" type="reset" disabled={!dirty}>
                    Resetovať
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default SubscriptionTypeForm;
