import { Button, Grid, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';
import { useApi, User } from '../../ctx/Api';
import UserCard from '../UserCard';
import RightDrawer from '../RightDrawer';
import EditUserForm, { EmptyUser } from './EditUserForm';

export const UserAdministration: FC = () => {
  const { getUsers } = useApi();

  const [users, setUsers] = useState<Array<User>>([]);
  const [addingUser, setAddingUser] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editedUser, setEditedUser] = useState<User>(EmptyUser);

  // const handleStartEditing = (u: User) => () => { setEditedUser(u); setIsEditing(true); }

  // useEffect(() => {
  //     console.log("NASRAT")
  //     if (!isEditing) return;
  //     const newUserData = users.find((u) => u._id === editedUser._id);
  //     if (newUserData) setEditedUser(newUserData);
  // }, [editedUser, users, isEditing]);

  const handleStopEditing = () => setIsEditing(false);

  const handleStartAddingUser = () => setAddingUser(true);
  const handleStopAddingUser = () => setAddingUser(false);

  const pullUsers = useCallback(() => {
    getUsers().then((users) => {
      setUsers(users);
    });
  }, [getUsers]);

  useEffect(pullUsers, [pullUsers]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} container justifyContent="space-between">
        <Grid item>
          <Typography variant="h4">Používatelia</Typography>
        </Grid>
        <Grid item>
          <Button variant="outlined" startIcon={<PersonAddIcon />} onClick={handleStartAddingUser}>
            Pridať
          </Button>
          <RightDrawer isOpened={addingUser} handleClose={handleStopAddingUser}>
            <EditUserForm
              formName="Pridať používateľa"
              type="create"
              user={EmptyUser}
              afterSuccessfulSubmit={pullUsers}
            />
          </RightDrawer>
        </Grid>
      </Grid>
      {users.map((u) => (
        <Grid item xs={12} key={`${u._id}-user-card`}>
          <UserCard
            user={u}
            actions={[
              <Button
                variant="outlined"
                startIcon={<EditIcon />}
                onClick={() => {
                  setEditedUser(u);
                  setIsEditing(true);
                }}
              >
                Upraviť
              </Button>,
            ]}
          />
        </Grid>
      ))}
      <RightDrawer isOpened={isEditing} handleClose={handleStopEditing}>
        <EditUserForm
          type="admin"
          formName="Upraviť údaje používateľa"
          user={editedUser}
          afterSuccessfulSubmit={() => {
            handleStopEditing();
            pullUsers();
          }}
        />
      </RightDrawer>
    </Grid>
  );
};

const AdminPanel: FC = () => {
  return (
    <Grid container spacing={5}>
      <Grid item md={6} xs={12}>
        <UserAdministration />
      </Grid>

      <Grid item md={6} xs={12} container spacing={3} />
    </Grid>
  );
};

export default AdminPanel;
