import { Button } from '@mui/material';
import { FC } from 'react';

interface IAcceptRejectButtonPair {
  onConfirm: () => Promise<void> | void;
  onReject?: () => Promise<void> | void;
}

const AcceptRejectButtonPair: FC<IAcceptRejectButtonPair> = ({ onConfirm, onReject }) => {
  return (
    <>
      <Button variant="contained" color="secondary" onClick={onConfirm}>
        Potvrdiť
      </Button>
      {onReject && (
        <Button variant="outlined" onClick={onReject}>
          Zrušiť
        </Button>
      )}
    </>
  );
};

export default AcceptRejectButtonPair;
