import { FC, useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Input,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { CSVLink } from 'react-csv';
import { Add, Download, UploadFile } from '@mui/icons-material';
import { IDataSource, useLyduska } from './Lyduska.Context';
import { readFile } from '../../../utils/fileProcessor';

const CSVDownload: FC<any> = ({ data, headers, ...props }) => {
  const csvLink: any = useRef();
  const constructHeaderDataLine = () => {
    const res = {};
    headers.forEach((h) => {
      // eslint-disable-next-line prefer-destructuring
      res[h] = h.split('- ')[1];
    });
    return res;
  };

  const handleCSVDownload = async () => {
    if (csvLink && csvLink.current && csvLink.current.link) {
      csvLink.current.link.click();
    }
  };
  return (
    <>
      <Button variant="outlined" startIcon={<Download />} onClick={handleCSVDownload} {...props}>
        Stiahnuť výsledok
      </Button>
      <CSVLink
        // headers={headers.map((h) => ({ label: h.split(' ')[0] || h, key: h }))}
        // data={data}
        // data={[headers.map((hd) => ({})), ...data]}
        data={data}
        filename="Vysledok.csv"
        style={{ display: 'none' }}
        ref={csvLink}
        target="_blank"
      />
    </>
  );
};

const Lyduska: FC = () => {
  const { addFile, files, toDownload, headers } = useLyduska();

  const [fileToAdd, setFileToAdd] = useState<IDataSource>();

  const valid = fileToAdd && fileToAdd.featureName && fileToAdd.fileName && fileToAdd.fileText;

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="h1" color="primary" sx={{ fontFamily: 'monospace' }}>
            Lyduškin parser
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Nahrať súbory" />
            <CardContent>
              <Grid container spacing={3} alignItems="flex-end">
                <Grid item>
                  <InputLabel htmlFor="upload-contacts-file">
                    <Input
                      style={{ display: 'none' }}
                      id="upload-contacts-file"
                      name="upload-contacts-file"
                      type="file"
                      onChange={(e) => {
                        readFile(e, (i) => {
                          setFileToAdd((p) => ({ ...p, fileText: i.text, fileName: i.name }));
                        });
                      }}
                    />

                    <Button
                      startIcon={<UploadFile />}
                      color="secondary"
                      variant="contained"
                      component="span"
                    >
                      Zvoliť súbor
                    </Button>
                  </InputLabel>
                </Grid>
                <Grid item>
                  <Typography sx={{ fontFamily: 'monospace' }}>{fileToAdd?.fileName}</Typography>
                </Grid>
                <Grid item sx={{ ml: 'auto' }}>
                  <TextField
                    size="small"
                    value={fileToAdd?.featureName || ''}
                    onChange={(e) => setFileToAdd((p) => ({ ...p, featureName: e.target.value }))}
                    label="Meno príznaku"
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={!valid}
                    startIcon={<Add />}
                    onClick={() => {
                      addFile(fileToAdd);
                      setFileToAdd(undefined);
                    }}
                  >
                    Pridať
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} spacing={3} container>
                  <Grid item>
                    <Typography variant="h5">Súbory</Typography>
                  </Grid>
                  <Grid item sx={{ ml: 'auto' }}>
                    <CSVDownload
                      variant="contained"
                      color="secondary"
                      disabled={!files?.length}
                      startIcon={<Download />}
                      data={toDownload}
                      headers={headers}
                    />
                  </Grid>
                </Grid>
                {files?.map((f, i) => (
                  <Grid item xs={12} key={`${f.fileName}${i}`}>
                    {f.fileName} {f.featureName}
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Lyduska;
