import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { Close } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ClaimItemsTable } from './ClaimItemsTable';
import AddClaimItemForm from './AddClaimItemForm';
import { useNewOrder } from '../../ctx/order/NewOrder.Context';
import ContactTypeSelector from '../contacts/ContactTypeSelector';
import { ContactTypes } from '../../ctx/contacts/NewContact.Context';
import CompanyInfoForm from '../contacts/forms/CompanyInfoForm';
import IndividualInfoForm from '../contacts/forms/IndividualInfo';
import BasicContactInfoForm from '../contacts/forms/BasicContactInfoForm';
import FuzzyContactSearcher from '../contact/FuzzyContactSearcher';
import { ContactsProvider } from '../../ctx/contacts/Contacts.Context';
import ContactChip from '../contact/ContactChip';
import {
  CompleteCompany,
  CompleteIndividual,
  isCompany,
  isIndividual,
} from '../../ctx/contacts/Contact.Context';
import { StockItemsProvider } from '../../ctx/stockItems/StockItems.Context';

const AddClaimItems: FC = () => {
  const { items, rawTotalString, totalString } = useNewOrder();
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} container>
          <Grid item>
            <Typography variant="h6" color="primary">
              Položky
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={5} container>
          <AddClaimItemForm skipDiscount order />
        </Grid>
        <Grid item xs={7} container spacing={2}>
          <Grid item xs={12}>
            <ClaimItemsTable items={items.map((ci, i) => ({ ...ci, id: `nci-${i}`, idx: i }))} />
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs />
            <Grid item sx={{ textAlign: 'right' }}>
              <Typography>Výsledná cena: {totalString}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

interface INewOrder {
  handleStopAdding: () => void;
  onNewOrderSuccess?: () => void;
}

const NewOrder: FC<INewOrder> = ({ handleStopAdding, onNewOrderSuccess }) => {
  const {
    isValid,
    submitNewOrder,
    accountingYear,
    totalString,
    claimantType,
    payerType,
    setClaimantCompanyContact,
    setClaimantContact,
    setPayerCompanyContact,
    setPayerContact,
    setPayerType,
    setClaimantType,
    setPayerBasicContactInfo,
    setClaimantBasicContactInfo,
    claimantDatabaseCompanyContact,
    setClaimantDatabaseCompanyContact,
    setClaimantDatabaseIndividualContact,
    setPayerDatabaseCompanyContact,
    setPayerDatabaseIndividualContact,
    payerDatabaseCompanyContact,
    payerDatabaseIndividualContact,
    claimantDatabaseIndividualContact,
    issued,
    setIssued,
    claimantCompanyContact,
    claimantContact,
    claimantBasicContactInfo,
    payerCompanyContact,
    payerContact,
    payerBasicContactInfo,
  } = useNewOrder();

  const handleSubmitNewOrder = async () => {
    const ok = await submitNewOrder();
    if (ok && onNewOrderSuccess) onNewOrderSuccess();
    if (ok) handleStopAdding();
  };

  return (
    <Card>
      <CardContent>
        <Stack direction="column" spacing={2}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="h5" color="secondary">
                Nová objednávka
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="caption">Účtovný rok: {accountingYear?.prefix}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">Suma: {totalString}</Typography>
            </Grid>
            <Grid item xs />

            <Grid item>
              <IconButton onClick={handleStopAdding}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Divider />
          {/* DATES */}
          <Box>
            <Grid item xs={12} container spacing={3}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Dátum vystavenia"
                    value={issued || ''}
                    onChange={(value) => {
                      console.log(value);
                      const date = new Date(value!);
                      setIssued(date);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Box>

          <Divider />
          <Box>
            <Grid container spacing={3} justifyContent="center">
              <Grid item md={6} container spacing={2} alignContent="flex-start">
                <Grid item xs={12} container spacing={3} alignItems="center">
                  <Grid item>
                    <Typography variant="h6" color="primary">
                      Dodávateľ
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ContactTypeSelector value={claimantType} onSelect={setClaimantType} />
                  </Grid>
                  <Grid item xs>
                    <ContactsProvider>
                      <FuzzyContactSearcher
                        actions={[
                          {
                            icon: <CheckCircleIcon />,
                            shouldClosePoper: true,
                            onClick: (contactData) => {
                              if (isIndividual(contactData)) {
                                setClaimantDatabaseIndividualContact(
                                  contactData as CompleteIndividual
                                );
                                setClaimantContact({
                                  firstName: contactData.firstName,
                                  lastName: contactData.lastName,
                                  gender: contactData.gender,
                                });
                                setClaimantBasicContactInfo({
                                  email: contactData.email,
                                  phoneNumber: contactData.phoneNumber,
                                  iban: contactData.iban,
                                  swift: contactData.swift,
                                  bank: contactData.bank,
                                  address: {
                                    street: contactData.address.street,
                                    zip: contactData.address.zip,
                                    city: contactData.address.city,
                                    state: contactData.address.state,
                                  },
                                });
                                setClaimantType(ContactTypes.Individual);
                              }
                              if (isCompany(contactData)) {
                                setClaimantDatabaseCompanyContact(contactData as CompleteCompany);
                                setClaimantCompanyContact({
                                  companyName: contactData.companyName,
                                  ico: contactData.ico,
                                  dic: contactData.dic,
                                  icDph: contactData.icDph,
                                  dphPayer: contactData.dphPayer,
                                  contactPersonFirstName: contactData.contactPersonFirstName,
                                  contactPersonLastName: contactData.contactPersonLastName,
                                });
                                setClaimantBasicContactInfo({
                                  email: contactData.email,
                                  phoneNumber: contactData.phoneNumber,
                                  iban: contactData.iban,
                                  swift: contactData.swift,
                                  bank: contactData.bank,
                                  address: {
                                    street: contactData.address.street,
                                    zip: contactData.address.zip,
                                    city: contactData.address.city,
                                    state: contactData.address.state,
                                  },
                                });
                                setClaimantType(ContactTypes.Company);
                              }
                            },
                          },
                        ]}
                      />
                    </ContactsProvider>
                  </Grid>
                </Grid>
                <Grid item xs={12} />
                {claimantType === ContactTypes.Individual && claimantDatabaseIndividualContact && (
                  <>
                    <Grid item xs={12}>
                      <ContactChip
                        contact={claimantDatabaseIndividualContact}
                        handleDelete={() => setClaimantDatabaseIndividualContact(undefined)}
                      />
                    </Grid>
                    <Grid item xs={12} />
                  </>
                )}
                {claimantType === ContactTypes.Company && claimantDatabaseCompanyContact && (
                  <>
                    <Grid item xs={12}>
                      <ContactChip
                        contact={claimantDatabaseCompanyContact}
                        handleDelete={() => setClaimantDatabaseCompanyContact(undefined)}
                      />
                    </Grid>
                    <Grid item xs={12} />
                  </>
                )}
                <Grid item xs={12}>
                  {claimantType === ContactTypes.Company && (
                    <CompanyInfoForm
                      onConfirm={setClaimantCompanyContact}
                      initialValues={claimantDatabaseCompanyContact || claimantCompanyContact}
                    />
                  )}
                  {claimantType === ContactTypes.Individual && (
                    <IndividualInfoForm
                      initialValues={claimantDatabaseIndividualContact || claimantContact}
                      onConfirm={setClaimantContact}
                      omitFields={['gender']}
                    />
                  )}
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={12}>
                  <BasicContactInfoForm
                    initialValues={
                      (claimantType === ContactTypes.Individual &&
                        claimantDatabaseIndividualContact) ||
                      (claimantType === ContactTypes.Company && claimantDatabaseCompanyContact) ||
                      claimantBasicContactInfo
                    }
                    omitFields={['iban', 'bank', 'swift']}
                    onConfirm={setClaimantBasicContactInfo}
                  />
                </Grid>
              </Grid>

              <Grid item md={6} container spacing={2} alignContent="flex-start">
                <Grid item xs={12} container spacing={3} alignItems="center">
                  <Grid item>
                    <Typography variant="h6" color="primary">
                      Platca
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ContactTypeSelector value={payerType} onSelect={setPayerType} />
                  </Grid>
                  <Grid item xs>
                    <ContactsProvider>
                      <FuzzyContactSearcher
                        actions={[
                          {
                            icon: <CheckCircleIcon />,
                            shouldClosePoper: true,
                            onClick: (contactData) => {
                              if (isIndividual(contactData)) {
                                setPayerDatabaseIndividualContact(
                                  contactData as CompleteIndividual
                                );
                                setPayerContact({
                                  firstName: contactData.firstName,
                                  lastName: contactData.lastName,
                                  gender: contactData.gender,
                                });
                                setPayerBasicContactInfo({
                                  email: contactData.email,
                                  phoneNumber: contactData.phoneNumber,
                                  iban: contactData.iban,
                                  swift: contactData.swift,
                                  bank: contactData.bank,
                                  address: {
                                    street: contactData.address.street,
                                    zip: contactData.address.zip,
                                    city: contactData.address.city,
                                    state: contactData.address.state,
                                  },
                                });
                                setPayerType(ContactTypes.Individual);
                              }
                              if (isCompany(contactData)) {
                                setPayerDatabaseCompanyContact(contactData as CompleteCompany);
                                setPayerCompanyContact({
                                  companyName: contactData.companyName,
                                  ico: contactData.ico,
                                  dic: contactData.dic,
                                  icDph: contactData.icDph,
                                  dphPayer: contactData.dphPayer,
                                  contactPersonFirstName: contactData.contactPersonFirstName,
                                  contactPersonLastName: contactData.contactPersonLastName,
                                });
                                setPayerBasicContactInfo({
                                  email: contactData.email,
                                  phoneNumber: contactData.phoneNumber,
                                  iban: contactData.iban,
                                  swift: contactData.swift,
                                  bank: contactData.bank,
                                  address: {
                                    street: contactData.address.street,
                                    zip: contactData.address.zip,
                                    city: contactData.address.city,
                                    state: contactData.address.state,
                                  },
                                });
                                setPayerType(ContactTypes.Company);
                              }
                            },
                          },
                        ]}
                      />
                    </ContactsProvider>
                  </Grid>
                </Grid>
                <Grid item xs={12} />
                {payerType === ContactTypes.Individual && payerDatabaseIndividualContact && (
                  <>
                    <Grid item xs={12}>
                      <ContactChip
                        contact={payerDatabaseIndividualContact}
                        handleDelete={() => setPayerDatabaseIndividualContact(undefined)}
                      />
                    </Grid>
                    <Grid item xs={12} />
                  </>
                )}
                {payerType === ContactTypes.Company && payerDatabaseCompanyContact && (
                  <>
                    <Grid item xs={12}>
                      <ContactChip
                        contact={payerDatabaseCompanyContact}
                        handleDelete={() => setPayerDatabaseCompanyContact(undefined)}
                      />
                    </Grid>
                    <Grid item xs={12} />
                  </>
                )}
                <Grid item xs={12}>
                  {payerType === ContactTypes.Company && (
                    <CompanyInfoForm
                      onConfirm={setPayerCompanyContact}
                      initialValues={payerDatabaseCompanyContact || payerCompanyContact}
                    />
                  )}
                  {payerType === ContactTypes.Individual && (
                    <IndividualInfoForm
                      onConfirm={setPayerContact}
                      omitFields={['gender']}
                      initialValues={payerDatabaseIndividualContact || payerContact}
                    />
                  )}
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={12}>
                  <BasicContactInfoForm
                    onConfirm={setPayerBasicContactInfo}
                    omitFields={['iban', 'bank', 'swift']}
                    initialValues={
                      (payerType === ContactTypes.Individual
                        ? payerDatabaseIndividualContact
                        : payerDatabaseCompanyContact) || payerBasicContactInfo
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <StockItemsProvider>
            <AddClaimItems />
          </StockItemsProvider>
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          color="secondary"
          variant="contained"
          startIcon={<SaveIcon />}
          disabled={!isValid}
          onClick={handleSubmitNewOrder}
        >
          Uložiť
        </Button>
        <Button
          onClick={handleStopAdding}
          variant="outlined"
          startIcon={<CancelIcon />}
          sx={{ mr: 1 }}
        >
          Zrušiť
        </Button>
      </CardActions>
    </Card>
  );
};

export default NewOrder;
