import { DataGrid, DataGridProps, GridSelectionModel } from '@mui/x-data-grid';
import _ from 'lodash';
import { FC, useState, useEffect } from 'react';

export interface IDataGridWithSelection {
  controls?: { setFce: (ids: string[]) => void; selectionModel: string[] };
}

export const DataGridWithSelection: FC<IDataGridWithSelection & DataGridProps> = ({
  controls,
  ...props
}) => {
  const [sortModel, setSortModel] = useState(controls ? [...controls.selectionModel] : []);

  useEffect(() => {
    if (!controls) return;
    setSortModel([...controls.selectionModel]);
  }, [controls]);

  const handleSelectionModelChange = (newSelectionModel: GridSelectionModel) => {
    controls?.setFce(newSelectionModel as string[]);
  };
  return (
    <DataGrid
      autoHeight
      {...props}
      columns={[
        ...props.columns,
        {
          field: 'workaround',
          headerName: '',
          width: 0,
          maxWidth: 0,
          minWidth: 0,
        },
      ]}
      disableSelectionOnClick={!controls}
      checkboxSelection
      onSelectionModelChange={(model) => {
        if (!_.isEqual(model, sortModel) && props.rows.length > 0) {
          handleSelectionModelChange(model as string[]);
        }
      }}
      selectionModel={sortModel}
      sx={{
        '& .MuiDataGrid-row': {
          ...(!controls
            ? {
                '& .MuiDataGrid-cell:nth-last-of-type': {
                  width: '100%!important',
                  maxWidth: '100%!important',
                  minWidth: '100%!important',
                },
              }
            : {
                '& .MuiDataGrid-cell:last-of-type': {
                  width: '100%!important',
                  maxWidth: '100%!important',
                  minWidth: '100%!important',
                },
              }),
        },

        '& .MuiDataGrid-columnHeaders': {
          ...(!controls
            ? {
                '& .MuiDataGrid-columnHeader:nth-last-of-type(2)': {
                  width: '100%!important',
                  maxWidth: '100%!important',
                  minWidth: '100%!important',
                },
              }
            : {
                '& .MuiDataGrid-columnHeader:last-of-type': {
                  width: '100%!important',
                  maxWidth: '100%!important',
                  minWidth: '100%!important',
                },
              }),
        },
        '& .Mui-selected': {
          ...(!controls && { backgroundColor: 'unset!important' }),
        },

        '& .MuiDataGrid-columnHeader': { transition: 'all 1s' },
        '& .MuiDataGrid-cell': { transition: 'all 1s' },

        '& .MuiDataGrid-cellCheckbox': {
          ...(!controls && { minWidth: '0!important' }),
          transition: 'all 1s',
          width: '0!important',
        },
        '& .MuiDataGrid-columnHeaderCheckbox': {
          ...(!controls && { minWidth: '0!important' }),
          transition: 'all 1s',
          width: '0!important',
        },
      }}
    />
  );
};
