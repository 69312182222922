import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { Close, Error, Group, RemoveCircle } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { ClaimItemsTable } from './ClaimItemsTable';
import AddClaimItemForm from './AddClaimItemForm';
import { isCompleteAddress, useNewClaim } from '../../ctx/NewClaim.Context';
import ContactTypeSelector from '../contacts/ContactTypeSelector';
import { ContactTypes } from '../../ctx/contacts/NewContact.Context';
import CompanyInfoForm from '../contacts/forms/CompanyInfoForm';
import IndividualInfoForm from '../contacts/forms/IndividualInfo';
import BasicContactInfoForm from '../contacts/forms/BasicContactInfoForm';
import FuzzyContactSearcher from '../contact/FuzzyContactSearcher';
import { ContactsProvider } from '../../ctx/contacts/Contacts.Context';
import ContactChip from '../contact/ContactChip';
import {
  CompleteCompany,
  CompleteIndividual,
  isCompany,
  isIndividual,
} from '../../ctx/contacts/Contact.Context';
import { PaymentForm } from '../../ctx/Claims.Context';
import ContactIconButtonAction from '../contact/actions/ContactIconButtonAction';
import { ContactsTable } from '../contacts/ContactsTable';
import RightDrawerAddContactSelector from '../RightDrawerAddContactSelector';
import { indexArray } from '../../ctx/contacts/ImportContacts.Context';
import { StockItemsProvider, useStockItems } from '../../ctx/stockItems/StockItems.Context';
import { ClaimGroupSelector } from './ClaimGroupSelector';

const AddClaimItems: FC = () => {
  const { items, rawTotalString, totalString, discount, setDiscount } = useNewClaim();
  const { onSearch, hits, query, onClearQuery } = useStockItems();
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} container>
          <Grid item>
            <Typography variant="h6" color="primary">
              Položky
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={5} container>
          <AddClaimItemForm />
        </Grid>
        <Grid item xs={7} container spacing={2}>
          <Grid item xs={12}>
            <ClaimItemsTable items={items.map((ci, i) => ({ ...ci, id: `nci-${i}`, idx: i }))} />
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item>
              <TextField
                type="number"
                label="Celková zľava"
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                value={discount}
                onChange={(e) => {
                  e.stopPropagation();
                  if (!e.target.value) setDiscount(0);
                  else setDiscount(Number.parseInt(e.target.value, 10));
                }}
              />
            </Grid>
            <Grid item xs />
            <Grid item sx={{ textAlign: 'right' }}>
              <Typography>Pred zľavou: {rawTotalString}</Typography>
              <Typography>Výsledná cena: {totalString}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

interface INewClaim {
  handleStopAdding: () => void;
}

const NewClaim: FC<INewClaim> = ({ handleStopAdding }) => {
  const {
    isValid,
    submitNewClaim,
    accountingYear,
    totalString,
    claimantType,
    payerType,
    setClaimantCompanyContact,
    setClaimantContact,
    setPayerCompanyContact,
    setPayerContact,
    setPayerType,
    setClaimantType,
    setPayerBasicContactInfo,
    setClaimantBasicContactInfo,
    claimantDatabaseCompanyContact,
    setClaimantDatabaseCompanyContact,
    setClaimantDatabaseIndividualContact,
    setIsMultiPayers,
    setPayerDatabaseCompanyContact,
    setPayerDatabaseIndividualContact,
    payerDatabaseCompanyContact,
    payerDatabaseIndividualContact,
    claimantDatabaseIndividualContact,
    multiPayers,
    setMultiPayers,
    isMultiPayers,
    addMultiPayer,
    removeMultiPayer,
    hardNumber,
    setHardNumber,
    hardDate,
    setHardDate,
    claimGroupValue,
    setClaimGroupValue,
    claimGroups,
    selectedGroup,
    setSelectedGroup,
    newGroupName,
    setNewGroupName,
    issued,
    setIssued,
    deliveryDate,
    setDeliveryDate,
    maturityDate,
    setMaturityDate,
    orderNumber,
    setOrderNumber,
    paymentForm,
    setPaymentForm,
    claimantCompanyContact,
    claimantContact,
    claimantBasicContactInfo,
    payerCompanyContact,
    payerContact,
    payerBasicContactInfo,
    en,
    setEn,
  } = useNewClaim();

  const handleSubmitNewClaim = async () => {
    const ok = await submitNewClaim();
    console.log(ok);
    if (ok) handleStopAdding();
  };
  const [addingPeopleToClaim, setAddingPeopleToClaim] = useState<boolean>(false);
  const handleStartAddingPeopleToClaim = () => setAddingPeopleToClaim(true);
  const handleStopAddingPeopleToClaim = () => setAddingPeopleToClaim(false);

  const [hardValues, setHardValues] = useState<boolean>(false);

  return (
    <>
      <Card>
        <CardContent>
          <Stack direction="column" spacing={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography variant="h5" color="secondary">
                  Nová pohľadávka
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="caption">Účtovný rok: {accountingYear?.prefix}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">Suma: {totalString}</Typography>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isMultiPayers}
                      onChange={(e) => setIsMultiPayers(e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label="Hromadná pohľadávka"
                />
              </Grid>
              <Grid item xs />
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={en}
                      onChange={(e) => setEn(e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label="EN"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={hardValues}
                      onChange={(e) => setHardValues(e.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label="Hodnoty na tvrdo"
                />
              </Grid>
              <Grid item>
                <IconButton onClick={handleStopAdding}>
                  <Close />
                </IconButton>
              </Grid>
              {hardValues && (
                <Grid item xs={12} container spacing={3}>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <TextField
                      type="number"
                      label="Číslo faktúry"
                      value={hardNumber || ''}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setHardNumber(Number(e.target.value));
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Dátum vystavenia"
                        value={hardDate || ''}
                        onChange={(value) => {
                          console.log(value);
                          const date = new Date(value!);
                          setHardDate(date);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {/* CLAIM GROUPS */}
            <Divider />
            <Box>
              <ClaimGroupSelector
                value={claimGroupValue}
                groups={claimGroups}
                handleSelectGroup={setSelectedGroup}
                newGroupName={newGroupName}
                handleChangeNewGroupName={setNewGroupName}
                selectedGroup={selectedGroup}
                handleValueChange={setClaimGroupValue}
              />
            </Box>
            {/* DATES */}
            <Box>
              <Grid item xs={12} container spacing={3}>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Dátum vystavenia"
                      value={issued || ''}
                      onChange={(value) => {
                        console.log(value);
                        const date = new Date(value!);
                        setIssued(date);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Dátum dodania"
                      value={deliveryDate || ''}
                      onChange={(value) => {
                        console.log(value);
                        const date = new Date(value!);
                        setDeliveryDate(date);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Dátum splatnosti"
                      value={maturityDate || ''}
                      onChange={(value) => {
                        console.log(value);
                        const date = new Date(value!);
                        setMaturityDate(date);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item>
                  <TextField
                    label="Ćíslo objednávky"
                    type="number"
                    value={orderNumber}
                    onChange={(e) => setOrderNumber(Number(e.target.value))}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Forma úhrady</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      value={paymentForm}
                      label="Forma úhrady"
                      onChange={(e) => setPaymentForm(e.target.value as any)}
                    >
                      <MenuItem value={PaymentForm.Account}>{PaymentForm.Account}</MenuItem>
                      <MenuItem value={PaymentForm.Cash}>{PaymentForm.Cash}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            <Divider />
            <Box>
              <Grid container spacing={3} justifyContent="center">
                <Grid item md={6} container spacing={2} alignContent="flex-start">
                  <Grid item xs={12} container spacing={3} alignItems="center">
                    <Grid item>
                      <Typography variant="h6" color="primary">
                        Dodávateľ
                      </Typography>
                    </Grid>
                    <Grid item>
                      <ContactTypeSelector value={claimantType} onSelect={setClaimantType} />
                    </Grid>
                    <Grid item xs>
                      <ContactsProvider>
                        <FuzzyContactSearcher
                          actions={[
                            {
                              icon: <CheckCircleIcon />,
                              shouldClosePoper: true,
                              onClick: (contactData) => {
                                if (isIndividual(contactData)) {
                                  setClaimantDatabaseIndividualContact(
                                    contactData as CompleteIndividual
                                  );
                                  setClaimantContact({
                                    firstName: contactData.firstName,
                                    lastName: contactData.lastName,
                                    gender: contactData.gender,
                                  });
                                  setClaimantBasicContactInfo({
                                    email: contactData.email,
                                    phoneNumber: contactData.phoneNumber,
                                    iban: contactData.iban,
                                    swift: contactData.swift,
                                    bank: contactData.bank,
                                    address: {
                                      street: contactData.address.street,
                                      zip: contactData.address.zip,
                                      city: contactData.address.city,
                                      state: contactData.address.state,
                                    },
                                  });
                                  setClaimantType(ContactTypes.Individual);
                                }
                                if (isCompany(contactData)) {
                                  setClaimantDatabaseCompanyContact(contactData as CompleteCompany);
                                  setClaimantCompanyContact({
                                    companyName: contactData.companyName,
                                    ico: contactData.ico,
                                    dic: contactData.dic,
                                    icDph: contactData.icDph,
                                    dphPayer: contactData.dphPayer,
                                    contactPersonFirstName: contactData.contactPersonFirstName,
                                    contactPersonLastName: contactData.contactPersonLastName,
                                  });
                                  setClaimantBasicContactInfo({
                                    email: contactData.email,
                                    phoneNumber: contactData.phoneNumber,
                                    iban: contactData.iban,
                                    swift: contactData.swift,
                                    bank: contactData.bank,
                                    address: {
                                      street: contactData.address.street,
                                      zip: contactData.address.zip,
                                      city: contactData.address.city,
                                      state: contactData.address.state,
                                    },
                                  });
                                  setClaimantType(ContactTypes.Company);
                                }
                              },
                            },
                          ]}
                        />
                      </ContactsProvider>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} />
                  {claimantType === ContactTypes.Individual && claimantDatabaseIndividualContact && (
                    <>
                      <Grid item xs={12}>
                        <ContactChip
                          contact={claimantDatabaseIndividualContact}
                          handleDelete={() => setClaimantDatabaseIndividualContact(undefined)}
                        />
                      </Grid>
                      <Grid item xs={12} />
                    </>
                  )}
                  {claimantType === ContactTypes.Company && claimantDatabaseCompanyContact && (
                    <>
                      <Grid item xs={12}>
                        <ContactChip
                          contact={claimantDatabaseCompanyContact}
                          handleDelete={() => setClaimantDatabaseCompanyContact(undefined)}
                        />
                      </Grid>
                      <Grid item xs={12} />
                    </>
                  )}
                  <Grid item xs={12}>
                    {claimantType === ContactTypes.Company && (
                      <CompanyInfoForm
                        onConfirm={setClaimantCompanyContact}
                        initialValues={claimantDatabaseCompanyContact || claimantCompanyContact}
                      />
                    )}
                    {claimantType === ContactTypes.Individual && (
                      <IndividualInfoForm
                        initialValues={claimantDatabaseIndividualContact || claimantContact}
                        onConfirm={setClaimantContact}
                        omitFields={['gender']}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} />
                  <Grid item xs={12}>
                    <BasicContactInfoForm
                      initialValues={
                        (claimantType === ContactTypes.Individual &&
                          claimantDatabaseIndividualContact) ||
                        (claimantType === ContactTypes.Company && claimantDatabaseCompanyContact) ||
                        claimantBasicContactInfo
                      }
                      onConfirm={setClaimantBasicContactInfo}
                    />
                  </Grid>
                </Grid>
                {!isMultiPayers ? (
                  <Grid item md={6} container spacing={2} alignContent="flex-start">
                    <Grid item xs={12} container spacing={3} alignItems="center">
                      <Grid item>
                        <Typography variant="h6" color="primary">
                          Platca
                        </Typography>
                      </Grid>
                      <Grid item>
                        <ContactTypeSelector value={payerType} onSelect={setPayerType} />
                      </Grid>
                      <Grid item xs>
                        <ContactsProvider>
                          <FuzzyContactSearcher
                            actions={[
                              {
                                icon: <CheckCircleIcon />,
                                shouldClosePoper: true,
                                onClick: (contactData) => {
                                  if (isIndividual(contactData)) {
                                    setPayerDatabaseIndividualContact(
                                      contactData as CompleteIndividual
                                    );
                                    setPayerContact({
                                      firstName: contactData.firstName,
                                      lastName: contactData.lastName,
                                      gender: contactData.gender,
                                    });
                                    setPayerBasicContactInfo({
                                      email: contactData.email,
                                      phoneNumber: contactData.phoneNumber,
                                      iban: contactData.iban,
                                      swift: contactData.swift,
                                      bank: contactData.bank,
                                      address: {
                                        street: contactData.address.street,
                                        zip: contactData.address.zip,
                                        city: contactData.address.city,
                                        state: contactData.address.state,
                                      },
                                    });
                                    setPayerType(ContactTypes.Individual);
                                  }
                                  if (isCompany(contactData)) {
                                    setPayerDatabaseCompanyContact(contactData as CompleteCompany);
                                    setPayerCompanyContact({
                                      companyName: contactData.companyName,
                                      ico: contactData.ico,
                                      dic: contactData.dic,
                                      icDph: contactData.icDph,
                                      dphPayer: contactData.dphPayer,
                                      contactPersonFirstName: contactData.contactPersonFirstName,
                                      contactPersonLastName: contactData.contactPersonLastName,
                                    });
                                    setPayerBasicContactInfo({
                                      email: contactData.email,
                                      phoneNumber: contactData.phoneNumber,
                                      iban: contactData.iban,
                                      swift: contactData.swift,
                                      bank: contactData.bank,
                                      address: {
                                        street: contactData.address.street,
                                        zip: contactData.address.zip,
                                        city: contactData.address.city,
                                        state: contactData.address.state,
                                      },
                                    });
                                    setPayerType(ContactTypes.Company);
                                  }
                                },
                              },
                            ]}
                          />
                        </ContactsProvider>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} />
                    {payerType === ContactTypes.Individual && payerDatabaseIndividualContact && (
                      <>
                        <Grid item xs={12}>
                          <ContactChip
                            contact={payerDatabaseIndividualContact}
                            handleDelete={() => setPayerDatabaseIndividualContact(undefined)}
                          />
                        </Grid>
                        <Grid item xs={12} />
                      </>
                    )}
                    {payerType === ContactTypes.Company && payerDatabaseCompanyContact && (
                      <>
                        <Grid item xs={12}>
                          <ContactChip
                            contact={payerDatabaseCompanyContact}
                            handleDelete={() => setPayerDatabaseCompanyContact(undefined)}
                          />
                        </Grid>
                        <Grid item xs={12} />
                      </>
                    )}
                    <Grid item xs={12}>
                      {payerType === ContactTypes.Company && (
                        <CompanyInfoForm
                          onConfirm={setPayerCompanyContact}
                          initialValues={payerDatabaseCompanyContact || payerCompanyContact}
                        />
                      )}
                      {payerType === ContactTypes.Individual && (
                        <IndividualInfoForm
                          onConfirm={setPayerContact}
                          omitFields={['gender']}
                          initialValues={payerDatabaseIndividualContact || payerContact}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item xs={12}>
                      <BasicContactInfoForm
                        onConfirm={setPayerBasicContactInfo}
                        omitFields={['iban', 'bank', 'swift']}
                        initialValues={
                          (payerType === ContactTypes.Individual
                            ? payerDatabaseIndividualContact
                            : payerDatabaseCompanyContact) || payerBasicContactInfo
                        }
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid item xs={12} container>
                      <Grid item>
                        <Typography variant="h6">Platcovia</Typography>
                      </Grid>
                      <Grid item xs />
                      <Grid item>
                        <Button
                          variant="contained"
                          color="secondary"
                          startIcon={<Group />}
                          onClick={handleStartAddingPeopleToClaim}
                        >
                          Upraviť hromadne
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item md={6}>
                      <FuzzyContactSearcher
                        omit={multiPayers.map((mp) => mp._id)}
                        actions={[
                          {
                            icon: <CheckCircleIcon />,
                            shouldClosePoper: true,
                            onClick: (contactData) => addMultiPayer(contactData),
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12} container>
                      <ContactsTable
                        contacts={indexArray(multiPayers)}
                        actions={[
                          {
                            element: ContactIconButtonAction,
                            props: {
                              icon: <RemoveCircle />,
                              action: (_c, idx) => removeMultiPayer(idx),
                            },
                          },
                        ]}
                        customCols={[
                          {
                            field: 'valid',
                            headerName: 'Validný',
                            renderCell: ({ row }) =>
                              isCompleteAddress(row.address) ? (
                                <Tooltip title="Kontakt je validný = má adresu a tak sa pre neho môže vytvoriť pohľadávka.">
                                  <Box display="flex" alignItems="center">
                                    <CheckCircleIcon />
                                  </Box>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Kontakt nemá adresu a tak sa pre neho nemôže vytvoriť pohľadávka.">
                                  <Box display="flex" alignItems="center">
                                    <Error sx={{ color: 'error.main' }} />
                                  </Box>
                                </Tooltip>
                              ),
                          },
                        ]}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
            <Divider />
            <StockItemsProvider>
              <AddClaimItems />
            </StockItemsProvider>
          </Stack>
        </CardContent>
        <CardActions>
          <Button
            color="secondary"
            variant="contained"
            startIcon={<SaveIcon />}
            disabled={!isValid}
            onClick={handleSubmitNewClaim}
          >
            Uložiť
          </Button>
          <Button
            onClick={handleStopAdding}
            variant="outlined"
            startIcon={<CancelIcon />}
            sx={{ mr: 1 }}
          >
            Zrušiť
          </Button>
        </CardActions>
      </Card>
      <RightDrawerAddContactSelector
        opened={addingPeopleToClaim}
        handleClose={handleStopAddingPeopleToClaim}
        caption="Výber kontaktov pre pohľadávku"
        initialSelected={multiPayers.map((mp) => mp._id)}
        onConfirm={(_x) => undefined}
        onConfirmFull={setMultiPayers}
      />
    </>
  );
};

export default NewClaim;
