import { Tooltip, IconButton } from '@mui/material';
import { FC } from 'react';
import { IContactAction } from '../../contacts/ContactsTable';
import { CompleteIndividual } from '../../../ctx/contacts/Contact.Context';

interface IContactIconButtonAction extends IContactAction {
  icon: any;
  action: (contsct: CompleteIndividual, idx?: number) => void;
  tooltip?: string;
  idx?: number;
}

const ContactIconButtonAction: FC<IContactIconButtonAction> = ({
  contactData,
  idx,
  icon,
  action,
  tooltip,
  ...other
}) => {
  return tooltip ? (
    <Tooltip title={tooltip} {...other}>
      <IconButton onClick={() => action(contactData, idx)}>{icon}</IconButton>
    </Tooltip>
  ) : (
    <IconButton onClick={() => action(contactData, idx)} {...other}>
      {icon}
    </IconButton>
  );
};
export default ContactIconButtonAction;
