import { RadioGroup, FormControlLabel, Radio, FormControl, FormLabel } from '@mui/material';
import { FC } from 'react';
import { ContactTypes } from '../../ctx/contacts/NewContact.Context';

interface IContactTypeSelector {
  onSelect(selectedType: ContactTypes): void;
  value: ContactTypes;
  label?: string;
}

const ContactTypeSelector: FC<IContactTypeSelector> = ({ onSelect, value, label }) => {
  const companyContactRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSelect((event.target as HTMLInputElement).value as ContactTypes);
  };

  return (
    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">{label}</FormLabel>
      <RadioGroup row value={value} onChange={companyContactRadioChange}>
        <FormControlLabel value={ContactTypes.Individual} control={<Radio />} label="Individuál" />
        <FormControlLabel value={ContactTypes.Company} control={<Radio />} label="Spoločnosť" />
      </RadioGroup>
    </FormControl>
  );
};

export default ContactTypeSelector;
