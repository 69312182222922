import { FC } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import { DataGridWithSelection, IDataGridWithSelection } from '../DataGridWithSelection';
import { Email } from '../../ctx/subscriptions/Subscription.Context';
import { fDateTime } from '../../utils/formatTime';
import EmailPoper from './EmailPoper';

interface ISubscriptionColumns {
  reloadData?: () => void;
}

const subscriptionColumns = ({ reloadData }: ISubscriptionColumns): GridColDef[] => [
  {
    field: 'sent',
    headerName: 'Odoslaný',
    flex: 1,
    renderCell: ({ row }: { row: Email }) => (
      <EmailPoper
        emailData={row}
        rootElement={<Typography variant="body2">{fDateTime(row.sent)}</Typography>}
      />
    ),
  },
  {
    field: 'options.to',
    headerName: 'To',
    flex: 1,
    renderCell: ({ row }: { row: Email }) => (
      <EmailPoper
        emailData={row}
        rootElement={<Typography variant="body2">{row.options.to}</Typography>}
      />
    ),
  },
];

interface IEmailsTable extends IDataGridWithSelection {
  emails: Array<Email>;
  reloadData?: () => void;
}

export const EmailsTable: FC<IEmailsTable> = ({ emails, reloadData, ...props }) => {
  return (
    <DataGridWithSelection
      {...props}
      autoHeight
      columns={subscriptionColumns({ reloadData })}
      rows={emails.map((c, i) => ({ ...c, id: `email-${i}` }))}
    />
  );
};
