import { createContext, FC, useCallback, useContext, useMemo, useState } from 'react';
import { useFeedback } from '../Feedback';
import { useApi } from '../Api';
import { ClaimGroup, ClaimStateEnum } from '../Claims.Context';
import { CompleteContact } from '../../components/contact/IndividualBusinessCard';
import { CompleteListCampaign } from '../listCampaigns/ListCampaigns.Context';
import { CompleteTag } from '../tags/Tags.Context';

export interface TagFilter {
  type: 'TagFilter';
  isAnd: boolean;
  filters: Array<{
    tag?: CompleteTag; // this field is not supported by backend
    tagId: string;
    value: boolean;
  }>;
}
export interface ListCampaignFilter {
  type: 'ListCampaignFilter';
  isAnd: boolean;
  filters: Array<{
    listCampaign?: CompleteListCampaign; // this field is not supported by backend
    listCampaignId: string;
    value: 'get' | 'didntGet' | 'reacted' | 'didntReact';
  }>;
}
export interface ClaimFilter {
  type: 'ClaimFilter';
  isAnd: boolean;
  filters: Array<{
    startDate: Date | undefined;
    endDate: Date | undefined;
    count: number | undefined;
    sum: number | undefined;
    state: ClaimStateEnum | undefined;
  }>;
}
export interface ClaimGroupFilter {
  type: 'ClaimGroupFilter';
  isAnd: boolean;
  filters: Array<{
    claimGroup?: ClaimGroup; // this field is not supported by backend
    claimGroupId: string;
    value: boolean | undefined;
  }>;
}

interface ContactFilters {
  isAnd: boolean;
  filters: Array<TagFilter | ListCampaignFilter | ClaimFilter | ClaimGroupFilter>;
}

interface IContactFiltersContext {
  isAnd: boolean;
  tagFilter: TagFilter;
  listCampaignFilter: ListCampaignFilter;
  claimFilter: ClaimFilter;
  claimGroupFilter: ClaimGroupFilter;
  setIsAnd: (is: boolean) => void;
  setTagFilter(fce: (p: TagFilter) => TagFilter): void;
  setListCampaignFilter(campaignFilter: ListCampaignFilter): void;
  setClaimFilter(claimFilter: ClaimFilter): void;
  setClaimGroupFilter(claimGroupFilter: ClaimGroupFilter): void;
  getContacts: () => Promise<CompleteContact[]>;
  clearAllFilters: () => void;
}

const ContactFiltersContext = createContext<IContactFiltersContext>({
  isAnd: undefined,
  tagFilter: undefined,
  listCampaignFilter: undefined,
  claimFilter: undefined,
  claimGroupFilter: undefined,
  setIsAnd: () => undefined,
  setTagFilter: () => undefined,
  setListCampaignFilter: () => undefined,
  setClaimFilter: async () => undefined,
  setClaimGroupFilter: async () => undefined,
  getContacts: async () => undefined,
  clearAllFilters: () => undefined,
});

export const useContactFilters = () => {
  const context = useContext(ContactFiltersContext);
  if (!context) {
    throw new Error('Parent must be wrapped inside ContactFiltersProvider');
  }
  return context;
};

interface IContactFiltersProvider {
  onContactFiltersSuccess?: Function;
}

export const ContactFiltersProvider: FC<IContactFiltersProvider> = ({
  onContactFiltersSuccess,
  children,
}) => {
  const { API, defaultErrorHandle } = useApi();

  const [tagFilter, setTagFilter] = useState<TagFilter>({
    type: 'TagFilter',
    isAnd: true,
    filters: [],
  });
  const [listCampaignFilter, setListCampaignFilter] = useState<ListCampaignFilter>({
    type: 'ListCampaignFilter',
    isAnd: true,
    filters: [],
  });
  const [claimFilter, setClaimFilter] = useState<ClaimFilter>({
    type: 'ClaimFilter',
    isAnd: true,
    filters: [],
  });
  const [claimGroupFilter, setClaimGroupFilter] = useState<ClaimGroupFilter>({
    type: 'ClaimGroupFilter',
    isAnd: true,
    filters: [],
  });

  const [isAnd, setIsAnd] = useState<boolean>(true);

  const clearAllFilters = useCallback(() => {
    setTagFilter({
      type: 'TagFilter',
      isAnd: true,
      filters: [],
    });
    setListCampaignFilter({
      type: 'ListCampaignFilter',
      isAnd: true,
      filters: [],
    });
    setClaimFilter({
      type: 'ClaimFilter',
      isAnd: true,
      filters: [],
    });
    setClaimGroupFilter({
      type: 'ClaimGroupFilter',
      isAnd: true,
      filters: [],
    });
  }, []);

  const getContacts = useCallback(async (): Promise<CompleteContact[]> => {
    const filters = [];

    if (listCampaignFilter.filters.length)
      filters.push({
        ...listCampaignFilter,
        filters: listCampaignFilter.filters.map(({ value, listCampaignId }) => ({
          value,
          listCampaignId,
        })),
      });

    if (tagFilter.filters.length)
      filters.push({
        ...tagFilter,
        filters: tagFilter.filters.map(({ value, tagId }) => ({
          value,
          tagId,
        })),
      });

    if (claimFilter.filters.length) filters.push(claimFilter);
    if (claimGroupFilter.filters.length)
      filters.push({
        ...claimGroupFilter,
        filters: claimGroupFilter.filters.map(({ value, claimGroupId }) => ({
          value,
          claimGroupId,
        })),
      });

    const filter: ContactFilters = { isAnd, filters };
    const res = await API.post('contacts/filtersearch', filter);
    console.log(res.data);

    return res.data;
  }, [API, claimFilter, claimGroupFilter, isAnd, listCampaignFilter, tagFilter]);

  const contextObjects = useMemo(
    () => ({
      isAnd,
      tagFilter,
      listCampaignFilter,
      claimFilter,
      claimGroupFilter,
      setIsAnd,
      setTagFilter,
      setListCampaignFilter,
      setClaimFilter,
      setClaimGroupFilter,
      getContacts,
      clearAllFilters,
    }),
    [
      claimFilter,
      claimGroupFilter,
      clearAllFilters,
      getContacts,
      isAnd,
      listCampaignFilter,
      tagFilter,
    ]
  );

  return (
    <ContactFiltersContext.Provider value={contextObjects}>
      {children}
    </ContactFiltersContext.Provider>
  );
};
