import { Box, Card, CardContent, Chip, Stack, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DateRangeIcon from '@mui/icons-material/DateRange';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import Popper from '@mui/material/Popper';
import { usePopupState, bindHover, bindPopper } from 'material-ui-popup-state/hooks';
import Fade from '@mui/material/Fade';
import { DoneAll } from '@mui/icons-material';
import { fDateTime } from '../../utils/formatTime';
import {
  ActualVariableSubscriptionState,
  SubscriptionState,
  VariableSubscriptionStateEnum,
} from '../../ctx/subscriptions/Subscriptions.Context';
import { fEur } from '../../utils/formatNumber';

const VariableSubscriptionStateChip: FC<ActualVariableSubscriptionState> = (subscriptionState) => {
  const popupState = usePopupState({ variant: 'popper', popupId: 'demoPopper' });

  return (
    <>
      {subscriptionState.title === VariableSubscriptionStateEnum.cantTell && (
        <Chip
          {...bindHover(popupState)}
          icon={<PendingIcon />}
          label={subscriptionState.title}
          color="warning"
          variant="filled"
        />
      )}
      {subscriptionState.title === VariableSubscriptionStateEnum.paid && (
        <Chip
          {...bindHover(popupState)}
          icon={<CheckCircleIcon />}
          label={subscriptionState.title}
          color="success"
          variant="filled"
        />
      )}
      {subscriptionState.title === VariableSubscriptionStateEnum.paidLess && (
        <Chip
          {...bindHover(popupState)}
          icon={<CancelIcon />}
          label={subscriptionState.title}
          color="error"
          variant="filled"
        />
      )}
      {subscriptionState.title === VariableSubscriptionStateEnum.paidMore && (
        <Chip
          {...bindHover(popupState)}
          icon={<DoneAll />}
          label={subscriptionState.title}
          color="primary"
          variant="filled"
        />
      )}
      <Popper {...bindPopper(popupState)} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Card sx={{ backgroundColor: 'white', backdropFilter: 'blur(5px)' }}>
              <CardContent>
                <Stack direction="column" spacing={2}>
                  <Stack direction="row" spacing={2}>
                    <DateRangeIcon />
                    <Typography>{fDateTime(subscriptionState.date)}</Typography>
                  </Stack>
                  <Tooltip
                    title={
                      <Box>
                        <Stack direction="column" spacing={1}>
                          <Typography>{`Očakávané: ${fEur(
                            subscriptionState.sumToBePaid
                          )}`}</Typography>
                          <Typography>{`Zaplatené: ${fEur(subscriptionState.sumPaid)}`}</Typography>
                        </Stack>
                      </Box>
                    }
                  >
                    <Box>
                      {subscriptionState.title === VariableSubscriptionStateEnum.paidMore && (
                        <Stack direction="row" spacing={2}>
                          <TextSnippetIcon />
                          <Typography>{`Zaplatené naviac: ${fEur(
                            subscriptionState.howMuch
                          )}`}</Typography>
                        </Stack>
                      )}
                      {subscriptionState.title === VariableSubscriptionStateEnum.paidLess && (
                        <Stack direction="row" spacing={2}>
                          <TextSnippetIcon />
                          <Typography>{`Zaplatené menej: ${fEur(
                            subscriptionState.howMuch
                          )}`}</Typography>
                        </Stack>
                      )}
                      {subscriptionState.title === VariableSubscriptionStateEnum.paid && (
                        <Stack direction="row" spacing={2}>
                          <TextSnippetIcon />
                          <Typography>{`Zaplatené: ${fEur(
                            subscriptionState.sumToBePaid
                          )}`}</Typography>
                        </Stack>
                      )}
                      {subscriptionState.title === VariableSubscriptionStateEnum.cantTell && (
                        <Stack direction="row" spacing={2}>
                          <TextSnippetIcon />
                          <Typography>{`Chýba zadať ${subscriptionState.howMuch} očakávaných platieb`}</Typography>
                        </Stack>
                      )}
                    </Box>
                  </Tooltip>
                </Stack>
              </CardContent>
            </Card>
          </Fade>
        )}
      </Popper>
    </>
  );
};
export default VariableSubscriptionStateChip;
