import {
  ClickAwayListener,
  Box,
  TextField,
  Popper,
  Card,
  Stack,
  CardContent,
  Typography,
  debounce,
} from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { ContactsProvider, useContacts } from '../../ctx/contacts/Contacts.Context';
import IndividualBusinessCard, { CompleteContact } from './IndividualBusinessCard';
import { CompleteCompany, CompleteIndividual } from '../../ctx/contacts/Contact.Context';
import ContactFullInfoCard from './ContactFullInfoCard';

export interface IContactIconAction {
  icon: any;
  onClick: (contact: CompleteIndividual | CompleteCompany) => void;
  shouldClosePoper?: boolean;
  tooltip?: string;
}

interface IFuzzyContactSearcher {
  actions?: IContactIconAction[];
  // eslint-disable-next-line react/no-unused-prop-types
  omit?: string[];
  fullCard?: boolean;
  setFound?: (cntcts: CompleteContact[]) => void;
  popper?: boolean;
}

const FuzzyContactSearcherWrapped: FC<IFuzzyContactSearcher> = ({
  actions,
  fullCard,
  setFound,
  popper = true,
}) => {
  const [query, setQuery] = useState('');
  const { pullFuzzyContacts, currentContactsFuzzyWithoutOmited } = useContacts();

  // Debounce the pullFuzzyContacts function
  const debouncedPullFuzzyContacts = useCallback(
    debounce((value: string) => {
      pullFuzzyContacts(value);
    }, 300),
    []
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);

    // pullFuzzyContacts(event.target.value);
    debouncedPullFuzzyContacts(event.target.value);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <TextField
          fullWidth
          id="outlined-name"
          autoComplete="off"
          inputProps={{
            autoComplete: 'off',
          }}
          label="Hľadať kontakt..."
          value={query}
          onChange={handleChange}
          onClick={handleClick}
        />
        {popper && (
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            placement="bottom-start"
            style={{ zIndex: 1000000000 }}
          >
            <Card sx={{ bgcolor: 'background.paper', minWidth: 400 }}>
              <Stack direction="column" spacing={2} sx={{ m: 2 }}>
                {currentContactsFuzzyWithoutOmited?.map((cif, i) =>
                  fullCard ? (
                    <ContactFullInfoCard contactInfo={cif} />
                  ) : (
                    <IndividualBusinessCard
                      key={`fuzzy-card-${i}`}
                      contactData={cif}
                      actions={actions?.map((a) =>
                        a.shouldClosePoper
                          ? {
                              ...a,
                              onClick: (props) => {
                                handleClickAway();
                                a.onClick(props);
                              },
                            }
                          : a
                      )}
                    />
                  )
                )}
              </Stack>
              {!query && (
                <CardContent>
                  <Typography variant="caption">Začnite písať</Typography>
                </CardContent>
              )}
            </Card>
          </Popper>
        )}
      </Box>
    </ClickAwayListener>
  );
};

const FuzzyContactSearcher: FC<IFuzzyContactSearcher> = (props) => (
  <ContactsProvider omit={props.omit}>
    <FuzzyContactSearcherWrapped {...props} />
  </ContactsProvider>
);

export default FuzzyContactSearcher;
