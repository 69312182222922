import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useApi } from '../Api';
import { Company, CompleteClaim, Individual } from '../Claims.Context';
import { ListCampaignWithContacts } from '../listCampaigns/ListCampaign.context';
import { CompleteListCampaign } from '../listCampaigns/ListCampaigns.Context';
import { CompleteSubscription } from '../subscriptions/Subscriptions.Context';
import { CompleteTag } from '../tags/Tags.Context';

export interface CompleteIndividual extends Individual {
  _id: string;
  fullName: string;
  tagTrees?: CompleteTag[];
}

export interface CompleteCompany extends Company {
  _id: string;
}

export const isIndividual = (contact: Individual | Company): contact is CompleteIndividual =>
  'firstName' in contact;

export const isCompany = (contact: Individual | Company): contact is Company =>
  'companyName' in contact;

interface IContactContext {
  contactData?: CompleteIndividual;
  pullData: () => Promise<CompleteIndividual>;
  editContact: (newData: CompleteIndividual) => Promise<CompleteIndividual>;
  contactClaims: CompleteClaim[];
  contactListCampaigns: ListCampaignWithContacts[];
  contactSubscriptions: CompleteSubscription[];
}

const ContactContext = createContext<IContactContext>({
  contactData: undefined,
  contactClaims: undefined,
  contactListCampaigns: undefined,
  contactSubscriptions: undefined,
  pullData: () => undefined,
  editContact: () => undefined,
});

export const useContact = () => {
  const context = useContext(ContactContext);
  if (!context) {
    throw new Error('Parent must be wrapped inside ContactProvider');
  }

  return context;
};

interface IContactProvider {
  // tba
  id: string;
  dataProvider?: boolean;
  initialData?: CompleteIndividual;
}

export const ContactProvider: FC<IContactProvider> = ({
  id,
  dataProvider = true,
  children,
  initialData,
}) => {
  const { API, defaultErrorHandle } = useApi();

  const [contactData, setContactData] = useState<CompleteIndividual>(initialData);
  const [contactClaims, setContactClaims] = useState<CompleteClaim[]>([]);
  const [contactListCampaigns, setContactListCampaigns] = useState<ListCampaignWithContacts[]>([]);
  const [contactSubscriptions, setContactSubscriptions] = useState<CompleteSubscription[]>([]);

  const pullData = useCallback(async (): Promise<CompleteIndividual> => {
    if (!API) return undefined;
    try {
      const res = await API.get(`contacts/${id}`);
      console.log(res.data);
      if (dataProvider) setContactData(res.data);
      return res.data;
    } catch (e: any) {
      defaultErrorHandle(e);
      return undefined;
    }
  }, [API, dataProvider, defaultErrorHandle, id]);

  const pullClaims = useCallback(async () => {
    if (!API || !dataProvider) return;
    try {
      const res = await API.get(`claims/forcontact/${id}`);
      setContactClaims(res.data);
      console.log(res.data);
    } catch (e: any) {
      defaultErrorHandle(e);
    }
  }, [API, dataProvider, defaultErrorHandle, id]);

  const pullSubscriptions = useCallback(async () => {
    if (!API || !dataProvider) return;
    try {
      const res = await API.get(`subscriptions/forcontact/${id}`);
      setContactSubscriptions(res.data);
      console.log(res.data);
    } catch (e: any) {
      defaultErrorHandle(e);
    }
  }, [API, dataProvider, defaultErrorHandle, id]);

  const pullListCampaigns = useCallback(async () => {
    if (!API || !dataProvider) return;
    try {
      const res = await API.get(`listcampaigns/forcontact/${id}`);
      setContactListCampaigns(res.data);
      console.log(res.data);
    } catch (e: any) {
      defaultErrorHandle(e);
    }
  }, [API, dataProvider, defaultErrorHandle, id]);

  const editContact = useCallback(
    async (newData: CompleteIndividual): Promise<CompleteIndividual> => {
      if (!API) return undefined;
      try {
        const res = await API.put(`contacts/${id}`, {
          ...newData,
          contactPersons: newData.contactPersons?.map((cp) => ({
            caption: cp.caption,
            person: cp.person._id,
          })),
        });
        console.log(res);
        if (dataProvider) setContactData(res.data);
        return res.data;
      } catch (e: any) {
        defaultErrorHandle(e);
        return undefined;
      }
    },
    [API, dataProvider, defaultErrorHandle, id]
  );

  useEffect(() => {
    if (dataProvider) {
      pullData();
      pullClaims();
      pullSubscriptions();
      pullListCampaigns();
    }
  }, [dataProvider, pullClaims, pullData, pullListCampaigns, pullSubscriptions]);

  const contextObjects = useMemo(
    () => ({
      contactData,
      pullData,
      editContact,
      contactClaims,
      contactListCampaigns,
      contactSubscriptions,
    }),
    [contactData, pullData, editContact, contactClaims, contactListCampaigns, contactSubscriptions]
  );

  return <ContactContext.Provider value={contextObjects}>{children}</ContactContext.Provider>;
};
