import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFeedback } from '../Feedback';
import { useApi } from '../Api';
import { AccountingYear, Company, CompleteAccountingYear, Individual } from '../Claims.Context';

export enum OrderStateEnum {
  pending = 'Čakajúca',
  paid = 'Zaplatená',
  cancelled = 'Zrušená',
}

export interface OrderState {
  date: Date;
  title: OrderStateEnum;
  note: string;
  addedBy: string;
}

export interface Order {
  _id: string;
  number: number;
  issued: Date;
  rawTotal: number;
  finalPrice: number;
  state: OrderState;
  stateHistory: OrderState[];
  items: OrderItem[];
  emailInvoiceSent: boolean;
  payer?: Individual | Company;
  claimant?: Individual | Company;
  payerRef?: string;
  claimantRef?: string;
  orderPrint?: string;
}

export interface OrderItem {
  title: string;
  quantity: number;
  unitPrice: number;
}

interface IOrdersContext {
  accountingYears: CompleteAccountingYear[];
  currentAccountingYear: CompleteAccountingYear | undefined;
  orders: Order[];
  setCurrentAccountingYearId(id: string): void;
  pullOrders(): void;
  downloadOrderPdf(orderId: string, filename: string): void;
  setOrderState(orderId: string, state: OrderStateEnum, note?: string): void;
  deleteOrder(orderId: string): void;
}

const OrdersContext = createContext<IOrdersContext>({
  accountingYears: [],
  currentAccountingYear: undefined,
  orders: [],
  setCurrentAccountingYearId: () => undefined,
  pullOrders: () => undefined,
  downloadOrderPdf: () => undefined,
  setOrderState: () => undefined,
  deleteOrder: () => undefined,
});

export const useOrders = () => {
  const context = useContext(OrdersContext);
  if (!context) {
    throw new Error('Parent must be wrapped inside OrdersProvider');
  }
  return context;
};

export const OrdersProvider: FC = ({ children }) => {
  const { API, defaultErrorHandle } = useApi();
  const { error, success } = useFeedback();
  const [accountingYears, setAccountingYears] = useState<CompleteAccountingYear[]>([]);
  const [currentAccountingYearId, setCurrentAccountingYearId] = useState<string>();
  const [orders, setOrders] = useState<Order[]>([]);

  const currentAccountingYear = accountingYears.find((ay) => ay._id === currentAccountingYearId);

  useEffect(() => {
    if (!API) return;
    API.get('/accountingYear')
      .then((res) => {
        console.log(res.data);
        if (res.data.length === 0) return;
        setAccountingYears(res.data);
        const now = new Date();
        const currentYear = res.data.find(
          (ay: AccountingYear) => new Date(ay.start) < now && new Date(ay.end) > now
        );
        if (currentYear) setCurrentAccountingYearId(currentYear._id);
        else setCurrentAccountingYearId(res.data[0]._id);
      })
      .catch((e: any) => {
        error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
      });
  }, [API, error]);

  const pullOrders = useCallback(() => {
    if (!API || !currentAccountingYearId) return;
    API.get(`/orders/${currentAccountingYearId}`)
      .then((res) => {
        console.log(res.data);
        setOrders(res.data);
      })
      .catch((e: any) => {
        error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
      });
  }, [API, error, currentAccountingYearId]);

  useEffect(() => {
    pullOrders();
  }, [pullOrders]);

  const downloadOrderPdf = useCallback(
    async (orderId: string, filename: string) => {
      if (!API) return;
      try {
        const res = await API.get(`/orders/${orderId}/pdf`, { responseType: 'blob' });
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      } catch (e: any) {
        defaultErrorHandle(e);
      }
    },
    [API, defaultErrorHandle]
  );

  const setOrderState = useCallback(
    async (orderId: string, state: OrderStateEnum, note?: string) => {
      if (!API) return;
      try {
        await API.put(`/orders/${orderId}/state`, { state, note });
        success('Order state updated');
        pullOrders();
      } catch (e: any) {
        defaultErrorHandle(e);
      }
    },
    [API, defaultErrorHandle, pullOrders, success]
  );

  const deleteOrder = useCallback(
    async (orderId: string) => {
      if (!API) return;
      try {
        await API.delete(`/orders/${orderId}`);
        success('Order deleted');
        pullOrders();
      } catch (e: any) {
        defaultErrorHandle(e);
      }
    },
    [API, defaultErrorHandle, pullOrders, success]
  );

  const contextObjects = useMemo(
    () => ({
      accountingYears,
      currentAccountingYear,
      orders,
      setCurrentAccountingYearId,
      pullOrders,
      downloadOrderPdf,
      setOrderState,
      deleteOrder,
    }),
    [
      accountingYears,
      currentAccountingYear,
      orders,
      pullOrders,
      setCurrentAccountingYearId,
      downloadOrderPdf,
      setOrderState,
      deleteOrder,
    ]
  );

  return <OrdersContext.Provider value={contextObjects}>{children}</OrdersContext.Provider>;
};
