import Fuse from 'fuse.js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'throttle-debounce';

interface IUseFuseOptions<T1> extends Fuse.IFuseOptions<T1> {
  limit?: number;
  matchAllOnEmptyQuery?: boolean;
}

export const useFuse = <T1 extends object>(list: Array<T1>, options: IUseFuseOptions<T1>) => {
  // defining our query state in there directly
  const [query, updateQuery] = useState<string>('');

  // removing custom options from Fuse options object
  // NOTE: `limit` is actually a `fuse.search` option, but we merge all options for convenience
  const { limit, matchAllOnEmptyQuery, ...fuseOptions } = options;

  // let's memoize the fuse instance for performances
  const fuse = useMemo(
    () =>
      new Fuse(list, {
        ...fuseOptions,
        getFn: (obj: T1, path: string) =>
          (obj[path] as string)?.normalize('NFD').replace(/[\u0300-\u036F]/g, ''),
      }),
    [list, fuseOptions]
  );

  // memoize results whenever the query or options change
  const hits = useMemo(
    // if query is empty and `matchAllOnEmptyQuery` is `true` then return all list
    // NOTE: we remap the results to match the return structure of `fuse.search()`
    () =>
      // !query && matchAllOnEmptyQuery
      //   ? fuse
      //       .getIndex()
      //       .docs.slice(0, limit)
      //       .map((item, refIndex) => ({ item, refIndex }))
      //   : fuse.search(query, { limit }),
      fuse.search(query, { limit }),
    [fuse, limit, matchAllOnEmptyQuery, query]
  );

  // debounce updateQuery and rename it `setQuery` so it's transparent
  const setQuery = useCallback(debounce(100, updateQuery), []);

  // pass a handling helper to speed up implementation
  const onSearch = useCallback((e: string) => setQuery(e.trim()), [setQuery]);
  const onClearQuery = useCallback(() => setQuery(''), [setQuery]);

  // useEffect(() => console.log(hits), [hits]);/

  // still returning `setQuery` for custom handler implementations
  return {
    hits,
    onSearch,
    query,
    setQuery,
    onClearQuery,
  };
};
