/* eslint-disable react/no-unstable-nested-components */
import {
  ExpandMore,
  ChevronRight,
  Label,
  Delete,
  DragIndicatorOutlined,
  EjectOutlined,
  Edit,
  Close,
} from '@mui/icons-material';
import { TreeItem, treeItemClasses, TreeView } from '@mui/lab';
import {
  alpha,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Grow,
  IconButton,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { cloneElement, FC, useEffect, useState } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { CompleteTag, useTags } from '../../ctx/tags/Tags.Context';
import { recursiveIndexGetter } from '../../utils/tagsRecursive';
import RightDrawer from '../RightDrawer';
import TagForm from './TagForm';

const StyledTreeItem = styled((props: any) => (
  <TreeItem nodeId={props.nodeId} TransitionProps={{ timeout: 0 }} {...props} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

const ItemTypes = {
  TAG: 'tag',
};

interface ITagTreeItem extends ITagsGrid {
  tag: CompleteTag;
  parentsIds: string[];
}

function findNode(id, currentNode) {
  let i;
  let currentChild;
  let result;

  if (id === currentNode._id) {
    return true;
  }
  // Use a for loop instead of forEach to avoid nested functions
  // Otherwise "return" will not work properly

  for (i = 0; i < currentNode.children.length; i += 1) {
    currentChild = currentNode.children[i];

    // Search in the current child
    result = findNode(id, currentChild);

    // Return the result if the node has been found
    if (result !== false) {
      return result;
    }
  }

  // The node has not been found and we have no more options
  return false;
}

const TagTreeItem: FC<ITagTreeItem> = (props) => {
  const {
    isSelected,
    isSelectedNot,
    addSelected,
    moveSelectedToSelectedNot,
    removeSelectedOrSelectedNot,
    moveTag,
    removeTag,
    updateTag,
  } = useTags();
  const { tag, binarySelect = true, parentsIds, onlyView, onlyTagSelect, editingTags } = props;
  const childrenIds = tag.children.flatMap(recursiveIndexGetter);

  const [editingTag, setEditingTag] = useState<boolean>(false);
  const handleStartEditingTag = () => setEditingTag(true);
  const handleStopEditingTag = () => setEditingTag(false);

  const imSelected = isSelected(tag);
  const imSelectedNot = isSelectedNot(tag);
  const [isShownMenu, setIsShownMenu] = React.useState(false);

  const toggleThis = () => {
    if (binarySelect && imSelected) {
      removeSelectedOrSelectedNot(tag._id, [...parentsIds, ...childrenIds]);
      return;
    }
    // if not filter, nor notFilter, then add to filter
    if (!imSelected && !imSelectedNot) {
      addSelected(tag._id, childrenIds);
    }
    // if filter and not notFilter, then move to notFilter
    else if (imSelected && !imSelectedNot) {
      moveSelectedToSelectedNot(tag._id, [...parentsIds], [...childrenIds]);
    }
    // if not filter and notFilter, then remove filter
    else if (!imSelected && imSelectedNot) {
      removeSelectedOrSelectedNot(tag._id, [...parentsIds, ...childrenIds]);
    }
    // this should never happen
    else {
      throw Error(
        `Invalid filter state: 
        id:${tag.caption} 
        filter: ${imSelected} 
        notFilter: ${imSelectedNot}`
      );
    }
  };

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: ItemTypes.TAG,
    item: props.tag,

    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();

      if (item && dropResult) {
        // props.onDropTag(item);
      }
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const [{ isOver, isOverCurrent }, drop] = useDrop(
    () => ({
      accept: ItemTypes.TAG,
      drop(item: any, monitor) {
        if (monitor.didDrop()) return;
        if (monitor.isOver({ shallow: true })) moveTag(item._id, tag._id);
      },
      canDrop(item) {
        return !findNode(tag._id, item);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }) && monitor.canDrop(),
      }),
    }),
    []
  );

  const tagCheckbox = (
    <>
      <Checkbox
        onClick={toggleThis}
        size="small"
        color={(imSelected && 'success') || (imSelectedNot && 'error') || 'primary'}
        indeterminate={imSelectedNot}
        checked={imSelected}
      />
      {/* {!tag.isCategory && <Label />} */}
    </>
  );

  const plainTagLabel = (
    <Stack direction="row" spacing={1} alignItems="center">
      <Label />
      <Typography variant="h6" color="primary">
        {tag.caption}
      </Typography>
    </Stack>
  );

  const plainCategoryLabel = (
    <Typography variant="body1" color="secondary">
      {tag.caption}
    </Typography>
  );

  const selectCategoryLabel = <FormControlLabel control={tagCheckbox} label={plainCategoryLabel} />;

  const selectTagLabel = <FormControlLabel control={tagCheckbox} label={plainTagLabel} />;

  const label =
    (tag.isCategory &&
      (((onlyView || onlyTagSelect) && plainCategoryLabel) || selectCategoryLabel)) ||
    (onlyView && plainTagLabel) ||
    selectTagLabel;

  const editingLabel = (
    <Stack
      ref={drop}
      direction="row"
      spacing={1}
      onMouseEnter={(e) => {
        e.stopPropagation();
        setIsShownMenu(true);
      }}
      onMouseLeave={(e) => {
        setIsShownMenu(false);
      }}
      sx={{ ...(isOverCurrent && { backgroundColor: 'green' }) }}
    >
      {cloneElement(label, { ref: preview })}
      <Grow in={isShownMenu && !isDragging} {...(!isShownMenu ? { timeout: 1000 } : {})}>
        <Stack direction="row">
          <Tooltip enterDelay={500} title="Editovať tag">
            <IconButton size="small" onClick={handleStartEditingTag}>
              <Edit />
            </IconButton>
          </Tooltip>
          {!tag.isCategory && (
            <Tooltip enterDelay={500} title="Zmazať tag">
              <IconButton size="small" onClick={() => removeTag(tag._id)}>
                <Delete />
              </IconButton>
            </Tooltip>
          )}
          {!tag.isRoot && (
            <Tooltip enterDelay={500} title="Premiestniť tag na vrchol">
              <IconButton size="small" onClick={() => moveTag(tag._id, '')}>
                <EjectOutlined />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip enterDelay={500} title="Premiestniť tag">
            <IconButton
              ref={drag}
              size="small"
              sx={[{ cursor: 'grab' }, isDragging && { cursor: 'grabbing' }]}
            >
              <DragIndicatorOutlined />
            </IconButton>
          </Tooltip>
        </Stack>
      </Grow>
    </Stack>
  );

  return (
    <>
      <StyledTreeItem
        nodeId={tag._id}
        label={editingTags ? editingLabel : label}
        sx={{
          '& .Mui-focused': { background: 'inherit!important' },
          ...(!tag.isCategory && { '& .MuiTreeItem-iconContainer': { display: 'none' } }),
        }}
      >
        {tag.children.map((tagChild) => (
          <TagTreeItem
            {...{ ...props, tag: tagChild, parentsIds: [...parentsIds, tag._id] }}
            key={tagChild._id}
          />
        ))}
      </StyledTreeItem>
      <RightDrawer isOpened={editingTag} handleClose={handleStopEditingTag}>
        <Stack sx={{ m: 3 }} spacing={3}>
          <Box>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Editovať tag</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleStopEditingTag}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <TagForm
            onConfirm={(d) => updateTag(tag._id, d)}
            initialValues={tag}
            autoSubmit={false}
            requiredFields={['caption']}
          />
        </Stack>
        Î
      </RightDrawer>
    </>
  );
};

interface ITagsGrid {
  onlyView?: boolean;
  onlyTagSelect?: boolean;
  binarySelect?: boolean;
  freeze?: boolean;
  defaultExpanded?: boolean;
  editingTags?: boolean;
  toggleButton?: boolean;
  defaultSlected?: string[];
}

const TagsGrid: FC<ITagsGrid> = (props) => {
  const { tagData, allIndexes } = useTags();
  const { defaultExpanded, freeze, toggleButton } = props;

  const [expanded, setExpanded] = React.useState<string[]>(defaultExpanded ? allIndexes : []);

  useEffect(() => {
    if (defaultExpanded) setExpanded(allIndexes);
  }, [defaultExpanded, allIndexes]);

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    if (freeze) return;
    setExpanded(nodeIds);
  };

  const handleExpandClick = () => {
    setExpanded((oldExpanded) => (oldExpanded.length === 0 ? allIndexes : []));
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Grid container sx={{ overflow: 'scroll' }}>
        {toggleButton && (
          <Grid item>
            <Button variant="outlined" onClick={handleExpandClick}>
              {expanded.length === allIndexes.length ? 'Zbaliť všetky' : 'Rozbaliť všetky'}
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <TreeView
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            disableSelection
            // defaultEndIcon={<Label />}
            // {...(freeze ? { expanded: allIndexes } : undefined)}
            // {...(defaultExpanded ? { defaultExpanded: allIndexes } : undefined)}
            // defaultExpanded={allIndexes}
            // selected={selected}
            disabledItemsFocusable
            // onNodeToggle={handleToggle}
            // onNodeSelect={handleSelect}
            expanded={expanded}
            // selected={selected}
            onNodeToggle={handleToggle}
            // onNodeSelect={handleSelect}
          >
            {tagData.map((tag) => (
              <TagTreeItem key={tag._id} {...props} parentsIds={[]} tag={tag} />
            ))}
          </TreeView>
        </Grid>
      </Grid>
    </DndProvider>
  );
};

export default TagsGrid;
