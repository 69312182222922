/* eslint-disable prefer-template */
import { Claim, ClaimItem } from '../ctx/Claims.Context';

export const applyDiscount = (val: number, discount: number) => (val * (100 - discount)) / 100;

export const claimItemTotal = (item: ClaimItem) =>
  item.rawUnitPrice * item.unitsCount * ((100 - item.discount) / 100);

interface ClaimMinimum {
  items: ClaimItem[];
  discount?: number;
}
const roundToTwo = (num: number) => {
  return Number.parseFloat(`${Math.round(Number.parseFloat(`${num}e+2`))}e-2`);
};
export const claimTotal = (claim: Claim | ClaimMinimum) => {
  let total = 0;
  claim.items.forEach((i) => (total += claimItemTotal(i)));

  if (claim.discount) total *= (100 - claim.discount) / 100;
  return roundToTwo(total);
};

/**
 * Decimal adjustment of a number.
 *
 * @param {String}  type  The type of adjustment.
 * @param {Number}  value The number.
 * @param {Integer} exp   The exponent (the 10 logarithm of the adjustment base).
 * @returns {Number} The adjusted value.
 */
function decimalAdjust(type, value, exp) {
  // If the exp is undefined or zero...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  // If the value is not a number or the exp is not an integer...
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  // Shift
  value = value.toString().split('e');
  value = Math[type](+(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp)));
  // Shift back
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp));
}

// Decimal round
export const round10 = (value, exp) => decimalAdjust('round', value, exp);
// Decimal floor
const floor10 = (value, exp) => decimalAdjust('floor', value, exp);
// Decimal ceil
const ceil10 = (value, exp) => decimalAdjust('ceil', value, exp);
