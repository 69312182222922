import { FC } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { StockItemAvatar } from '../../utils/stringAvatar';
import { fEur } from '../../utils/formatNumber';
import { CompleteStockItem } from '../../ctx/stockItems/StockItems.Context';

interface IListCampaignColumns {
  reloadData?: () => void;
  navigate: any;
  customCols?: GridColDef[];
}

interface IRowDef {
  row: CompleteStockItem;
}

const stockItemColumns = ({
  reloadData,
  navigate,
  customCols = [],
}: IListCampaignColumns): GridColDef[] => [
  {
    field: 'caption',
    headerName: 'Názov',
    flex: 4,
    renderCell: ({ row }: IRowDef) => (
      <>
        <StockItemAvatar item={row} />
        <Typography variant="body1" noWrap sx={{ ml: 1 }}>
          {row.title}
        </Typography>
      </>
    ),
  },
  {
    field: 'rawUnitPrice',
    headerName: 'Cena',
    flex: 1,
    align: 'right',
    renderCell: ({ row }: IRowDef) => <>{fEur(row.rawUnitPrice)}</>,
  },

  ...customCols,
];

interface IStockItemsTable {
  stockItems: Array<CompleteStockItem>;
  reloadData?: () => void;
  rowClick?: (e: any) => void;
  customCols?: GridColDef[];
  onClose?: () => void;
}

export const StockItemsTable: FC<IStockItemsTable> = ({
  stockItems,
  reloadData,
  customCols,
  rowClick,
  onClose,
}) => {
  const navigate = useNavigate();
  return (
    <div style={{ position: 'relative' }}>
      {onClose && (
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', top: 0, right: 0 }}
          color="primary"
        >
          <Close />
        </IconButton>
      )}
      <DataGrid
        autoHeight
        onRowClick={rowClick}
        columns={stockItemColumns({ reloadData, navigate, customCols })}
        rows={stockItems.map((c) => ({ ...c, id: c._id }))}
      />
    </div>
  );
};
