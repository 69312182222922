import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  Modal,
  Skeleton,
  Stack,
  Tooltip,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { CancelPresentation, Edit, FileDownloadOff, Send } from '@mui/icons-material';
import ClaimStateChip from '../components/claims/ClaimStateChip';
import { ClaimState } from '../ctx/Claims.Context';
import { fDate } from '../utils/formatTime';
import { ClaimProvider, useClaim } from '../ctx/Claim.Context';
import ClaimStateChanger from '../components/claims/ClaimStateChanger';
import ClaimBasicInfoCard from '../components/claim/ClaimBasicInfoCard';
import { EmailsTable } from '../components/email/EmailsTable';
import EmailOptionsForm from '../components/email/forms/EmailForm';
import { EmailOptions } from '../ctx/subscriptions/Subscription.Context';
import OutlinedSection from '../components/OutlinedSection';
import NewClaim from '../components/claims/NewClaim';
import { NewClaimProvider } from '../ctx/NewClaim.Context';

export const modalStyle = {
  // position: 'absolute' as 'absolute',
  // top: '50%',
  left: '50%',
  transform: 'translate(-50%, -0%)',
  width: '40vw',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  // overflow: 'scroll',
};

const ClaimStateTimeline: FC<{ states: ClaimState[] }> = ({ states }) => {
  return (
    <Timeline position="alternate">
      {states.map((s, i) => (
        <TimelineItem key={i}>
          <TimelineOppositeContent color="text.secondary">{fDate(s.date)}</TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot />
            {i < states.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <ClaimStateChip {...s} />
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

const ClaimInfo: FC = () => {
  const {
    claimData,
    downloadInvoice,
    pullData,
    sendInvoiceEmail,
    createCreditNote,
    downloadCreditNote,
    previewInvoiceEmail,
  } = useClaim();

  const [openEmailmodal, setOpenEmailModal] = useState(false);
  const handleOpenEmailModal = () => setOpenEmailModal(true);
  const handleCloseEmailModal = () => setOpenEmailModal(false);

  const [emailOptions, setEmailOptions] = useState<EmailOptions>();

  const [editingClaim, setEditingClaim] = useState<boolean>(false);
  const handleStartEditingClaim = () => setEditingClaim(true);
  const handleStopEditingClaim = () => setEditingClaim(false);

  useEffect(() => {
    const pull = async () => {
      const email = await previewInvoiceEmail();
      setEmailOptions(email?.options);
    };
    pull();
  }, [previewInvoiceEmail]);

  return claimData ? (
    <>
      <Grid container spacing={3}>
        <Grid item md={6} container spacing={3}>
          <Grid item xs={12}>
            <ClaimBasicInfoCard claimData={claimData} />
          </Grid>
        </Grid>
        <Grid item md={6} container spacing={3}>
          <Grid item xs={12}>
            <OutlinedSection label="Akcie">
              <Stack direction="row">
                <ClaimStateChanger
                  afterChange={pullData}
                  button={
                    <IconButton>
                      <ChangeCircleIcon />
                    </IconButton>
                  }
                />
                <Tooltip title="Stiahnuť faktúru">
                  <IconButton onClick={downloadInvoice}>
                    <FileDownloadIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={
                    claimData.payer.email
                      ? 'Odoslať email s faktúrou'
                      : 'Pohľadávka neobsahuje zadaný klientov email.'
                  }
                >
                  <Box>
                    <IconButton disabled={!claimData.payer.email} onClick={handleOpenEmailModal}>
                      <Send />
                    </IconButton>
                  </Box>
                </Tooltip>
                <Tooltip title="Zrušiť a vytvoriť dobropis">
                  <Box>
                    <IconButton disabled={!!claimData.creditNote} onClick={createCreditNote}>
                      <CancelPresentation />
                    </IconButton>
                  </Box>
                </Tooltip>
                <Tooltip title="Stiahnuť dobropis">
                  <Box>
                    <IconButton disabled={!claimData.creditNote} onClick={downloadCreditNote}>
                      <FileDownloadOff />
                    </IconButton>
                  </Box>
                </Tooltip>
                <Tooltip title="Editovať faktúru">
                  <IconButton onClick={handleStartEditingClaim}>
                    <Edit />
                  </IconButton>
                </Tooltip>
              </Stack>
            </OutlinedSection>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Emaily" />
              <CardContent>
                <EmailsTable emails={claimData.emails} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <ClaimStateTimeline states={claimData.stateHistory} />
          </Grid>
        </Grid>
        {editingClaim && (
          <Grid item xs={12}>
            <Collapse in={editingClaim}>
              <NewClaimProvider
                accountingYear={claimData.accountingYear}
                editing={claimData}
                onNewClaimSuccess={pullData}
              >
                <NewClaim handleStopAdding={handleStopEditingClaim} />
              </NewClaimProvider>
            </Collapse>
          </Grid>
        )}
      </Grid>
      <Modal
        open={openEmailmodal}
        onClose={handleCloseEmailModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          position: 'absolute',
          // top: '10%',
          left: '10%',
          overflow: 'scroll',
          height: '100%',
          display: 'block',
          // pt: 100,
          zIndex: 9999999999999,
        }}
      >
        <Card sx={modalStyle}>
          <EmailOptionsForm
            requiredFields={['from', 'to', 'text', 'subject', 'replyTo']}
            initialValues={emailOptions}
            autoSubmit={false}
            onConfirm={(d) => {
              sendInvoiceEmail(d);
              console.log('tak wtf');
              handleCloseEmailModal();
            }}
          />
        </Card>
      </Modal>
    </>
  ) : (
    <Skeleton />
  );
};

const Claim: FC = () => {
  const { id } = useParams();

  return (
    <ClaimProvider id={id}>
      <ClaimInfo />
    </ClaimProvider>
  );
};

export default Claim;
