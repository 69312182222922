import { FC } from 'react';
import { Grid, Typography } from '@mui/material';

import { CheckCircle, RemoveCircle } from '@mui/icons-material';
import { BasicContactInfo } from '../../../ctx/Claims.Context';
import ContactIconButtonAction from '../../contact/actions/ContactIconButtonAction';
import FuzzyContactSearcher from '../../contact/FuzzyContactSearcher';
import { ContactsTable } from '../ContactsTable';

interface IContactPersonsEditor {
  onConfirm(d: BasicContactInfo): void;
  initialValues: BasicContactInfo;
}

const ContactPersonsEditor: FC<IContactPersonsEditor> = ({ onConfirm, initialValues }) => {
  const contactPersons = initialValues.contactPersons.map((cp) => cp.person);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} container>
        <Grid item>
          <Typography variant="h6">Kontaktné osoby</Typography>
        </Grid>
        <Grid item xs />
      </Grid>

      <Grid item md={6}>
        <FuzzyContactSearcher
          omit={contactPersons.map((mp) => mp._id)}
          actions={[
            {
              icon: <CheckCircle />,
              shouldClosePoper: true,
              onClick: (contactData) =>
                onConfirm({
                  ...initialValues,
                  contactPersons: [
                    ...initialValues.contactPersons,
                    { caption: `Kontaktná osoba`, person: contactData },
                  ],
                }),
            },
          ]}
        />
      </Grid>
      <Grid item xs={12} container>
        <ContactsTable
          contacts={contactPersons}
          actions={[
            {
              element: ContactIconButtonAction,
              props: {
                icon: <RemoveCircle />,
                action: (_c, idx) => {
                  initialValues.contactPersons.splice(idx, 1);
                  onConfirm({
                    ...initialValues,
                    contactPersons: [...initialValues.contactPersons],
                  });
                },
              },
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default ContactPersonsEditor;
