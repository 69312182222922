import { FC } from 'react';
import { date, number, object, string } from 'yup';

import { useFormik, Form, FormikProvider } from 'formik';
import { Button, Grid, Stack, TextField } from '@mui/material';

import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { MyFormikTextField } from '../../MyFormikTextField';
import { SubmitListener } from '../../../utils/SubmitListener';
import { ListCampaign } from '../../../ctx/listCampaigns/ListCampaigns.Context';

interface IListCampaignForm {
  onConfirm(d: ListCampaign): void;
  initialValues?: ListCampaign;
  requiredFields?: Array<keyof ListCampaign>;
  omitFields?: Array<keyof ListCampaign>;
  autoSubmit?: boolean;
}

const ListCampaignForm: FC<IListCampaignForm> = ({
  onConfirm,
  initialValues,
  requiredFields,
  omitFields,
  autoSubmit = true,
}) => {
  const ValidationSchema = object().shape({
    description: string().when('x', {
      is: () => requiredFields?.includes('description') || false,
      then: string().required(),
    }),
    caption: string().when('x', {
      is: () => requiredFields?.includes('caption') || false,
      then: string().required(),
    }),
    date: date().when('x', {
      is: () => requiredFields?.includes('date') || false,
      then: date().required(),
    }),
  });

  // const now = new Date();
  const formik = useFormik<Partial<ListCampaign>>({
    initialValues: initialValues || {
      description: '',
      caption: '',
    },
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      console.log(values);
      onConfirm(values as ListCampaign);
    },
  });
  const { errors, touched, values, handleSubmit, getFieldProps, isValid, dirty } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {autoSubmit && <SubmitListener />}
        <Grid container spacing={1}>
          {!omitFields?.includes('caption') && (
            <Grid item xs={12}>
              <MyFormikTextField
                label="Názov"
                fieldName="caption"
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}
          {!omitFields?.includes('date') && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid item xs={6}>
                <DatePicker
                  label="Dátum"
                  value={values.date}
                  onChange={(value) => {
                    formik.setFieldValue('date', value);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
            </LocalizationProvider>
          )}
          {!omitFields?.includes('description') && (
            <Grid item xs={12}>
              <MyFormikTextField
                label="Popis"
                fieldName="description"
                multiline
                minRows={4}
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}

          {!autoSubmit && (
            <Grid item xs={12}>
              <Stack direction="row-reverse" spacing={1}>
                <Stack spacing={1} direction="row-reverse">
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={!dirty || !isValid}
                  >
                    Potvrdiť
                  </Button>
                  <Button variant="outlined" color="primary" type="reset" disabled={!dirty}>
                    Resetovať
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default ListCampaignForm;
