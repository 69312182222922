import { Chip } from '@mui/material';
import { FC } from 'react';
import { usePopupState, bindHover } from 'material-ui-popup-state/hooks';
import { CompleteCompany, CompleteIndividual, isCompany } from '../../ctx/contacts/Contact.Context';
import ContactBasicInfoPoper from './ContactBasicInfoPopper';

interface IContactChip {
  contact: CompleteCompany | CompleteIndividual;
  handleDelete: () => void;
}

const ContactChip: FC<IContactChip> = ({ contact, handleDelete }) => {
  const popupState = usePopupState({ variant: 'popper', popupId: 'demoPopper' });
  const label = isCompany(contact) ? contact.companyName : contact.fullName;

  return (
    <ContactBasicInfoPoper
      rootElement={
        <Chip label={label} onDelete={handleDelete} {...bindHover(popupState)} variant="outlined" />
      }
      basicContactInfoData={contact}
    />
  );
};
export default ContactChip;
