import { FC } from 'react';
import { object, string } from 'yup';

import { useFormik, Form, FormikProvider } from 'formik';
import { Divider, Grid } from '@mui/material';

import { MyFormikTextField } from '../../MyFormikTextField';
import { SubmitListener } from '../../../utils/SubmitListener';
import { BasicContactInfo } from '../../../ctx/Claims.Context';

interface IBasicContactInfo {
  onConfirm(d: BasicContactInfo): void;
  initialValues?: BasicContactInfo;
  requiredFields?: Array<keyof BasicContactInfo>;
  omitFields?: Array<keyof BasicContactInfo>;
}

const BasicContactInfoForm: FC<IBasicContactInfo> = ({
  onConfirm,
  initialValues,
  requiredFields,
  omitFields,
}) => {
  const ValidationSchema = object().shape({
    email: string().when('x', {
      is: (x) => requiredFields?.includes('email') || false,
      then: string().required(),
    }),
    phoneNumber: string().when('x', {
      is: (x) => requiredFields?.includes('phoneNumber') || false,
      then: string().required(),
    }),
    iban: string().when('x', {
      is: (x) => requiredFields?.includes('iban') || false,
      then: string().required(),
    }),
    address: object()
      .shape({
        street: string(),
        zip: string(),
        city: string(),
        state: string(),
      })
      .when('x', {
        is: (x) => requiredFields?.includes('address') || false,
        then: object().required(),
      }),
  });

  const formik = useFormik<BasicContactInfo>({
    initialValues: initialValues || {
      email: '',
      phoneNumber: '',
      iban: '',
      swift: '',
      bank: '',
      address: {
        street: '',
        zip: '',
        city: '',
        state: '',
      },
    },
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      console.log(values);
      onConfirm({
        email: values.email,
        phoneNumber: values.phoneNumber,
        iban: values.iban,
        swift: values.swift,
        bank: values.bank,
        address: {
          street: values.address.street,
          zip: values.address.zip,
          city: values.address.city,
          state: values.address.state,
        },
      });
    },
  });
  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <SubmitListener />
        <Grid container spacing={1}>
          {!omitFields?.includes('address') && (
            <>
              <Grid item xs={12}>
                <MyFormikTextField
                  label="Ulica a číslo"
                  fieldName="address.street"
                  {...{ getFieldProps, touched, errors, values }}
                />
              </Grid>
              <Grid item xs={4}>
                <MyFormikTextField
                  label="PSČ"
                  fieldName="address.zip"
                  {...{ getFieldProps, touched, errors, values }}
                />
              </Grid>
              <Grid item xs={8}>
                <MyFormikTextField
                  label="Mesto"
                  fieldName="address.city"
                  {...{ getFieldProps, touched, errors, values }}
                />
              </Grid>
              <Grid item xs={5}>
                <MyFormikTextField
                  label="Štát"
                  fieldName="address.state"
                  {...{ getFieldProps, touched, errors, values }}
                />
              </Grid>
              <Grid item xs={12} sx={{ my: 1 }} />
            </>
          )}
          {!omitFields?.includes('email') && (
            <Grid item xs={8}>
              <MyFormikTextField
                label="Email"
                fieldName="email"
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}
          {!omitFields?.includes('phoneNumber') && (
            <Grid item xs={4}>
              <MyFormikTextField
                label="Telefónne číslo"
                fieldName="phoneNumber"
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}
          {!omitFields?.includes('iban') && (
            <Grid item xs={9}>
              <MyFormikTextField
                label="IBAN"
                fieldName="iban"
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}{' '}
          {!omitFields?.includes('swift') && (
            <Grid item xs={6}>
              <MyFormikTextField
                label="SWIFT"
                fieldName="swift"
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}{' '}
          {!omitFields?.includes('bank') && (
            <Grid item xs={6}>
              <MyFormikTextField
                label="Banka"
                fieldName="bank"
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default BasicContactInfoForm;
