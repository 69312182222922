import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { ArrowBack, Done } from '@mui/icons-material';
import { IImportContactsStep } from '../../../pages/ImportContacts';
import { useImportContacts } from '../../../ctx/contacts/ImportContacts.Context';
import TagsGrid from '../../tag/TagsGrid';
import { useTags } from '../../../ctx/tags/Tags.Context';
import { ContactDuplicitiesTable } from './ContactDuplicitiesTable';

const CheckDuplicitesStep: FC<IImportContactsStep> = ({ next, back }) => {
  const { contactsWithDuplicities, fileName, importContacts } = useImportContacts();

  const { selected } = useTags();
  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} container spacing={1}>
            <Grid item xs>
              <Typography variant="h5">{fileName}</Typography>
            </Grid>

            <Grid item>
              <Button
                startIcon={<ArrowBack />}
                variant="outlined"
                onClick={() => {
                  if (back) back();
                }}
              >
                Späť na kontrolu kontaktov
              </Button>
            </Grid>
            <Grid item>
              <Button
                startIcon={<Done />}
                variant="contained"
                color="secondary"
                onClick={() => {
                  importContacts(selected);
                  next();
                }}
              >
                Hotovo
              </Button>
            </Grid>
          </Grid>

          <Grid item md={6} container spacing={3} alignContent="flex-start">
            <Grid item xs={12}>
              <Typography variant="caption">
                Tagy, ktoré tu zvolíte budú po dokončení priradené kontaktom.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TagsGrid onlyTagSelect />
            </Grid>
          </Grid>

          <Grid item md={6} container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="caption">
                Bolo nájdených {contactsWithDuplicities.length} potenciálne duplicitných kontaktov.
                Rozhodinite o tom, či sú naozaj duplicitné a ak áno, čo sa s nimi má stať.
              </Typography>
            </Grid>{' '}
            {/* <Grid item xs={12}>
              <Stack direction="row-reverse" spacing={1} alignItems="center">
                <Button variant="outlined" disabled={duplici}>Originály</Button>
                <Button variant="outlined">Duplicity</Button>
                <Typography>Označiť všetky ako:</Typography>
              </Stack>
            </Grid> */}
            <Grid item xs={12}>
              <ContactDuplicitiesTable contacts={contactsWithDuplicities} />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CheckDuplicitesStep;
