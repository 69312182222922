import { FC } from 'react';
import { boolean, number, object, string } from 'yup';

import { useFormik, Form, FormikProvider } from 'formik';
import { Button, FormControlLabel, Grid, Stack, Switch, TextField } from '@mui/material';

import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { MyFormikTextField } from '../../MyFormikTextField';
import { SubmitListener } from '../../../utils/SubmitListener';
import { ListCampaignContactRelationBasicInfo } from '../../../ctx/listCampaigns/ListCampaign.context';

interface IListCampaignForm {
  onConfirm(d: ListCampaignContactRelationBasicInfo): void;
  initialValues?: ListCampaignContactRelationBasicInfo;
  requiredFields?: Array<keyof ListCampaignContactRelationBasicInfo>;
  omitFields?: Array<keyof ListCampaignContactRelationBasicInfo>;
  autoSubmit?: boolean;
}

const ListCampaignContactRelFrom: FC<IListCampaignForm> = ({
  onConfirm,
  initialValues,
  requiredFields,
  omitFields,
  autoSubmit = true,
}) => {
  const ValidationSchema = object().shape({
    note: string().when('x', {
      is: () => requiredFields?.includes('note') || false,
      then: number().required(),
    }),
  });

  // const now = new Date();
  const formik = useFormik<Partial<ListCampaignContactRelationBasicInfo>>({
    initialValues: initialValues || {
      note: '',
    },
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      console.log(values);
      onConfirm(values as ListCampaignContactRelationBasicInfo);
    },
  });
  const { errors, touched, values, handleSubmit, getFieldProps, setFieldValue, isValid, dirty } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {autoSubmit && <SubmitListener />}
        <Grid container spacing={1}>
          {!omitFields?.includes('reacted') && (
            <FormControlLabel
              control={
                <Switch
                  checked={!!getFieldProps('reacted').value}
                  // gross workaround
                  onChange={() => setFieldValue('reacted', !getFieldProps('reacted').value)}
                  name={getFieldProps('reacted').name}
                />
              }
              label="Reagoval"
            />
          )}
          {!omitFields?.includes('note') && (
            <Grid item xs={12}>
              <MyFormikTextField
                label="Poznámka"
                fieldName="note"
                multiline
                minRows={4}
                {...{ getFieldProps, touched, errors, values }}
              />
            </Grid>
          )}

          {!autoSubmit && (
            <Grid item xs={12}>
              <Stack direction="row-reverse" spacing={1}>
                <Stack spacing={1} direction="row-reverse">
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={!dirty || !isValid}
                  >
                    Potvrdiť
                  </Button>
                  <Button variant="outlined" color="primary" type="reset" disabled={!dirty}>
                    Resetovať
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default ListCampaignContactRelFrom;
