import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { alpha, AppBar, Box, Container, Grid, Toolbar } from '@mui/material';
import Logo from './authorized/Logo';
import LogoKan from './authorized/LogoKan';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const PublicLayout: FC = () => {
  return (
    <Container>
      <AppBar
        sx={(theme) => ({
          boxShadow: 'none',
          backdropFilter: 'blur(6px)',
          WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
          backgroundColor: alpha(theme.palette.background.default, 0.72),
        })}
      >
        <Toolbar
          sx={{
            minHeight: { sx: APP_BAR_MOBILE, lg: APP_BAR_DESKTOP },
          }}
        >
          <Grid container justifyContent="space-between">
            <Grid item alignItems="center" sx={{ display: 'flex' }}>
              <LogoKan />
            </Grid>
            <Grid item>
              <Box
                sx={{ height: '100%', zIndex: 100000 }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {/* <Typography variant="h1" color="secondary">
                KANis
              </Typography> */}
                <Box
                  sx={{
                    fontFamily: 'Monospace',
                    fontSize: '50px',
                    // fontSize: 'h1.fontSize',
                    color: 'primary.main',
                    fontWeight: 'bold',
                  }}
                >
                  KAN
                </Box>
                <Box
                  sx={{
                    fontFamily: 'Monospace',
                    fontSize: '50px',
                    // fontSize: 'h1.fontSize',
                    color: 'secondary.main',
                    fontWeight: 'bold',
                  }}
                >
                  is
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ transform: 'rotateY(180deg)' }}>
                <Logo secondary />
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          flexGrow: 1,
          overflow: 'auto',
          minHeight: '100%',
          paddingTop: { xs: `${APP_BAR_MOBILE + 24}px`, lg: `${APP_BAR_DESKTOP + 24}px` },
          paddingBottom: 10,
          px: 1,
        }}
      >
        <Outlet />
      </Box>
    </Container>
  );
};

export default PublicLayout;
