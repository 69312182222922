import { Drawer } from '@mui/material';
import { FC } from 'react';
import Scrollbar from './Scrollbar';

export interface IRightDrawer {
  // user: User;
  isOpened: boolean;
  handleClose: () => void;
  size?: 'quarter' | 'third' | 'half' | 'twothirds' | 'threequarters';
  scrollbar?: boolean;
  // adminEditing?: boolean;
}

export const getWidth = (size: IRightDrawer['size']) => {
  switch (size) {
    case 'quarter':
      return '25vw';
    case 'third':
      return '34vw';
    case 'half':
      return '50vw';
    case 'twothirds':
      return '67vw';
    case 'threequarters':
      return '75vw';
    default:
      return '25vw';
  }
};

const RightDrawer: FC<IRightDrawer> = (props) => {
  const { isOpened, handleClose, size = 'third', scrollbar } = props;

  const width = () => {
    switch (size) {
      case 'quarter':
        return '25vw';
      case 'third':
        return '34vw';
      case 'half':
        return '50vw';
      case 'twothirds':
        return '67vw';
      case 'threequarters':
        return '75vw';
      default:
        return '25vw';
    }
  };

  return (
    <Drawer
      // keepMounted={false}
      anchor="right"
      open={isOpened}
      onClose={handleClose}
      PaperProps={{
        sx: {
          // width: 540,
          width: width(),
          maxWidth: '100vw',
          border: 'none',
          overflow: 'hidden',
          borderTopLeftRadius: '16px',
          borderBottomLeftRadius: '16px',
          boxShadow: 3,
          height: '100vh',
          maxHeight: '100vh',
          // backdropFilter: 'blur(10px)',
          // WebkitBackdropFilter: 'blur(10px)',
          // backgroundColor: alpha(theme.palette.secondary.main, 0.8)
        },
      }}
      ModalProps={{
        BackdropProps: {
          sx: {
            backdropFilter: 'blur(1px)',
            WebkitBackdropFilter: 'blur(1px)',
            backgroundOpacity: 0,
          },
        },
      }}
    >
      {/* {scrollbar ? : props.children} */}
      <Scrollbar sx={{}}>{props.children}</Scrollbar>
    </Drawer>
  );
};

export default RightDrawer;
