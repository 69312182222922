import { FC } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Avatar, Box, Checkbox, Divider, Stack, Switch, Tooltip } from '@mui/material';
import { Label, LabelOutlined, RestorePage, RestorePageOutlined } from '@mui/icons-material';
import ContactBasicInfoPoper from '../../contact/ContactBasicInfoPopper';
import { ContactTableActions } from '../ContactsTable';
import {
  ContactWithDuplicities,
  useImportContacts,
} from '../../../ctx/contacts/ImportContacts.Context';
import { ContactAvatar, stringAvatar } from '../../../utils/stringAvatar';
import ContactFullInfoPopper from '../../contact/ContactFullInfoPopper';
import { isCompany } from '../../../ctx/contacts/Contact.Context';

const contactColumns = (): GridColDef[] => [
  {
    field: 'firstName',
    headerName: 'Meno',
    flex: 1,
  },
  {
    field: 'lastName',
    headerName: 'Priezvisko',
    flex: 1,
  },
  {
    field: 'contact',
    headerName: 'Kontakt',
    flex: 1,
    renderCell: ({ row }) => (
      <ContactBasicInfoPoper
        basicContactInfoData={row}
        rootElement={<Box>{row.email || row.phoneNumber || row.address.street}</Box>}
      />
    ),
  },
  {
    field: 'actions',
    headerName: 'Potenciálne dupliticné kontakty',
    flex: 3,
    renderCell: ({ row }: { row: ContactWithDuplicities }) => <DuplicityCell contact={row} />,
  },
];

const DuplicityCell: FC<{ contact: ContactWithDuplicities }> = ({ contact }) => {
  const { isDuplicate, addDuplicate, removeDuplicate, updateDuplicate } = useImportContacts();

  const imDuplicate = isDuplicate(contact);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const duplicate = event.target.checked;
    if (duplicate) addDuplicate(contact);
    if (!duplicate) removeDuplicate(contact);
  };

  return (
    <Stack direction="row" spacing={2}>
      <Tooltip
        title={
          imDuplicate
            ? 'Jedná sa o duplicitný kontakt (neuloží sa do databázy)'
            : 'Nejedná sa o duplicitný kontakt'
        }
        placement="left"
      >
        <Switch
          checked={Boolean(imDuplicate) || false}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Tooltip>
      {contact.potentialDuplicities.map((pd, i) => (
        <Stack direction="row" key={`${contact.idx}-duplicity-${pd._id}`}>
          <Divider orientation="vertical" sx={{ mr: 2 }} />
          <ContactFullInfoPopper contactInfo={pd} rootElement={<ContactAvatar contact={pd} />} />
          <Tooltip
            title={`Updatovať kontakt ${
              isCompany(pd) ? pd.companyName : `${pd.firstName} ${pd.lastName}`
            } novými informáciami zo súboru.`}
          >
            <Checkbox
              checked={(imDuplicate && imDuplicate.potentialDuplicities[i].update) || false}
              disabled={!imDuplicate}
              onChange={() => {
                const newDuplicities = [...contact.potentialDuplicities];
                console.log(pd.update);
                console.log({ ...pd, update: !pd.update });
                newDuplicities.splice(i, 1, {
                  ...pd,
                  update: !imDuplicate.potentialDuplicities[i].update,
                });
                updateDuplicate({
                  ...contact,
                  potentialDuplicities: newDuplicities as any,
                });
              }}
              icon={<RestorePageOutlined />}
              checkedIcon={<RestorePage />}
              size="small"
            />
          </Tooltip>{' '}
          <Tooltip
            title={`Pridať kontaktu ${
              isCompany(pd) ? pd.companyName : `${pd.firstName} ${pd.lastName}`
            } tagy ako ostatným importovaným kontaktom.`}
          >
            <Checkbox
              sx={{ ml: 1 }}
              icon={<LabelOutlined />}
              checkedIcon={<Label />}
              size="small"
              checked={(imDuplicate && imDuplicate.potentialDuplicities[i].tag) || false}
              disabled={!imDuplicate}
              onChange={() => {
                const newDuplicities = [...contact.potentialDuplicities];
                newDuplicities.splice(i, 1, {
                  ...pd,
                  tag: !imDuplicate.potentialDuplicities[i].tag,
                });
                updateDuplicate({
                  ...contact,
                  potentialDuplicities: newDuplicities as any,
                });
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Tooltip>
        </Stack>
      ))}
    </Stack>
  );
};

interface IContactDuplicitiesTable {
  contacts: Array<ContactWithDuplicities>;
  reloadData?: () => void;
  actions?: ContactTableActions;
}

export const ContactDuplicitiesTable: FC<IContactDuplicitiesTable> = ({
  contacts,
  reloadData,
  actions,
}) => {
  return (
    <DataGrid
      autoHeight
      columns={contactColumns()}
      rows={contacts.map((c) => ({ ...c, id: c.idx }))}
      sx={{
        '& .MuiDataGrid-renderingZone': {
          maxHeight: 'none !important',
        },
        '& .MuiDataGrid-cell': {
          lineHeight: 'unset !important',
          maxHeight: 'none !important',
          whiteSpace: 'normal',
        },
        '& .MuiDataGrid-row': {
          maxHeight: 'none !important',
        },
      }}
    />
  );
};
