import axios, { AxiosInstance } from 'axios';
import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFeedback } from './Feedback';
import { storeToken, clearToken, getToken } from '../storage/tokenSlice';

// ---------------------------------

export interface UserBasicInfo {
  firstName: string;
  lastName: string;
  email: string;
  admin: boolean;
}

export interface User extends UserBasicInfo {
  _id: string;
}

export interface NewPassword {
  password: string;
  passwordConfirmation: string;
}
// ---------------------------------

// const BASE_URL: string = "http://localhost:5001/api/";

const defaultOptions = {
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
};

interface IApiContext {
  API: AxiosInstance | undefined;
  defaultErrorHandle: (e: any) => void;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => void;
  changePassword: (args: {
    password: string;
    newPassword: string;
    newPasswordConfirmation: string;
  }) => Promise<boolean>;
  editMe: (args: Partial<User>) => Promise<boolean>;

  createUser: (args: UserBasicInfo) => Promise<boolean>;
  getUsers: () => Promise<Array<User>>;
  editUser: (user: User) => Promise<boolean>;
  pullUserInfo: (id: string) => Promise<User>;

  isLoggedIn: boolean | undefined;
  user: User | undefined;
}

const ApiContext = createContext<IApiContext>({
  API: undefined,
  defaultErrorHandle: () => undefined,
  login: async () => false,
  logout: () => undefined,
  changePassword: async () => false,
  editMe: async () => false,

  createUser: async () => false,
  getUsers: async () => [],
  editUser: async () => false,
  pullUserInfo: async () => undefined,

  isLoggedIn: undefined,
  user: undefined,
});

export const useApi = () => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error('Parent must be wrapped inside ApiProvider');
  }

  return context;
};

export const ApiProvider: FC = ({ children }) => {
  const tokenFromStorage = useSelector(getToken);
  const dispatch = useDispatch();

  const [isLoggedIn, setIsLoggedIn] = useState<boolean | undefined>(undefined);
  const [user, setUser] = useState<User>();

  const [API, setApi] = useState<AxiosInstance | undefined>(undefined);

  const { error, success } = useFeedback();
  const [canSetApi, setCanSetApi] = useState<boolean>(true);

  const defaultErrorHandle = useCallback(
    (e: any) => {
      console.log(e.message, e.response);
      try {
        const messages: string[] = [];

        if (e.response?.data) {
          if (Array.isArray(e.response.data))
            e.response.data.map((d: any) => messages.push(d.message));
          else messages.push(e.response.data.message);
        }

        error(`${e.message}:\n${messages.join('\n')}`);
      } catch {
        error('error occured, cannot read its data');
        console.log('cannot display error message');
      }
    },
    [error]
  );

  useEffect(() => {
    // if (!canSetApi) return;
    // setCanSetApi(false);
    // setTimeout(() => setCanSetApi(true), 1000);
    console.log('setting api');
    setApi(() => {
      const api: AxiosInstance = axios.create(defaultOptions);
      api.interceptors.request.use((config) => {
        config.headers!.authorization = `Bearer ${tokenFromStorage.accessToken}`;
        config.headers!['x-refresh'] = `${tokenFromStorage.refreshToken}`;
        return config;
      });
      api.interceptors.response.use(
        (response) => {
          const newAccessToken = response.headers['x-access-token'];
          delete response.headers['x-access-token'];

          if (newAccessToken) {
            // console.log(newAccessToken, tokenFromStorage.accessToken);
            if (tokenFromStorage.accessToken === newAccessToken) {
              console.log('this is weird, but it has to stop');
              return response;
            }

            dispatch(storeToken({ ...tokenFromStorage, accessToken: newAccessToken }));
          }

          return response;
        },
        (response) => {
          console.log(response.response.data);
          throw response;
        }
      );
      return api;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, tokenFromStorage]);

  const login = useCallback(
    async (email: string, password: string): Promise<boolean> => {
      if (!API) return false;

      try {
        const res = await API.post('sessions', { email, password });
        console.log(res);
        const { data } = res;
        dispatch(storeToken(data));
        return true;
      } catch (e: any) {
        console.log(e);
        error('hmm');
        // error(`${e.response.status}: ${e.response.data.msg}`);
        return false;
      }
    },
    [API, error, dispatch]
  );

  const pullMyData = useCallback((): void => {
    if (!API) return;

    API.get('users/me')
      .then((r) => {
        setIsLoggedIn(true);
        setUser(r.data);
      })
      .catch(() => {
        console.log('tusom');
        setIsLoggedIn(false);
        setUser(undefined);
      });
  }, [API]);

  const pullUserInfo = useCallback(
    async (id: string): Promise<User> => {
      if (!API) return undefined;
      try {
        const res = await API.get(`users/${id}`);
        return res.data;
      } catch (e: any) {
        error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
        return undefined;
      }
    },
    [API, error]
  );

  useEffect(() => {
    console.log('pulling my data');
    pullMyData();
  }, [pullMyData]);

  const changePassword = useCallback(
    async (args): Promise<boolean> => {
      if (!API) return false;

      try {
        console.log(args);
        const res = await API.post(`users/me/changepassword/`, args);
        console.log(res);
        success('Heslo úspešne zmenené');
        return true;
      } catch (e: any) {
        error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
        return false;
      }
    },
    [API, error, success]
  );

  const editMe = useCallback(
    async (args: Partial<User>): Promise<boolean> => {
      if (!API) return false;

      try {
        console.log(args);
        const res = await API.post(`users/me/edit`, args);
        console.log(res);
        success(`Vaše údaje boli úspešne zmenené.`);
        // pullMyData();
        return true;
      } catch (e: any) {
        error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
        return false;
      }
    },
    [API, error, success]
  );

  const createUser = useCallback(
    async (args: UserBasicInfo): Promise<boolean> => {
      if (!API) return false;

      try {
        await API.post(`users`, args);
        return true;
      } catch (e: any) {
        error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
        return false;
      }
    },
    [API, error]
  );

  const getUsers = useCallback(async (): Promise<Array<User>> => {
    if (!API) return [];
    try {
      const res = await API.get(`users`);
      console.log('getting users');
      return res.data;
    } catch (e: any) {
      error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
      return [];
    }
  }, [API, error]);

  const editUser = useCallback(
    async (user: User): Promise<boolean> => {
      if (!API) return false;
      try {
        await API.post(`users/edit/${user._id}`, user);
        success('Údaje používateľa boli úspešne upravené.');
        return true;
      } catch (e: any) {
        error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
        return false;
      }
    },
    [API, error, success]
  );

  const logout = useCallback(() => {
    console.log('logout');
    dispatch(clearToken({}));
  }, [dispatch]);

  const contextObjects = useMemo(
    () => ({
      API,
      defaultErrorHandle,
      isLoggedIn,
      user,
      login,
      logout,
      changePassword,
      editMe,
      createUser,
      getUsers,
      editUser,
      pullUserInfo,
    }),
    [
      API,
      defaultErrorHandle,
      pullUserInfo,
      isLoggedIn,
      user,
      login,
      logout,
      changePassword,
      editMe,
      createUser,
      getUsers,
      editUser,
    ]
  );

  return <ApiContext.Provider value={contextObjects}>{children}</ApiContext.Provider>;
};
