import { FC } from 'react';
import { DataGrid, GridColDef, GridRowId } from '@mui/x-data-grid';
import { Tooltip, Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { fEur } from '../../utils/formatNumber';
import { Payment, PaymentType } from '../../ctx/PaymentProcess.Context';
import { fDate, fDateTime } from '../../utils/formatTime';

export interface IpaymentColumns {
  omit?: string[];
  customCols?: GridColDef[];
}

const ifNotOmit = (omit: string[], col: GridColDef) =>
  omit && omit.includes(col.field) ? [] : [col];

const paymentColumns = ({ omit, customCols = [] }: IpaymentColumns): GridColDef[] => {
  return [
    ...ifNotOmit(omit, {
      field: 'type',
      headerName: 'Typ',
      flex: 0.5,
      align: 'center',
      renderCell: ({ row }) =>
        row.type === PaymentType.CRDT ? (
          <ArrowDownwardIcon sx={{ color: 'success.main' }} />
        ) : (
          <ArrowUpwardIcon sx={{ color: 'error.main' }} />
        ),
    }),
    ...ifNotOmit(omit, {
      field: 'counterParty',
      headerName: 'Protistrana',
      flex: 1,
      renderCell: ({ row }) => {
        let counterParty = row.payer;
        if (row.type === PaymentType.DBIT) counterParty = row.receiver;
        return (
          <Tooltip
            arrow
            title={
              <div
                style={{ whiteSpace: 'pre-line' }}
              >{`${counterParty.name}\n${counterParty.iban}`}</div>
            }
          >
            <Typography variant="body2" noWrap>
              {counterParty.name || counterParty.iban}
            </Typography>
          </Tooltip>
        );
      },
    }),
    {
      field: 'amount',
      headerName: 'Suma',
      flex: 1,
      align: 'right',
      renderCell: ({ row }) => (
        <Tooltip arrow title={fEur(row.amount)}>
          <Typography variant="body2" noWrap>
            {fEur(row.amount)}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'variableSymbol',
      headerName: 'Variabilný symbol',
      flex: 1,
      renderCell: ({ row }) => (
        <Tooltip arrow title={row.variableSymbol}>
          <Typography variant="body2" noWrap>
            {row.variableSymbol}
          </Typography>
        </Tooltip>
      ),
    },

    {
      field: 'note',
      headerName: 'Správa',
      flex: 1,
      renderCell: ({ row }) => (
        <Tooltip arrow title={row.note}>
          <Typography variant="body2" noWrap>
            {row.note}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'date',
      headerName: 'Dátum',
      flex: 1,
      renderCell: ({ row }) => (
        <Tooltip arrow title={fDateTime(row.date)}>
          <Typography variant="body2" noWrap>
            {fDate(row.date)}
          </Typography>
        </Tooltip>
      ),
    },
    ...customCols,
  ];
};

interface IPaymentsTable extends IpaymentColumns {
  payments: Array<Payment & { id: GridRowId }>;
}

export const PaymentsTable: FC<IPaymentsTable> = ({ payments, ...colprops }) => {
  return <DataGrid hideFooter autoHeight columns={paymentColumns(colprops)} rows={payments} />;
};
