import { Card, CardHeader, CardContent, Stack, Typography, CardActions } from '@mui/material';
import { FC } from 'react';
import { CompleteSubscriptionType } from '../../ctx/subscriptions/Subscriptions.Context';
import { fEur } from '../../utils/formatNumber';
import { SubscriptionTypeAvatar } from '../../utils/stringAvatar';

const SubscriptionTypeInfoCard: FC<CompleteSubscriptionType> = (subscriptionTypeData) => {
  return (
    <Card>
      <CardHeader
        title={subscriptionTypeData.caption}
        avatar={<SubscriptionTypeAvatar subscriptionType={subscriptionTypeData} />}
        subheader={
          <Stack direction="column">
            <Typography variant="caption">Cena: {fEur(subscriptionTypeData.price)}</Typography>
            <Typography variant="caption">Prefix: {subscriptionTypeData.prefix}</Typography>
            <Typography variant="caption">
              Deň splatnosti: {subscriptionTypeData.dayInMonth}
            </Typography>
          </Stack>
        }
      />
      <CardActions />
    </Card>
  );
};

export default SubscriptionTypeInfoCard;
