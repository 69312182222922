export function getSafe(fn) {
  try {
    return fn();
  } catch (e) {
    return ' ';
  }
}
const dateOptions: any = { year: 'numeric', month: 'numeric', day: 'numeric' };

export const getDbitItem = (ntry: any) => ({
  date: new Date(getSafe(() => ntry.ValDt.Dt)).toLocaleString('sk-SK', dateOptions),
  cash: 'BÚ',
  partner: getSafe(() => ntry.NtryDtls.TxDtls.RltdPties.Dbtr.Nm),
  sum: getSafe(() => ntry.Amt['#text']).replace('.', ','),
  message: getSafe(() => ntry.NtryDtls.TxDtls.RmtInf.Ustrd),
  code: ' ',
  who: getSafe(() => ntry.NtryDtls.TxDtls.RltdPties.InitgPty.Nm),
  where: getSafe(() => ntry.NtryDtls.TxDtls.RltdAgts.Prtry[0].Agt.BrnchId.Nm),
  address: getSafe(() => ntry.NtryDtls.TxDtls.RltdAgts.Prtry[0].Agt.BrnchId.PstlAdr.AdrLine),
});

export const getCrdtItem = (ntry: any) => ({
  date: new Date(getSafe(() => ntry.ValDt.Dt)).toLocaleString('sk-SK', dateOptions),
  cash: 'BÚ',
  partner: getSafe(() => ntry.NtryDtls.TxDtls.RltdPties.Dbtr.Nm),
  sum: getSafe(() => ntry.Amt['#text']).replace('.', ','),
  message: getSafe(() => ntry.NtryDtls.TxDtls.RmtInf.Ustrd),
  code: ' ',
  state: getSafe(() => ntry.NtryDtls.TxDtls.RltdPties.Dbtr.PstlAdr.Ctry),
  street: getSafe(() => ntry.NtryDtls.TxDtls.RltdPties.Dbtr.PstlAdr.AdrLine[0]),
  city: getSafe(() => ntry.NtryDtls.TxDtls.RltdPties.Dbtr.PstlAdr.AdrLine[1]),
});

function download(data, filename) {
  const file = new Blob([data], { type: 'text/csv;charset=utf8;' });
  const a = document.createElement('a');
  const url = URL.createObjectURL(file);
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
}

export const downloadFileForSheets = (
  startDate: Date,
  endDate: Date,
  crdtArray: any,
  dbitArray: any
) => {
  const startDateString = startDate
    .toLocaleString('ko-KR', dateOptions)
    .replace(/ /g, '-')
    .replace(/\./g, '');
  const endDateString = endDate
    .toLocaleString('ko-KR', dateOptions)
    .replace(/ /g, '-')
    .replace(/\./g, '');
  const dateTag = `${startDateString}_${endDateString}`;

  if (crdtArray.length) {
    const replacerCrdt = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const headerCrdt = Object.keys(crdtArray[0]);
    let csvCrdt = crdtArray.map((row) =>
      headerCrdt.map((fieldName) => JSON.stringify(row[fieldName], replacerCrdt)).join(',')
    );
    csvCrdt = csvCrdt.join('\r\n');
    download(csvCrdt, `${dateTag}_prijmy.csv`);
  }

  if (dbitArray.length) {
    const replacerDbit = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const headerDbit = Object.keys(dbitArray[0]);
    let csvDbit = dbitArray.map((row) =>
      headerDbit.map((fieldName) => JSON.stringify(row[fieldName], replacerDbit)).join(',')
    );
    csvDbit = csvDbit.join('\r\n');
    download(csvDbit, `${dateTag}_vydavky.csv`);
  }
};
