import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import LoginForm from '../components/login/LoginForm';
import Logo from '../layouts/authorized/Logo';

const Login: FC = () => {
  const [carousel, setcarousel] = useState<HTMLElement>();
  const [selectedIndex, setselectedIndex] = useState(0);
  const cellCount = 9;

  useEffect(() => {
    setcarousel(document.querySelector('.carousel') as HTMLElement);
    // setInterval(decreaseSelectedIndex, 400);
  }, []);

  const rotateCarousel = useCallback(() => {
    if (!carousel) return;
    const angle = (selectedIndex / cellCount) * -360;
    carousel.style.transform = `translateZ(-288px) rotateY(${angle}deg)`;
  }, [carousel, selectedIndex]);

  const increaseSelectedIndex = () => setselectedIndex((p) => p + 1);
  const decreaseSelectedIndex = () => setselectedIndex((p) => p - 1);

  useEffect(() => {
    rotateCarousel();
  }, [rotateCarousel]);

  return (
    <Grid container alignItems="stretch" sx={{ height: '100%' }} spacing={5}>
      <Grid item md={6} container alignItems="center" justifyContent="flex-start">
        <Grid item xs={12}>
          <div className="scene">
            <Box
              sx={{ height: '100%', zIndex: 100000 }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {/* <Typography variant="h1" color="secondary">
                KANis
              </Typography> */}
              <Box
                sx={{
                  fontFamily: 'Monospace',
                  fontSize: '100px',
                  // fontSize: 'h1.fontSize',
                  color: 'primary.main',
                  fontWeight: 'bold',
                }}
              >
                KAN
              </Box>
              <Box
                sx={{
                  fontFamily: 'Monospace',
                  fontSize: '100px',
                  // fontSize: 'h1.fontSize',
                  color: 'secondary.main',
                  fontWeight: 'bold',
                }}
              >
                is
              </Box>
            </Box>
            <div className="carousel">
              <div className="carousel__cell">
                <Logo secondary sx={{ width: '100%', zIndex: 1, color: 'secondary.main' }} />
              </div>
              <div className="carousel__cell" />
              <div className="carousel__cell" />
              <div className="carousel__cell">
                <Logo sx={{ width: '100%' }} />
              </div>
              <div className="carousel__cell" />
              <div className="carousel__cell" />
              <div className="carousel__cell">
                <Logo sx={{ width: '100%' }} />
              </div>
              <div className="carousel__cell" />
              <div className="carousel__cell" />
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid item md={6} container alignItems="center">
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <LoginForm />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
