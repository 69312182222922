import { Typography, Grid } from '@mui/material';
import { FC } from 'react';
import { BasicContactInfo } from '../../ctx/Claims.Context';

interface IContactBasicInfoCard {
  basicContactInfoData: BasicContactInfo;
}

const ContactBasicInfoCard: FC<IContactBasicInfoCard> = ({ basicContactInfoData }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>{basicContactInfoData.address?.street}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          {basicContactInfoData.address?.zip} {basicContactInfoData.address?.city}
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography>{basicContactInfoData.address?.state}</Typography>
      </Grid>
      {basicContactInfoData.address &&
        (basicContactInfoData.email ||
          basicContactInfoData.phoneNumber ||
          basicContactInfoData.iban) && <Grid item xs={12} sx={{ m: 1 }} />}
      {basicContactInfoData.email && (
        <Grid item xs={12}>
          <Typography>{basicContactInfoData.email}</Typography>
        </Grid>
      )}
      {basicContactInfoData.phoneNumber && (
        <Grid item xs={12}>
          <Typography>{basicContactInfoData.phoneNumber}</Typography>
        </Grid>
      )}
      {basicContactInfoData.iban && (
        <Grid item xs={12}>
          <Typography>{basicContactInfoData.iban}</Typography>
        </Grid>
      )}
      {/* {Object.keys(basicContactInfoData).some((k) =>
        ['otherEmails', 'otherAddresses', 'otherPhoneNumbers', 'otherIbans'].includes(k)
      ) && (  */}
      {['otherEmails', 'otherAddresses', 'otherPhoneNumbers', 'otherIbans'].some(
        (k) => basicContactInfoData[k]?.length > 0
      ) && (
        <>
          <Grid item xs={12} sx={{ m: 1 }} />
          {basicContactInfoData.otherEmails?.map((oe, i) => (
            <Grid key={`oe-${i}`} item xs={12}>
              <Typography>{oe}</Typography>
            </Grid>
          ))}
          {basicContactInfoData.otherPhoneNumbers?.map((op, i) => (
            <Grid key={`op-${i}`} item xs={12}>
              <Typography>{op}</Typography>
            </Grid>
          ))}
          {basicContactInfoData.otherIbans?.map((oi, i) => (
            <Grid key={`oi-${i}`} item xs={12}>
              <Typography>{oi}</Typography>
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );
};

export default ContactBasicInfoCard;
