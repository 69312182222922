import { Card, CardContent, Chip, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DateRangeIcon from '@mui/icons-material/DateRange';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import Popper from '@mui/material/Popper';
import { usePopupState, bindHover, bindPopper } from 'material-ui-popup-state/hooks';
import Fade from '@mui/material/Fade';
import { ClaimState } from '../../ctx/Claims.Context';
import AccountLink from '../links/AccountLink';
import { fDateTime } from '../../utils/formatTime';

const ClaimStateChip: FC<ClaimState> = (claimState) => {
  const popupState = usePopupState({ variant: 'popper', popupId: 'demoPopper' });

  return (
    <>
      {claimState.title === 'Čakajúca' && (
        <Chip
          {...bindHover(popupState)}
          icon={<PendingIcon />}
          label="Čakajúca"
          color="warning"
          variant="filled"
        />
      )}
      {claimState.title === 'Zaplatená' && (
        <Chip
          {...bindHover(popupState)}
          icon={<CheckCircleIcon />}
          label="Zaplatená"
          color="success"
          variant="filled"
        />
      )}
      {claimState.title === 'Zrušená' && (
        <Chip
          {...bindHover(popupState)}
          icon={<CancelIcon />}
          label="Zrušená"
          color="error"
          variant="filled"
        />
      )}
      <Popper {...bindPopper(popupState)} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Card sx={{ backgroundColor: 'white', backdropFilter: 'blur(5px)' }}>
              <CardContent>
                <Stack direction="column" spacing={2}>
                  <Stack direction="row" spacing={2}>
                    <AccountCircleIcon />
                    <AccountLink id={claimState.addedBy} />
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <DateRangeIcon />
                    <Typography>{fDateTime(claimState.date)}</Typography>
                  </Stack>
                  {claimState.note && (
                    <Stack direction="row" spacing={2}>
                      <TextSnippetIcon />
                      <Typography>{claimState.note}</Typography>
                    </Stack>
                  )}
                </Stack>
              </CardContent>
            </Card>
          </Fade>
        )}
      </Popper>
    </>
  );
};
export default ClaimStateChip;
