import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFeedback } from './Feedback';
import { useApi } from './Api';
import { ClaimStateEnum, CompleteClaim } from './Claims.Context';
import { Email, EmailOptions } from './subscriptions/Subscription.Context';

interface IClaimContext {
  claimData?: CompleteClaim;
  pullData: () => Promise<CompleteClaim>;
  setPending: (note?: string) => Promise<CompleteClaim>;
  setPaid: (note?: string) => Promise<CompleteClaim>;
  setCancelled: (note?: string) => Promise<CompleteClaim>;
  downloadInvoice: () => void;
  downloadCreditNote: () => void;
  createCreditNote: () => Promise<void>;
  deleteClaim: () => Promise<boolean>;
  previewInvoiceEmail: () => Promise<Email>;
  sendInvoiceEmail: (email: EmailOptions) => Promise<CompleteClaim>;
}

const ClaimContext = createContext<IClaimContext>({
  claimData: undefined,
  pullData: () => undefined,
  setPending: () => undefined,
  setPaid: () => undefined,
  setCancelled: () => undefined,
  downloadInvoice: () => undefined,
  createCreditNote: () => undefined,
  deleteClaim: () => undefined,
  downloadCreditNote: () => undefined,
  previewInvoiceEmail: () => undefined,
  sendInvoiceEmail: () => undefined,
});

export const useClaim = () => {
  const context = useContext(ClaimContext);
  if (!context) {
    throw new Error('Parent must be wrapped inside ClaimProvider');
  }
  return context;
};

interface IClaimProvider {
  id: string;
  dataProvider?: boolean;
  initialData?: CompleteClaim;
}

export const ClaimProvider: FC<IClaimProvider> = ({
  id,
  dataProvider = true,
  children,
  initialData,
}) => {
  const { API, defaultErrorHandle } = useApi();
  const { success } = useFeedback();

  const [claimData, setClaimData] = useState<CompleteClaim>(initialData);

  const pullData = useCallback(async (): Promise<CompleteClaim> => {
    if (!API) return undefined;
    console.log('pulling claim');
    try {
      const res = await API.get(`claims/${id}`);
      console.log(res);
      if (dataProvider) setClaimData(res.data);
      return res.data;
    } catch (e: any) {
      defaultErrorHandle(e);
      return undefined;
    }
  }, [API, dataProvider, defaultErrorHandle, id]);

  useEffect(() => {
    if (dataProvider) pullData();
  }, [dataProvider, pullData]);

  const setClaimState = useCallback(
    async (state: ClaimStateEnum, note?: string): Promise<CompleteClaim> => {
      let endPoint: string;
      switch (state) {
        case ClaimStateEnum.pending:
          endPoint = 'setpending';
          break;
        case ClaimStateEnum.paid:
          endPoint = 'setpaid';
          break;
        case ClaimStateEnum.cancelled:
          endPoint = 'setcancelled';
          break;
        default:
          break;
      }
      try {
        const res = await API.post(`claims/${id}/${endPoint}`, { note });
        if (dataProvider) setClaimData(res.data);
        return res.data;
      } catch (e: any) {
        defaultErrorHandle(e);
        return undefined;
      }
    },
    [API, dataProvider, defaultErrorHandle, id]
  );

  const setPending = useCallback(
    async (note?: string) => setClaimState(ClaimStateEnum.pending, note),
    [setClaimState]
  );
  const setPaid = useCallback(
    async (note?: string) => setClaimState(ClaimStateEnum.paid, note),
    [setClaimState]
  );
  const setCancelled = useCallback(
    async (note?: string) => setClaimState(ClaimStateEnum.cancelled, note),
    [setClaimState]
  );

  const downloadInvoice = useCallback(() => {
    API.get(`claims/${id}/invoice`, { responseType: 'blob' }).then((response) => {
      console.log(response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', claimData.invoice.filename);
      document.body.appendChild(link);
      link.click();
    });
  }, [API, claimData, id]);

  const downloadCreditNote = useCallback(() => {
    if (!claimData.creditNote) return;
    API.get(`claims/${id}/creditNote`, { responseType: 'blob' }).then((response) => {
      console.log(response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', claimData.creditNote.filename);
      document.body.appendChild(link);
      link.click();
    });
  }, [API, claimData, id]);

  const createCreditNote = useCallback(async () => {
    const res = await API.post(`claims/${id}/createcreditnote`);
    console.log(res);
    pullData();
  }, [API, id, pullData]);

  const previewInvoiceEmail = useCallback(async (): Promise<Email> => {
    if (!API) return undefined;
    try {
      const res = await API.get(`claims/${id}/previewInvoiceEmail`);
      console.log(res);
      return res.data;
    } catch (e: any) {
      defaultErrorHandle(e);
      return undefined;
    }
  }, [API, defaultErrorHandle, id]);

  const sendInvoiceEmail = useCallback(
    async (emailOptions: EmailOptions): Promise<CompleteClaim> => {
      if (!API) return undefined;
      try {
        const res = await API.post(`claims/${id}/sendInvoiceEmail`, emailOptions);
        console.log(res);
        if (dataProvider) setClaimData(res.data);
        return res.data;
      } catch (e: any) {
        defaultErrorHandle(e);
        return undefined;
      }
    },
    [API, dataProvider, defaultErrorHandle, id]
  );

  const deleteClaim = useCallback(async (): Promise<boolean> => {
    if (!API) return false;
    try {
      const res = await API.delete(`claims/${id}`);
      success('Pohľadávka zmazaná');
      return true;
    } catch (e: any) {
      defaultErrorHandle(e);
      return false;
    }
  }, [API, defaultErrorHandle, id, success]);

  const contextObjects = useMemo(
    () => ({
      claimData,
      pullData,
      setPending,
      setPaid,
      setCancelled,
      downloadInvoice,
      downloadCreditNote,
      createCreditNote,
      previewInvoiceEmail,
      sendInvoiceEmail,
      deleteClaim,
    }),
    [
      claimData,
      pullData,
      setPending,
      setPaid,
      setCancelled,
      downloadInvoice,
      downloadCreditNote,
      createCreditNote,
      previewInvoiceEmail,
      sendInvoiceEmail,
      deleteClaim,
    ]
  );

  return <ClaimContext.Provider value={contextObjects}>{children}</ClaimContext.Provider>;
};
