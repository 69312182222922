import { Fragment, useEffect, useState } from 'react';
import { Button, Grid, Typography, Stack, Box } from '@mui/material';
import { Add, FilterAlt, FilterAltOff } from '@mui/icons-material';
import { useContactFilters } from '../../ctx/contacts/ContactFilters.Context';
import TagFilterDisplayer from './filters/TagFilterDisplayer';
import AndOrSelector from './filters/AndOrSelector';
import RightDrawer from '../RightDrawer';
import ContactFiltersSelector from './filters/ContactFiltersSelector';
import OutlinedSection from '../OutlinedSection';
import { useContacts } from '../../ctx/contacts/Contacts.Context';
import { CompleteIndividual, isIndividual } from '../../ctx/contacts/Contact.Context';
import ClaimGroupFilterDisplayer from './filters/ClaimGroupFilterDisplayer';

export const ContcatFiltersDisplaySelected = () => {
  const { tagFilter, listCampaignFilter, claimFilter, claimGroupFilter, isAnd, setIsAnd } =
    useContactFilters();

  const panels = [tagFilter, listCampaignFilter, claimFilter, claimGroupFilter].reduce(
    (p, s) => p + (s.filters.length > 0 ? 1 : 0),
    0
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {[<TagFilterDisplayer key="hola" />, <ClaimGroupFilterDisplayer key="dohola" />].map(
          (c, i) => (
            <Fragment key={`selected-${i}`}>
              {c}
              <Box sx={{ pl: 3 }}>
                <AndOrSelector visible={i + 1 < panels} value={isAnd} valueSetter={setIsAnd} />
              </Box>
            </Fragment>
          )
        )}
      </Grid>
    </Grid>
  );
};

export const ContactFilters = () => {
  const { getContacts, clearAllFilters } = useContactFilters();

  const { setCurrentContacts, pulAllContacts } = useContacts();

  const [addingFilter, setAddingFilter] = useState<boolean>(false);
  const handleStartAddingFilter = () => setAddingFilter(true);
  const handleStopAddingFilter = () => setAddingFilter(false);

  const handleConfirmFilters = async () => {
    const contacts = await getContacts();
    setCurrentContacts(contacts.filter((c) => isIndividual(c)) as CompleteIndividual[]);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <OutlinedSection label="Filtrovať">
            <Grid container spacing={1}>
              <Grid item xs={12} container spacing={1}>
                <Grid item>
                  <Button variant="outlined" onClick={handleStartAddingFilter} startIcon={<Add />}>
                    Filter
                  </Button>
                </Grid>
                <Grid item xs />
                <Grid item>
                  <Button variant="outlined" onClick={clearAllFilters} startIcon={<FilterAltOff />}>
                    Zrušiť
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    sx={{ ml: 'auto' }}
                    variant="outlined"
                    onClick={handleConfirmFilters}
                    startIcon={<FilterAlt />}
                  >
                    Zobraziť
                  </Button>
                </Grid>{' '}
                <Grid item>
                  <Button variant="outlined" onClick={() => pulAllContacts()}>
                    Zobraziť všetky
                  </Button>
                </Grid>
              </Grid>
              {/* <Grid item xs={12}>
            
          </Grid> */}
            </Grid>
          </OutlinedSection>
        </Grid>
        {/* <Grid item xs={12}>
          {[<TagFilterDisplayer key="hola" />].map((c, i) => (
            <Fragment key={`selected-${i}`}>
              {c}
              <Box sx={{ pl: 3 }}>
                <AndOrSelector visible={i + 1 < panels} value={isAnd} valueSetter={setIsAnd} />
              </Box>
            </Fragment>
          ))}
        </Grid> */}
      </Grid>
      <RightDrawer isOpened={addingFilter} handleClose={handleStopAddingFilter}>
        <Stack sx={{ m: 3 }} direction="column" spacing={3}>
          <Typography>Pridať filter</Typography>
          <Box>
            <ContactFiltersSelector />
          </Box>
        </Stack>
      </RightDrawer>
    </>
  );
};
