import { Outlet, useLocation, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { useApi } from '../ctx/Api';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------
const UnauthorizedLayout = () => {
  const navigate = useNavigate();

  const { search } = useLocation();
  const next = new URLSearchParams(search).get('next');

  const { isLoggedIn } = useApi();

  useEffect(() => {
    if (isLoggedIn) navigate(next || '/');
  }, [isLoggedIn, navigate, next]);
  return <Outlet />;
};

export default UnauthorizedLayout;
