import { FC } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { fDateTime } from '../../utils/formatTime';

interface Request {
  createdAt: string;
  email: string;
  eshop_type: string;
  eshop_url: string;
  first_name: string;
  id: string;
  last_name: string;
  updatedAt: string;
}

const requestColumns: GridColDef[] = [
  {
    field: 'createdAt',
    headerName: 'Dátum',
    flex: 1,
    renderCell: (params) => <>{fDateTime(params.row.createdAt)}</>,
  },
  {
    field: 'eshop_url',
    headerName: 'Link eshopu',
    flex: 1,
  },
  {
    field: 'eshop_type',
    headerName: 'Technológia eshopu',
    flex: 1,
  },
  {
    field: 'contact',
    headerName: 'Kontaktná osoba',
    renderCell: (params) => (
      <>
        {params.row.first_name} {params.row.last_name}
      </>
    ),
    flex: 1,
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
  },
];

interface IRequestsTable {
  requests: Array<Request>;
}

export const RequestsTable: FC<IRequestsTable> = ({ requests }) => {
  return <DataGrid autoHeight columns={requestColumns} rows={requests} />;
};
