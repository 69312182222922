import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFeedback } from '../Feedback';
import { useApi } from '../Api';
import { CompleteContact } from '../../components/contact/IndividualBusinessCard';
import { CompletePayment } from '../PaymentProcess.Context';
import { Email } from './Subscription.Context';
import { IEmailPreviewData } from '../Claims.Context';

export enum SubscriptionStateEnum {
  pending = 'Čakajúce',
  paid = 'Zaplatené',
  delayed = 'Omeškané',
  inAdvance = 'Zap. vopred',
}

export interface SubscriptionState {
  date: Date;
  title: SubscriptionStateEnum;
  delayedTimes: number;
  payments: number;
  forgivedPayments: number;
  expectedPayments: number;
}

export interface Subscription {
  subscriptionType: CompleteSubscriptionType;
  price: number;
  start: Date;
  end: Date;
  payer?: string;
}

export interface ForgivmentPayment {
  date: Date;
  reason: string;
}
export interface CompleteForgivmentPayment extends ForgivmentPayment {
  _id: string;
}

export enum VariableSubscriptionStateEnum {
  paid = 'Zaplatené',
  paidLess = 'Zaplatené menej',
  paidMore = 'Zaplatené viac',
  cantTell = 'Nemožno vypočítať',
}

export interface ActualVariableSubscriptionState {
  date: Date;
  title: VariableSubscriptionStateEnum;
  howMuch: number;
  sumPaid: number;
  sumToBePaid: number;
}

export interface CompleteSubscription extends Omit<Subscription, 'payer'> {
  _id: string;
  owner: string;
  urlId: string;
  qrCodeText: string;
  isActive: boolean;
  actualState: SubscriptionState;
  variableSymbol: string;
  payer: CompleteContact;
  payments: CompletePayment[];
  expectedPayments: number[];
  actualVariableState: ActualVariableSubscriptionState | undefined;
  emails: Email[];
}

export interface SubscriptionType {
  price: number;
  caption: string;
  dayInMonth: number;
  prefix: string;
}

export interface CompleteSubscriptionType extends SubscriptionType {
  _id: string;
}

interface ISubscriptionsContext {
  currentSubscriptions: CompleteSubscription[];
  subscriptionTypes: CompleteSubscriptionType[];
  currentSubscriptionType: CompleteSubscriptionType | undefined;
  setCurrentSubscriptionTypeId(y: string): void;
  isLoading: boolean;
  submitNewSubscriptionType(subscriptionType: SubscriptionType): Promise<boolean>;
  pullCurrentSubscriptions(): void;
  selectedSubscriptions: string[];
  setSelectedSubscriptions: (sontacts: string[]) => void;
  deleteSubscriptionType: (id: string) => void;
  deleteSelected: () => Promise<void>;
  setCurrentPaymentForSelected: (payment: number) => Promise<void>;
  previewSubscriptionsEmails: (ids: string[]) => Promise<IEmailPreviewData>;
  sendSubscriptionsEmails: (emails: IEmailPreviewData) => Promise<void>;
}

const SubscriptionsContext = createContext<ISubscriptionsContext>({
  currentSubscriptions: [],
  subscriptionTypes: [],
  currentSubscriptionType: undefined,
  setCurrentSubscriptionTypeId: () => undefined,
  selectedSubscriptions: undefined,
  setSelectedSubscriptions: () => undefined,
  deleteSubscriptionType: () => undefined,
  isLoading: false,
  pullCurrentSubscriptions: () => undefined,
  submitNewSubscriptionType: async () => false,
  deleteSelected: async () => undefined,
  setCurrentPaymentForSelected: async () => undefined,
  previewSubscriptionsEmails: () => undefined,
  sendSubscriptionsEmails: () => undefined,
});

export const useSubscriptions = () => {
  const context = useContext(SubscriptionsContext);
  if (!context) {
    throw new Error('Parent must be wrapped inside SubscriptionsProvider');
  }
  return context;
};

export interface ISubscriptionsProvider {
  id?: string;
}

export const SubscriptionsProvider: FC<ISubscriptionsProvider> = ({ id, children }) => {
  const { API, defaultErrorHandle } = useApi();
  const { error, success } = useFeedback();

  const [subscriptionTypes, setSubscriptionTypes] = useState<CompleteSubscriptionType[]>([]);
  const [currentSubscriptionTypeId, setCurrentSubscriptionTypeId] = useState<string>('');
  const [currentSubscriptionType, setCurrentSubscriptionType] =
    useState<CompleteSubscriptionType>();

  useEffect(() => {
    setCurrentSubscriptionTypeId(id);
    setCurrentSubscriptionType(subscriptionTypes.find((ay) => ay._id === id));
  }, [currentSubscriptionTypeId, id, subscriptionTypes]);

  // const currentSubscriptionType = subscriptionTypes.find(
  //   (ay) => ay._id === currentSubscriptionTypeId
  // );

  const [currentSubscriptions, setCurrentSubscriptions] = useState<CompleteSubscription[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedSubscriptions, setSelectedSubscriptions] = useState<string[]>([]);

  const pullCurrentSubscriptions = useCallback(() => {
    if (!API || !currentSubscriptionType) return;
    setIsLoading(true);
    API.get(`/subscriptionType/${currentSubscriptionTypeId}/subscriptions`)
      .then((res) => {
        // console.log(res.data);
        setCurrentSubscriptions(res.data);
        setIsLoading(false);
      })
      .catch((e: any) => {
        setIsLoading(false);
        error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
      });
  }, [currentSubscriptionTypeId, API, currentSubscriptionType, error]);

  useEffect(pullCurrentSubscriptions, [pullCurrentSubscriptions]);

  const pullTypes = useCallback(() => {
    if (!API) return;
    API.get(`/subscriptionType`)
      .then((res) => {
        console.log(res.data);
        setSubscriptionTypes(res.data);
        if (res.data.length === 0) return;
        setCurrentSubscriptionTypeId(res.data[0]?._id);
      })
      .catch((e: any) => {
        error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
      });
  }, [API, error]);

  useEffect(pullTypes, [pullTypes]);

  const submitNewSubscriptionType = useCallback(
    async (subscriptionType: SubscriptionType): Promise<boolean> => {
      if (!API) return false;
      console.log(subscriptionType);
      try {
        const res = await API.post('subscriptionType', subscriptionType);
        success('Typ predplatného pridaný');
        pullTypes();
        return true;
      } catch (e: any) {
        error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
        return false;
      }
    },
    [API, error, pullTypes, success]
  );
  const deleteSubscriptionType = useCallback(
    async (id: string): Promise<boolean> => {
      if (!API) return false;
      try {
        const res = await API.delete(`subscriptionType/${id}`);
        console.log(res);
        success('Typ predplatného zmazaný');
        pullTypes();
        return true;
      } catch (e: any) {
        error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
        return false;
      }
    },
    [API, error, pullTypes, success]
  );

  const deleteSelected = useCallback(async () => {
    try {
      const res = await Promise.all(
        selectedSubscriptions.map(async (ss) => {
          const res = await API.delete(`subscriptions/${ss}`);
          return res.data;
        })
      );
      console.log(res);
      pullCurrentSubscriptions();
    } catch (e: any) {
      defaultErrorHandle(e);
    }
  }, [API, defaultErrorHandle, pullCurrentSubscriptions, selectedSubscriptions]);

  const setCurrentPaymentForSelected = useCallback(
    async (payment: number) => {
      try {
        const res = await Promise.all(
          selectedSubscriptions.map(async (ss) => {
            const subscription = currentSubscriptions.find((s) => s._id === ss);
            if (!subscription) return undefined;
            const currentPaymentIndex = subscription.actualState.expectedPayments - 1;
            const newExpectedPayments = subscription.expectedPayments;
            newExpectedPayments.splice(currentPaymentIndex, 1, payment);

            const res = await API.put(`subscriptions/${ss}/expectedpayments`, {
              expectedPayments: newExpectedPayments,
            });
            return res.data;
          })
        );
        console.log(res);
        pullCurrentSubscriptions();
      } catch (e: any) {
        defaultErrorHandle(e);
      }
    },
    [API, currentSubscriptions, defaultErrorHandle, pullCurrentSubscriptions, selectedSubscriptions]
  );

  const previewSubscriptionsEmails = useCallback(
    async (ids: string[]) => {
      if (!API) return undefined;
      console.log(ids);
      try {
        const res = await API.post(`/subscriptions/previewSubscriptionsEmails`, { ids });
        console.log(res);
        return res.data;
      } catch (e: any) {
        defaultErrorHandle(e);
        return undefined;
      }
    },
    [API, defaultErrorHandle]
  );

  const sendSubscriptionsEmails = useCallback(
    async (emails: IEmailPreviewData) => {
      if (!API) return undefined;
      try {
        const res = await API.post(`/subscriptions/sendSubscriptionsEmails`, emails);
        console.log(res);
        success(`Podarilo sa odoslať ${res.data.sent} z ${res.data.from}`);
        return res.data;
      } catch (e: any) {
        defaultErrorHandle(e);
        return undefined;
      }
    },
    [API, defaultErrorHandle, success]
  );

  const contextObjects = useMemo(
    () => ({
      currentSubscriptions,
      subscriptionTypes,
      currentSubscriptionType,
      setCurrentSubscriptionTypeId,
      isLoading,
      submitNewSubscriptionType,
      pullCurrentSubscriptions,
      selectedSubscriptions,
      setSelectedSubscriptions,
      deleteSelected,
      setCurrentPaymentForSelected,
      deleteSubscriptionType,
      previewSubscriptionsEmails,
      sendSubscriptionsEmails,
    }),
    [
      currentSubscriptions,
      subscriptionTypes,
      currentSubscriptionType,
      isLoading,
      submitNewSubscriptionType,
      pullCurrentSubscriptions,
      selectedSubscriptions,
      deleteSelected,
      setCurrentPaymentForSelected,
      deleteSubscriptionType,
      previewSubscriptionsEmails,
      sendSubscriptionsEmails,
    ]
  );

  return (
    <SubscriptionsContext.Provider value={contextObjects}>{children}</SubscriptionsContext.Provider>
  );
};
