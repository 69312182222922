import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'counter',
  initialState: {
    token: {},
  },
  reducers: {
    storeToken: (state, newState) => {
      state.token = newState.payload;
    },
    clearToken: (state, _) => {
      state.token = {};
    },
  },
});

export const { storeToken, clearToken } = slice.actions;

export const getToken = (state: any) => state.counter.token;

export default slice.reducer;
