import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

LogoKan.propTypes = {
  sx: PropTypes.object,
  secondary: PropTypes.bool,
};

// eslint-disable-next-line react/function-component-definition
export default function LogoKan({ sx, secondary = false }) {
  return secondary ? (
    <Box
      component="img"
      src="/assets/logo-secondary.svg"
      sx={{ width: 80, color: '#00466F', ...sx }}
    />
  ) : (
    <Box
      component="img"
      src="/assets/logo_KAN_cropped.svg"
      sx={{ width: 80, color: '#00466F', ...sx }}
    />
  );
}
