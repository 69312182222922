import React from 'react';
import { SvgIcon } from '@mui/material';
import { ReactComponent as AvatarMan } from './svg/avatar-man.svg';

const AvatarManIcon = (props) => (
  <SvgIcon {...props}>
    <AvatarMan />
  </SvgIcon>
);

export default AvatarManIcon;
