import { FC, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  Collapse,
  Grid,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import { useParams } from 'react-router-dom';
import { Delete, Edit, ExpandLess, ExpandMore, Label, Mail } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { NewSubscriptionProvider } from '../ctx/subscriptions/NewSubscription.Context';
import {
  SubscriptionsProvider,
  useSubscriptions,
} from '../ctx/subscriptions/Subscriptions.Context';
import { SubscriptionsTable } from '../components/subscriptions/SubscriptionsTable';
import NewSubscription from '../components/subscriptions/NewSubscription';
import RightDrawer from '../components/RightDrawer';
import { IEmailPreviewData } from '../ctx/Claims.Context';
import EmailOptionsForm from '../components/email/forms/EmailForm';
import { modalStyle } from './Claim';

const SubscriptionTypeWrapped: FC = () => {
  const [addingSubscription, setAddingSubscription] = useState<boolean>(false);
  const handleStartAddingSubscription = () => setAddingSubscription(true);
  const handleStopAddingSubscription = () => setAddingSubscription(false);

  const [massActions, setMassActions] = useState<boolean>(false);
  const handleStartMassActions = () => setMassActions(true);
  const handleStopMassActions = () => setMassActions(false);

  const [settingActualPayment, setSettingActualPayment] = useState<boolean>(false);
  const handleStartSettingActualPayment = () => {
    setNewActualPayment(currentSubscriptionType.price);
    setSettingActualPayment(true);
  };
  const handleStopSettingActualPayment = () => setSettingActualPayment(false);

  const [newActualPayment, setNewActualPayment] = useState<number>();

  const {
    currentSubscriptionType,
    currentSubscriptions,
    pullCurrentSubscriptions,
    selectedSubscriptions,
    setSelectedSubscriptions,
    setCurrentPaymentForSelected,
    deleteSelected,
    previewSubscriptionsEmails,
    sendSubscriptionsEmails,
  } = useSubscriptions();

  const [emailPreviewData, setEmailPreviewData] = useState<IEmailPreviewData>();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} spacing={2} container>
          <Grid item>
            <Typography variant="h4">Predplatné:</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h4">
              {currentSubscriptionType?.caption} ({currentSubscriptionType?.prefix})
            </Typography>
          </Grid>
          <Grid item xs />
          <Grid item>
            <Button
              variant="outlined"
              startIcon={!massActions ? <ExpandMore /> : <ExpandLess />}
              onClick={massActions ? handleStopMassActions : handleStartMassActions}
            >
              Hromadné akcie
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              startIcon={<AddIcon />}
              disabled={addingSubscription}
              onClick={handleStartAddingSubscription}
            >
              Nové predplatné
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ ...(!massActions && { pt: '0 !important' }), transition: 'all 1s' }}
        >
          <Collapse
            in={massActions}
            sx={{
              transition: 'all 2s',
              boxShadow: '0px 0px 0px 1px rgba(224, 224, 224, 0)',
              backgroundColor: 'rgba(224, 224, 224, 0)',
              outline: 'solid 1px rgba(224, 224, 224, 1)',
            }}
            component={Card}
          >
            <CardContent>
              <Stack direction="row-reverse" spacing={1}>
                <Button
                  variant="outlined"
                  startIcon={<Mail />}
                  onClick={async () => {
                    const emailPreview = await previewSubscriptionsEmails(selectedSubscriptions);
                    console.log(emailPreview);
                    setEmailPreviewData(emailPreview);
                  }}
                >
                  Email
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<Edit />}
                  onClick={handleStartSettingActualPayment}
                >
                  Aktuálna platba
                </Button>
                <Button variant="outlined" startIcon={<Delete />} onClick={deleteSelected}>
                  Zmazať
                </Button>
              </Stack>
            </CardContent>
          </Collapse>
        </Grid>
        {addingSubscription && (
          <Grid item xs={12}>
            <Collapse in={addingSubscription}>
              <NewSubscriptionProvider
                subscriptionType={currentSubscriptionType}
                onNewSubscriptionSuccess={pullCurrentSubscriptions}
              >
                <NewSubscription handleStopAdding={handleStopAddingSubscription} />
              </NewSubscriptionProvider>
            </Collapse>
          </Grid>
        )}
        <Grid item xs={12}>
          <Card>
            <SubscriptionsTable
              controls={
                massActions
                  ? { selectionModel: selectedSubscriptions, setFce: setSelectedSubscriptions }
                  : undefined
              }
              reloadData={pullCurrentSubscriptions}
              subscriptions={currentSubscriptions}
            />
          </Card>
        </Grid>
      </Grid>
      <RightDrawer isOpened={settingActualPayment} handleClose={handleStopSettingActualPayment}>
        <Stack direction="column" spacing={2} sx={{ m: 3 }}>
          <Typography variant="h5">
            Nastaviť aktuálnu platbu pre {selectedSubscriptions.length} predplatných
          </Typography>
          <TextField
            type="number"
            label="Cena"
            value={newActualPayment}
            onChange={(e) => setNewActualPayment(Number(e.target.value))}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <Stack direction="row-reverse">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setCurrentPaymentForSelected(newActualPayment)}
            >
              Potvrdiť
            </Button>
          </Stack>
        </Stack>
      </RightDrawer>
      <Modal
        open={!!emailPreviewData}
        onClose={() => setEmailPreviewData(undefined)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          position: 'absolute',
          // top: '10%',
          left: '10%',
          overflow: 'scroll',
          height: '100%',
          display: 'block',
          // pt: 100,
          zIndex: 9999999999999,
        }}
      >
        <Card sx={{ ...modalStyle, width: '80vw' }}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <EmailOptionsForm
                requiredFields={['from', 'to', 'text', 'subject', 'replyTo']}
                initialValues={emailPreviewData?.email.options}
                autoSubmit={false}
                onConfirm={async (d) => {
                  const toSend: IEmailPreviewData = {
                    data: emailPreviewData.data,
                    email: { options: d },
                  };
                  console.log(toSend);
                  const res = await sendSubscriptionsEmails(toSend);
                  setEmailPreviewData(undefined);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <DataGrid
                rows={emailPreviewData?.data.map((d, c) => ({ ...d, id: `cprw-${c}` }))}
                columns={[
                  { field: 'recipient', flex: 2 },
                  { field: 'currentPrice', flex: 1 },
                ]}
              />
            </Grid>
          </Grid>
        </Card>
      </Modal>
    </>
  );
};

const SubscriptionType: FC = () => {
  const { id } = useParams();

  return (
    <SubscriptionsProvider id={id}>
      <SubscriptionTypeWrapped />
    </SubscriptionsProvider>
  );
};

export default SubscriptionType;
