import { Card, CardHeader, CardContent, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { CompletePaymentsSourceFile } from '../../ctx/PaymentsSourceFile.Context';
import { fEur } from '../../utils/formatNumber';
import { fDate } from '../../utils/formatTime';
import { PaymentsSourceFileAvatar } from '../../utils/stringAvatar';

const PaymentSourceFileInfoCard: FC<CompletePaymentsSourceFile> = (sourceFileData) => {
  return (
    <Card>
      <CardHeader
        title={sourceFileData.name}
        avatar={<PaymentsSourceFileAvatar psf={sourceFileData} />}
      />
      <CardContent>
        {sourceFileData && (
          <Stack direction="column">
            <Typography>
              {fDate(sourceFileData.from)} - {fDate(sourceFileData.to)}
            </Typography>
            <Typography>
              Prijmy: {sourceFileData.crdtCount} ({fEur(sourceFileData.crdtSum)})
            </Typography>
            <Typography>
              Výdaje: {sourceFileData.dbitCount} ({fEur(sourceFileData.dbitSum)})
            </Typography>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};

export default PaymentSourceFileInfoCard;
