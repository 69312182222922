import { FC } from 'react';
import { DataGrid, GridColDef, GridRowId } from '@mui/x-data-grid';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { AddCircle, Info, RemoveCircle } from '@mui/icons-material';
import { fEur } from '../../utils/formatNumber';
import {
  MismatchedSumClaim,
  PaymentClaim,
  PaymentSubscription,
  usePaymentsSourceFile,
} from '../../ctx/PaymentsSourceFile.Context';
import ClaimPoper from '../claim/ClaimPoper';
import SubscriptionPoper from '../subscription/SubscriptionPoper';
import { ContactNameColumnRender } from '../contacts/ContactsTable';
import { CompleteSubscription } from '../../ctx/subscriptions/Subscriptions.Context';
import { CompleteClaim } from '../../ctx/Claims.Context';

const PairerUnpairer: FC<{
  pair: PaymentClaim | PaymentSubscription;
}> = ({ pair }) => {
  const { deleteMatch, applyPotential, applyPotentialSubscription, deleteSubscription } =
    usePaymentsSourceFile();
  // const asset = type === 'potentials' ? pair.potentialClaim : pair.claim;
  const claim = pair.claim || (pair as PaymentClaim).potentialClaim;
  const mismatchedSumClaim = (pair as MismatchedSumClaim).claimWithSameVS;
  const pairedClaim = !!pair.claim;
  const subscription = pair.subscription || (pair as PaymentSubscription).potentialSubscription;
  const pairedSubscription = !!pair.subscription;

  return (
    <>
      {mismatchedSumClaim && (
        <ClaimPoper
          claimData={mismatchedSumClaim}
          rootElement={
            <Tooltip arrow title="Otvoriť pohľadávku v novom okne">
              <IconButton
                size="small"
                onClick={() => window.open(`/claims/${mismatchedSumClaim._id}`)}
              >
                <Info sx={{ color: 'info.main' }} />
              </IconButton>
            </Tooltip>
          }
        />
      )}
      {claim && (
        <>
          <Tooltip arrow title={pairedClaim ? 'Zrušiť párovanie' : 'Spárovať'}>
            <IconButton onClick={() => (pairedClaim ? deleteMatch(pair) : applyPotential(pair))}>
              {pairedClaim ? (
                <RemoveCircle sx={{ color: 'error.main' }} />
              ) : (
                <AddCircle sx={{ color: 'success.main' }} />
              )}
            </IconButton>
          </Tooltip>
          <ClaimPoper
            claimData={claim}
            rootElement={
              <Tooltip arrow title="Otvoriť pohľadávku v novom okne">
                <IconButton size="small" onClick={() => window.open(`/claims/${claim._id}`)}>
                  <Info sx={{ color: 'info.main' }} />
                </IconButton>
              </Tooltip>
            }
          />
        </>
      )}
      {subscription && (
        <>
          <Tooltip arrow title={pairedSubscription ? 'Zrušiť párovanie' : 'Spárovať'}>
            <IconButton
              onClick={() =>
                pairedSubscription ? deleteSubscription(pair) : applyPotentialSubscription(pair)
              }
            >
              {pairedSubscription ? (
                <RemoveCircle sx={{ color: 'error.main' }} />
              ) : (
                <AddCircle sx={{ color: 'success.main' }} />
              )}
            </IconButton>
          </Tooltip>
          <SubscriptionPoper
            subscriptionData={subscription}
            rootElement={
              <Tooltip arrow title="Otvoriť predplatné v novom okne">
                <IconButton
                  size="small"
                  onClick={() => window.open(`/subscriptions/${subscription._id}`)}
                >
                  <Info sx={{ color: 'info.main' }} />
                </IconButton>
              </Tooltip>
            }
          />
        </>
      )}
    </>
  );
};

const paymentClaimColumns = (): GridColDef[] => {
  const claim = (pair): CompleteClaim | undefined => {
    console.log(pair);
    return (
      pair.claim ||
      (pair as PaymentClaim).potentialClaim ||
      (pair as MismatchedSumClaim).claimWithSameVS
    );
  };
  const subscription = (pair): CompleteSubscription | undefined =>
    pair.subscription || (pair as PaymentSubscription).potentialSubscription;
  return [
    {
      field: 'payer',
      headerName: 'Prijaté od',
      flex: 1,
      renderCell: ({ row }) => (
        <Tooltip
          arrow
          title={
            <div
              style={{ whiteSpace: 'pre-line' }}
            >{`Meno: ${row.payer.name}\nIBAN: ${row.payer.iban}`}</div>
          }
        >
          <Typography variant="body2" noWrap>
            {row.payer.name}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'amount',
      headerName: 'Suma',
      flex: 1,
      align: 'right',
      renderCell: ({ row }) => (
        <Tooltip arrow title={fEur(row.amount)}>
          <Typography variant="body2" noWrap>
            {fEur(row.amount)}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'action',
      headerName: 'Akcia',
      flex: 1,
      align: 'center',
      renderCell: ({ row }) => <PairerUnpairer pair={row} />,
    },
    ...claimRows(claim, subscription),
  ];
};

const ensureFullName = (contact: any) => {
  if (contact.fullName) return contact.fullName;
  return `${contact.firstName} ${contact.lastName}`;
};

const claimRows = (
  claim: (pair: any) => CompleteClaim | undefined,
  subscription: (pair: any) => CompleteSubscription | undefined
): GridColDef[] => [
  {
    field: 'claimpayer',
    headerName: 'Pohľadávka na',
    flex: 1,
    renderCell: ({ row }) => (
      <>
        {subscription(row) && ContactNameColumnRender(subscription(row).payer)}
        {claim(row) && (
          <Tooltip arrow title={ensureFullName(claim(row).payer)}>
            <Typography variant="body2" noWrap>
              {ensureFullName(claim(row).payer)}
            </Typography>
          </Tooltip>
        )}
      </>
    ),
  },
  {
    field: 'claimamount',
    headerName: 'Suma',
    flex: 1,
    align: 'right',
    renderCell: ({ row }) => (
      <>
        {subscription(row) && (
          <Tooltip arrow title={fEur(subscription(row).subscriptionType.price)}>
            <Typography variant="body2" noWrap>
              {fEur(subscription(row).subscriptionType.price)}
            </Typography>
          </Tooltip>
        )}
        {claim(row) && (
          <Tooltip arrow title={fEur(claim(row).finalPrice)}>
            <Typography variant="body2" noWrap>
              {fEur(claim(row).finalPrice)}
            </Typography>
          </Tooltip>
        )}
      </>
    ),
  },
  {
    field: 'claimvariableSymbol',
    headerName: 'Variabilný symbol',
    flex: 1,
    renderCell: ({ row }) => (
      <>
        {subscription(row) && (
          <Tooltip arrow title={subscription(row).variableSymbol}>
            <Typography variant="body2" noWrap>
              {subscription(row).variableSymbol}
            </Typography>
          </Tooltip>
        )}
        {claim(row) && (
          <Tooltip arrow title={claim(row).invoice._id}>
            <Typography variant="body2" noWrap>
              {claim(row).invoice._id}
            </Typography>
          </Tooltip>
        )}
      </>
    ),
  },
];

type PaymentsClaimsTableRow = (PaymentClaim | PaymentSubscription) & { id: GridRowId };

interface IPaymentsClaimsTable {
  paymentsClaims: Array<PaymentsClaimsTableRow>;
}

export const PaymentsClaimsTable: FC<IPaymentsClaimsTable> = ({ paymentsClaims }) => {
  return <DataGrid hideFooter autoHeight columns={paymentClaimColumns()} rows={paymentsClaims} />;
};
