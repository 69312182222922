import { Typography, Grid } from '@mui/material';
import { FC } from 'react';
import { BasicContactInfo } from '../../ctx/Claims.Context';

export interface IContactBasicInfoTextLine {
  info: BasicContactInfo;
  priorityField: 'phoneNumber' | 'address' | 'email';
}

const ContactBasicInfoTextLine: FC<IContactBasicInfoTextLine> = ({ info, priorityField }) => {
  // console.log(info, priorityField, info[priorityField]);
  const pfText = priorityField === 'address' ? info.address?.city : info[priorityField];
  return (
    <Typography>
      {info ? pfText || info.email || info.phoneNumber || info.address.street || info.iban : ''}
    </Typography>
  );
};

export default ContactBasicInfoTextLine;
