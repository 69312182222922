import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useApi } from '../Api';
import { CompletePaymentsSourceFile } from '../PaymentsSourceFile.Context';

interface IPaymentSourceFilesContext {
  paymentSourceFilesData?: CompletePaymentsSourceFile[];
  pullData: () => Promise<CompletePaymentsSourceFile[]>;
  removePaymentSourceFile: (paymentSourceFileId: string) => Promise<void>;
}

const PaymentSourceFilesContext = createContext<IPaymentSourceFilesContext>({
  paymentSourceFilesData: [],
  pullData: () => undefined,
  removePaymentSourceFile: () => undefined,
});

export const usePaymentSourceFiles = () => {
  const context = useContext(PaymentSourceFilesContext);
  if (!context) {
    throw new Error('Parent must be wrapped inside PaymentSourceFilesProvider');
  }

  return context;
};

interface IPaymentSourceFilesProvider {
  // tba
  id?: string;
  dataProvider?: boolean;
  initialData?: CompletePaymentsSourceFile[];
  // onConfirmSelection?: ;
}

export const PaymentSourceFilesProvider: FC<IPaymentSourceFilesProvider> = ({
  id,
  dataProvider = true,
  children,
  initialData,
}) => {
  const { API, defaultErrorHandle } = useApi();

  const [paymentSourceFilesData, setPaymentSourceFileData] = useState<CompletePaymentsSourceFile[]>(
    initialData || []
  );

  const pullData = useCallback(async (): Promise<CompletePaymentsSourceFile[]> => {
    try {
      const res = await API.get(`payments/source`);
      if (dataProvider) setPaymentSourceFileData(res.data);
      return res.data;
    } catch (e: any) {
      defaultErrorHandle(e);
      return undefined;
    }
  }, [API, dataProvider, defaultErrorHandle]);
  useEffect(() => {
    if (dataProvider) pullData();
  }, [dataProvider, pullData]);

  const removePaymentSourceFile = useCallback(
    async (paymentSourceFileId: string) => {
      try {
        const res = await API.delete(`payments/source/${paymentSourceFileId}`);
        console.log(res);
        pullData();
      } catch (e: any) {
        defaultErrorHandle(e);
      }
    },
    [API, defaultErrorHandle, pullData]
  );

  const contextObjects = useMemo(
    () => ({
      paymentSourceFilesData,
      pullData,
      removePaymentSourceFile,
    }),
    [pullData, removePaymentSourceFile, paymentSourceFilesData]
  );

  return (
    <PaymentSourceFilesContext.Provider value={contextObjects}>
      {children}
    </PaymentSourceFilesContext.Provider>
  );
};
