// ----------------------------------------------------------------------

export default function DataGrid(theme) {
  return {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 0,
        },
        main: {
          border: 0,
        },
        cell: {
          '&:focus': { outline: 0 },
          '&:focus-within': { outline: 0 },
        },
        // row: {
        //   selected: { backgroundColor: '#000000' }
        // }
      },
    },
  };
}
