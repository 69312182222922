import { FC, useState } from 'react';
import { Box, Button, Card, Grid, IconButton, Stack, Typography } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import { Close } from '@mui/icons-material';
import { useListCampaigns } from '../ctx/listCampaigns/ListCampaigns.Context';
import RightDrawer from '../components/RightDrawer';
import ListCampaignForm from '../components/listCampaigns/forms/ListCampaignForm';
import { ListCampaignsTable } from '../components/listCampaigns/ListCampaignTable';

const ListCampaigns: FC = () => {
  const { listcampaignsData, pullData, createListCampaign } = useListCampaigns();

  const [addingListCampaign, setAddingListCampaign] = useState<boolean>(false);
  const handleStartAddingListCampaign = () => setAddingListCampaign(true);
  const handleStopAddingListCampaign = () => setAddingListCampaign(false);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} spacing={2} container>
          <Grid item>
            <Typography variant="h4">Fyzické listy</Typography>
          </Grid>
          <Grid item xs />
          <Grid item>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              disabled={addingListCampaign}
              onClick={handleStartAddingListCampaign}
            >
              Nová kampaň
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <ListCampaignsTable
              reloadData={pullData}
              listcampaigns={listcampaignsData}
              // actions={[{ element: ListCampaignRedirecter }]}
            />
          </Card>
        </Grid>
      </Grid>
      <RightDrawer isOpened={addingListCampaign} handleClose={handleStopAddingListCampaign}>
        <Stack sx={{ m: 3 }} spacing={3}>
          <Box>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Vytvoriť Kampaň</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleStopAddingListCampaign}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <ListCampaignForm
            onConfirm={createListCampaign}
            autoSubmit={false}
            requiredFields={['caption']}
          />
        </Stack>
      </RightDrawer>
    </>
  );
};

export default ListCampaigns;
