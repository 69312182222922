import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { cloneElement, FC, ReactElement, useState } from 'react';
import { useClaim } from '../../ctx/Claim.Context';
import { ClaimStateEnum } from '../../ctx/Claims.Context';
import RightDrawer from '../RightDrawer';

interface IClaimStateForm {
  handleClose: () => void;
  afterSubmit?: () => void;
}

const ClaimStateForm: FC<IClaimStateForm> = ({ handleClose, afterSubmit }) => {
  const { claimData, setPending, setPaid, setCancelled } = useClaim();

  const [claimState, setClaimState] = useState<ClaimStateEnum>();
  //   console.log(claimData);
  const [note, setNote] = useState<string>();

  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);
  };

  const handleStateSelectChange = (event: SelectChangeEvent) => {
    setClaimState(event.target.value as ClaimStateEnum);
  };

  const handleSubmit = async () => {
    switch (claimState) {
      case ClaimStateEnum.pending:
        await setPending(note);
        break;
      case ClaimStateEnum.paid:
        await setPaid(note);
        break;
      case ClaimStateEnum.cancelled:
        await setCancelled(note);
        break;
      default:
        return;
    }
    console.log(afterSubmit);
    if (afterSubmit) afterSubmit();
    handleClose();
  };

  return (
    <Stack spacing={3} sx={{ m: 3 }} direction="column">
      <Typography>Zmeniť stav pohľadávky {claimData.number}</Typography>
      <FormControl fullWidth>
        <InputLabel>Nový stav</InputLabel>
        <Select value={claimState || ''} label="Nový stav" onChange={handleStateSelectChange}>
          <MenuItem
            disabled={claimData?.state.title === ClaimStateEnum.pending}
            value={ClaimStateEnum.pending}
          >
            Čakajúca
          </MenuItem>
          <MenuItem
            disabled={claimData?.state.title === ClaimStateEnum.paid}
            value={ClaimStateEnum.paid}
          >
            Zaplatená
          </MenuItem>
          <MenuItem
            disabled={claimData?.state.title === ClaimStateEnum.cancelled}
            value={ClaimStateEnum.cancelled}
          >
            Zrušená
          </MenuItem>
        </Select>
      </FormControl>
      <TextField label="Poznámka" multiline minRows={4} value={note} onChange={handleNoteChange} />
      <Stack direction="row-reverse" spacing={1}>
        <Button
          onClick={handleSubmit}
          disabled={!claimState}
          color="secondary"
          variant="contained"
          startIcon={<SaveIcon />}
        >
          Uložiť
        </Button>
        <Button onClick={handleClose} variant="outlined" startIcon={<CancelIcon />}>
          Zrušiť
        </Button>
      </Stack>
    </Stack>
  );
};

interface IClaimStateChanger {
  button: ReactElement;
  afterChange?: () => void;
}
const ClaimStateChanger: FC<IClaimStateChanger> = ({ button, afterChange }) => {
  const [opened, setOpened] = useState<boolean>(false);
  const handleOpen = () => setOpened(true);
  const handleClose = () => setOpened(false);

  return (
    <>
      <Tooltip title="Zmeniť stav">{cloneElement(button, { onClick: handleOpen })}</Tooltip>
      <RightDrawer isOpened={opened} handleClose={handleClose}>
        <ClaimStateForm handleClose={handleClose} afterSubmit={afterChange} />
      </RightDrawer>
    </>
  );
};

export default ClaimStateChanger;
