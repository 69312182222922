import {
  Add,
  CheckBox,
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
  Close,
  Delete,
} from '@mui/icons-material';
import { Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import RightDrawer from '../components/RightDrawer';
import TagForm from '../components/tag/TagForm';
import TagsGrid from '../components/tag/TagsGrid';
import { useTags } from '../ctx/tags/Tags.Context';

const Tags: FC = () => {
  const { selected, createTag, selectAll, removeSelectedOrSelectedNotAll, isAllSelected } =
    useTags();

  const [addingTag, setAddingTag] = useState<boolean>(false);
  const handleStartAddingTag = () => setAddingTag(true);
  const handleStopAddingTag = () => setAddingTag(false);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} container>
          <Grid item>
            <Typography variant="h5">Tagy</Typography>
          </Grid>
          <Grid item xs />
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Add />}
              onClick={handleStartAddingTag}
            >
              Vytvoriť tag
            </Button>
          </Grid>
        </Grid>
        {/* <Grid item xs={12} container spacing={1}>
          <Grid item>
            <Button
              variant="outlined"
              startIcon={<CheckBox />}
              onClick={selectAll}
              disabled={isAllSelected}
            >
              Označiť všetky
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              startIcon={<CheckBoxOutlineBlank />}
              onClick={removeSelectedOrSelectedNotAll}
              disabled={selected.length === 0}
            >
              Odznačiť všetky
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              startIcon={<Delete />}
              onClick={removeSelectedOrSelectedNotAll}
              disabled={selected.length === 0}
            >
              Odstrániť {selected.length > 0 && selected.length}
            </Button>
          </Grid>
        </Grid> */}
        <Grid item xs={12} container>
          <TagsGrid editingTags onlyView defaultExpanded toggleButton />
        </Grid>
      </Grid>
      <RightDrawer isOpened={addingTag} handleClose={handleStopAddingTag}>
        <Stack sx={{ m: 3 }} spacing={3}>
          <Box>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Vytvoriť tag</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleStopAddingTag}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <TagForm onConfirm={createTag} autoSubmit={false} requiredFields={['caption']} />
        </Stack>
      </RightDrawer>
    </>
  );
};

export default Tags;
