import { Button, Card, CardContent, Chip, Grid, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { ArrowBack, Done, Edit, Label, LabelOutlined, RemoveCircle } from '@mui/icons-material';
import { ToImportContact, useImportContacts } from '../../../ctx/contacts/ImportContacts.Context';
import { ContactsTable } from '../ContactsTable';
import { ContactTypes } from '../../../ctx/contacts/NewContact.Context';
import ContactIconButtonAction from '../../contact/actions/ContactIconButtonAction';
import { IImportContactsStep } from '../../../pages/ImportContacts';
import { BasicContactInfo, Company, Individual } from '../../../ctx/Claims.Context';
import RightDrawer from '../../RightDrawer';
import BasicContactInfoForm from '../forms/BasicContactInfoForm';
import IndividualInfoForm from '../forms/IndividualInfo';
import CompanyInfoForm from '../forms/CompanyInfoForm';

const CheckContactsStep: FC<IImportContactsStep> = ({ next, back }) => {
  const {
    contacts,
    typeOfContacts,
    checkForDuplicities,
    removeContact,
    updateContact,
    fileName,
    toImportTags,
  } = useImportContacts();

  const [individualInEdit, setIndividualInEdit] = useState<Individual>();
  const [companyInEdit, setCompanyInEdit] = useState<Company>();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const actions = [
    {
      element: ContactIconButtonAction,
      props: {
        icon: <Edit />,
        action: (contactData) => {
          if (typeOfContacts === ContactTypes.Individual) setIndividualInEdit({ ...contactData });
          if (typeOfContacts === ContactTypes.Company) setCompanyInEdit({ ...contactData });
          setIsEditing(true);
        },
        tooltip: 'Editovať',
      },
    },
    {
      element: ContactIconButtonAction,
      props: {
        icon: <RemoveCircle />,
        action: (contactData) => {
          console.log('removing', contactData);
          removeContact(contactData.idx);
        },
        tooltip: 'Odstrániť',
      },
    },
  ];

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs>
              <Typography variant="h5">{fileName}</Typography>
            </Grid>

            <Grid item>
              <Button
                startIcon={<ArrowBack />}
                variant="outlined"
                onClick={() => {
                  if (back) back();
                }}
              >
                Nahrať iný súbor
              </Button>
            </Grid>
            <Grid item>
              <Button
                startIcon={<Done />}
                variant="contained"
                color="secondary"
                onClick={() => {
                  checkForDuplicities();
                  next();
                }}
              >
                Skontrolovať duplicity
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <Typography variant="h6">Nájdené tagy</Typography>
                <Chip avatar={<Label />} label="Existujúce" />
                <Chip avatar={<LabelOutlined />} label="Nové" />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                {toImportTags.map((t, i) => (
                  <Chip
                    avatar={t.inDatabase ? <Label /> : <LabelOutlined />}
                    key={`to-import-tag-${i}`}
                    label={t.label}
                  />
                ))}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              {typeOfContacts === ContactTypes.Individual && (
                <ContactsTable
                  contacts={contacts}
                  actions={actions}
                  customCols={[
                    {
                      headerName: 'Tagy',
                      field: 'tag',
                      flex: 2,
                      renderCell: ({ row }: { row: ToImportContact }) => (
                        <Stack direction="row" spacing={2}>
                          {row.toaddtags?.map((t, i) => {
                            const avatar = toImportTags.find((tit) => tit.label === t)
                              ?.inDatabase ? (
                              <Label />
                            ) : (
                              <LabelOutlined />
                            );
                            return (
                              <Chip avatar={avatar} key={`${row.fullName}-tag-${i}`} label={t} />
                            );
                          })}
                        </Stack>
                      ),
                    },
                    {
                      headerName: 'Kontakt. os.',
                      field: 'contactPersons',
                      flex: 2,
                      // renderCell: ({ row }: { row: ToImportContact }) => (
                      //   <Stack direction="row" spacing={2}>
                      //     {row.toaddtags.map((t, i) => {
                      //       const avatar = toImportTags.find((tit) => tit.label === t)
                      //         ?.inDatabase ? (
                      //         <Label />
                      //       ) : (
                      //         <LabelOutlined />
                      //       );
                      //       return (
                      //         <Chip avatar={avatar} key={`${row.fullName}-tag-${i}`} label={t} />
                      //       );
                      //     })}
                      //   </Stack>
                      // ),
                    },
                  ]}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <RightDrawer isOpened={isEditing} handleClose={() => setIsEditing(false)}>
        <Stack direction="column" spacing={2} sx={{ m: 3 }}>
          {typeOfContacts === ContactTypes.Individual ? (
            <IndividualInfoForm
              initialValues={individualInEdit}
              onConfirm={(d) => {
                console.log({ ...individualInEdit, ...d });
                setIndividualInEdit((i) => ({ ...i, ...d }));
              }}
            />
          ) : (
            <CompanyInfoForm
              initialValues={companyInEdit}
              onConfirm={(d) => {
                setCompanyInEdit((i) => ({ ...i, ...d }));
              }}
            />
          )}
          <BasicContactInfoForm
            initialValues={
              typeOfContacts === ContactTypes.Individual
                ? (individualInEdit as BasicContactInfo)
                : (companyInEdit as BasicContactInfo)
            }
            onConfirm={(d) => {
              if (typeOfContacts === ContactTypes.Individual)
                setIndividualInEdit((i) => ({ ...i, ...d }));
              else if (typeOfContacts === ContactTypes.Company)
                setCompanyInEdit((i) => ({ ...i, ...d }));
            }}
          />
          <Stack direction="row-reverse">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                const contactData: any =
                  typeOfContacts === ContactTypes.Individual ? individualInEdit : companyInEdit;
                console.log(contactData);
                updateContact(contactData.idx, contactData);
                setIsEditing(false);
              }}
            >
              Potvrdiť
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setIsEditing(false);
              }}
            >
              Zrušiť
            </Button>
          </Stack>
        </Stack>
      </RightDrawer>
    </>
  );
};

export default CheckContactsStep;
