import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CompleteContact } from '../../components/contact/IndividualBusinessCard';
import { useFuse } from '../../hooks/useFuse';
import { useApi } from '../Api';

export interface WithFuzzyContext {
  hits;
  query;
  onSearch;
  onClearQuery;
}

interface IContactsContext extends WithFuzzyContext {
  currentContacts: CompleteContact[];
  currentContactsFuzzy: CompleteContact[];
  currentContactsFuzzyWithoutOmited: CompleteContact[];
  isLoading: boolean;
  pulAllContacts: (noSetCurrentContacts?: boolean) => Promise<CompleteContact[]>;
  pullSelectedContacts: (noSetCurrentContacts?: boolean) => Promise<CompleteContact[]>;
  pullFuzzyContacts(query: string): void;
  selectedContacts: string[];
  setSelectedContacts: (sontacts: string[]) => void;
  deleteContacts: (sontacts: string[]) => void;
  setCurrentContacts: (contacts: CompleteContact[]) => void;
}

const ContactsContext = createContext<IContactsContext>({
  currentContacts: [],
  currentContactsFuzzy: [],
  currentContactsFuzzyWithoutOmited: [],
  isLoading: false,
  pulAllContacts: () => undefined,
  pullSelectedContacts: () => undefined,
  pullFuzzyContacts: () => undefined,
  setCurrentContacts: () => undefined,
  selectedContacts: [],
  setSelectedContacts: () => undefined,
  deleteContacts: () => undefined,
  hits: undefined,
  query: undefined,
  onSearch: undefined,
  onClearQuery: undefined,
});

export const useContacts = () => {
  const context = useContext(ContactsContext);
  if (!context) {
    throw new Error('Parent must be wrapped inside ContactsProvider');
  }
  return context;
};

interface IContactsProvider {
  initialSelected?: string[];
  omit?: string[];
  autoPull?: boolean;
}

export const ContactsProvider: FC<IContactsProvider> = ({
  initialSelected = [],
  autoPull = false,
  children,
  omit = [],
}) => {
  const { API, defaultErrorHandle } = useApi();

  const [currentContacts, setCurrentContacts] = useState<CompleteContact[]>([]);
  const [currentContactsFuzzy, setCurrentContactsFuzzy] = useState<CompleteContact[]>([]);
  const currentContactsFuzzyWithoutOmited = currentContactsFuzzy.filter(
    (cc) => !omit.includes(cc._id)
  );

  const [selectedContacts, setSelectedContacts] = useState<string[]>(initialSelected);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { hits, query, onSearch, onClearQuery } = useFuse(currentContacts, {
    keys: ['fullName', 'companyName'],
    includeMatches: true,
    matchAllOnEmptyQuery: true,
  });

  const pulAllContacts = useCallback(
    async (noSetCurrentContacts = false) => {
      if (!API) return [];
      if (noSetCurrentContacts !== true) setIsLoading(true);
      try {
        const res = await API.get(`contacts`);
        console.log(res.data);
        if (noSetCurrentContacts !== true) {
          setCurrentContacts(res.data);
          setIsLoading(false);
        }
        return res.data;
      } catch (e) {
        setIsLoading(false);
        defaultErrorHandle(e);
      }
      return [];
    },
    [API, defaultErrorHandle]
  );

  const pullSelectedContacts = useCallback(
    async (noSetCurrentContacts = false) => {
      if (!API) return [];
      if (noSetCurrentContacts !== true) setIsLoading(true);

      try {
        const res = await API.post(`contacts/getByIds`, { ids: selectedContacts });

        if (noSetCurrentContacts !== true) {
          setCurrentContacts(res.data);
          setIsLoading(false);
        }
        return res.data;
      } catch (e) {
        setIsLoading(false);
        defaultErrorHandle(e);
      }
      return [];
    },
    [API, defaultErrorHandle, selectedContacts]
  );

  const pullFuzzyContacts = useCallback(
    async (query: string) => {
      if (!API) return;
      setIsLoading(true);
      const res = await API.get(`/contacts/fuzzy`, { params: { query } });
      console.log(res);
      setCurrentContactsFuzzy(res.data);
    },
    [API]
  );

  const deleteContacts = useCallback(
    async (ids: string[]) => {
      if (!API) return;
      try {
        const res = await Promise.all(
          ids.map(async (id) => {
            const res = await API.delete(`contacts/${id}`);
            return res.data;
          })
        );
        setSelectedContacts((p) => p.filter((c) => !ids.includes(c)));
        setCurrentContacts((p) => p.filter((c) => !ids.includes(c._id)));

        console.log(res);
      } catch (e) {
        setIsLoading(false);
        defaultErrorHandle(e);
      }
    },
    [API, defaultErrorHandle]
  );

  useEffect(() => {
    if (autoPull) pulAllContacts();
  }, [autoPull, pulAllContacts]);

  const contextObjects = useMemo(
    () => ({
      currentContacts,
      currentContactsFuzzy,
      currentContactsFuzzyWithoutOmited,
      isLoading,
      pulAllContacts,
      pullFuzzyContacts,
      selectedContacts,
      setSelectedContacts,
      hits,
      query,
      onSearch,
      onClearQuery,
      pullSelectedContacts,
      setCurrentContacts,
      deleteContacts,
    }),
    [
      currentContacts,
      currentContactsFuzzy,
      currentContactsFuzzyWithoutOmited,
      isLoading,
      pulAllContacts,
      pullFuzzyContacts,
      selectedContacts,
      hits,
      query,
      onSearch,
      onClearQuery,
      pullSelectedContacts,
      deleteContacts,
    ]
  );

  return <ContactsContext.Provider value={contextObjects}>{children}</ContactsContext.Provider>;
};
