import { FC } from 'react';
import { Grid, Typography } from '@mui/material';

interface IAndOrSelector {
  visible: boolean;
  value: boolean;
  valueSetter: (and: boolean) => void;
}

const AndOrSelector: FC<IAndOrSelector> = ({ visible, value, valueSetter }) =>
  visible ? (
    <Grid item xs={12}>
      <Grid
        container
        spacing={2}
        direction={value ? 'row' : 'row-reverse'}
        justifyContent={value ? 'flex-start' : 'flex-end'}
      >
        <Grid item>
          <Typography
            onClick={() => valueSetter(true)}
            variant="caption"
            sx={{ fontWeight: value ? 'bold' : '', cursor: 'pointer' }}
          >
            a zároveň
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            onClick={() => valueSetter(false)}
            variant="caption"
            sx={{ fontWeight: !value ? 'bold' : '', cursor: 'pointer' }}
          >
            alebo
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  ) : null;

export default AndOrSelector;
