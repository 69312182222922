import { FC, useEffect } from 'react';
import { Button, Card, CardContent, Grid, Stack, Tooltip, Typography } from '@mui/material';

import { ContactFiltersProvider } from '../../ctx/contacts/ContactFilters.Context';
import { ContactsProvider, useContacts } from '../../ctx/contacts/Contacts.Context';
import { MySearchField } from '../MySearchField';
import { ContactFilters, ContcatFiltersDisplaySelected } from './ContactFilters';
import { ContactsTable, ContactTableActions } from './ContactsTable';
import OutlinedSection from '../OutlinedSection';
import { CompleteContact } from '../contact/IndividualBusinessCard';

interface IAddContactsSelector {
  onConfirm: (contactsIds: string[]) => void;
  onConfirmFull?: (contacts: CompleteContact[]) => void;
  autoConfirm: boolean;
  initialSelected?: string[];
  selectionEnabled?: boolean;
  contactTableActions?: ContactTableActions;
}

const AddContactsSelectorWrapped: FC<IAddContactsSelector> = ({
  onConfirm,
  onConfirmFull,
  autoConfirm,
  initialSelected = [],
  selectionEnabled = true,
  contactTableActions = [],
}) => {
  const {
    currentContacts,
    pulAllContacts,
    selectedContacts,
    setSelectedContacts,
    onSearch,
    hits,
    query,
    onClearQuery,
    pullSelectedContacts,
  } = useContacts();

  const handleConfirm = async () => {
    if (onConfirm) onConfirm(selectedContacts);
    if (onConfirmFull) onConfirmFull(await pullSelectedContacts(true));
  };
  // const handleConfirmFull = () => onConfirmFull(selectedContacts);

  useEffect(() => {
    if (autoConfirm && onConfirm) onConfirm(selectedContacts);
  }, [autoConfirm, onConfirm, selectedContacts]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} container spacing={3} justifyContent="space-between">
        <Grid item>
          <ContactFilters />
        </Grid>
        <Grid item>
          <Grid item>
            <OutlinedSection
              label={
                <Tooltip title="Nie všetky označené kontakty musia byť viditeľné v tabulke nižšie. Tabuľka zobrazuje výber kontaktov na základe zvolených filtrov.">
                  <Typography variant="caption">
                    Označených kontaktov: {selectedContacts.length}
                  </Typography>
                </Tooltip>
              }
            >
              <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => pullSelectedContacts()}
                    disabled={selectedContacts.length === 0}
                  >
                    Zobraziť
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => setSelectedContacts([])}
                    disabled={selectedContacts.length === 0}
                  >
                    Odznačiť
                  </Button>
                </Grid>
                <Grid item>
                  {!autoConfirm && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleConfirm}
                      disabled={
                        selectedContacts.length === initialSelected.length &&
                        !selectedContacts.some((c) => !initialSelected.includes(c))
                      }
                    >
                      Potvrdiť
                    </Button>
                  )}
                </Grid>
              </Grid>
            </OutlinedSection>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4} container spacing={3} alignContent="flex-start">
        <Grid item xs={12}>
          <ContcatFiltersDisplaySelected />
        </Grid>
      </Grid>
      <Grid item xs={8} container spacing={3} justifyContent="flex-end">
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <MySearchField setQuery={onSearch} clearQuery={onClearQuery} />
                </Grid>
              </Grid>
            </CardContent>
            <ContactsTable
              controls={
                selectionEnabled
                  ? { selectionModel: selectedContacts, setFce: setSelectedContacts }
                  : undefined
              }
              reloadData={pulAllContacts}
              contacts={!query ? currentContacts : hits.map((h) => ({ ...h.item, hit: h }))}
              actions={contactTableActions}
            />
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

const AddContactsSelector: FC<IAddContactsSelector> = (props) => (
  <ContactsProvider initialSelected={props.initialSelected}>
    <ContactFiltersProvider>
      <AddContactsSelectorWrapped {...props} />
    </ContactFiltersProvider>
  </ContactsProvider>
);

export default AddContactsSelector;
