import { Typography, Grid } from '@mui/material';
import { FC } from 'react';
import { Email } from '../../ctx/subscriptions/Subscription.Context';

interface IEmailBasicInfoCard {
  basicEmailInfoData: Email;
}

const EmailBasicInfoCard: FC<IEmailBasicInfoCard> = ({ basicEmailInfoData }) => {
  return (
    <Grid container>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={4}>
          <Typography>From:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>{basicEmailInfoData.options.from}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={4}>
          <Typography>To:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>{basicEmailInfoData.options.to}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={4}>
          <Typography>Reply to:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>{basicEmailInfoData.options.replyTo}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={4}>
          <Typography>Subject:</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>{basicEmailInfoData.options.subject}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ m: 2 }} />
      <Grid item xs={12}>
        <Typography sx={{ whiteSpace: 'pre-line' }}>{basicEmailInfoData.options.text}</Typography>
      </Grid>
    </Grid>
  );
};

export default EmailBasicInfoCard;
