import { CallSplit, Delete, Splitscreen, SubdirectoryArrowLeft } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import OutlinedSection from '../components/OutlinedSection';
import PaymentSplitter, { CPaymentWParts } from '../components/payments/PaymentSplitter';
import { PaymentsTable } from '../components/payments/PaymentsTable';
import PSFMatchMaker from '../components/payments/PSFMatchMaket';
import RightDrawer from '../components/RightDrawer';
import {
  PaymentsSourceFileProvider,
  usePaymentsSourceFile,
} from '../ctx/PaymentsSourceFile.Context';
import { fEur } from '../utils/formatNumber';
import { fDate } from '../utils/formatTime';
import { PaymentsSourceFileAvatar } from '../utils/stringAvatar';

const PaymentsSourceFileInfo: FC = () => {
  const { sourceFileData, payments, getPayment, splitPayment } = usePaymentsSourceFile();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [paymentToBeSplitted, setPaymentToBeSplitted] = useState<CPaymentWParts>();

  const handleStartSplittingPayment = async (id: string) => {
    setPaymentToBeSplitted(await getPayment(id));
  };
  const handleStopSplittingPayment = () => {
    setPaymentToBeSplitted(undefined);
  };

  return sourceFileData ? (
    <>
      <Grid container spacing={3}>
        <Grid item md={6} container spacing={3} alignContent="flex-start">
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={sourceFileData.name}
                avatar={<PaymentsSourceFileAvatar psf={sourceFileData} />}
              />
              <CardContent>
                {sourceFileData && (
                  <Stack direction="column">
                    <Typography>
                      {fDate(sourceFileData.from)} - {fDate(sourceFileData.to)}
                    </Typography>
                    <Typography>
                      Prijmy: {sourceFileData.crdtCount} ({fEur(sourceFileData.crdtSum)})
                    </Typography>
                    <Typography>
                      Výdaje: {sourceFileData.dbitCount} ({fEur(sourceFileData.dbitSum)})
                    </Typography>
                  </Stack>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <PSFMatchMaker />
          </Grid>
        </Grid>
        <Grid item md={6} container spacing={3} alignContent="flex-start">
          {/* <Grid item xs={12}> */}
          {/* <OutlinedSection label="Akcie">
              <IconButton>
                <Delete />
              </IconButton>
            </OutlinedSection>
          </Grid> */}
          <Grid item xs={12}>
            <Card>
              <PaymentsTable
                payments={payments.map((p) => ({ ...p, id: p.ntryRef }))}
                customCols={[
                  {
                    field: 'split',
                    headerName: 'Rozdeliť',
                    width: 50,
                    renderCell: ({ row }) =>
                      !row.isPart ? (
                        <IconButton onClick={() => handleStartSplittingPayment(row._id)}>
                          <CallSplit />
                        </IconButton>
                      ) : (
                        <SubdirectoryArrowLeft />
                      ),
                  },
                ]}
              />
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <RightDrawer isOpened={!!paymentToBeSplitted} handleClose={handleStopSplittingPayment}>
        <Box sx={{ m: 3 }}>
          <PaymentSplitter
            payment={paymentToBeSplitted}
            onConfirm={(id, parts) => {
              splitPayment(id, parts);
              handleStopSplittingPayment();
            }}
          />
        </Box>
      </RightDrawer>
    </>
  ) : (
    <Skeleton />
  );
};

const PaymentsSourceFile: FC = () => {
  const { id } = useParams();

  return (
    <PaymentsSourceFileProvider id={id}>
      <PaymentsSourceFileInfo />
    </PaymentsSourceFileProvider>
  );
};

export default PaymentsSourceFile;
