import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Papa from 'papaparse';
import { readFile } from '../../utils/fileProcessor';
import { useApi } from '../Api';
import { Company, Individual, Gender } from '../Claims.Context';
import { ContactTypes } from './NewContact.Context';
import { useFeedback } from '../Feedback';
import { isCompany, isIndividual } from './Contact.Context';
import { CompleteContact } from '../../components/contact/IndividualBusinessCard';
import { CompleteTag } from '../tags/Tags.Context';

type IHeaderMappingOptions = Array<{ databaseField: string; supportedFields: Array<string> }>;

export const tableHeaderMappingIndividual: IHeaderMappingOptions = [
  { databaseField: 'firstName', supportedFields: ['meno', 'firstname', 'krstnemeno'] },
  { databaseField: 'lastName', supportedFields: ['priezvisko', 'lastname'] },
  { databaseField: 'gender', supportedFields: ['pohlavie', 'rod', 'gender'] },
];

export const tableHeaderMappingCompany: IHeaderMappingOptions = [
  { databaseField: 'companyName', supportedFields: ['meno', 'menospolocnosti', 'companyname'] },
  { databaseField: 'ico', supportedFields: ['ico'] },
  { databaseField: 'dic', supportedFields: ['dic'] },
  {
    databaseField: 'contactPersonFirstName',
    supportedFields: ['menokontaktnejosoby', 'contactpersonfirstname'],
  },
  {
    databaseField: 'contactPersonLastName',
    supportedFields: ['priezviskokontaktnejosoby', 'contactpersonlastname'],
  },
];

export const tableHeaderMappingBasic: IHeaderMappingOptions = [
  { databaseField: 'address.street', supportedFields: ['ulica', 'adresa', 'street'] },
  { databaseField: 'address.city', supportedFields: ['mesto', 'city'] },
  { databaseField: 'address.zip', supportedFields: ['psc', 'zip'] },
  { databaseField: 'address.state', supportedFields: ['stat', 'krajina', 'state'] },
  { databaseField: 'address.street2', supportedFields: ['ulica2', 'adresa2', 'street2'] },
  { databaseField: 'email', supportedFields: ['mail', 'email', 'mailovaadresa', 'emailovaadresa'] },
  {
    databaseField: 'phoneNumber',
    supportedFields: ['telefon', 'mobil', 'telefonnecislo', 'phonenumber'],
  },
  { databaseField: 'iban', supportedFields: ['iban', 'cislouctu', 'ucet'] },
  {
    databaseField: 'contactPersons',
    supportedFields: ['kontaktnaosoba', ' kontaktneosoby'],
  },
];

const headerOptions: IHeaderMappingOptions = [
  ...tableHeaderMappingBasic,
  ...tableHeaderMappingIndividual,
  ...tableHeaderMappingCompany,
];

const getTableHeader = (tableHeader: string, type: ContactTypes): string | undefined => {
  if (tableHeader === 'tag') return tableHeader;
  const header = headerOptions.find((o) => o.supportedFields.includes(tableHeader))?.databaseField;
  if (!header) return undefined;
  if (
    type === ContactTypes.Company &&
    tableHeaderMappingIndividual.some((o) => o.databaseField === header)
  ) {
    return undefined;
  }
  if (
    type === ContactTypes.Individual &&
    tableHeaderMappingCompany.some((o) => o.databaseField === header)
  ) {
    return undefined;
  }
  return header;
};

export type PotentialDuplicity = CompleteContact & {
  tag: boolean;
  update: boolean;
};

export type ContactWithDuplicities = ToImportContact & {
  potentialDuplicities: PotentialDuplicity[];
};

export type PlainContact = Company | Individual;

export type ToImportContact = PlainContact & { idx: number; toaddtags: string[] };
export const indexArray = (arr: any[]) => arr.map((e, i) => ({ ...e, idx: i }));

export const isToImportContact = (c: any): c is ToImportContact => 'idx' in c;
export const getToImportContactName = (c: any): string => {
  if (isCompany(c)) return c.companyName;
  if (isIndividual(c)) return `${c.firstName} ${c.lastName}`;
  return '__CHYBNÝ KONTAKT__';
};

interface IImportSummary {
  tagged: number;
  updated: number;
  created: number;
}

interface ITagToImport {
  label: string;
  inDatabase: boolean;
  tag?: CompleteTag;
}

interface IImportContactsContext {
  fileSelectedHandler: (e: any) => void;
  fileName: string;
  foundTags: string[];
  toImportTags: ITagToImport[];
  typeOfContacts: ContactTypes;
  contacts: ToImportContact[];
  setTypeOfContacts: (t: ContactTypes) => void;
  addContact: (t: ToImportContact, idx?: number) => void;
  removeContact: (idx: number) => void;
  updateContact: (idx: number, t: ToImportContact) => void;
  checkForDuplicities: () => Promise<void>;
  contactsWithDuplicities: ContactWithDuplicities[];
  importContacts: (tags: string[]) => Promise<void>;
  contactsDuplicities: ContactWithDuplicities[];
  addDuplicate: (t: ContactWithDuplicities) => void;
  removeDuplicate: (t: ContactWithDuplicities) => void;
  updateDuplicate: (t: ContactWithDuplicities) => void;
  isDuplicate: (t: ContactWithDuplicities) => ContactWithDuplicities | undefined;
  affectedContacts: CompleteContact[];
  importSummary: IImportSummary;
}

const ImportContactsContext = createContext<IImportContactsContext>({
  fileSelectedHandler: () => undefined,
  fileName: '',
  foundTags: [],
  toImportTags: [],
  typeOfContacts: ContactTypes.Individual,
  contacts: [],
  setTypeOfContacts: () => undefined,
  addContact: () => undefined,
  removeContact: () => undefined,
  updateContact: () => undefined,
  checkForDuplicities: () => undefined,
  contactsWithDuplicities: [],
  importContacts: () => undefined,
  contactsDuplicities: [],
  addDuplicate: () => undefined,
  removeDuplicate: () => undefined,
  updateDuplicate: () => undefined,
  isDuplicate: () => undefined,
  affectedContacts: [],
  importSummary: undefined,
});

export const useImportContacts = () => {
  const context = useContext(ImportContactsContext);
  if (!context) {
    throw new Error('Parent must be wrapped inside ImportContactsProvider');
  }

  return context;
};

interface IImportContactsProvider {}

export const ImportContactsProvider: FC<IImportContactsProvider> = ({ children }) => {
  const { API, defaultErrorHandle } = useApi();
  const { error } = useFeedback();

  const [fileName, setFileName] = useState<string>();
  const [fileText, setFileText] = useState<string>();

  const [foundTags, setFoundTags] = useState<string[]>([]);
  const [toImportTags, setToImportTags] = useState<ITagToImport[]>([]);

  const [typeOfContacts, setTypeOfContacts] = useState<ContactTypes>(ContactTypes.Individual);

  const [contacts, setContacts] = useState<ToImportContact[]>([]);

  const [contactsWithDuplicities, setContactsWithDuplicities] = useState<ContactWithDuplicities[]>(
    []
  );

  const [contactsDuplicities, setContactsDuplicities] = useState<ContactWithDuplicities[]>([]);

  const [affectedContacts, setAffectedContacts] = useState<CompleteContact[]>([]);
  const [importSummary, setImportSummary] = useState<IImportSummary>();

  const fileSelectedHandler = useCallback((event) => {
    readFile(event, (i) => {
      setFileName(i.name);
      setFileText(i.text);
    });
  }, []);

  const parseFileText = useCallback(() => {
    if (!fileText) return;
    const parsed = Papa.parse(fileText);
    // eslint-disable-next-line prefer-destructuring
    const data: [] = parsed.data;
    const headers: [] = data.shift();
    if (!headers) {
      error('Súbor neobsahuje záhlavie.');
      return;
    }

    const databaseHeaders = [];
    headers.forEach((h: string) => {
      const normalizedHeader = h
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '') // accents
        .replace(/\s+/g, '') // whitespaces
        .toLocaleLowerCase()
        .replace(/[^a-z0-9]/gi, ''); // not aplhanumeric
      const databaseHeader = getTableHeader(normalizedHeader, typeOfContacts);
      databaseHeaders.push(databaseHeader);
    });

    const contacts: ToImportContact[] = [];
    const toaddtags = new Set<string>();
    data.forEach((d: any) => {
      const parsedContact: any = { type: typeOfContacts, contactPersons: [] };
      databaseHeaders.forEach((h, i) => {
        if (!h) return;
        const fieldValue: string = d[i];
        fieldValue.replace(/\s\s+/g, ' ');
        if (h === 'tag') {
          const splitted = fieldValue.split(',');
          parsedContact.toaddtags = splitted.map((t) => {
            const trimmedTag = t.trim();
            toaddtags.add(trimmedTag);
            return trimmedTag;
          });
        }
        if (h === 'contactPersons') {
          parsedContact.contactPersons = fieldValue
            .split(',')
            .flatMap((cp) => (cp.trim() ? [cp.trim()] : []));
        } else {
          parsedContact[h] = fieldValue.trim();
        } // this is where data are stored
      });

      if (isIndividual(parsedContact)) {
        if (!parsedContact.gender) {
          if (
            parsedContact.lastName.endsWith('á') ||
            parsedContact.firstName.endsWith('a') ||
            parsedContact.firstName.endsWith('e')
          ) {
            parsedContact.gender = Gender.FEMALE;
          } else {
            parsedContact.gender = Gender.MALE;
          }
        }
      }
      contacts.push({ ...parsedContact, idx: contacts.length });
    });
    console.log(contacts);
    console.log(toaddtags);
    setFoundTags([...toaddtags]);
    setContacts(contacts);
  }, [error, fileText, typeOfContacts]);
  useEffect(parseFileText, [parseFileText]);

  const checkFoundTags = useCallback(async () => {
    if (!API) return;
    const res = await API.post('tags/checkIfExist', { tags: foundTags });
    setToImportTags(res.data.sort((tit) => (tit.inDatabase ? 1 : -1)));
  }, [API, foundTags]);

  useEffect(() => {
    checkFoundTags();
  }, [checkFoundTags]);

  const addContact = useCallback((contact: ToImportContact, idx: number = 0) => {
    setContacts((p) => {
      p.splice(idx, 0, contact as ToImportContact);
      return [...p];
    });
  }, []);

  const removeContact = useCallback((idx: number) => {
    setContacts((p) => {
      p.splice(idx, 1);
      return indexArray([...p]);
    });
  }, []);

  const updateContact = useCallback((idx: number, contact: ToImportContact) => {
    setContacts((p) => {
      p.splice(idx, 1, contact as ToImportContact);
      return [...p];
    });
  }, []);

  const checkForDuplicities = useCallback(async () => {
    try {
      const res = await API.post('contacts/checkBeforeImport', contacts);
      console.log('tusom', res.data);
      const withPotDup: ContactWithDuplicities[] = res.data
        .filter((d) => d.potentialDuplicities && d.potentialDuplicities.length > 0)
        .map((d) => ({
          ...d,
          potentialDuplicities: d.potentialDuplicities.map((pd) => ({
            ...pd,
            tag: true,
            update: true,
          })),
        }));
      setContactsWithDuplicities([...withPotDup]);
      setContactsDuplicities([...withPotDup]);
    } catch (e) {
      defaultErrorHandle(e);
    }
  }, [API, defaultErrorHandle, contacts]);

  const removeDuplicate = useCallback((duplicate: ContactWithDuplicities) => {
    setContactsDuplicities((p) => {
      const idxInArray = p.findIndex((id) => id.idx === duplicate.idx);
      p.splice(idxInArray, 1);
      return [...p];
    });
  }, []);

  const updateDuplicate = useCallback((duplicate: ContactWithDuplicities) => {
    setContactsDuplicities((p) => {
      const idxInArray = p.findIndex((id) => id.idx === duplicate.idx);
      p.splice(idxInArray, 1, duplicate);
      return [...p];
    });
  }, []);

  const addDuplicate = useCallback((duplicate: ContactWithDuplicities) => {
    setContactsDuplicities((p) => {
      return [...p, duplicate as ContactWithDuplicities];
    });
  }, []);

  const isDuplicate = useCallback(
    (duplicate: ToImportContact) => {
      return contactsDuplicities.find((id) => id.idx === duplicate.idx);
    },
    [contactsDuplicities]
  );

  const importContacts = useCallback(
    async (tags: string[]) => {
      try {
        // create tags;
        const importedTags: ITagToImport[] = await Promise.all(
          toImportTags.map(async (tit) => {
            if (tit.inDatabase) return tit;
            const res = await API.post('tags', { caption: tit.label });
            return { ...tit, tag: res.data };
          })
        );
        // add tags ids to contacts array // contacts with duplicities + created contacts
        // const contacts = contacts.

        const duplicitiesIdxs = [...contactsDuplicities.map((id) => id.idx)];

        const toAddContacts = [...contacts.filter((ic) => !duplicitiesIdxs.includes(ic.idx))];
        const confirmedDuplicities = [...contactsDuplicities];
        console.log(confirmedDuplicities);

        const toUpdateContactsWithDuplicities = [
          ...confirmedDuplicities.map((iwd) => ({
            ...iwd,
            potentialDuplicities: [...iwd.potentialDuplicities.filter((pd) => pd.update)],
          })),
        ];

        const toTagFromDuplicities = [
          ...confirmedDuplicities.flatMap((cd) => {
            // console.log(cd.potentialDuplicities);
            return [...cd.potentialDuplicities.filter((pd) => pd.tag).map((pd) => pd._id)];
          }),
        ];

        console.log(toUpdateContactsWithDuplicities);

        const res = await API.post(
          'contacts/many',
          toAddContacts.map((tac) => ({ ...tac, contactPersons: [] }))
        );
        const createdContacts: CompleteContact[] = res.data;

        const updatedContacts: CompleteContact[] = (
          await Promise.all(
            toUpdateContactsWithDuplicities.map(async (tucwd) => {
              const updated = await Promise.all(
                tucwd.potentialDuplicities.map(async (pd) => {
                  const res = await API.put(`contacts/${pd._id}/addData`, tucwd);
                  const updatedContact: CompleteContact = res.data;
                  return updatedContact;
                })
              );
              return updated;
            })
          )
        ).flat();

        const toTagContacts = [
          ...createdContacts.map((c: CompleteContact) => c._id),
          ...toTagFromDuplicities,
        ];

        await API.post('tags/assign', {
          toAssign: tags,
          toRemove: [],
          contacts: toTagContacts,
          companies: [],
        });

        // tag contacts with tags form file
        await Promise.all(
          importedTags.map(async (tit) => {
            const tagContacts = [
              ...contactsWithDuplicities
                .filter((c) => c.toaddtags.includes(tit.label))
                .map((c) => c.potentialDuplicities[0]._id),
              ...createdContacts.flatMap((cc) =>
                contacts.find((c) => c.email === cc.email).toaddtags.includes(tit.label)
                  ? [cc._id]
                  : []
              ),
            ];
            console.log(tit);
            console.log(tagContacts);
            await API.post('tags/assign', {
              toAssign: [tit.tag._id],
              toRemove: [],
              contacts: tagContacts, // get contacts ids that needs tags; contacts with duplicitis
              companies: [],
            });
          })
        );

        await Promise.all(
          contactsWithDuplicities.map(async (cwd) => {
            if (cwd.contactPersons.length) {
              await Promise.all(
                cwd.contactPersons.map(async (cp) => {
                  await API.put(`/contacts/${cwd.potentialDuplicities[0]._id}/addCP`, {
                    email: cp,
                  });
                })
              );
            }
          })
        );
        await Promise.all(
          createdContacts.map(async (cc) => {
            const importedData = contacts.find((c) => c.email === cc.email);
            if (importedData.contactPersons?.length) {
              await Promise.all(
                importedData.contactPersons.map(async (cp) => {
                  await API.put(`/contacts/${cc._id}/addCP`, {
                    email: cp,
                  });
                })
              );
            }
          })
        );

        const allAffectedIds = [
          ...createdContacts.map((c) => c._id),
          ...updatedContacts.map((uc) => uc._id),
          ...toTagContacts,
        ];
        setImportSummary({
          created: createdContacts.length,
          updated: updatedContacts.length,
          tagged: toTagContacts.length,
        });

        const allAffectedContacts = await API.post('contacts/getByIds', { ids: allAffectedIds });
        setAffectedContacts(allAffectedContacts.data);
      } catch (e) {
        defaultErrorHandle(e);
      }
    },
    [toImportTags, contactsDuplicities, contacts, API, contactsWithDuplicities, defaultErrorHandle]
  );

  const contextObjects = useMemo(
    () => ({
      fileSelectedHandler,
      fileName,
      typeOfContacts,
      contacts,
      toImportTags,
      foundTags,
      setTypeOfContacts,
      addContact,
      removeContact,
      updateContact,
      checkForDuplicities,
      importContacts,
      addDuplicate,
      removeDuplicate,
      updateDuplicate,
      isDuplicate,
      affectedContacts,
      importSummary,
      contactsDuplicities,
      contactsWithDuplicities,
    }),
    [
      fileSelectedHandler,
      fileName,
      typeOfContacts,
      contacts,
      toImportTags,
      foundTags,
      addContact,
      removeContact,
      updateContact,
      checkForDuplicities,
      importContacts,
      addDuplicate,
      removeDuplicate,
      updateDuplicate,
      isDuplicate,
      affectedContacts,
      importSummary,
      contactsDuplicities,
      contactsWithDuplicities,
    ]
  );

  return (
    <ImportContactsContext.Provider value={contextObjects}>
      {children}
    </ImportContactsContext.Provider>
  );
};
