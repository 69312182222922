import { Label } from '@mui/icons-material';
import { Stack, Typography, CardContent, Grid, Card } from '@mui/material';
import { FC, Fragment, useEffect } from 'react';
import { TagFilter, useContactFilters } from '../../../ctx/contacts/ContactFilters.Context';
import { OutlinedCard } from '../../OutlinedCard';
import AndOrSelector from './AndOrSelector';

const TagFilterDisplayer: FC = () => {
  const { tagFilter, setTagFilter } = useContactFilters();

  const inCategoriesIDs =
    tagFilter?.filters.flatMap(({ tag }) => [...tag.children.map((ch) => ch._id)]) || [];
  const categories =
    tagFilter?.filters.filter(({ tag }) => !inCategoriesIDs.includes(tag._id) && tag.isCategory) ||
    [];
  const simpleTags =
    tagFilter?.filters.filter(({ tag }) => !inCategoriesIDs.includes(tag._id) && !tag.isCategory) ||
    [];

  const removeFilter = (idx: number) =>
    setTagFilter((p) => {
      const filters = [...p.filters];
      filters.splice(idx, 1);
      return { ...p, filters };
    });

  const setIsAnd = (isAnd: boolean) =>
    setTagFilter((p) => {
      return { ...p, isAnd };
    });

  return tagFilter?.filters.length > 0 ? (
    <OutlinedCard>
      <Stack direction="row-reverse" sx={{ pr: 3, pt: 1, mb: -2 }}>
        <Typography color="gray" variant="caption">
          <Label fontSize="small" />
        </Typography>
      </Stack>
      <CardContent>
        <Grid container spacing={1}>
          {categories.map((c, i) => (
            <Fragment key={`selected-tag-category-${i}`}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4}>
                        <Typography color="secondary" variant="body2">
                          {`${c.value ? 'v kategórii' : 'mimo kategóriu'}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="h6">{`${c.tag.caption} `}</Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <AndOrSelector
                visible={i + 1 < categories.length || simpleTags.length > 0}
                value={tagFilter.isAnd}
                valueSetter={setIsAnd}
              />
            </Fragment>
          ))}
          {simpleTags.map((c, i) => (
            <Fragment key={`selected-simple-tag-${i}`}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4}>
                        <Typography color="secondary" variant="body2">
                          {`${c.value ? 'má tag' : 'nemá tag'}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="h6">{`${c.tag.caption} `}</Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <AndOrSelector
                visible={i + 1 < simpleTags.length}
                value={tagFilter.isAnd}
                valueSetter={setIsAnd}
              />
            </Fragment>
          ))}
        </Grid>
      </CardContent>
    </OutlinedCard>
  ) : null;
};

export default TagFilterDisplayer;
