import { Button, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useApi } from '../../../ctx/Api';
import { useTags } from '../../../ctx/tags/Tags.Context';
import TagsGrid from '../../tag/TagsGrid';

interface IAddTags {
  selectedContacts: string[];
  onSuccess?: () => void;
}

const AddTags: FC<IAddTags> = ({ selectedContacts, onSuccess }) => {
  const { selected, selectedNot } = useTags();
  const { API } = useApi();
  const handleAddTags = async () => {
    const res = await API.post('tags/assign', {
      toAssign: selected,
      toRemove: selectedNot,
      contacts: selectedContacts,
      companies: [],
    });
    console.log(res);
    if (onSuccess) onSuccess();
  };
  return (
    <Stack direction="column" spacing={3}>
      <Typography variant="h5">Pridať tag {selectedContacts.length} kontaktom</Typography>
      <Stack direction="row-reverse" spacing={3}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleAddTags}
          disabled={
            (selected.length === 0 && selectedNot.length === 0) || selectedContacts.length === 0
          }
        >
          Potvrdiť
        </Button>
      </Stack>
      <TagsGrid onlyTagSelect defaultExpanded binarySelect={false} />
    </Stack>
  );
};

export default AddTags;
