import { Button, Card, CardActions, CardContent, Stack, Typography } from '@mui/material';
import { Warning } from '@mui/icons-material';
import { FC } from 'react';
import { usePaymentsSourceFile } from '../../ctx/PaymentsSourceFile.Context';
import { PaymentsClaimsTable } from './PaymentsClaimsTable';

const PSFMatchMaker: FC = () => {
  const {
    potentialMatches,
    sourceFileData,
    applyAllPotentials,
    matches,
    deleteAllMatches,
    potentialSubscriptions,
    subscriptions,
    deleteAllSubscriptions,
    applyAllPotentialSubscriptions,
    mismatchedSumClaims,
  } = usePaymentsSourceFile();

  return (
    <>
      {mismatchedSumClaims && mismatchedSumClaims.length > 0 && (
        <Card sx={{ marginBottom: 2 }}>
          <CardContent>
            <Stack direction="row" spacing={1} alignItems="center">
              <Warning color="error" />
              <Typography>
                Súbor obsahuje {mismatchedSumClaims.length} pohľadávok, ktoré majú zhodný VS ale
                suma nesedí.
              </Typography>
            </Stack>
          </CardContent>
          <PaymentsClaimsTable
            paymentsClaims={mismatchedSumClaims.map((p, i) => ({
              ...p,
              id: `mismatchedSumClaim-${sourceFileData.name}-${i}`,
            }))}
          />
        </Card>
      )}
      <Card>
        {matches && matches.length > 0 && (
          <>
            <CardContent>
              <Typography>
                Súbor obsahuje {matches.length} platieb, ktoré pasujú k pohľadávkam a už boli
                sprárované.
              </Typography>
            </CardContent>
            <CardActions>
              <Button variant="outlined" onClick={deleteAllMatches}>
                Zrušiť všetky spárovania
              </Button>
            </CardActions>
            <PaymentsClaimsTable
              paymentsClaims={matches.map((p, i) => ({
                ...p,
                id: `paymentClaim-${sourceFileData.name}-${i}`,
              }))}
            />
          </>
        )}
        {potentialMatches && potentialMatches.length > 0 && (
          <>
            <CardContent>
              <Typography>
                Súbor obsahuje {potentialMatches.length} platieb, ktoré pasujú k pohľadávkam a
                zatiaľ neboli sprárované.
              </Typography>
            </CardContent>
            <CardActions>
              <Button variant="contained" color="secondary" onClick={applyAllPotentials}>
                Spárovať všetky
              </Button>
            </CardActions>
            <PaymentsClaimsTable
              paymentsClaims={potentialMatches.map((p, i) => ({
                ...p,
                id: `paymentClaim-${sourceFileData.name}-${i}`,
              }))}
            />
          </>
        )}
      </Card>
      <Card>
        {subscriptions && subscriptions.length > 0 && (
          <>
            <CardContent>
              <Typography>
                Súbor obsahuje {subscriptions.length} platieb, ktoré pasujú k predplatným a už boli
                sprárované.
              </Typography>
            </CardContent>
            <CardActions>
              <Button variant="outlined" onClick={deleteAllSubscriptions}>
                Zrušiť všetky spárovania
              </Button>
            </CardActions>
            <PaymentsClaimsTable
              paymentsClaims={subscriptions.map((p, i) => ({
                ...p,
                id: `paymentSubscription-${sourceFileData.name}-${i}`,
              }))}
            />
          </>
        )}
        {potentialSubscriptions && potentialSubscriptions.length > 0 && (
          <>
            <CardContent>
              <Typography>
                Súbor obsahuje {potentialSubscriptions.length} platieb, ktoré pasujú k predplatným a
                zatiaľ neboli sprárované.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                color="secondary"
                onClick={applyAllPotentialSubscriptions}
              >
                Spárovať všetky
              </Button>
            </CardActions>
            <PaymentsClaimsTable
              paymentsClaims={potentialSubscriptions.map((p, i) => ({
                ...p,
                id: `paymentSubscription-${sourceFileData.name}-${i}`,
              }))}
            />
          </>
        )}
      </Card>
    </>
  );
};

export default PSFMatchMaker;
