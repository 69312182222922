import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Save from '@mui/icons-material/Save';
import { usePaymentProcess } from '../ctx/PaymentProcess.Context';
import { PaymentsTable } from '../components/payments/PaymentsTable';
import { fDate } from '../utils/formatTime';
import { fEur } from '../utils/formatNumber';
import { PaymentsSourceFileProvider } from '../ctx/PaymentsSourceFile.Context';
import PSFMatchMaker from '../components/payments/PSFMatchMaket';

const PaymentProcess: FC = () => {
  const {
    fileSelectedHandler,
    fileParsed,
    uploadPayments,
    fileName,
    downloadFilesForGSheets,
    payments,
    isFilealreadyInDatabase,
    uploadWholeFile,
    uploadedPayments,
    sourceFileData,
    paymentsSourceFileId,
  } = usePaymentProcess();

  return (
    <Grid container spacing={3}>
      {!fileParsed && (
        <Grid item xs={12} container justifyContent="center">
          <Grid item>
            <label htmlFor="upload-payments-file">
              <input
                style={{ display: 'none' }}
                id="upload-payments-file"
                name="upload-payments-file"
                type="file"
                onChange={fileSelectedHandler}
              />

              <Button
                startIcon={<UploadFileIcon />}
                color="secondary"
                variant="contained"
                component="span"
              >
                Nahrať súbor
              </Button>
            </label>
          </Grid>
        </Grid>
      )}
      {fileParsed && (
        <>
          <Grid item md={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <CardHeader title={fileName} />
                  <CardContent>
                    {sourceFileData && (
                      <Stack direction="column">
                        <Typography>
                          {fDate(sourceFileData.from)} - {fDate(sourceFileData.to)}
                        </Typography>
                        <Typography>
                          Prijmy: {sourceFileData.crdtCount} ({fEur(sourceFileData.crdtSum)})
                        </Typography>
                        <Typography>
                          Výdaje: {sourceFileData.dbitCount} ({fEur(sourceFileData.dbitSum)})
                        </Typography>
                      </Stack>
                    )}
                  </CardContent>

                  <CardActions>
                    <Button
                      onClick={uploadWholeFile}
                      startIcon={<Save />}
                      variant="contained"
                      color="secondary"
                      disabled={isFilealreadyInDatabase}
                    >
                      Nahrať do databázy
                    </Button>
                    <Button
                      sx={{ mr: 1 }}
                      onClick={downloadFilesForGSheets}
                      startIcon={<FileDownloadIcon />}
                      variant="outlined"
                    >
                      Stiahnuť CSV
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              {isFilealreadyInDatabase && (
                <Grid item xs={12}>
                  <Card>
                    <CardContent> Tento súbor sa už v databáze nachádza. </CardContent>
                    <CardActions>
                      <Button
                        onClick={uploadPayments}
                        startIcon={<Save />}
                        variant="contained"
                        color="secondary"
                        disabled={!isFilealreadyInDatabase}
                      >
                        Znova nahrať platby zo súboru
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              )}
              {uploadedPayments && (
                <>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        Podarilo sa nahrať {uploadedPayments.length} z {payments.length} platieb.
                        Ostatné platby už v databáze boli.
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <PaymentsSourceFileProvider
                      id={paymentsSourceFileId}
                      dataProvider={false}
                      initialData={{ ...sourceFileData, _id: paymentsSourceFileId }}
                    >
                      <PSFMatchMaker />
                    </PaymentsSourceFileProvider>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item md={6} container spacing={3}>
            {payments && (
              <Grid item xs={12}>
                <Card>
                  <PaymentsTable
                    payments={payments.map((p, i) => ({ ...p, id: `payment-${fileName}-${i}` }))}
                  />
                </Card>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default PaymentProcess;
