import { Label, Receipt } from '@mui/icons-material';
import { Stack, Typography, CardContent, Grid, Card } from '@mui/material';
import { FC, Fragment, useEffect } from 'react';
import { ClaimGroupFilter, useContactFilters } from '../../../ctx/contacts/ContactFilters.Context';
import { OutlinedCard } from '../../OutlinedCard';
import AndOrSelector from './AndOrSelector';

const ClaimGroupFilterDisplayer: FC = () => {
  const { claimGroupFilter, setClaimGroupFilter } = useContactFilters();

  const setIsAnd = (isAnd: boolean) => setClaimGroupFilter({ ...claimGroupFilter, isAnd });

  return claimGroupFilter?.filters.length > 0 ? (
    <OutlinedCard>
      <Stack direction="row-reverse" sx={{ pr: 3, pt: 1, mb: -2 }}>
        <Typography color="gray" variant="caption">
          <Receipt fontSize="small" />
        </Typography>
      </Stack>
      <CardContent>
        <Grid container spacing={1}>
          {claimGroupFilter.filters.map((c, i) => (
            <Fragment key={`selected-cg-${i}`}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4}>
                        <Typography color="secondary" variant="body2">
                          {c.value === undefined && 'má'}
                          {c.value === true && 'má zaplatenú'}
                          {c.value === false && 'má nezaplatenú'}
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography variant="h6">{`${c.claimGroup?.title} `}</Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <AndOrSelector
                visible={i + 1 < claimGroupFilter.filters.length}
                value={claimGroupFilter.isAnd}
                valueSetter={setIsAnd}
              />
            </Fragment>
          ))}
        </Grid>
      </CardContent>
    </OutlinedCard>
  ) : null;
};

export default ClaimGroupFilterDisplayer;
