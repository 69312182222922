import { createContext, FC, useCallback, useContext, useMemo, useState } from 'react';
import { Snackbar, Alert, AlertColor } from '@mui/material';

interface IFeedbackContext {
  success: (msg: string) => void;
  error: (msg: string) => void;
}

const FeedbackContext = createContext<IFeedbackContext>({
  success: () => undefined,
  error: () => undefined,
});

export const useFeedback = () => {
  const context = useContext(FeedbackContext);
  if (!context) {
    throw new Error('Parent must be wrapped inside FeedbackProvider');
  }

  return context;
};

export const FeedbackProvider: FC = ({ children }) => {
  const [feedbackOpened, setFeedbackOpened] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('success');
  const [feedbackSeverity, setFeedbackSeverity] = useState<AlertColor>();

  const openFeedback = (severity: AlertColor, msg: string) => {
    setFeedbackSeverity(severity);
    setFeedbackMessage(msg);
    setFeedbackOpened(true);
  };

  const closeFeedback = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setFeedbackOpened(false);
  };

  const success = useCallback((msg: string) => {
    openFeedback('success', msg);
  }, []);
  const error = useCallback((msg: string) => {
    openFeedback('error', msg);
  }, []);

  const contextObjects = useMemo(
    () => ({
      success,
      error,
    }),
    [success, error]
  );

  return (
    <FeedbackContext.Provider value={contextObjects}>
      {children}
      <Snackbar open={feedbackOpened} autoHideDuration={6000} onClose={closeFeedback}>
        <Alert elevation={6} variant="filled" onClose={closeFeedback} severity={feedbackSeverity}>
          {feedbackMessage}
        </Alert>
      </Snackbar>
    </FeedbackContext.Provider>
  );
};
