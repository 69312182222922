import { Grid, Button } from '@mui/material';
import { FC } from 'react';
import OtpDialog from '../components/OtpDialog';
import { useApi } from '../ctx/Api';

const Dashboard: FC = () => {
  const { API } = useApi();
  return (
    <Grid container>
      <Grid item>toto je dashboard</Grid>
    </Grid>
  );
};

export default Dashboard;
