// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            // boxShadow: 'none'
          },
        },
        sizeMedium: {
          height: 40,
          borderRadius: 20,
          paddingLeft: 24,
          paddingRight: 24,
        },
        sizeLarge: {
          height: 48,
        },
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          // boxShadow: theme.customShadows.primary
          boxShadow: 'none',
        },
        containedSecondary: {
          // boxShadow: theme.customShadows.secondary
          boxShadow: 'none',
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        startIcon: {
          marginLeft: -8,
        },
      },
    },
  };
}
