import { FC } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { IconButton, Link, Stack, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fDate } from '../../utils/formatTime';
import {
  ListCampaignWithContacts,
  useListCampaign,
} from '../../ctx/listCampaigns/ListCampaign.context';
import { ListCampaignAvatar } from '../../utils/stringAvatar';
import FuseHighlight from '../../hooks/FuseHighlight';
import ListCampaignReactedProgress from './ListCampaignReactedProgress';

interface IListCampaignColumns {
  reloadData?: () => void;
  navigate: any;
  customCols?: GridColDef[];
}

const listcampaignColumns = ({
  reloadData,
  navigate,
  customCols = [],
}: IListCampaignColumns): GridColDef[] => [
  {
    field: 'caption',
    headerName: 'Názov',
    flex: 1,
    renderCell: ({ row }) => (
      <Stack direction="row" spacing={2} alignItems="center">
        <ListCampaignAvatar campaign={row} />
        <Link onClick={() => navigate(`/listcampaigns/${row._id}`)} color="inherit">
          <Typography variant="body1">
            {row.hit ? <FuseHighlight hit={row.hit} attribute="captiom" /> : row.caption}
          </Typography>
        </Link>
      </Stack>
    ),
  },
  {
    field: 'date',
    headerName: 'Dátum',
    flex: 1,
    renderCell: (params) => <>{fDate(params.row.date)}</>,
  },
  {
    field: 'description',
    headerName: 'Popis',
    flex: 1,
    renderCell: ({ row }) => <Typography noWrap>{row.description}</Typography>,
  },
  {
    field: 'contactRelations',
    headerName: 'Dostali',
    flex: 0.3,
    align: 'right',
    renderCell: ({ row }: { row: ListCampaignWithContacts }) => row.contactRelations.length,
  },
  {
    field: 'reacted',
    flex: 0.5,
    headerName: 'Reagovali',
    renderCell: ({ row }: { row: ListCampaignWithContacts }) => (
      <ListCampaignReactedProgress {...row} />
    ),
  },
  ...customCols,
];

interface IListCampaignsTable {
  listcampaigns: ListCampaignWithContacts[];
  reloadData?: () => void;
  customCols?: GridColDef[];
}

export const ListCampaignsTable: FC<IListCampaignsTable> = ({
  listcampaigns,
  reloadData,
  customCols,
}) => {
  const navigate = useNavigate();
  return (
    <DataGrid
      autoHeight
      columns={listcampaignColumns({ reloadData, navigate, customCols })}
      rows={listcampaigns.map((c) => ({ ...c, id: c._id }))}
    />
  );
};
