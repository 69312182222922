import { Card, Grid } from '@mui/material';
import { useState, FC } from 'react';
import { RequestsTable } from '../components/requests/RequestsTable';

const Requests: FC = () => {
  const [requests, setRequests] = useState([]);

  // const { API } = useApi();

  const loadRequests = () => {
    // API.get('contactRequests')
    //   .then(res => {
    //     console.log(res.data);
    //     setRequests(res.data);
    //   })
  };

  // useEffect(loadRequests, [API]);

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12}>
        <Card>
          <RequestsTable requests={requests} />
        </Card>
      </Grid>
    </Grid>
  );
};

export default Requests;
