import { Business, MonetizationOn, Square, TextSnippet } from '@mui/icons-material';
import { Avatar, Box } from '@mui/material';
import { alpha } from '@mui/material/styles';
import toMaterialStyle from 'material-color-hash';
import { FC } from 'react';
import { CompleteContact } from '../components/contact/IndividualBusinessCard';
import { isCompany } from '../ctx/contacts/Contact.Context';
import { CompleteListCampaign } from '../ctx/listCampaigns/ListCampaigns.Context';
import { CompletePaymentsSourceFile } from '../ctx/PaymentsSourceFile.Context';
import { CompleteStockItem } from '../ctx/stockItems/StockItems.Context';
import { CompleteSubscriptionType } from '../ctx/subscriptions/Subscriptions.Context';

export const stringToColor = (text) => toMaterialStyle(text, 300).backgroundColor;

export function stringAvatar(name) {
  // console.log(stringToColor(name));
  // console.log(alpha(stringToColor(name), 0.5));
  const fractions = name.split(' ');
  const initials =
    fractions.length > 1 ? `${fractions[0][0]}${fractions[1][0]}` : `${fractions[0][0]}`;
  return {
    sx: {
      ...toMaterialStyle(name, 300),
    },
    children: initials,
  };
}

export function colorAvatar(name) {
  return {
    sx: {
      bgcolor: alpha(stringToColor(name), 0.5),
    },
    // children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
  };
}

interface IListCampaignAvatar {
  campaign: CompleteListCampaign;
  props?: any;
}

export const ListCampaignAvatar: FC<IListCampaignAvatar> = ({ campaign, ...props }) => (
  <Avatar
    variant="rounded"
    sx={{
      ...toMaterialStyle(campaign._id, 300),
    }}
    children={
      <Box sx={{ opacity: 0.5 }} display="flex">
        <TextSnippet />
      </Box>
    }
    {...props}
  />
);

interface IContactAvatar {
  contact: CompleteContact;
  props?: any;
}

export const ContactAvatar: FC<IContactAvatar> = ({ contact, ...props }) => {
  return (
    <Avatar
      variant="rounded"
      sx={{
        ...toMaterialStyle((contact._id && `${contact._id}${contact._id}`) || 'unknown id', 300),
      }}
      children={isCompany(contact) ? <Business /> : `${contact.firstName[0]}${contact.lastName[0]}`}
      {...props}
    />
  );
};

interface IsubscriptionTypeAvatar {
  subscriptionType: CompleteSubscriptionType;
  props?: any;
}

export const SubscriptionTypeAvatar: FC<IsubscriptionTypeAvatar> = ({
  subscriptionType,
  ...props
}) => {
  return (
    <Avatar
      variant="rounded"
      sx={{
        ...toMaterialStyle(
          (subscriptionType._id && `${subscriptionType._id}${subscriptionType._id}`) ||
            'unknown id',
          300
        ),
      }}
      children={`${subscriptionType.prefix}`}
      {...props}
    />
  );
};

interface IPaymentsSourceFileAvatar {
  psf: CompletePaymentsSourceFile;
  props?: any;
}

export const PaymentsSourceFileAvatar: FC<IPaymentsSourceFileAvatar> = ({ psf, ...props }) => (
  <Avatar
    variant="rounded"
    sx={{
      ...toMaterialStyle(psf._id, 300),
    }}
    children={
      <Box sx={{ opacity: 0.5 }} display="flex">
        <MonetizationOn />
      </Box>
    }
    {...props}
  />
);

interface IStockItemAvatar {
  item: CompleteStockItem;
  props?: any;
}

export const StockItemAvatar: FC<IStockItemAvatar> = ({ item, ...props }) => (
  <Avatar
    variant="rounded"
    sx={{
      ...toMaterialStyle(item._id, 300),
    }}
    children={
      <Box sx={{ opacity: 0.5 }} display="flex">
        <Square />
      </Box>
    }
    {...props}
  />
);
